import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AiRoutingModule } from './ai-routing.module';
import { AiHomeComponent } from './ai-home/ai-home.component';
import { FormsModule } from '@angular/forms';
import { AiChatComponent } from './ai-chat/ai-chat.component';
import { AiPromptLibraryComponent } from './ai-prompt-library/ai-prompt-library.component';
import { AiPromptComponent } from './ai-prompt/ai-prompt.component';
import { AiResultComponent } from './ai-result/ai-result.component';
import { SyncfusionModule } from 'syncfusion.module';
import { CoreModule } from 'app/core/core.module';
import { SharedModule } from 'app/shared/shared.module';
import { AiLandingComponent } from './ai-landing/ai-landing.component';


@NgModule({
  declarations: [
    AiHomeComponent,
    AiChatComponent,
    AiPromptLibraryComponent,
    AiPromptComponent,
    AiResultComponent,
    AiLandingComponent
  ],
  imports: [
    CommonModule,
    AiRoutingModule,
    CoreModule,
    SharedModule
  ]
})
export class AiModule { }
