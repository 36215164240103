import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {
  ColumnChooserService,
  PageService,
  PageSettingsModel,
  QueryCellInfoEventArgs,
  RowDataBoundEventArgs,
  ToolbarService,
  ExcelExportService,
  PdfExportService
} from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { DialogUtility } from '@syncfusion/ej2-popups';
import { ITreeData, TreeGridExcelExportProperties, TreeGridPdfExportProperties } from '@syncfusion/ej2-treegrid';

import { Query } from '@syncfusion/ej2-data';
import { EmitType } from '@syncfusion/ej2-base';
import { FilteringEventArgs } from '@syncfusion/ej2-dropdowns';
import { FreezeService, TreeGridComponent } from '@syncfusion/ej2-angular-treegrid';
import { ReportService } from 'app/service/report/report.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { JWTToken } from 'app/core/service/auth/auth';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { Chart } from 'angular-highcharts';
import { AccumulationTheme, IAccLoadedEventArgs } from '@syncfusion/ej2-angular-charts';
import { CustomCurrencyPipe } from 'app/shared/utills/currency.pipe';
import { DatePipe } from '@angular/common';
import { GrowthbookService } from 'app/core/service/growthBook/growthbook.service';
import { CalendarView, DateRangePickerComponent } from '@syncfusion/ej2-angular-calendars';
@Component({
  selector: 'app-expense-report',
  templateUrl: './expense-report.component.html',
  styleUrls: ['./expense-report.component.scss'],
  providers: [
    ToolbarService,
    PageService,
    ExcelExportService,
    PdfExportService,
    FreezeService,
    CustomCurrencyPipe,
    DatePipe,
  ],
})
export class ExpenseReportComponent implements OnInit {
  constructor(
    private _reportService: ReportService,
    public authServices: AuthServices,
    private loader: LoaderService,
    private _customCurrencyPipe: CustomCurrencyPipe,
    // public growthBookServ: GrowthbookService,
    public datepipe: DatePipe
  ) {}

  @ViewChild('datePicker') datePicker: DateRangePickerComponent;
  @ViewChild('treegrid') public treegrid: TreeGridComponent;
  public gridData: Object[] = [];
  public formatedData: Object[] = [];
  public pageSettings: PageSettingsModel;
  public toolbar: string[];
  public tableHeader = [];
  public customAttributes: Object;
  // public selectedAccount = 'vendor';
  public selectedAccount = { name: 'Vendor', value: 'vendor' };
  public height: number;
  public screenHeight: any;
  jwtToken: JWTToken = undefined;
  public setdateperiod = 'twelwe';
  public timeranges = [
    { name: 'YTD', end_date: '2023-05-20', start_date: '2023-01-01' },
  ];
  public selectedPeriod = {
    name: 'YTD',
    end_date: '2023-05-20',
    start_date: '2023-01-01',
  };
  public collapseStatePersist: boolean = true;
  public totExp = 124948;

  // public selPeriod = "yeartodate";
  // public timePeriod = [
  //   {name:"Year-to-date", value: 'yeartodate'},
  //   {name: "Quarter-to-date", value: 'quartertodate'},
  //   {name: "Last Quarter", value: 'lastquarter'},
  //   {name: "Last Month", value: 'lastmonth'}
  // ];
  public selAcc = { name: '', value: '', currency: '' };
  public acc = [];
  public accounts = [
    { name: 'Vendor', value: 'vendor' },
    { name: 'Category', value: 'category' },
  ];
  public selExport = 'excel';
  public exports = [
    { name: 'Excel', value: 'excel' },
    { name: 'Pdf', value: 'pdf' },
  ];

  public chartData: any;
  public showChart = true;
  public donutchart: Chart;

  public palettes = [
    '#F9A03F',
    '#499167',
    '#54A9C5',
    '#C24545',
    '#369F73',
    '#4773A7',
    '#E87D65',
    '#725E16',
    '#8268A5',
    '#0D3D86',
  ];

  public animation: Object = {
    enable: true,
  };
  public border: Object = { width: 3 };
  public pieTooltipSetting: Object = {
    enable: true,
    textStyle: {
      fontFamily: 'Inter, sans-serif',
    },
  };
  public dataLabel: Object = {
    visible: true,
    position: 'Outside',
    name: 'y',
    connectorStyle: { length: '10px', type: 'Curve' },
    font: { fontFamily: 'Inter, sans-serif', color: '#8D8F95' },
    // template: '<div style="color: #8D8F95; font-family: Inter, sans-serif">${point.y}</div>',
  };
  public enableBorderOnMouseMove: boolean = false;
  // public enableSmartLabels: boolean = true;
  public pielegendSettings: Object = {
    visible: true,
    textStyle: { fontFamily: 'Inter, sans-serif', color: '#8D8F95' },
  };
  public accumulationload(args: IAccLoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.accumulation.theme = <AccumulationTheme>(
      (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1))
        .replace(/-dark/i, 'Dark')
        .replace(/contrast/i, 'Contrast')
    );
  }

  public start: CalendarView = 'Year';
  public depth: CalendarView = 'Year';
  public format: string = 'MMM yyyy';

  public startDate: any;
  public endDate: any;

  // public growthBookCustom = this.growthBookServ.getGBookStatus(
  //   'reports_custom_date'
  // );

  ngOnInit() {
    this.loader.show();
    this.pageSettings = { pageSize: 10 };
    this.customAttributes = { class: 'customcss' };
    this.fetchTimeRange();
    this.statestatus();
    this.retrieveSelectedAccount();
  }

  exportexcelpdf() {
    if (this.selExport === 'excel') {
      this.excelDownload();
    } else if (this.selExport === 'pdf') {
      this.pdfDownload();
    }
  }

  async fetchTimeRange() {
    this.jwtToken = await this.authServices.getJWTDetail();
    this._reportService.getExpenseFilter(this.jwtToken.org.id).subscribe(
      (data) => {
        this.acc = data.accounts;
        this.timeranges = data.timerange;
        this.selectedPeriod = this.timeranges[0];
        // if (this.growthBookCustom) {
          this.timeranges.push({
            name: 'Custom',
            end_date: this.endDate,
            start_date: this.startDate,
          });
        // }
        if (this.acc.length > 0) {
          // console.log('1',this.acc[0])
          //   console.log('2',this._reportService.getAccount())
          this.selAcc = this.acc.filter(
            (d) => d.value === this._reportService.getAccount().value
          )[0];
        }
        this.fetchExpense();
      },
      (err) => {
        this.loader.hide();
      }
    );
  }
  pdfDownload() {
    let pdfExportProperties: TreeGridPdfExportProperties = {
      isCollapsedStatePersist: this.collapseStatePersist,
    };
    this.treegrid.pdfExport(pdfExportProperties);
  }

  excelDownload() {
    let excelExportProperties: TreeGridExcelExportProperties = {
      isCollapsedStatePersist: this.collapseStatePersist,
    };
    this.treegrid.excelExport(excelExportProperties);
  }

  queryCellInfo(args: QueryCellInfoEventArgs) {
    if (!(args.data as ITreeData).hasChildRecords) {
      if ((args.cell as HTMLElement).classList.contains('e-unboundcell')) {
        (
          (args.cell as HTMLElement).querySelector(
            '.e-unboundcelldiv'
          ) as HTMLElement
        ).style.display = 'none';
      }
    }
  }
  rowDataBound(args: RowDataBoundEventArgs) {
    if (!(args.data as ITreeData).hasChildRecords) {
      // (args.row as HTMLElement).style.backgroundColor = 'green';
      (args.row as HTMLElement).classList.add('child-row');
    }
  }
  dataBound(event): void {
    this.treegrid.grid.hideScroll();
  }

  public textRender(args): void {
    args.text = args.point.x + ': ' + args.text;
  }

  async fetchExpense() {
    this._reportService.setAccount(this.selAcc);
    this.loader.show();
    this.tableHeader = [];
    this.gridData = [];
    this._reportService
      .getExpenseReport(
        this.jwtToken.org.id,
        this.selectedPeriod.start_date,
        this.selectedPeriod.end_date,
        this.selectedAccount.value,
        this.selAcc.value
      )
      .subscribe(
        (data) => {
          let fData = [];
          if (Object.keys(data).length !== 0) {
            fData = data.data.map((d) => {
              d.subAccount = [];
              return d;
            });
          }
          this.formatedData = fData;
          this.gridData = fData;
          this.chartData = this.formatDataForChart();
          this.formatedData.forEach((grid: any) => {
            Object.entries(grid).forEach(([key, value]) => {
              // if (key === 'total') {
              //   grid[key] = this.currencyPipe.transform(value as string | number, this.selAcc.currency);
              // }
              // if (grid.category === 'Total' && key !== 'category' && key !== 'subAccount') {

              if (key !== 'category' && key !== 'subAccount') {
                grid[key] = this._customCurrencyPipe.transform(
                  value as string | number,
                  this.selAcc.currency
                );
              }
            });
          });
          this.treegrid.dataSource = this.formatedData;
          // this.donutchart = this.CreatePieChart(chartData.chartData);
          this.tableHeader = data.header;
          this.setdateperiod = this._reportService.getSelectedPeriod(this.selectedPeriod,this.startDate,this.endDate,this.tableHeader.length);
          // this.treegrid.refresh()
          this.loader.hide();
        },
        (err) => {
          this.loader.hide();
        }
      );
  }

  hidechart() {
    this.showChart = false;
  }
  showchart() {
    this.showChart = true;
  }

  // CreatePieChart(datas) {
  //   const donutchart = new Chart({
  //     chart: {
  //       backgroundColor:"transparent",
  //       plotBackgroundColor: null,
  //       plotBorderWidth: null,
  //       plotShadow: false,
  //       type: 'pie'
  //     },
  //     title: {
  //       text: ''
  //     },
  //     legend: {
  //       align: 'right',
  //       layout: 'vertical',
  //       verticalAlign: 'middle',
  //       useHTML: true,
  //       labelFormatter: function() {
  //       return '<div style="color:#000000,font-size:12px !important; text-transform: capitalize; margin-bottom:15px;"><b>' + this.name + '</b></div>';
  //       }
  //   },
  //     tooltip: {
  //       pointFormat: '{series.name}: <b>{point.percentage:.1f}% ({point.y})</b>'
  //     },
  //     credits: {
  //       enabled: false
  //     },
  //     accessibility: {
  //       point: {
  //         valueSuffix: '%'
  //       }
  //     },
  //     plotOptions: {
  //       pie: {
  //         allowPointSelect: true,
  //         cursor: 'pointer',
  //         innerSize: '75%',
  //         dataLabels: {
  //           enabled: false
  //         },
  //         showInLegend: true,
  //         colors: this.donutChartColors
  //       }
  //     },
  //     series: [{
  //       type: 'pie',
  //       name: 'Total',
  //       colorByPoint: true,
  //       data: datas
  //     }]
  //   });
  //   return donutchart;
  // }

  public formatDataForChart(): any {
    let obj: any = {};
    let chartData = [];
    let totVendorExp = 0;
    this.gridData.forEach((gD: any) => {
      if (gD.category !== 'Total') {
        obj = {};
        obj.name = gD.category;
        obj.y = gD.total;
        obj.TextMapping = gD.category + ' : ' + gD.total;
        chartData.push(obj);
        totVendorExp = totVendorExp + obj.y;
      }
    });
    this.totExp = totVendorExp;
    return chartData;
  }

  dropdownChangedEvent(selaccount) {
    this.selAcc = selaccount;
    this.fetchExpense();
  }

  dropdownAccChangedEvent(selaccount) {
    this.selectedAccount = selaccount;
    this.fetchExpense();
    this.state();
  }

  dropdownTimeChangedEvent(seltime) {
    this.selectedPeriod = seltime;
    if (this.selectedPeriod.name !== 'Custom') {
      this.fetchExpense();
    } else {
      this.openDatePicker();
    }
  }
  state() {
    const newState = {
      ...window.history.state,
      selectedAccount: this.selectedAccount,
    };
    history.pushState(newState, '');
    if (!localStorage.getItem('selectedAccount')) {
      localStorage.setItem(
        'selectedAccount',
        JSON.stringify(this.selectedAccount)
      );
    }
  }
  statestatus() {
    if (window.history.state && window.history.state.selectedAccount) {
      this.selectedAccount = window.history.state.selectedAccount;
    }
  }
  retrieveSelectedAccount() {
    const selectedAccountStored = localStorage.getItem('selectedAccount');
    if (selectedAccountStored) {
      this.selectedAccount = JSON.parse(selectedAccountStored);
    }
  }

  openDatePicker() {
    setTimeout(() => {
      this.datePicker.show();
    }, 1);
  }

  onDateChange(event: any) {
    if (event && event.value) {
      const selectedDates = event.value;
      this.startDate = this.datepipe.transform(selectedDates[0], 'yyyy-MM-dd');
      this.endDate = this.datepipe.transform(selectedDates[1], 'yyyy-MM-dd');
      this.selectedPeriod = {
        name: 'Custom',
        end_date: this.endDate,
        start_date: this.startDate,
      };
      this.fetchExpense();
    }
  }
}
