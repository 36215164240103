import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JWTToken } from 'app/core/service/auth/auth';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { AiService } from 'app/service/ai/ai.service';
import { CommonService } from 'app/service/shared/common.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-ai-landing',
  templateUrl: './ai-landing.component.html',
  styleUrls: ['./ai-landing.component.scss'],
})
export class AiLandingComponent implements OnInit {
  public answerText: string;
  public inputText: string;
  public tblData: any = {};
  public showSecondQues: boolean = false;
  public promptData: any;
  public environment = environment;
  public allign = [5, 7, 7, 5];
  //  [
  //   { icon: 'fa-receivable-prompt', title: 'Recievables' },
  //   { icon: 'fa-numero-profile', title: 'Customer Contracts' },
  //   { icon: 'fa-numero-invoice', title: 'Invoice' },
  //   { icon: 'fa-cash-flow', title: 'Cash Flow'},
  //   { icon: 'fa-vendor-prompt', title: 'Vendor' },
  // ];
  public data: any;
  jwtToken: JWTToken = undefined;

  constructor(
    public _commonService: CommonService,
    public _aiService: AiService,
    private _loader: LoaderService,
    private _router: Router,
    public _authServices: AuthServices
  ) {}

  ngOnInit(): void {
    this._commonService.setHideAiIcon(false);
    this._authServices.getJWTDetail().then((data) => {
      this.jwtToken = data;
      // this.getAITableData();
      this.getAiDasboard();
    });
    this._commonService.setPageHeader('Numero Copilot');
    this._commonService.setsubPageHeader('');
  }

  // getAITableData() {
  //   this._loader.show();
  //   this._aiService.getAIData().subscribe(
  //     (data) => {
  //       this.tblData = data;
  //       this._loader.hide();
  //     },
  //     (err) => {
  //       this._loader.hide();
  //     }
  //   );
  // }
  getAiDasboard() {
    this._loader.show();
    this._aiService.getAidashboard().subscribe(
      (data) => {
        this.promptData = data.data;
        this._loader.hide();
      },
      (err) => {
        this._loader.hide();
      }
    );
  }
  filterNavigation(item) {
    let targetRoute;
    targetRoute = '/ai/chat';
    const obj = {
      ...item,
      prompt: item.prompt,
      prompt_key: item.prompt_key,
    };
    this._router.navigateByUrl(targetRoute, { state: obj });
    console.log('item', item);
  }

  getResultForInputText(questionType, input) {
    this._loader.show();
    const qData = { prompt: this.inputText, prompt_key: this.inputText };
    this.navigateToResult(questionType, qData);
  }

  navigateToResult(questionType, questionData) {
    let targetRoute = '/ai/chat';
    let quesData = questionData;
    quesData.quesType = questionType;
    this._router.navigateByUrl(targetRoute, { state: questionData });
  }
}
