<div class="section">
    <!-- <div class="container" style="display:none;">
        <div class="row full-height justify-content-center">
            <div class="col-12 text-center align-self-center py-5">
                <div class="section pb-5 pt-5 pt-sm-2 text-center">
                    <h6 class="mb-0 pb-3"><span>Log In </span><span>Sign Up</span></h6>
                      <input class="checkbox" type="checkbox" id="reg-log" name="reg-log"/>
                      <label for="reg-log"></label>
                    <div class="card-3d-wrap mx-auto">
                        <div class="card-3d-wrapper">
                            <div class="card-front">
                                <div class="center-wrap">
                                    <div class="section text-center">
                                        <h4 class="mb-4 pb-3">Log In Ram</h4>
                                        <div class="form-group">
                                          <button type="button" class="form-style btn-google"  (click)="googleSignIn()"><img src="https://s3.amazonaws.com/workflow-dev.dataflo.io/assets/image/google.svg" alt="image"/> Log In with Google</button>
                                        </div>
                                        <div class="div-or">or</div>
                                        <form [formGroup]="signinForm" (submit)="signIn()">
                                            <div class="form-group">
                                                <input type="email" maxlength="50" class="form-style" placeholder="Your Email" formControlName="email" [ngClass]="{ 'is-invalid': signin.email.errors }">
                                                <i class="input-icon uil uil-at"></i>
                                            </div>
                                            <div class="form-group mt-2">
                                                <input type="password" maxlength="50" class="form-style" placeholder="Your Password" formControlName="password" [ngClass]="{ 'is-invalid': signin.password.errors }">
                                                <i class="input-icon uil uil-lock-alt"></i>
                                            </div>
                                            <button type="submit" class="btn mt-4">Log In</button>
                                            <p class="mb-0 mt-4 text-center"><a href="#0" class="link">Forgot your password?</a></p>
                                        </form>
                                    </div>
                                  </div>
                              </div>
                            <div class="card-back">
                                <div class="center-wrap">
                                    <div class="section text-center">
                                        <h4 class="mb-4 pb-3">Sign Up</h4>
                                        <form [formGroup]="signupForm" (submit)="signUp()">

                                            <div class="form-group mt-2">
                                                <input type="email" maxlength="50" class="form-style" placeholder="Your Email" formControlName="email" [ngClass]="{ 'is-invalid': signup.email.errors }">
                                            </div>
                                            <div class="form-group mt-2">
                                                <input type="password" maxlength="50" class="form-style" placeholder="Your Password" formControlName="password" [ngClass]="{ 'is-invalid': signup.password.errors }">
                                            </div>
                                            <button type="submit" class="btn mt-4">Sign Up</button>
                                        </form>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
    </div> -->
    <div class="div-numero-container">
        <div class="row">
            <div class="col-md-7 div-login-left-side">
                <div class="row">
                    <div class="col-md-12 div-left-login">

                        <div class="div-logo">
                            <img  src="{{environment.imagePrefixURI}}/utill/GoConstant-logo.svg" alt="image"/>
                        </div>
                        <div class="div-login-header">
                            Your Finance Co-Pilot for instant answers
                        </div>
                        <div class="div-login-desc">
                            <p class="text-desc-login">Say no to endless searching</p>
                            <!-- <p>Measure, Act and Automate with a no-code platform</p>
                            <p>Built for Accountants</p> -->
                        </div>
                        <div class="div-login-img">
                            <img src="{{environment.imagePrefixURI}}/utill/login-right.svg" alt="image"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5 div-login-right-side">
                <div class="row" *ngIf="isLogin">
                    <div class="col-md-12 div-login-box">
                        <div class="div-login-box-cen">
                            <div class="wid50">

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="div-login-txt">
                                            <h3>Login </h3>
                                            <p>Login to Your Account</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 mt-4  text-center">
                                      <!-- <img class="cursor-pointer" (click)="googleSignIn()" src="{{environment.imagePrefixURI}}/utill/google-signin.svg" alt="Google Signin"> -->
                                        <button type="button" class="btn-google" (click)="googleSignIn()"><img src="{{environment.imagePrefixURI}}/utill/google.svg" alt="image"/> <p>Sign in with Google</p></button>
                                    </div>
                                    <div class="col-md-12 mt-4  text-center">
                                      <!-- <img class="cursor-pointer" (click)="microSoftSignIn()" src="{{environment.imagePrefixURI}}/utill/microsoft-signin.svg" alt="Google Signin"> -->
                                        <button type="button" class="btn-microsoft" (click)="microSoftSignIn()"><img src="{{environment.imagePrefixURI}}/utill/microsoft.svg" alt="image"/> <p>Sign in with Microsoft</p></button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="div-or-login">
                                            <div class="div-or-line"></div>
                                            <div class="div-or">or</div>
                                        </div>
                                        <form [formGroup]="signinForm" (submit)="signIn()">
                                            <div class="form-group">
                                                <label>Email</label>
                                                <input type="email" maxlength="50" class="form-style" placeholder="Your Email" formControlName="email" [ngClass]="{ 'is-invalid': signin.email.errors }">
                                                <i class="input-icon uil uil-at"></i>
                                            </div>
                                            <div class="form-group mt-4">
                                                <label>Password</label>
                                                <input type="password" maxlength="50" class="form-style" placeholder="Your Password" formControlName="password" [ngClass]="{ 'is-invalid': signin.password.errors }">
                                                <i class="input-icon uil uil-lock-alt"></i>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 mt-3"></div>
                                                <div class="col-md-6 text-right mt-3">
                                                    <a class="link" (click)="showForgotPass()">Reset Password</a>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <button type="submit" class="btn btn-login mt-3 mb-2">Log In</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="row" *ngIf="false">
                    <div class="col-md-12 div-login-box">
                        <div class="div-login-box-cen">
                            <div class="wid50">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="div-login-txt">
                                            <h3>Sign Up <img src="./assets/image/hand.svg" alt="image"/></h3>
                                            <p>Let's create an account for you!</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 mt-4">
                                        <button type="button" class="btn-google" (click)="googleSignIn()"><img src="./assets/image/google.svg" alt="image"/> <p>Sign in with Google</p></button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="div-or-login">
                                            <div class="div-or-line"></div>
                                            <div class="div-or">or</div>
                                        </div>
                                        <form [formGroup]="signupForm" (submit)="signUp()">
                                            <div class="form-group mt-2">
                                                <label>Email</label>
                                                <input type="email" maxlength="50" class="form-style" placeholder="Your Email" formControlName="email" [ngClass]="{ 'is-invalid': signup.email.errors }">

                                            </div>
                                            <div class="form-group mt-4">
                                                <label>Password</label>
                                                <input type="password" maxlength="50" class="form-style" placeholder="Your Password" formControlName="password" [ngClass]="{ 'is-invalid': signup.password.errors }">
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 mt-3">
                                                    <button type="submit" class="btn btn-login mt-3 mb-2">Create Account</button>
                                                    <p class="text-lef mt-2">Already have an account <a class="link" (click)="showSignIn()">Log in</a></p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="div-terms-policy">
                                                        <p>By Creating an Account, you accept our <a>Terms of Service</a> and <a>Privacy Policy</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="row" *ngIf="isForgotPass">
                    <div class="col-md-12 div-login-box">
                        <div class="div-login-box-cen">
                            <div class="wid50">

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="div-login-txt">
                                            <h3>Reset your Password</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <form [formGroup]="forgotPasswordForm" (submit)="forgotPassword()">
                                            <div class="form-group mt-4">
                                                <label>Email </label>
                                                <input type="email" maxlength="50" class="form-style" placeholder="Enter your email" formControlName="email" [ngClass]="{ 'is-invalid': signin.email.errors }">
                                                <i class="input-icon uil uil-at"></i>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-12">
                                                    <button type="submit" class="btn btn-login mt-4 mb-2">Send me the link</button>
                                                    <p class="text-left mt-2">Back to <a class="link" (click)="showSignIn()">Log in</a></p>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
