import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { handleError } from 'app/service/core/handle-error';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FlowchartService {
  constructor(private http: HttpClient) { }
  getFlowcharts = (entityKey) : Observable<any> => {
    return this.http
      .get(`${environment.server.baseURL}/process?entity_key=${entityKey}` )
      .pipe(catchError(handleError));
  }

  getFlowchart = (entityKey: string , flowKey , version) : Observable<any> => {
    const ver =  version !== null ? `&version=${version}` : '';
    return this.http
      .get(`${environment.server.baseURL}/process?entity_key=${entityKey}&key=${flowKey}${ version !== null ? `&version=${version}` : ''}` )
      .pipe(catchError(handleError));
  }

  searchFlowchart = (searchStr: string, entityKey: string): Observable<any> => {
    return this.http.get(environment.server.baseURL + `/process?entity_key=` + entityKey + `&search_string=${searchStr}`)
  }

  postFlowchart = (flowData :any) : Observable<any> => {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .post(`${environment.server.baseURL}/process`, flowData, {responseType: 'text'})
      .pipe(catchError(handleError));
  }

  putFlowchart = (flowData :any) : Observable<any> => {
    return this.http
      .put(`${environment.server.baseURL}/process`, flowData, {responseType: 'text'})
      .pipe(catchError(handleError));
  }

  deleteFlowcharts = (entityKey, flowKey ) : Observable<any> => {
     const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .delete(`${environment.server.baseURL}/process?entity_key=${entityKey}&key=${flowKey}`,httpOptions )
      .pipe(catchError(handleError));
  }
}
