import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JWTToken } from 'app/core/service/auth/auth';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { ConnectorService } from 'app/service/connectorconfig/connector.service';
import { AuthDetailPopupComponent } from '../authdetail-popup/authdetail-popup.component';
import { AuthenticationPopupComponent } from '../authentication-popup/authentication-popup.component';
import { environment } from 'environments/environment';
import { WorkbenchService } from 'app/service/workbench/workbench.service';
import { ToastrService } from 'ngx-toastr';
import { CommonPopupAlertComponent } from 'app/shared/common-popup-alert/common-popup-alert.component';

@Component({
  selector: 'app-connector-card',
  templateUrl: './connector-card.component.html',
  styleUrls: ['./connector-card.component.scss']
})
export class ConnectorCardComponent implements OnChanges {

  @Input() _CONNECTOR_;

  public deleteConfirmationText: string;
  public showAlert: any = undefined;
  public modalRef: any;
  public environment = environment;
  constructor(
    public modalService: NgbModal,
    public connectorService: ConnectorService,
    public authServices: AuthServices,
    private loader: LoaderService,
    private _workbenchService: WorkbenchService,
    private toaster: ToastrService,
    private _NgbModal: NgbModal,
  ) { }

  @Input() connectors;
  // @Input() categoryList;
  @Input() activeConnections: any = {};
  @Input() deleteConnection: any = {};
  jwtToken: JWTToken = undefined;
  webhookUrl: string = '';
  @Output() authPopupSubmitted = new EventEmitter<string>();

  isAuthFormVisible : boolean = false;


  ngOnChanges(changes: SimpleChanges): void {
      if(changes.connectors.currentValue != null && changes.connectors.currentValue != undefined) {
        this.connectors = changes.connectors.currentValue;
      }
  }

  showAuthPopup(connection: any): void {
    this.isAuthFormVisible = true;

    let modalRef = this.modalService.open(AuthenticationPopupComponent, {
      backdrop: 'static',
      keyboard: true,
      centered: true,
      size: 'md',
      animation: true,
    });
    modalRef.componentInstance.connection = connection;
    modalRef.result
    .then((reason) => {
      this.authPopupSubmitted.emit(reason);
    })
    .catch((error) => {
    });
  }

  viewConnections(connection: any): void {

  }

  deleteConnector(delConnection: any): void {
    this.deleteConfirmationText = '';
    this.showAlert = true
    this.deleteConnection = delConnection;
  }

  validateConfirmationMessage = () => {
    if(this.deleteConfirmationText && this.deleteConfirmationText === 'permanently delete') {
      this.showAlert = false;
    } else {
      this.showAlert = true;
    }
  }
  handleDeleteConfirm = async (delConnection) => {
    this.loader.show();
    if (this.jwtToken === undefined) {
      this.jwtToken = await this.authServices.getJWTDetail();
    }
    this.connectorService.deleteConnection(delConnection.key,
      this.jwtToken.org.id, this.jwtToken.userEmail).subscribe((data) => {
          console.log("Deleted successfully...")
          this.toaster.warning('Successfully Disconnected!', 'warning', {
            timeOut: 3000,
          });
          this.authPopupSubmitted.emit(this.activeConnections.source_key);
          this.loader.hide();
          this.deleteConfirmationText = '';
          localStorage.removeItem('ar-identifier')
          this._workbenchService.getAutomation(this.jwtToken.org.id).subscribe(d=>{})
    });
  }

  closeModal = () => {
    this.modalRef.close();
  }

  authPopupSubmit(sourceKey: string) : void {
    this.authPopupSubmitted.emit(sourceKey);
  }

  async fetchWebhookUrl(source: string) : Promise<void> {
    let jwt = await this.authServices.getJWTDetail();
    this.connectorService.fetchWebhook(jwt.org.id, source).subscribe((data: any) => {
      this.webhookUrl = data;
      console.log("Webhook URL", this.webhookUrl)
    });
  }

  /* To copy any Text */
copyText(val: string){
  let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }


  deleteConnectionPopup = async(delConnection:any) => {
    const modelref = this._NgbModal.open(CommonPopupAlertComponent, {
      size: 'md',
      windowClass: 'nu-simple-popup-alert-modal',
      backdropClass: 'nu-simple-popup-alert-backdrop',
      centered: true,
    });
    modelref.componentInstance.messageText = `Disconnecting [${delConnection?.identifier}] account will delete all data associated with the account.Are you sure you want to proceed?`;
    modelref.componentInstance.showDeleteConnection = true;
    modelref.componentInstance.showConfirmButton= false;
    modelref.componentInstance.showDeleteButton= false;
    modelref.result.then(async data=>{
      if(data === 'success') {
        this.loader.show();
        this.deleteConfirmationText = '';
        this.showAlert = true;
        this.deleteConnection = delConnection;
        if (this.jwtToken === undefined) {
          this.jwtToken = await this.authServices.getJWTDetail();
        }
        this.connectorService.deleteConnection(delConnection.key,
          this.jwtToken?.org.id, this.jwtToken?.userEmail).subscribe((data) => {
              console.log("Deleted successfully...")
              this.toaster.warning('Successfully Disconnected!', 'warning', {
                timeOut: 3000,
              });
              this.authPopupSubmitted.emit(this.activeConnections.source_key);
              this.deleteConfirmationText = '';
              localStorage.removeItem('ar-identifier')
              this.loader.hide();
        });
      }
    }).catch(err=>{
      this.toaster.error('Ah! The disconnection failed. Please try disconnecting again.', 'ERROR', {
        timeOut: 3000,
      });
      console.log('err',err)
    })
  }
}
