import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreRoutingModule } from './core-routing.module';
import { SideBarComponent } from './component/side-bar/side-bar.component';
import { LoginComponent } from './component/login/login.component';
import { SharedModule } from 'app/shared/shared.module';
import { LoginListenerComponent } from './component/login-listener/login-listener.component';
import { SupportLoginComponent } from './component/support-login/support-login.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';



@NgModule({
  declarations: [
    SideBarComponent,
    LoginListenerComponent,
    SupportLoginComponent,
    ResetPasswordComponent
  ],
  imports: [
    CommonModule,
    CoreRoutingModule,
    SharedModule,
    NgbModule,
  ],
  exports: [
    SideBarComponent
  ]
})
export class CoreModule { }
