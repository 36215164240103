import { Component, OnInit, SecurityContext } from '@angular/core';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeValue,
} from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { JWTToken } from 'app/core/service/auth/auth';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { WorkflowService } from 'app/service/workflow/workflow.service';
import { environment } from 'environments/environment';
import { CommonService } from 'app/service/shared/common.service';
import { LoaderService } from 'app/core/service/loader/loader.service';

@Component({
  selector: 'app-workflow-editor',
  templateUrl: './workflow-editor.component.html',
  styleUrls: ['./workflow-editor.component.scss'],
})
export class WorkflowEditorComponent implements OnInit {
  name = 'Set iframe source';
  urlSafe: SafeResourceUrl;
  jwtToken: JWTToken;
  urlLoad = false;
  constructor(
    public sanitizer: DomSanitizer,
    public workflowService: WorkflowService,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    public authServices: AuthServices,
    public commonService: CommonService,
    private _loader : LoaderService,
  ) {}

  async ngOnInit() {
    setTimeout(() => {
      this._loader.hide()
    }, 4000);
    let workflowId = this.activatedRoute.snapshot.paramMap.get('id');
    if (!this.jwtToken) {
      this.jwtToken = await this.authServices.getJWTDetail();
    }
    let res = await this.workflowService.initializeN8N(
      this.jwtToken.userEmail,
      this.jwtToken.org.domainName
    );
    this.initializeWorkflow(workflowId);
    this.commonService.setPageHeader('Workflows');
  }

  initializeWorkflow(workflowId: string) {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.workflowService.getWorkflowEngineUrl(workflowId)
    );

    this.urlLoad = true;
  }

  workFlowURL() {
    return this.urlSafe;
  }

  handleBack = () => {
    this.router.navigate(['workflow']);
  };
}
