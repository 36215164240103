<div class="row">
  <ng-container *ngFor="let connection of connectors; let i = index">
    <div class="col-md-numero">
      <div class="div-numero-card">
        <div class="row">
          <div class="col-md-6">
            <div class="div-img">
              <img src="{{ connection.image_url }}" alt="image"/>
            </div>
          </div>
          <div class="col-md-6 text-right cptr">
            <a
              *ngIf="
                connection.oauth_endpoint &&
                !connection.oauth_endpoint.startsWith(
                  'https://accounts.google.com'
                )
              "
              type="button"
              class="btn"
              [ngClass]="
                connection?.connectedCount > 0
                  ? 'btn-numero-connectors'
                  : 'btn-numero-connectors-connect'
              "
              href="{{ connection.oauth_endpoint }}"
              >{{ connection?.connectedCount > 0 ? "+ Add New" : "Connect" }}</a
            >

            <a
              *ngIf="!connection.oauth_endpoint"
              class="btn"
              [ngClass]="
                connection?.connectedCount > 0
                  ? 'btn-numero-connectors'
                  : 'btn-numero-connectors-connect'
              "
              (click)="showAuthPopup(connection)"
              [popover]="popContent"
              outsideClick="true"
              placement="bottom"
              container="body"
              [disabled]="showAlert"
              >{{ connection?.connectedCount > 0 ? "+ Add New" : "Connect" }}</a
            >
            <a
              *ngIf="
                connection.oauth_endpoint &&
                connection.oauth_endpoint.startsWith(
                  'https://accounts.google.com'
                )
              "
              type="button"
              class=""
              href="{{ connection.oauth_endpoint }}"
            >
              <img
                style="height: 29px; width: 100%"
                src="{{environment.imagePrefixURI}}/utill/btn_google_signin.svg" alt="image"
              />
            </a>
          </div>
          <div class="col-md-9">
            <div class="div-desc-head">
              {{ connection.name }}
            </div>
          </div>
          <div class="col-md-3 text-right pl-0"  >
            <div
              class="dropdown div-connect-dropdown"
              *ngIf="connection?.connectedCount > 0"
            >
              <button
                class="btn btn-connect dropdown-toggle"
                type="button"
                id="dropdownMenuButton_{{ connection.source_key }}"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="fas fa-plug mt-1"></i
                ><span>{{ connection.connectedCount || 0 }}</span>
              </button>
              <ul
                class="dropdown-menu"
                aria-labelledby="dropdownMenuButton_{{ connection.key }}"
              >
                <li *ngFor="let con of connection.connectedItems">
                  <div class="dropdown-item">
                    <div class="row">
                      <div class="col-2">
                        <div class="div-avatar" title="{{ con.owner }}">
                          {{ con.owner.charAt(0).toUpperCase() }}
                        </div>
                      </div>
                      <div class="col-8 pl-0 pr-0 div-drop-email" placement="top" ngbTooltip="{{ con.name }}">{{ con.name }}</div>
                      <div
                        class="col-2 text-right"
                        (click)="deleteConnectionPopup(con)"
                      >
                        <i class="far fa-trash-alt delete-icon"></i>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div
              class="dropdown div-connect-dropdown"
              *ngIf="connection?.connectedCount === 0 && _CONNECTOR_"
            >
              <button
                class="btn btn-connect dropdown-toggle no-cur"
                type="button"
              >
                <i class="fas fa-plug mt-1"></i
                ><span>{{ connection.connectedCount || 0 }}</span>
              </button>
            </div>
            <div
              class="dropdown div-webhook-dropdown"
              *ngIf="connection.webhook && connection?.connectedCount > 0"
            >
              <button
                class="btn btn-connect dropdown-toggle"
                type="button"
                id="dropdownWebhookButton_{{ connection.source_key }}"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                (click)="fetchWebhookUrl(connection.source_key)"
              >
                <img src="./assets/image/webhook.svg" alt="image"/>
              </button>
              <ul
                class="dropdown-menu"
                aria-labelledby="dropdownWebhookButton_{{
                  connection.source_key
                }}"
              >
                <li>
                  <div class="dropdown-item" (click)="copyText(webhookUrl)">
                    <i class="far fa-clone"></i> Copy Webhook link
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-12">
            <div class="div-desc">
              <p>{{ connection.description || "" }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div
    class="modal left fade"
    id="connectionsModal"
    tabindex="-1"
    aria-labelledby="connectionsModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="col-11 pr-0 d-inline-flex chart-header-left">
            <img
              class="ml-1 img-ht al-self-center"
              src="{{ activeConnections.image_url }}" alt="image"
            />
            <div
              class="header-title al-self-center"
              title="{{ activeConnections.name }}"
            >
              {{ activeConnections.name }}
            </div>
          </div>
          <div class="col-1 text-right cptr">
            <i class="fal fa-times" data-bs-dismiss="modal"></i>
          </div>
        </div>
        <div class="modal-body">
          <div class="row">
            <ng-container *ngFor="let con of activeConnections.connectedItems">
              <div class="col-11 mb-3">{{ con.identifier }}</div>
              <div
                class="col-1 mb-3"
                data-bs-toggle="modal"
                data-bs-target="#deleteConnectionModal"
                (click)="deleteConnector(con)"
              >
                <i class="far fa-trash delete-icon"></i>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="deleteConnectionModal"
    tabindex="-1"
    aria-labelledby="deleteConnectionModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row">
            <div class="w-100 text-center my-4 div-dc-datasource">
              <img
                src="{{environment.imagePrefixURI}}/disconnect.svg"
                alt=""
                class="data-sources-logo mb-2"
              />
              <p>Are you sure you want to disconnect?</p>
            </div>
            <div class="div-dc-text">
              <p>
                Disconnecting
                <span style="font-weight: 600"
                  >{{ activeConnections?.name }} [{{
                    deleteConnection?.identifier
                  }}]</span
                >
                account will delete all data associated with the account. <br />
                Are you sure you want to proceed?
              </p>
              <p class="txt-per">Type “permanently delete”</p>
            </div>
            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-4">
                <div class="dialog-data-sources-name df-header text-center">
                  <input
                    class="form-control"
                    type="text"
                    [(ngModel)]="deleteConfirmationText"
                    placeholder="permanently delete"
                    (keyup)="validateConfirmationMessage()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            data-bs-toggle="modal"
            (click)="handleDeleteConfirm(deleteConnection)"
            [disabled]="showAlert !== false"
            class="btn btn-numero mr-2"
          >
            Disconnect
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
