import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonService } from 'app/service/shared/common.service';

@Component({
  selector: 'app-reconciliation-home',
  templateUrl: './reconciliation-home.component.html',
  styleUrls: ['./reconciliation-home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReconciliationHomeComponent   {

  form: FormGroup;

 
  title = 'example';
  licenseKey = 'xKqXL6AiYSsbPCr8Def6joi4AwVDaK';
  user = { user_id: 'default123' };

  constructor(
    public commonService: CommonService
  ) {}

  ngOnInit(): void {   
    this.commonService.setPageHeader("Reconciliation");
  }

  imported(result: boolean, data: any) {
    if(result) {
      console.log("Sheet uploaded successfully");
      console.log(data.row_success + " rows uploaded");
    }else{
      console.log("There was some problem uploading the sheet");
    }
  }

  uploadInvoiceFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({
      file: file,
    });
    this.form.get('file').updateValueAndValidity();

  }

  uploadBankFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({
      file: file,
    });
    this.form.get('file').updateValueAndValidity();

  }

}
