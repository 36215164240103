import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-support-login',
  templateUrl: './support-login.component.html',
  styleUrls: ['./support-login.component.scss'],
})
export class SupportLoginComponent implements OnInit {
  signupForm: FormGroup;
  signinForm: FormGroup;
  forgotPasswordForm: FormGroup;
  public isLogin = true;
  public isSignUp = false;
  public isForgotPass = false;
  public environment = environment;

  constructor(
    public authServices: AuthServices,
    private formBuilder: FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private _loader: LoaderService
  ) {}
  ngOnInit(): void {
    const jwt = this.authServices.getJWTFromCookie();
    // console.log('jwt', jwt != null);
    if (jwt) {
      // this.router.navigate(['workbench/ap/automation/dashboard']);
      this.router.navigate(['numero']);
    }

    this.signinForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.signupForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', Validators.required],
    });
  }

  signIn(): void {
    this._loader.show();
    let formData = this.signinForm.value;
    if (!this.signinForm.valid) {
      this.toaster.error(this.validateForm(formData), 'ERROR', {
        timeOut: 3000,
      });
      return;
    }
    this.authServices.signInClicked(formData.email, formData.password);
  }

  signUp(): void {
    let formData = this.signupForm.value;
    if (!this.signupForm.valid) {
      this.toaster.error(this.validateForm(formData), 'ERROR', {
        timeOut: 3000,
      });
      return;
    }
    this.authServices.signUpClicked(formData.email, formData.password);
  }

  validateForm(formData): string {
    let errorMsg = 'Bad credentials';
    if (!formData.email || formData.email === '') {
      errorMsg = 'Email id cannot be empty';
    } else if (!formData.password || formData.password === '') {
      errorMsg = 'Password cannot be empty';
    }
    return errorMsg;
  }

  showSignIn(): void {
    this.isLogin = true;
    this.isSignUp = false;
    this.isForgotPass = false;
  }

  showSignUp(): void {
    this.isLogin = false;
    this.isSignUp = true;
    this.isForgotPass = false;
  }

  showForgotPass(): void {
    this.isLogin = false;
    this.isSignUp = false;
    this.isForgotPass = true;
  }

  googleSignIn(): void {
    this._loader.show();
    this.authServices.googleSignInClicked();
  }

  microSoftSignIn(): void {
    this._loader.show();
    this.authServices.microSoftSignInClicked();
  }

  forgotPassword(): void {
    this._loader.show()
    if (!this.forgotPasswordForm.valid) {
      this.toaster.error('Cannot process your request.', 'ERROR', {
        timeOut: 3000,
      });
    } else {
      let response = this.authServices.resetPasswordClicked(
        this.forgotPasswordForm.value.email
      );
      console.log(response);
    }
  }

  get signin(): any {
    return this.signinForm.controls;
  }

  get signup(): any {
    return this.signupForm.controls;
  }
}
