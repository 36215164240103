import { AfterViewInit, Component, ViewChild, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JWTToken } from 'app/core/service/auth/auth';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { environment } from 'environments/environment';
import { async } from 'rxjs';
import { FlowChartModel } from '../flowchart';
import { FlowchartService } from '../flowchart.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { ConnectorService } from 'app/service/connectorconfig/connector.service';
import { ConnectionDetail } from 'app/service/connectorconfig/connector';

export interface PeriodicElementPF {
  key: string;
  active: boolean;
  name: string;
  category: string;
  tags: string;
  owner: string;
  createdAt: string;
  updatedAt: string;
  updatedBy: string;
  edges: any;
  nodes: any;
  vaersion: number
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-flowchart-home',
  templateUrl: './flowchart-home.component.html',
  styleUrls: ['./flowchart-home.component.scss'],
})
export class FlowchartHomeComponent implements OnInit, AfterViewInit {
  public urlSafe: SafeResourceUrl;
  public isTemplateVisible: boolean = true;
  public shFlow = false;
  public processFlowList : FlowChartModel[];
  public filteredProcessFlowList : FlowChartModel[];
  public jwtToken: JWTToken = undefined;
  public deleteConfText: string;
  public showAlert: boolean = true;
  public widgetData: any = {};

  @Input() deleteFlow : any = {};

  displayedColumnsPF: string[] = ['name', 'category', 'tags', 'owner', 'createdAt', 'updatedAt', 'actions'];
  public dataSourcePF;
  public connectorsList:ConnectionDetail[]  = [];
  public flowKey= undefined;

  constructor(
    public modalService: NgbModal,
    public sanitizer: DomSanitizer,
    public loader: LoaderService,
    public flowChartService: FlowchartService,
    public authServices: AuthServices,
    public connectorService: ConnectorService,
  ) {}

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit(): void {

  }
  ngOnInit(): void {

  }
}
