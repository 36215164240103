import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  NgbAccordionConfig,
  NgbActiveModal,
  NgbPanelChangeEvent,
} from '@ng-bootstrap/ng-bootstrap';
import { JWTToken } from 'app/core/service/auth/auth';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { ReportService } from 'app/service/report/report.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { environment } from 'environments/environment';
import { forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-intercompany-elimination',
  templateUrl: './intercompany-elimination.component.html',
  styleUrls: ['./intercompany-elimination.component.scss'],
  providers: [NgbAccordionConfig],
})
export class IntercompanyEliminationComponent implements OnInit {
  jwtToken: JWTToken = undefined;
  public selAccountIndetifier = { name: '', value: null };
  public acclist = [];
  @Input() public accounts = [];
  public fullAccDataMap = new Map();
  public accontMap = new Map();
  public activeAccordian = '';
  public inputTest = '';
  public eliminationform: FormGroup;
  public filteredAcclist = [];
  public env = environment;
  public noSeletedData = false;
  public submitEnable = false;
  @Input() panelId;
  constructor(
    private _NgbActiveModal: NgbActiveModal,
    private _reportService: ReportService,
    public _authServices: AuthServices,
    private fb: FormBuilder,
    private http: HttpClient,
    private _loader: LoaderService
  ) {
    this.eliminationform = this.fb.group({
      selectedElimination: this.fb.array([], [Validators.required]),
    });
  }

  async ngOnInit() {
    this.jwtToken = await this._authServices.getJWTDetail();
    this.selAccountIndetifier = this.accounts[0];
    this.getDataOninit();
  }

  getDataOninit() {
    this._loader.show();
    let API_URL = [];
    let API_VAR = [];
    this.accounts.forEach((ac, i) => {
      this.accontMap.set(ac.value, ac);
      API_URL.push(
        this.http.get(
          `${environment.server.baseURL}/settings/accounts?entity_key=${this.jwtToken.org.id}&account_identifier=${ac.value}`
        )
      );
      API_VAR.push(`acc${i}`);
    });
    forkJoin(API_URL).subscribe(
      (fullData) => {
        fullData.forEach((aD: any) => {
          aD.sort((a, b) => a.name.localeCompare(b.name));
  
          const knockOffAcc = aD.filter((selAcc) => selAcc.knock_off === true);
          this.fullAccDataMap.set(aD[0].account_identifier, {
            allData: aD,
            selectedData: knockOffAcc,
          });
        });
        this.checkdataEmpty();
        this.fetchKnockOff();
      },
      (err) => {
        this._loader.hide();
      }
    );
  }  

  onCheckboxChange(e: any, data) {
    const selectedElimination: FormArray = this.eliminationform.get(
      'selectedElimination'
    ) as FormArray;
    if (e.target.checked) {
      data.knock_off = true;
      selectedElimination.push(new FormControl(data));
    } else {
      let i: number = 0;
      selectedElimination.controls.forEach((item: any) => {
        if (item.value.account_id == e.target.value) {
          data.knock_off = false;

          selectedElimination.removeAt(i);
          return;
        }
        i++;
      });
    }
    this.validateMapData();
  }

  checkdataEmpty() {
    let dataEmpty = false;
    let submitEnable = false;
    let accLength = 0;
    this.fullAccDataMap.forEach((aD: any) => {
      if (aD.selectedData.length > 0) {
        dataEmpty = true;
        accLength++;
      }
    });
    this.submitEnable = accLength >= 2 ? true : false;
    this.noSeletedData = dataEmpty;
  }

  get activeModal() {
    return this._NgbActiveModal;
  }

  fetchKnockOff = (id?) => {
    this._loader.show();
    const selectedElimination: FormArray = this.eliminationform.get(
      'selectedElimination'
    ) as FormArray;

    for (let i = selectedElimination.length - 1; i >= 0; i--) {
      selectedElimination.removeAt(i);
    }
    let selectedAcc;
    if (id) {
      selectedAcc = id;
      this.selAccountIndetifier = this.accontMap.get(id);
    } else {
      selectedAcc = this.selAccountIndetifier.value;
    }
    this.panelId = selectedAcc;
    // if (this.fullAccDataMap.has(selectedAcc)) {
    this.acclist = this.fullAccDataMap.get(selectedAcc).allData;
    this.filterInput();
    this.checkSelectetedKnockOff(selectedAcc);
    this._loader.hide();
    // }

    // else {
    //   this._reportService
    //     .getKnockOffAcountList(
    //       this.jwtToken.org.id,
    //       this.selAccountIndetifier.value
    //     )
    //     .subscribe(
    //       (data) => {
    //         this.acclist = data;
    //         this.filterInput();
    //         this.checkSelectetedKnockOff();
    //         this._loader.hide();
    //       },
    //       (err) => {
    //         this._loader.hide();
    //       }
    //     );
    // }
  };

  public beforeChange($event: NgbPanelChangeEvent) {
    this.panelId = $event.panelId;
    this.selAccountIndetifier = this.accontMap.get($event.panelId);
    this.acclist = this.fullAccDataMap.get($event.panelId).allData;
    this.emptyFormData();
    this.checkSelectetedKnockOff(this.panelId);
    this.filterInput();
  }

  checkSelectetedKnockOff(id?: any) {
    const selectedElimination: FormArray = this.eliminationform.get(
      'selectedElimination'
    ) as FormArray;
    if (this.fullAccDataMap.size > 0) {
      this.acclist = this.fullAccDataMap.get(id).allData;
      this.acclist.forEach((data) => {
        if (data.knock_off === true) {
          selectedElimination.push(new FormControl(data));
        }
      });
      if (selectedElimination.length > 0) {
        this.fullAccDataMap.set(id, {
          allData: this.acclist,
          selectedData: selectedElimination.value,
        });
      }
    }
  }

  validateMapData() {
    let selectedAcc = this.selAccountIndetifier.value;
    const arrayData = this.acclist;
    const selectedElimination: FormArray = this.eliminationform.get(
      'selectedElimination'
    ) as FormArray;
    if (selectedElimination.length > 0) {
      this.fullAccDataMap.set(selectedAcc, {
        allData: arrayData,
        selectedData: selectedElimination.value,
      });
    } else {
      this.fullAccDataMap.set(selectedAcc, {
        allData: arrayData,
        selectedData: [],
      });
      for (let [key, value] of this.fullAccDataMap) {
        selectedAcc = key;
      }
      for (let i = selectedElimination.length - 1; i >= 0; i--) {
        selectedElimination.removeAt(i);
      }
      this.checkSelectetedKnockOff(selectedAcc);
      this.panelId = selectedAcc;
      this.selAccountIndetifier = this.accontMap.get(selectedAcc);
      this.filterInput();
    }
    this.checkdataEmpty();
  }

  removeSpecificMapData(accId) {
    let data = this.fullAccDataMap.get(accId);
    const selectedElimination: FormArray = this.eliminationform.get(
      'selectedElimination'
    ) as FormArray;
    for (let i = selectedElimination.length - 1; i >= 0; i--) {
      selectedElimination.removeAt(i);
    }
    let remainingAcc;
    data.selectedData = [];
    data.allData.forEach((element) => {
      element.knock_off = false;
    });
    this.fullAccDataMap.set(accId, data);
    this.checkdataEmpty();
  }

  emptyFormData() {
    const selectedElimination: FormArray = this.eliminationform.get(
      'selectedElimination'
    ) as FormArray;
    for (let i = selectedElimination.length - 1; i >= 0; i--) {
      selectedElimination.removeAt(i);
    }
  }

  submitElimination() {
    this._loader.show();
    let selAcc = [];
    const obj = { entity_key: this.jwtToken.org.id, selected_accounts: [] };
    this.fullAccDataMap.forEach((fAcc) => {
      fAcc.selectedData.forEach((element) => {
        selAcc.push({
          account_id: element.account_id,
          account_identifier: element.account_identifier,
        });
      });
    });
    obj.selected_accounts = selAcc;
    this._reportService.postKnockOffAcountList(obj).subscribe(
      (data) => {
        this.resetDefault();
      },
      (err) => {
        this._loader.hide();
      }
    );
  }

  resetDefault() {
    this.fullAccDataMap.clear();
    this.getDataOninit();
  }

  clearAll() {
    this.fullAccDataMap.clear();
    this.submitElimination();
  }

  filterInput() {
    if (this.inputTest.length > 0) {
      this.filteredAcclist = this.acclist.filter((data) => {
        return data.name.toLowerCase().includes(this.inputTest.toLowerCase());
      });
    } else {
      this.filteredAcclist = this.acclist;
    }
  }

  closePopup() {
    this.activeModal.close();
  }
}
