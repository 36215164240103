import { Component } from '@angular/core';

@Component({
  selector: 'app-upload-home',
  templateUrl: './upload-home.component.html',
  styleUrls: ['./upload-home.component.scss']
})
export class UploadHomeComponent {

}
