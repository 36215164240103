import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Output,
  Input,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { JWTToken } from 'app/core/service/auth/auth';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { WorkbenchService } from 'app/service/workbench/workbench.service';


@Component({
  selector: 'app-promise-to-pay',
  templateUrl: './promise-to-pay.component.html',
  styleUrls: ['./promise-to-pay.component.scss'],
  providers: [DatePipe],
})
export class PromiseToPayComponent {
  @Output() onSubmitSuccess: EventEmitter<any> = new EventEmitter();
  @Output() getMailDetailsEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Input() invoiceKey: string;

  // @Input() p2p_key: string | null ;
  p2pKey: string | null;
  getP2PKey: string | null;
  jwtToken: JWTToken = undefined;
  public showrightDrilldown: boolean = true;
  public isPatched: boolean = false;
  public p2pForm: FormGroup;
  public p2pModeOther: any;
  public paymentModeOther: any;
  public changeEventSubscription;

  modeOfP2POptions = ['Email', 'Phone', 'Others'];
  p2pPaymentOptions = ['Cheque', 'Credit card', 'ACH', 'Others'];
  isEditing: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _workbenchService: WorkbenchService,
    private _loader: LoaderService,
    public authServices: AuthServices,
    public datepipe: DatePipe
  ) {
    this.promiseToPay();
  }

  async ngOnInit() {
    this.jwtToken = await this.authServices.getJWTDetail();
    this.getP2PKeyWithData();
    this._workbenchService.editButtonClick$.subscribe(() => {
      this.isEditing = true;
    });
    // this.getP2PData();
  }

  closeDrilldown() {
    this.showrightDrilldown = false;
  }

  promiseToPay() {
    this.p2pForm = this._formBuilder.group({
      person_name: [{ value: '', disabled: true }, Validators.required],
      promise_date: [{ value: '', disabled: true }, Validators.required],
      p2p_mode: [{ value: ''}, Validators.required],
      payment_mode: [{ value: ''}],
    });
  }

  getP2PKeyWithData(){
    this.changeEventSubscription = this._workbenchService.p2pKey$.subscribe((p2pKey: string) => {
      this.getP2PKey = p2pKey;
      if (p2pKey !== undefined) {
        this.getP2PData(this.getP2PKey);
        this.isPatched = true;
      } else {
        this.p2pForm.reset();
        this.isPatched = false;
      }
    });
  }
  onSubmit() {
    if (this.p2pForm.valid) {
      this._loader.show();
      const promiseDateValue = this.p2pForm.get('promise_date').value;
      const transformedDate = this.datepipe.transform(
        promiseDateValue,
        'yyyy-MM-dd'
      );

      const p2pBody = {
        person_name: this.p2pForm.get('person_name').value,
        p2p_mode : this.p2pForm?.get('p2p_mode').value == "Others"? this.p2pModeOther: this.p2pForm?.get('p2p_mode').value,
        payment_mode: this.p2pForm.get('payment_mode').value === 'Others' ? this.paymentModeOther : this.p2pForm.get('payment_mode').value,
        promise_date: transformedDate,
        loggedin_user: this.jwtToken.userEmail,
        invoice_key: this.invoiceKey,
      };

      if (this.isPatched) {
        this.isPatched = true;
        this.updateP2PForm(p2pBody);
        this.isEditing = true;
      } else {
        this.isPatched = false;
        this.saveP2PForm(p2pBody);
        this.isEditing = false;
      }
    }
  }

  updateP2PForm(p2pBody: any) {
    const updatedP2PBody = {
      ...p2pBody,
      key: this.getP2PKey,
    };

    this._workbenchService.updateP2PFormDetail(updatedP2PBody).subscribe(
      (_response) => {
        this._loader.hide();
        this.p2pForm.reset();
        this.onSubmitSuccess.emit();
        this.getMailDetailsEmitter.emit();
        // this.getP2PData(this.getP2PKey);
        this.isPatched = true;
      },
      (_error) => {
        this._loader.hide();
        this.p2pForm.reset();
        this.onSubmitSuccess.emit();
      }
    );
  }

  saveP2PForm(p2pBody: any) {
    this._workbenchService.postP2PForm(p2pBody).subscribe(
      (_response) => {
        this._loader.hide();
        this.p2pForm.reset();
        this.onSubmitSuccess.emit();
        this.isPatched = true;
        this._workbenchService.triggerDrilldownClose();
        // if (this.getP2PKey) {
        //   this.getP2PData(this.getP2PKey);
        // }
        this.getMailDetailsEmitter.emit();
      },
      (_error) => {
        this._loader.hide();
        this.p2pForm.reset();
        this.onSubmitSuccess.emit();
      }
    );
  }

  openPicker(_event: Event) {
    const picker = document.querySelector('mat-datepicker');
    if (picker) {
      picker.classList.toggle('hidden');
    }
  }

  getP2PData(p2pKey: string) {
    if (p2pKey !== undefined) {
    this._loader.show();
    this._workbenchService.getP2PDetail(p2pKey).subscribe(
      (data) => {
        this._loader.hide();
        if (data === null) {
          this.p2pForm.reset();
        } else {
          this.patchFormValues(data);
        }
      },
      (_err) => {
        this._loader.hide();
      }
    );
    }
  }

  patchFormValues(data: any) {

    const p2pMode = this.modeOfP2POptions.includes(data.p2p_mode);
    const paymentMode = this.p2pPaymentOptions.includes(data.payment_mode);

    this.p2pForm.patchValue({
        person_name: data.person_name || '',
        p2p_mode: p2pMode?data.p2p_mode : 'Others',
        payment_mode: paymentMode?data.payment_mode : 'Others',
    });

    if (!p2pMode) {
      this.p2pModeOther = data.p2p_mode;
    }

    if (!paymentMode) {
        this.paymentModeOther = data.payment_mode;
    }

    if (data.promise_date) {
        this.patchTransformedDateToFormControl(data.promise_date);
    }
}

  patchTransformedDateToFormControl(transformedDate: string) {
    const dateObject = new Date(transformedDate);

    if (!isNaN(dateObject.getTime())) {
      this.p2pForm.get('promise_date').setValue(dateObject);
    } else {
      this.p2pForm.get('promise_date').setValue(null);
    }
  }

  resetFormValues() {
    if(this.isPatched = false){
    this.p2pForm.reset();
    } else{
      // this.getP2PData(this.getP2PKey);
    }
  }

  enableFormControls() {
    this.p2pForm.get('person_name')?.enable();
    this.p2pForm.get('promise_date')?.enable();
  }

  disableFormControls() {
    this.p2pForm.get('person_name')?.disable();
    this.p2pForm.get('promise_date')?.disable();
  }


  ngOnDestroy(): void {
    this.changeEventSubscription.unsubscribe();
  }
}
