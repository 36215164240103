import { Component, OnInit } from '@angular/core';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { LoaderService } from 'app/core/service/loader/loader.service';

@Component({
  selector: 'app-login-listener',
  templateUrl: './login-listener.component.html',
  styleUrls: ['./login-listener.component.scss']
})
export class LoginListenerComponent implements OnInit {

  constructor(public authServices: AuthServices,private loader : LoaderService) { }

  ngOnInit(): void {
    this.loader.hide()
    console.log("LoginListenerComponent")
    this.loader.show();
    this.authServices.handleGoogleCallback().then(()=>{
      console.log("Google Success")

    }).catch((err)=>{
      console.log("Google Failure")

    })
  //   this.authServices.handleMicroSoftCallback()
  // .then(() => {
  //   console.log("Microsoft Success")

  // })
  // .catch((error) => {
  //   console.log("Microsoft Failure")

  // });

    setTimeout(() => {
      this.loader.hide()
    }, 3000);
  }

}
