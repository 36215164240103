<div  [style.height.px]="chartHeight">
<ejs-accumulationchart [legendSettings]="legendSettings" id="chart-container" [tooltip]="pieTooltipSetting">
    <e-accumulation-series-collection>
      <e-accumulation-series
        [dataSource]="chartData"
        xName="name"
        yName="y"
        legendShape="Circle"
        innerRadius="70%"
        [palettes]="palettes"
      ></e-accumulation-series>
    </e-accumulation-series-collection>
  </ejs-accumulationchart>
</div>
