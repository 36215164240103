import { Component, OnInit } from '@angular/core';
import { JWTToken } from 'app/core/service/auth/auth';
import { GrowthbookService } from 'app/core/service/growthBook/growthbook.service';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { environment } from 'environments/environment';
import LogRocket from 'logrocket';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { RoleService } from 'app/service/role/role.service';
import { WorkbenchService } from 'app/service/workbench/workbench.service';
import { DashboardService } from 'app/service/dashboard/dashboard.service';
import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from 'app/service/shared/common.service';

@Component({
  selector: 'app-full-dashboard',
  templateUrl: './full-dashboard.component.html',
  styleUrls: ['./full-dashboard.component.scss'],
})
export class FullDashboardComponent implements OnInit {
  jwtToken: JWTToken = undefined;
  gbStatus = false;
  showHomepage = false;
  public currentUrl="";
  public _DBSTAT_;
  public environment :any = environment;
  constructor(
    public authServices: AuthServices,
    public growthBookServ: GrowthbookService,
    private _loader: LoaderService,
    private _roleService: RoleService,
    private _workbenchService: WorkbenchService,
    private _dashboardService: DashboardService,
    private _router: Router,
    private _commonService :CommonService
  ) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Access the current URL here
        this._commonService.setCurrentRoute(event.url);
        // console.log('Current URL full:', this._commonService.currentRoute);
      }
    });
  }

  public gbClientlogin = false;
  async ngOnInit(): Promise<any> {
    this._loader.show();
    this.jwtToken = await this.authServices.getJWTDetail();

    await this._roleService.setRoleToMap(this.jwtToken.org.rolePerms || []);
    await this.getAutomation()
    await this.getGrowthBook();
    if (this.environment.production) {
      LogRocket.init(this.environment.logRocket.key);
      LogRocket.identify(this.jwtToken.userEmail, {
        name: this.jwtToken.userName,
        email: this.jwtToken.userEmail,
      });
    } else {
      // console.log('false');
    }
  }

  async getGrowthBook(): Promise<any> {
    this.growthBookServ.setGBookAttr({
      entityId: this.jwtToken.org.id.toString(),
    });
    this.growthBookServ.getGrowthBook().subscribe(
      (data) => {
        this.growthBookServ.setGBookFeature(data.features);
        this.gbClientlogin = this.growthBookServ.getGBookStatus('client_login');
        this.gbStatus = true;
        // this._loader.hide();
      },
      (err) => {
        this.gbStatus = true;
        this._loader.hide();
      }
    );
  }

  async getAutomation(): Promise<void> {
    this._DBSTAT_= this._dashboardService.getDashboardStatus();
    if(Object.keys(this._DBSTAT_).length > 0) {
      this.showHomepage = true;
    } else{
      this._workbenchService
        .getAutomation(this.jwtToken.org.id)
        .subscribe((automation) => {
          this._dashboardService.setDashboard(automation);
          this.showHomepage = true;
        }, error=>{
          this.showHomepage = true;
        });
    }
  }
}
