import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { environment } from 'environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { CustomPaginator } from '../paginator/custom-paginator';

export interface PeriodicElement {
  contract: string;
  customers: string;
  contracttype: string;
  amount: string;
  paymentterms: string;
  billingfrequency: string;
  contractdate: string;
  startdate: string;
  enddate: string;
  assignee: string;
}
@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ]
})
export class GridComponent implements OnInit, AfterViewInit {
  displayedColumnsEx: string[];
  // [
  //   'select',
  //   'mode',
  //   'connectors',
  //   'run_time',
  //   'name',
  //   'created_at',
  //   'created_by',
  //   'last_executed_time',
  //   'status',
  // ];
  @Input() gridData = [];
  public dataSourceEx;
  public urlSafe;
  public selection = new SelectionModel<any>(true, []);
  public pageSize = 10;
  public gridDataKeys;
  public selectOperaterOptions = [
    { key: 'Has', value: 'has',operator :['string'] },
    { key: 'Do not contain ', value: 'dnc', operator :['string'] },
    { key: 'Equals', value: '===' ,operator :['string','number']},
    { key: 'Not Equals', value: '!==', operator : ['string','number']},
    { key: 'Less than ', value: '<' ,operator :['number', 'date'] },
    { key: 'Less than or equal to ', value: '<=' ,operator :['number', 'date']},
    { key: 'Greater than', value: '>',operator :['number', 'date'] },
    { key: 'Greater than or equal to', value: '>=' ,operator :['number', 'date']},
  ];
  dsLength = this.gridData.length;
  filterForm: FormGroup;
  public isExpand = false;
  public isFilter = false;
  public selectedOption = null;
  public selectedOperater = null;
  public selectedNumOperater = null;
  public filtervalue = '';
  public isIframe = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private formBuilder: FormBuilder,
    public sanitizer: DomSanitizer,
    public loader: LoaderService,
    private authService: AuthServices
  ) {}

  ngAfterViewInit(): void {
    this.dataSourceEx.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.dataSourceEx = new MatTableDataSource<any>(this.gridData);
    this.filterForm = this.formBuilder.group({
      filters: this.formBuilder.array([this.addFilterFormGroup()]),
    });
    // this.gridDataKeys = Object.keys(this.gridData[0]);
    this.gridDataKeys = [
      {key: 'Created By' , type: 'string'},
      {key: 'Execution Mode' , type: 'string'},
      {key: 'Execution Status' , type: 'string'},
      {key: 'Last Executed Time' , type: 'number'},
      {key: 'Created At' , type: 'date'},
      {key: 'Start Time' , type: 'date'},
      {key: 'End Time' , type: 'date'},
      {key: 'Workflow Id' , type: 'string'},
      {key: 'Execution Id' , type: 'string'},
    ];

    this.displayedColumnsEx = [
      'select',
      'Name',
      'Created By',
      'Execution Mode',
      'Execution Status',
      'Last Executed Time',
      'Created At',
      'Start Time',
      'End Time',
      'Workflow Id',
      'Execution Id',
    ];
  }

  public addFilter() {
    const filters = this.filterForm.get('filters') as FormArray;
    filters.push(this.addFilterFormGroup());
  }

  send(values) {
    console.log(values);
  }

  private addFilterFormGroup() {
    return this.formBuilder.group({
      selcolumn: [],
      seloperator: [],
      selvalue: [],
    });
  }

  public multiSearch(filteredItem) {
    this.dataSourceEx = new MatTableDataSource<any>(filteredItem);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceEx.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSourceEx.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.contract + 1
    }`;
  }

  expand() {
    this.isExpand = true;
  }

  expandclose() {
    this.isExpand = false;
  }

  custfilter() {
    this.isFilter = true;
  }

  clearfilter() {
    this.selectedOption = null;
    this.selectedOperater = null;
    this.selectedNumOperater = null;
    this.filtervalue = '';
    this.dataSourceEx = new MatTableDataSource<any>(this.gridData);
  }

  applyFilter(filterValue) {
    this.dataSourceEx.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceEx.paginator) {
      this.dataSourceEx.paginator.firstPage();
    }
  }

  launchIframe(url) {
    this.isIframe = !this.isIframe;
    this.loader.show();
    if (this.isIframe) {
      setTimeout(() => {
        this.loader.hide();
      }, 3000);
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      this.loader.hide();
    }
  }
}
