<div class="modal-header">
  <div class="div-remove"  *ngIf="showConfirmButton === true" id="modal-basic-title">Are you sure?</div>
  <div class="div-remove" *ngIf="showDeleteButton === true " id="modal-basic-title">Delete Search?</div>
  <div class="div-remove" *ngIf="showDeleteConnection === true " id="modal-basic-title">Are you sure you want to disconnect?</div>
  <div class="div-remove" *ngIf="showChangeButton === true " id="modal-basic-title">Change Document Type</div>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>

	<div class="modal-body">
        <div class="div-confirm">
             {{messageText}}
        </div>
	</div>

    <div class="modal-footer">
        <button *ngIf="btnCancelText" type="button" class="btn btn-cancel" (click)="activeModal.close('cancel')">{{btnCancelText}}</button>
        <button *ngIf="showConfirmButton === true" class="btn btn-delete" ngbAutofocus (click)="activeModal.close('success')" type="button">{{btnConfirmText}}</button>
        <button *ngIf="showDeleteConnection === true" class="btn btn-delete" ngbAutofocus (click)="activeModal.close('success')" type="button">{{btnDisconnectText}}</button>
        <button *ngIf="showDeleteButton ===  true" class="btn btn-delete"  ngbAutofocus (click)="activeModal.close('success')" type="button">{{btnDeleteText}}</button>
        <button *ngIf="showChangeButton ===  true" class="btn btn-numero"  ngbAutofocus (click)="activeModal.close('success')" type="button">{{btnChangeText}}</button>
      </div>
