<div class="div-preview-modal">
    <div class="div-preview-modal-header">
        <div class="row">
            <div class="col-11 pr-0 d-inline-flex chart-header-left mb-4">
              <img class="ml-1 img-ht al-self-center"  src="{{ connection.image_url }}" alt="image"/>
                <div
                class="chart-header-title cursor-default al-self-center"
                title="{{ connection.name }}"
                >{{ connection.name }}
                </div>
            </div>
            <div class="col-1 text-right">
                <button class="btn btn-close-preview" type="button" (click)="onDismiss('Close Modal on Click')">
                    <i class="far fa-times"></i>
                </button>
            </div>
        </div>
        <div *ngIf="badRequestText">
          <p class="error-message">{{badRequestText}}</p>
        </div>
        <div class="row">
          <div class="col-12 mb-2">
            <form [formGroup]="authForm" (ngSubmit)="formSubmit(connection)" *ngIf="!isDropDown">
              <div class="row" *ngFor="let field of connection.fields">
                <div class="form-group mb-3" *ngIf="!field.field_type">
                  <input
                    type="text"
                    [formControlName]="field.api_key"
                    placeholder="{{ field.name }}"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="row">
                <div class="form-group text-right">
                  <button type="submit" class="btn btn-numero" [disabled]="sumbitDisable" >Submit</button>
                </div>
              </div>
            </form>
            <div  *ngIf="isDropDown">
              <div class="row">
                <div class="form-group mb-3">

                  <select class="form-select mb-3" [(ngModel)]="selectedDropdownValues" [ngModelOptions]="{standalone: true}" >
                    <option disabled [value]="selectedDropdownValues">-Select-</option>
                    <option *ngFor="let bR of dropdownValues"  [ngValue]="bR">{{bR.name}}</option>
                  </select>
                  <input
                    type="text"
                    [(ngModel)]="fieldValue"
                    placeholder="{{ selectedDropdownValues?.name }}"
                    class="form-control"

                  />
                </div>
              </div>
              <div class="row">
                <div class="form-group text-right">
                  <button type="button" (click)="dropDownSubmit(connection)" class="btn btn-primary">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>
