
<div class="row">
    <!-- <div class="col-md-numero mb-2" *ngIf="workflowaddnew">
        <div class="div-numero-add-card" (click)="handleClickCreateFlow()">
            <div class="text-center">
                <i class="fal fa-plus"></i>
                <div class="div-add-new-workflow">Add New Workflow</div>
            </div>
        </div>
    </div>       -->
    <ng-container *ngIf="workflowselectedKey !== 'workflow#all'">
        <ng-container *ngFor="let workflow of workflows; let i = index">
            <!-- <div class="col-md-numero mb-2">
                <div class="div-numero-card" [ngClass]="{ 'active': workflow.connectionStatus && workflow.connectionStatus === 'active' }">
                    <div class="div-img" [ngClass]="workflow.connectionStatus && workflow.connectionStatus === 'active' ? 'active' : 'inactive' ">
                        <img src="{{workflow.imageUrl}}" (click)="navigate(workflow.workflowEngineId, workflow.workflowConfigKey, workflow.template)"/>
                        <div class="custom-control custom-switch custom-switch-sm" *ngIf="workflow.template === false">
                            <input type="checkbox" class="custom-control-input" id="{{workflow.workflowConfigKey}}"  [checked]="workflow.connectionStatus === 'active'" (click)="handleToggleWorkflow($event)">
                            <label class="custom-control-label" [for]="workflow.workflowConfigKey"></label>
                        </div>
                        <div class="div-workflow-menu" ngbDropdown container="body" routerLinkActive="isActive" display="dynamic" placement="bottom-end">
                            <div class="d-flex" ngbDropdownToggle>
                                <i class="far fa-ellipsis-v"></i>
                            </div>
                            <div class="div-delete-menu" ngbDropdownMenu>
                              <button ngbDropdownItem data-bs-toggle="modal"
                              data-bs-target="#deleteFlowModal"
                              (click)="confirmDeleteFlow(workflow.workflowEngineId)"><i class="far fa-trash-alt"></i> Delete</button>
                            </div>
                        </div>
                    </div>
                    <div class="div-desc">
                        <div class="row">
                            <div class="col-md-9 div-card-desc cust-tooltip">
                                <p class="">{{workflow.name}}</p>
                                <span class="tooltiptext">{{workflow.name}}</span>
                            </div>
                            <div class="col-md-3 text-right pr5 pr" *ngIf="workflow.template === false" >
                                <div class="div-avathar first" title="{{workflow.createdBy}}">{{workflow.createdBy.charAt(0).toUpperCase()}}</div>
                            </div>
                            <div class="col-md-3 text-center pr cursor-pointer"  *ngIf="workflow.csvHeaders">
                                <div class="far fa-cloud-download" (click)="downloadSampleData(workflow)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-md-full-numero">
                <div class="row">
                    <div class="col-md-2">
                        <div class="div-logo" (click)="navigate(workflow.workflowEngineId, workflow.workflowConfigKey, workflow.template)" [ngClass]="workflow.connectionStatus && workflow.connectionStatus === 'active' ? 'active' : 'inactive' ">
                            <ng-container *ngIf="workflow.tags">
                                <ng-container *ngFor="let img of workflow.tags.split(',')">
                                    <div class="div-img" *ngIf="img !== 'accounts receivables' && img !== 'accounts payables'">
                                        <img src="{{environment.imagePrefixURI}}/{{img}}.svg" />
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!workflow.tags">
                                <div class="div-img">
                                    <img src="{{environment.imagePrefixURI}}/generic.svg" />
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="v-h-center" [ngClass]="{'col-md-4': isItWorkflow, 'col-md-9': !isItWorkflow}">
                        <div class="div-workflow-header" (click)="navigate(workflow.workflowEngineId, workflow.workflowConfigKey, workflow.template)">
                            <p class="">{{workflow.name}}</p>
                            <span class="tooltiptext">{{workflow.name}}</span>
                        </div>
                    </div>
                    <div class="col-md-1 v-h-center">
                        <ng-container *ngIf="workflow.tags">
                            <ng-container *ngFor="let wtag of workflow.tags.split(',')">
                                <div *ngIf="wtag === 'accounts receivables'">
                                    AR
                                </div>
                                <div *ngIf="wtag === 'accounts payables'">
                                    AP
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                    <div class="col-md-1 v-h-center" *ngIf="isItWorkflow">
                        <div class="div-avathar first" title="{{workflow.createdBy}}">{{workflow.createdBy?.charAt(0).toUpperCase()}}</div>
                    </div>
                    <div class="col-md-2 v-h-center pl-0 pr-0" *ngIf="isItWorkflow">
                        <div>Last Update 24hr ago</div>
                    </div>
                    <div class="col-md-1 v-h-center" *ngIf="isItWorkflow">
                        <div class="custom-control custom-switch custom-switch-md" *ngIf="workflow.template === false">
                            <input type="checkbox" class="custom-control-input" id="{{workflow.workflowConfigKey}}"  [checked]="workflow.connectionStatus === 'active'" (click)="handleToggleWorkflow($event)">
                            <label class="custom-control-label" [for]="workflow.workflowConfigKey"></label>
                        </div>
                    </div>
                    <div class="col-md-1 v-h-center" *ngIf="isItWorkflow">
                        <div class="div-workflow-delete" ngbDropdown container="body" routerLinkActive="isActive" display="dynamic" placement="bottom-end">
                            <div class="d-flex" ngbDropdownToggle>
                                <i class="far fa-ellipsis-h"></i>
                            </div>
                            <div class="div-delete-menu" ngbDropdownMenu>
                              <button ngbDropdownItem data-bs-toggle="modal"
                              data-bs-target="#deleteFlowModal"
                              (click)="confirmDeleteFlow(workflow.workflowEngineId)"><i class="far fa-trash-alt"></i> Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <!-- Delete confirm -->
  <div
  class="modal fade"
  id="deleteFlowModal"
  tabindex="-1"
  aria-labelledby="deleteFlowModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Delete Workflow</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p>
          <strong> Are you sure you want to delete the workflow? </strong>
        </p>
        <p>
          Type <strong>“{{deleteworkflowtext}}”</strong> to proceed?
        </p>
        <div class="dialog-data-sources-name df-header text-center">
          <input
            class="form-control"
            type="text"
            [(ngModel)]="deleteConfText"
            placeholder="{{deleteworkflowtext}}"
            (keyup)="validateConfirmationMessage()"
          />
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          data-bs-toggle="modal"
          class="btn btn-numero mr-2"
          (click)="removeWorkflow()"
          [disabled]="showAlert !== false"
        >
          Delete
        </button>
        <button
          type="button"
          data-bs-dismiss="modal"
          class="btn btn-secondary"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
    
    <ng-container *ngIf="workflowselectedKey === 'workflow#all'">        
        <ng-container *ngFor="let workflow of activeworkflow; let i = index">
            <!-- <div class="col-md-numero mb-2">
                <div class="div-numero-card" [ngClass]="{ 'active': workflow.connectionStatus && workflow.connectionStatus === 'active' }">
                    <div class="div-img" [ngClass]="workflow.connectionStatus && workflow.connectionStatus === 'active' ? 'active' : 'inactive' ">
                        <img src="{{workflow.imageUrl}}" (click)="navigate(workflow.workflowEngineId, workflow.workflowConfigKey, workflow.template)"/>
                        <div class="custom-control custom-switch custom-switch-sm" *ngIf="workflow.template === false">
                            <input type="checkbox" class="custom-control-input" id="{{workflow.workflowConfigKey}}"  [checked]="workflow.connectionStatus === 'active'" (click)="handleToggleWorkflow($event)">
                            <label class="custom-control-label" [for]="workflow.workflowConfigKey"></label>
                        </div>
                        <div class="div-workflow-menu" ngbDropdown container="body" routerLinkActive="isActive" display="dynamic" placement="bottom-end">
                            <div class="d-flex" ngbDropdownToggle>
                                <i class="far fa-ellipsis-v"></i>
                            </div>
                            <div class="div-delete-menu" ngbDropdownMenu>
                              <button ngbDropdownItem data-bs-toggle="modal"
                              data-bs-target="#deleteFlowModal"
                              (click)="confirmDeleteFlow(workflow.workflowEngineId)"><i class="far fa-trash-alt"></i> Delete</button>
                            </div>
                        </div>
                    </div>
                    <div class="div-desc">
                        <div class="row">
                            <div class="col-md-9 div-card-desc cust-tooltip">
                                <p class="">{{workflow.name}}</p>
                                <span class="tooltiptext">{{workflow.name}}</span>
                            </div>
                            <div class="col-md-3 text-right pr5 pr" *ngIf="workflow.template === false" >
                                <div class="div-avathar first" title="{{workflow.createdBy}}">{{workflow.createdBy.charAt(0).toUpperCase()}}</div>
                            </div>
                            <div class="col-md-3 text-center pr cursor-pointer"  *ngIf="workflow.csvHeaders">
                                <div class="far fa-cloud-download" (click)="downloadSampleData(workflow)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-md-full-numero">
                <div class="row">
                    <div class="col-md-2">
                        <div class="div-logo" (click)="navigate(workflow.workflowEngineId, workflow.workflowConfigKey, workflow.template)" [ngClass]="workflow.connectionStatus && workflow.connectionStatus === 'active' ? 'active' : 'inactive' ">
                            <ng-container *ngIf="workflow.tags">
                                <ng-container *ngFor="let img of workflow.tags.split(',')">
                                    <div class="div-img" *ngIf="img !== 'accounts receivables' && img !== 'accounts payables'">
                                        <img src="{{environment.imagePrefixURI}}/{{img}}.svg" />
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!workflow.tags">
                                <div class="div-img">
                                    <img src="{{environment.imagePrefixURI}}/generic.svg" />
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-md-4 v-h-center">
                        <div class="div-workflow-header">
                            <p class="">{{workflow.name}}</p>
                            <span class="tooltiptext">{{workflow.name}}</span>
                        </div>
                    </div>
                    <div class="col-md-1 v-h-center">
                        <ng-container *ngIf="workflow.tags">
                            <ng-container *ngFor="let wtag of workflow.tags.split(',')">
                                <div *ngIf="wtag === 'accounts receivables'">
                                    AR
                                </div>
                                <div *ngIf="wtag === 'accounts payables'">
                                    AP
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                    <div class="col-md-1 v-h-center">
                        <div class="div-avathar first" title="{{workflow.createdBy}}">{{workflow.createdBy?.charAt(0).toUpperCase()}}</div>
                    </div>
                    <div class="col-md-2 v-h-center">
                        <div>Last Update 24hr ago</div>
                    </div>
                    <div class="col-md-1 v-h-center">
                        <div class="custom-control custom-switch custom-switch-md" *ngIf="workflow.template === false">
                            <input type="checkbox" class="custom-control-input" id="{{workflow.workflowConfigKey}}"  [checked]="workflow.connectionStatus === 'active'" (click)="handleToggleWorkflow($event)">
                            <label class="custom-control-label" [for]="workflow.workflowConfigKey"></label>
                        </div>
                    </div>
                    <div class="col-md-1 v-h-center">
                        <div class="div-workflow-delete" ngbDropdown container="body" routerLinkActive="isActive" display="dynamic" placement="bottom-end">
                            <div class="d-flex" ngbDropdownToggle>
                                <i class="far fa-ellipsis-h"></i>
                            </div>
                            <div class="div-delete-menu" ngbDropdownMenu>
                              <button ngbDropdownItem data-bs-toggle="modal"
                              data-bs-target="#deleteFlowModal"
                              (click)="confirmDeleteFlow(workflow.workflowEngineId)"><i class="far fa-trash-alt"></i> Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="div-sep"><h2><span>Inactive</span></h2></div>
        <ng-container *ngFor="let workflow of inactiveworkflow; let i = index">
            <!-- <div class="col-md-numero mb-2">
                <div class="div-numero-card" [ngClass]="{ 'active': workflow.connectionStatus && workflow.connectionStatus === 'active' }">
                    <div class="div-img" [ngClass]="workflow.connectionStatus && workflow.connectionStatus === 'active' ? 'active' : 'inactive' ">
                        <img src="{{workflow.imageUrl}}" (click)="navigate(workflow.workflowEngineId, workflow.workflowConfigKey, workflow.template)"/>
                        <div class="custom-control custom-switch custom-switch-sm" *ngIf="workflow.template === false">
                            <input type="checkbox" class="custom-control-input" id="{{workflow.workflowConfigKey}}"  [checked]="workflow.connectionStatus === 'active'" (click)="handleToggleWorkflow($event)">
                            <label class="custom-control-label" [for]="workflow.workflowConfigKey"></label>
                        </div>
                        <div class="div-workflow-menu" ngbDropdown container="body" routerLinkActive="isActive" display="dynamic" placement="bottom-end">
                            <div class="d-flex" ngbDropdownToggle>
                                <i class="far fa-ellipsis-v"></i>
                            </div>
                            <div class="div-delete-menu" ngbDropdownMenu>
                              <button ngbDropdownItem data-bs-toggle="modal"
                              data-bs-target="#deleteFlowModal"
                              (click)="confirmDeleteFlow(workflow.workflowEngineId)"><i class="far fa-trash-alt"></i> Delete</button>
                            </div>
                        </div>
                    </div>
                    <div class="div-desc">
                        <div class="row">
                            <div class="col-md-9 div-card-desc cust-tooltip">
                                <p class="">{{workflow.name}}</p>
                                <span class="tooltiptext">{{workflow.name}}</span>
                            </div>
                            <div class="col-md-3 text-right pr5 pr" *ngIf="workflow.template === false" >
                                <div class="div-avathar first" title="{{workflow.createdBy}}">{{workflow.createdBy.charAt(0).toUpperCase()}}</div>
                            </div>
                            <div class="col-md-3 text-center pr cursor-pointer"  *ngIf="workflow.csvHeaders">
                                <div class="far fa-cloud-download" (click)="downloadSampleData(workflow)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-md-full-numero">
                <div class="row">
                    <div class="col-md-2">
                        <div class="div-logo" (click)="navigate(workflow.workflowEngineId, workflow.workflowConfigKey, workflow.template)" [ngClass]="workflow.connectionStatus && workflow.connectionStatus === 'active' ? 'active' : 'inactive' ">
                            <ng-container *ngIf="workflow.tags">
                                <ng-container *ngFor="let img of workflow.tags.split(',')">
                                    <div class="div-img" *ngIf="img !== 'accounts receivables' && img !== 'accounts payables'">
                                        <img src="{{environment.imagePrefixURI}}/{{img}}.svg" />
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!workflow.tags">
                                <div class="div-img">
                                    <img src="{{environment.imagePrefixURI}}/generic.svg" />
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-md-4 v-h-center">
                        <div class="div-workflow-header">
                            <p class="">{{workflow.name}}</p>
                            <span class="tooltiptext">{{workflow.name}}</span>
                        </div>
                    </div>
                    <div class="col-md-1 v-h-center">
                        <ng-container *ngIf="workflow.tags">
                            <ng-container *ngFor="let wtag of workflow.tags.split(',')">
                                <div *ngIf="wtag === 'accounts receivables'">
                                    AR
                                </div>
                                <div *ngIf="wtag === 'accounts payables'">
                                    AP
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                    <div class="col-md-1 v-h-center">
                        <div class="div-avathar first" title="{{workflow.createdBy}}">{{workflow.createdBy.charAt(0).toUpperCase()}}</div>
                    </div>
                    <div class="col-md-2 v-h-center">
                        <div>Last Update 24hr ago</div>
                    </div>
                    <div class="col-md-1 v-h-center">
                        <div class="custom-control custom-switch custom-switch-md" *ngIf="workflow.template === false">
                            <input type="checkbox" class="custom-control-input" id="{{workflow.workflowConfigKey}}"  [checked]="workflow.connectionStatus === 'active'" (click)="handleToggleWorkflow($event)">
                            <label class="custom-control-label" [for]="workflow.workflowConfigKey"></label>
                        </div>
                    </div>
                    <div class="col-md-1 v-h-center">
                        <div class="div-workflow-delete" ngbDropdown container="body" routerLinkActive="isActive" display="dynamic" placement="bottom-end">
                            <div class="d-flex" ngbDropdownToggle>
                                <i class="far fa-ellipsis-h"></i>
                            </div>
                            <div class="div-delete-menu" ngbDropdownMenu>
                              <button ngbDropdownItem data-bs-toggle="modal"
                              data-bs-target="#deleteFlowModal"
                              (click)="confirmDeleteFlow(workflow.workflowEngineId)"><i class="far fa-trash-alt"></i> Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>