import { AfterViewInit, Component, ViewChild } from '@angular/core';
import {
  PageService,
  PageSettingsModel,
  QueryCellInfoEventArgs,
  RowDataBoundEventArgs,
  ToolbarService,
  ExcelExportService,
  PdfExportService
} from '@syncfusion/ej2-angular-grids';
import { ITreeData, TreeGridExcelExportProperties, TreeGridPdfExportProperties } from '@syncfusion/ej2-treegrid';
import { FreezeService, TreeGridComponent } from '@syncfusion/ej2-angular-treegrid';
import { ReportService } from 'app/service/report/report.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { JWTToken } from 'app/core/service/auth/auth';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { Chart } from 'angular-highcharts';
import { Router } from '@angular/router';
import { ILoadedEventArgs, Series, ChartTheme ,
  IAxisLabelRenderEventArgs,
 } from '@syncfusion/ej2-angular-charts';
import { getElement } from '@syncfusion/ej2-charts';
import { Browser } from '@syncfusion/ej2-base';
import { ChartService } from 'app/service/shared/chart.service';
import { CustomCurrencyPipe } from 'app/shared/utills/currency.pipe';
import { CalendarView, DateRangePickerComponent } from '@syncfusion/ej2-angular-calendars';
import { GrowthbookService } from 'app/core/service/growthBook/growthbook.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-cash-flow',
  templateUrl: './cash-flow.component.html',
  styleUrls: ['./cash-flow.component.scss'],
  providers: [ToolbarService, PageService, ExcelExportService, PdfExportService, FreezeService,CustomCurrencyPipe,DatePipe]
})
export class CashFlowComponent implements AfterViewInit {
  constructor(
    private _reportService: ReportService,
    public authServices: AuthServices,
    private loader: LoaderService,
    public _router: Router,
    private chartService: ChartService,
    private _customCurrencyPipe: CustomCurrencyPipe,
    // public growthBookServ: GrowthbookService,
    public datepipe: DatePipe
  ) { }

  @ViewChild('datePicker') datePicker: DateRangePickerComponent;
  @ViewChild('treegrid')
  public treegrid: TreeGridComponent;
  public gridData: Object[] = [];
  public formatedData: Object[] = [];
  public pageSettings: PageSettingsModel;
  public toolbar: string[];
  public widgetData: any = {};
  public tableHeader = [];
  public timeranges = [{ name: 'YTD', end_date: '2023-05-20', start_date: '2023-01-01' }];
  public selectedPeriod = { name: 'YTD', end_date: '2023-05-20', start_date: '2023-01-01' };
  public customAttributes: Object;
  public selectedAccount = 'Customer';
  public height: number;
  public screenHeight: any;
  jwtToken: JWTToken = undefined;
  public collapseStatePersist: boolean = true;
  public showHideWidget = undefined;

  public totalIncome = 0;
  public cogs = 0;
  public operatingExpense = 0;
  public netIncome = 0;

  public chartData: any;

  public selAcc = { name: '', value: '',currency:''};
  public accounts = [];
  public color = ['#0D3D86', '#F4AC45', '#499167']

  public showChart = true;

  public cashInflow = 17600;
  public cashOutflow = 17203;
  public operatingCashflow = 397;

  //Initializing Primary X Axis
  public primaryXAxis: Object = {
    majorGridLines: { width: 0 }, valueType: 'Category', majorTickLines: { width: 0 }, minorTickLines: { width: 0 }, lineStyle: { width: 0 }, labelStyle: {
      fontFamily: 'Inter,sans-serif',
      color: '#8D8F95',
    },
  };
  //Initializing Primary Y Axis
  public primaryYAxis: Object = {
    title: '', edgeLabelPlacement: 'Shift', majorGridLines: { color: '#C5C5C5', width: 1, dashArray: 5 }, lineStyle: { width: 0 }, majorTickLines: { width: 0 }, minorTickLines: { width: 0 }, labelStyle: {
      fontFamily: 'Inter,sans-serif',
      color: '#8D8F95',
    },
  };
  public marker: Object = {
    visible: true,
    width: 7, height: 7, isFilled: true
  };
  public nonMarker: Object = {
    visible: false,
  };
  public tooltip: Object = {
    enable: true,
    shared: true
  };
  public legendSettings: Object = {
    visible: false
  };
  public animation1: Object = {
    enable: false
  };
  public chartArea: Object = {
    border: {
      width: 0
    }
  };
  // custom code start
  public width: string = Browser.isDevice ? '100%' : '100%';
  public load(args: ILoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.chart.theme = <ChartTheme>(selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark");
  }
  // custom code end
  public vertical: boolean = false;
  public count: number = 0;

  public palette = [
    '#4D81E5', '#E7854D', '#32B19A'
  ];

  public axis: Object = [{
    title: '',
    majorGridLines: { color: '#C5C5C5', width: 1, dashArray: 5 },
    minorGridLines: { width: 0 },
    majorTickLines: { width: 0 },
    opposedPosition: true,
    lineStyle: { width: 0 },
    edgeLabelPlacement: 'Shift',
    name: 'yAxis2',
    labelFormat: '{value}'
  }];

  cornerRadius: Object = {
    bottomLeft: 4,
    bottomRight: 4,
    topRight: 4,
    topLeft: 4,
  };

  public title: string = '';

  
  public start: CalendarView = 'Year';
  public depth: CalendarView = 'Year';
  public format: string = 'MMM yyyy';

  public startDate : any;
  public endDate : any;
  public setdateperiod = 'twelwe';
  
  // public growthBookCustom = this.growthBookServ.getGBookStatus('reports_custom_date');

  ngOnInit() {
    this.widgetData = {
      widgetImage: `https://s3.amazonaws.com/dev-workflow.numero.app/assets/image/bg-image/Report-sample.svg`,
      widgetClassname: 'report-widget',
      widgetHeading: 'Connect your Accounting system',
      widgetDesc:
        'Consolidate multiple accounts and visualise - P&L , Cash Flow, Expense & Balance Sheet',
      widgetBtnText: 'Connect',
      widgetBtnNavigation: '',
      widgetBgImageUrl: `https://s3.amazonaws.com/live.dataflo.io/assets/image/dashboard-bg.png`,
    };
    this.loader.show();
    this.pageSettings = { pageSize: 10 };
    this.customAttributes = { class: 'customcss' };
    this.fetchTimeRange();
    //this.screenHeight = window.innerHeight - 300;
  }

  public multibarchart: Chart;

  ngAfterViewInit(): void {
    // this.treegrid.grid.height = 500;
    // setTimeout(() => {
    //   this.treegrid.grid.height = this.screenHeight;
    // },300);
  }

  pdfDownload() {
    let pdfExportProperties: TreeGridPdfExportProperties = {
      isCollapsedStatePersist: this.collapseStatePersist
    };
    this.treegrid.pdfExport(pdfExportProperties);
  }

  excelDownload() {
    let excelExportProperties: TreeGridExcelExportProperties = {
      isCollapsedStatePersist: this.collapseStatePersist
    };
    this.treegrid.excelExport(excelExportProperties);
  }
  childButtonClicked(event) {
    this._router.navigate(['connectors']);
  }
  queryCellInfo(args: QueryCellInfoEventArgs) {
    if (!(args.data as ITreeData).hasChildRecords) {
      if ((args.cell as HTMLElement).classList.contains('e-unboundcell')) {
        (
          (args.cell as HTMLElement).querySelector(
            '.e-unboundcelldiv'
          ) as HTMLElement
        ).style.display = 'none';
      }
    }
  }
  rowDataBound(args: RowDataBoundEventArgs) {
    if (!(args.data as ITreeData).hasChildRecords) {
      // (args.row as HTMLElement).style.backgroundColor = 'green';
      (args.row as HTMLElement).classList.add('child-row');
    }
  }
  dataBound(event): void {
    this.treegrid.grid.hideScroll();
  }

  async fetchTimeRange() {
    this.jwtToken = await this.authServices.getJWTDetail();
    this._reportService
      .getCashflowFilter(this.jwtToken.org.id)
      .subscribe(
        (data) => {
          this.accounts = data.accounts;
          this.timeranges = data.timerange;
          this.selectedPeriod = this.timeranges[0];
          // if (this.growthBookCustom) {
            this.timeranges.push({ name:'Custom',end_date: this.endDate, start_date: this.startDate});
          // }
          if (this.accounts.length > 0) {
            this.selAcc = this.accounts.filter(d => d.value === this._reportService.getAccount().value)[0]
            this.showHideWidget = false;
            this.fetchCashFlow()
          } else {
            this.loader.hide();
            this.showHideWidget = true
          }
        },
        (err) => {
          this.loader.hide();
        }
      );

  }

  fetchCashFlow(): void {
    this._reportService.setAccount(this.selAcc)
    this.loader.show()
    this.tableHeader = [];
    this.gridData =[];
    this._reportService
      .getCashflow(this.jwtToken.org.id, this.selectedPeriod.start_date, this.selectedPeriod.end_date, this.selAcc.value)
      .subscribe(
        (data) => {
          this.tableHeader = data.header;
          this.setdateperiod = this._reportService.getSelectedPeriod(this.selectedPeriod,this.startDate,this.endDate,this.tableHeader.length);
          this.gridData = data.data;
          this.chartData = this.formatDataForChart();
          this.formatedData=data.data;
          this.formatedData.map(grid => {
            for (const key in grid) {
              if (!isNaN(Number(grid[key])) && !Array.isArray(grid[key])) {
                grid[key] = parseFloat(grid[key])
              }
            }
          })
          this.formatedData.forEach((grid: any) => {
            Object.entries(grid).forEach(([key, value]) => {
              // if (key ==='total') {

              //   grid[key] = this.currencyPipe.transform(value as string | number, this.selAcc.currency);
              // }
              // if(grid.accountName==='Total Operating Cashflow'&& key!=='subAccount'&&key!=='accountName')

              if(key!=='subAccount'&&key!=='accountName')
              {
                grid[key] = this._customCurrencyPipe.transform(value as string | number, this.selAcc.currency);
              }
            });
          });
          // console.log(key , 'sadsadwqd', grid[key] , 'dsadsa' , Number(grid[key]) ,'check', !isNaN(Number(grid[key])) )
          this.treegrid.dataSource = this.formatedData
          this.loader.hide();
          setTimeout(() => {
            this.treegrid.expandAtLevel(0);
          }, 1000);
          //this.multibarchart = this.createChartLine(chartData.chartKeys , chartData.chartData);
        },
        (err) => {
          this.loader.hide();
        }
      );
  }

  public formatDataForChart(): any {
    const objKeys = Object.keys(this.gridData[0])
    const itemTobeRemoved = ["total", "subAccount", "accountName"]
    const chartKeys = this.removeItems(objKeys, itemTobeRemoved)

    let obj: any = {}
    let lineData = []
    let chartData = []
    this.gridData.forEach((gD: any) => {
      lineData = [];
      obj = {};
      if (gD.accountName === 'Cash Outflow' || gD.accountName === 'Cash Inflow' || gD.accountName === 'Total Operating Cashflow') {

        if (gD.accountName === 'Cash Outflow') {
          this.cashOutflow = gD.total | 0;
          obj.type = "Column";
          obj.marker = this.nonMarker
        }
        if (gD.accountName === 'Cash Inflow') {
          this.cashInflow = gD.total | 0;
          obj.type = "Column";
          obj.marker = this.nonMarker

        }
        if (gD.accountName === 'Total Operating Cashflow') {
          this.operatingCashflow = gD.total | 0;
          obj.type = "Line";
          obj.marker = this.marker

        }


        // lineData = []
        // obj= {}
        chartKeys.forEach(ck => {
          lineData.push({ column: parseFloat(gD[ck]), year: ck })
        })
        obj.name = gD.accountName
        obj.data = lineData
        chartData.push(obj)
      }
    })
    return chartData;
  }
  removeItems = (objKeys, itemToRemitemTobeRemovedove) => {
    return objKeys.filter(v => {
      return !itemToRemitemTobeRemovedove.includes(v);
    });
  }

  hidechart() {
    this.showChart = false;
  }
  showchart() {
    this.showChart = true;
  }
  OnChartAxisLabeRender(args: IAxisLabelRenderEventArgs) {
    this.chartService.formatAxisLabel(args);
  }

  dropdownChangedEvent(selaccount) {
    this.selAcc = selaccount;
    this.fetchCashFlow();
  }

  dropdownTimeChangedEvent(seltime) {
    this.selectedPeriod = seltime;
    if (this.selectedPeriod.name !== 'Custom') {
      this.fetchCashFlow();
    } else {
      this.openDatePicker();
    }
  }

  openDatePicker() {
    setTimeout(() => {
      this.datePicker.show();
    }, 1);
  }

  onDateChange(event: any) {
    if (event && event.value) {
      const selectedDates = event.value;
      this.startDate = this.datepipe.transform(selectedDates[0], 'yyyy-MM-dd');
      this.endDate = this.datepipe.transform(selectedDates[1], 'yyyy-MM-dd');
      this.selectedPeriod = {
        name: 'Custom',
        end_date: this.endDate,
        start_date: this.startDate,
      };
      this.fetchCashFlow();    
    }
  }
}
