import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-data-card',
  templateUrl: './data-card.component.html',
  styleUrls: ['./data-card.component.scss']
})
export class DataCardComponent {
  @Input() metricData;
  @Input() chartHeight;

  checkNumeric(value){
    return isNaN(value)
  }
}
