import { Component, Input, AfterViewInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import * as pdfjsLib from 'pdfjs-dist';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements AfterViewInit {
  @Input() pdfSrc!: string;
  @Input() scale: number = 1;
  @ViewChild('pdfContainer') pdfContainer!: ElementRef<HTMLDivElement>;
  pages: { canvas: HTMLCanvasElement }[] = [];
  totalPages: number = 0;
  public boundingBoxData = {
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0
  };
  private pdfDocument: any;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    if (this.pdfContainer) {
      this.renderPdf();
    }
  }

  renderPdf() {
    const pdfContainer = this.pdfContainer.nativeElement;
    pdfjsLib.getDocument({ data: atob(this.pdfSrc) })
      .promise
      .then((pdfDocument: any) => {
        this.pdfDocument = pdfDocument;
        this.totalPages = pdfDocument.numPages;
        for (let pageNum = 1; pageNum <= this.totalPages; pageNum++) {
          pdfDocument.getPage(pageNum).then((page: any) => {
            const canvas = this.renderer.createElement('canvas');
            const context = canvas.getContext('2d');
            const viewport = page.getViewport({ scale: this.scale });
            canvas.width = viewport.width;
            canvas.height = viewport.height;
            const renderContext = {
              canvasContext: context,
              viewport: viewport
            };
            page.render(renderContext);
            this.pages.push({ canvas: canvas });
            this.renderer.appendChild(pdfContainer, canvas);
          });
        }
      });
  }

  clearCanvas() {
    this.pages.forEach(page => {
      const canvas = page.canvas;
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
    });
  }

  getRectangleStyles(coord: any) {
    return {
      top: coord.y1 + 'px',
      left: coord.x1 + 'px',
      width: (coord.x2 - coord.x1) + 'px',
      height: (coord.y2 - coord.y1) + 'px'
    };
  }

  highlightPdf(coordinates: any[]) {
    this.clearCanvas();

    this.pages.forEach((page, pageIndex) => {
      const canvas = page.canvas;
      const context = canvas.getContext('2d');
      const viewport = page.canvas.width / this.scale;

      this.pdfDocument.getPage(pageIndex + 1).then((pageData: any) => {
        const viewport = pageData.getViewport({ scale: this.scale });

        const canvasCopy = document.createElement('canvas');
        const copyContext = canvasCopy.getContext('2d');

        canvasCopy.width = viewport.width;
        canvasCopy.height = viewport.height;

        pageData.render({
          canvasContext: copyContext,
          viewport: viewport
        }).promise.then(() => {
          coordinates.forEach(coord => {
            const rectangleStyles = this.getRectangleStyles(coord);
            const top = parseFloat(rectangleStyles.top);
            const left = parseFloat(rectangleStyles.left);
            const width = parseFloat(rectangleStyles.width);
            const height = parseFloat(rectangleStyles.height);

            copyContext.fillStyle = 'rgba(255, 255, 0, 0.5)';
            copyContext.fillRect(left, top, width, height);
          });

          context.drawImage(canvasCopy, 0, 0);
        });
      });
    });
  }
}
