import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class STAuthService {

  constructor(private http: HttpClient) {}

  resignJWTCustomClaim = (claimBody: any): Promise<any> => {
    return this.http.put(environment.auth.gatewayURL + `/auth/custom-claims`, claimBody).toPromise();
  }

  createBasicUser = (email: string): Promise<any> => {
    return this.http.post(environment.auth.gatewayURL + `/auth/invite-user?email=${email}`, null, {responseType: 'text'}).toPromise();
  }

}
