import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { ConnectorComponent } from './connectors/connector-home/connector-home.component';
import { LoginListenerComponent } from './core/component/login-listener/login-listener.component';
import { LoginComponent } from './core/component/login/login.component';
import { MasterGuard } from './core/guards/master.guard';
import { FullDashboardComponent } from './full-dashboard/component/full-dashboard/full-dashboard.component';
import { WorkflowEditorComponent } from './workflow/workflow-editor/workflow-editor.component';
import { WorkflowHomeComponent } from './workflow/workflow-home/workflow-home.component';
import { SupportLoginComponent } from './core/component/support-login/support-login.component';
import { ResetPasswordComponent } from './core/component/reset-password/reset-password.component';
import { AiModule } from './ai/ai.module';
import { VendorManagementModule } from './vendor-management/vendor-management.module';
import { NavigationComponent } from './shared/component/navigation/navigation.component';
import { CannyWidgetComponent } from './shared/external-tool/canny-widget/canny-widget.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    component: SupportLoginComponent
  },
  {
    path: 'support',
    component: LoginComponent
  },
  {
    path: 'auth/callback/google',
    component: LoginListenerComponent
  },
  {
    path: 'auth/callback/active-directory',
    component: LoginListenerComponent
  },
  {
    path: 'logout',
    component: LoginComponent
  },
{
    path: 'reset-password',
    component: ResetPasswordComponent
  },

  {
    path: '',
    component: FullDashboardComponent,
     canActivate: [MasterGuard],
     children: [
      {
       path: 'redirect',
       component: NavigationComponent
      },
      // {
      //   path: 'feedback',
      //   component: CannyWidgetComponent,
      // },
      // {
      //   path: 'feature',
      //   component: CannyWidgetComponent,
      // },
      // {
      //   path: 'dashboard',
      //   loadChildren : () => import('./dashboard/dashboard.module').then(m=> m.DashboardModule)
      // },
      {
        path: 'process-model',
        loadChildren : () => import('./process-flowchart/process-flowchart.module').then(m=> m.ProcessFlowchartModule)
      },
      {
        path: 'reconciliation',
        loadChildren : () => import('./reconciliation/reconciliation.module').then(m=> m.ReconciliationModule)
      },
      {
        path: 'workflow',
        loadChildren : () => import('./workflow/workflow.module').then(m=> m.WorkflowModule)
      },
      {
        path: 'connectors',
        loadChildren : () => import('./connectors/connectors.module').then(m=> m.ConnectorsModule)
      },
      {
        path: 'settings',
        loadChildren : () => import('./settings/settings.module').then(m=> m.SettingsModule)
      },
      // {
      //   path: 'document-dashboard',
      //   loadChildren : () => import('./documentdashboard/documentdashboard.module').then(m=> m.DocumentdashboardModule)
      // },
      {
        path: 'report',
        loadChildren : () => import('./reporting/reporting.module').then(m=> m.ReportingModule)
      },
      {
        path: 'import',
        loadChildren : () => import('./bulk-upload/bulk-upload.module').then(m=> m.BulkUploadModule)
      },
      {
        path: 'subscription',
        loadChildren : () => import('./subscription/subscription.module').then(m=> m.SubscriptionModule)
      },
      {
        path: 'workbench/ar/report',
        loadChildren : () => import('./workbench-ar-report/workbench-ar-report.module').then(m=> m.WorkbenchArReportModule)
      },
      {
        path: 'workbench/old-ar/automation',
        loadChildren : () => import('./workbench-ar-automation/workbench-ar-automation.module').then(m=> m.WorkbenchArAutomationModule)
      },
      {
        path: 'workbench/ap/report',
        loadChildren : () => import('./workbench/workbench.module').then(m=> m.WorkbenchModule)
      },
      {
        path: 'workbench/ap/automation',
        loadChildren : () => import('./workbench-v2/workbench-v2.module').then(m=> m.WorkbenchV2Module)
      },
      {
        path: 'workbench/new-ap/automation',
        loadChildren : () => import('./workbench-ap-automation/workbench-ap-automation.module').then(m=> m.WorkbenchApAutomationModule)
      },
      {
        path: 'workbench/ar/automation',
        loadChildren : () => import('./workbench-ar-automation-filter/workbench-ar-automation-filter.module').then(m=> m.WorkbenchArAutomationFilterModule)

      },
      // {
      //   path:'ai',
      //   loadChildren : () => import('./ai/ai.module').then(m=>AiModule)
      // },
      {
        path:'vendor',
        loadChildren : () => import('./vendor-management/vendor-management.module').then(m=>VendorManagementModule)
      }
     ]
  },
  // { path: 'numero/**', redirectTo: '/connectors' },
  { path: '**', redirectTo: 'redirect' },
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
