<!-- <app-side-bar></app-side-bar> -->
<div class="div-numero-container div-main-content">
    <div class="row">
        <div class="col-md-numero" >
            <div class="div-numero-card">
                <div class="div-doc-name">
                    Bank Transactions
                </div>
                <div class="div-desc">
                    <div class="row">
                        <div class="col-md-12 div-card-desc text-center">
                            <div class="upload-btn-wrapper cursor-pointer">
                                <button class="btn btn-numero">Upload CSV File</button>
                                <input type="file" id="file-input" (change)="uploadBankFile($event)"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-numero" >
            <div class="div-numero-card">
                <div class="div-doc-name">
                    Invoice Transactions
                </div>
                <div class="div-desc">
                    <div class="row">
                        <div class="col-md-12 div-card-desc text-center">
                            <div class="upload-btn-wrapper cursor-pointer">
                                <button class="btn btn-numero">Upload CSV File</button>
                                <input type="file" id="file-input" (change)="uploadInvoiceFile($event)"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 text-center mt-5">
            <button class="btn btn-numero">Submit</button>
        </div>
    </div>
</div>

