<div class="div-mes-body" *ngIf="!showSecondQues">
    <div class="container div-ai-home">
        <div class="div-ai-home-content">

            <div class="row" *ngIf="!showSecondQues">
                <div class="col-md-2  d-flex justify-content-center">
                    <img src="/assets/image/temp/ai-con-new.svg" alt="image" class="position-ai-img-fix"/>
                </div>
                <div class="col-md-8">
                    <div>
                        <p class="prmpt-lib-txt"><span class=""></span>Prompt Library</p>
                        <p class="txt-head-prompt">Here is your list of
                            prompt library related to Account Receivables</p>
                        <div class="row">
                            <div *ngFor="let item of promptData" class="col-md-3 mt-3 hover-prompt-box">
                                <div class="outer-prompt-box">
                                    <div class="promptcard cursor-pointer">
                                        <i class="fak {{ item.icon }} fa-3x"></i>
                                        <h5 class="prmpt-txt mt-2">{{ item.title }}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 btn-prmpt-library">
                    <!-- <div class="position-ai-img-fix">
                    <div class="div-btn-prmpt-library">
                        <button class="div-prmpt-btn" type="button"><i class="fak fa-numero-download"></i></button>
                    </div>
                    <div class="div-btn-prmpt-library">
                        <button class="div-prmpt-btn mt-2" type="button">
                            <i class="far fa-share fa-sm"></i>
                        </button>
                    </div>
                    <div class="div-btn-prmpt-library">
                        <button type="button" class="div-prmpt-btn mt-2 btn-slack">
                            <img src="/assets/image/temp/slack.svg" alt="image" />
                        </button>
                    </div>
                    </div> -->
                </div>
            </div>

            <div *ngIf="!showSecondQues">
                <div class="row fixed-bottom">
                    <div class="col-md-3"></div>
                    <div class="col-md-9 bg-clr-input">
                        <div class="div-ans-input mb-3">
                            <input class="form-control input-ai-ans"
                                placeholder="Please ask me anything. I will give my best answer" type="text"
                                [(ngModel)]="answerText" />
                            <button [disabled]="!answerText" (click)="showOutQues()"><i
                                    class="fak fa-numero-tele fa-lg mt-2"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="container div-ai-home" *ngIf="showSecondQues">
    <div class="div-ai-home-content">
        <div class="row">
            <div class="col-md-1 text-center">
                <img src="/assets/image/temp/ai-con-new.svg" alt="image" />
            </div>
            <div class="col-md-10 div-ques-overdue">
                <p>Give me Overdue outstanding customer details that are more than 30 days?</p>
                <span>Here is your outstanding customer list who are yet to pay for more than 30 days</span>
                <img src="/assets/image/temp/flag.svg" alt="image" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">

            </div>
            <div class="col-md-5 div-tbl-list">
                <table>
                    <thead>
                        <tr>
                            <th class="text-left">
                                <p>Customers</p>
                            </th>
                            <th class="text-right">
                                <p>Amount</p>
                            </th>
                            <th>Invoice</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of tblData">
                            <td class="text-left">{{item.customer}}</td>
                            <td class="text-right amt">{{item.amount}}</td>
                            <td>{{item.invoice}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-1 div-out-btns">
                <div>
                    <button type="button"><i class="fak fa-numero-download"></i></button>
                </div>
                <div>
                    <button type="button">
                        <img src="/assets/image/temp/share.svg" alt="image" />
                    </button>
                </div>
                <div>
                    <button type="button" class="btn-slack">
                        <img src="/assets/image/temp/slack.svg" alt="image" />
                    </button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <div class="div-ans-input mb-5">
                    <input class="form-control input-ai-ans" placeholder="" type="text" [(ngModel)]="answerOutText" />
                    <button [disabled]="!answerOutText"><i class="fak fa-numero-tele fa-lg mt-2"></i></button>
                </div>
            </div>
        </div>
    </div>
</div>
