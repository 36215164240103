import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FlowchartEditorComponent } from './flowchart-editor/flowchart-editor.component';
import { FlowchartHomeComponent } from './flowchart-home/flowchart-home.component';
import { FlowchartListComponent } from './flowchart-list/flowchart-list.component';

const routes: Routes = [
  {
    path: '',
    component: FlowchartListComponent,
  },
  {
    path: ':process-key',
    component: FlowchartEditorComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProcessFlowchartRoutingModule { }
