<div class="row">
  <div class="col-md-12 mt-4">
      <div class="div-mat-card">
          <div class="row">


          <app-custom-filter (filterEvent)="multiSearch($event)" (simpleFilterEvent)="applyFilter($event)" [gridData]="gridData" [gridDataKeys]="gridDataKeys"></app-custom-filter>

            <div class="col-md-12 p0">
              <div class="mat-elevation-z8">

                <table mat-table [dataSource]="dataSourceEx">
                  <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                      <mat-checkbox
                      (change)="$event ? toggleAllRows() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()"
                      >
                      </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)"
                        [aria-label]="checkboxLabel(row)"
                      >
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <ng-container  *ngFor="let item of displayedColumnsEx">
                  <ng-container
                   *ngIf="item !== 'select'  && item !== 'Name' && item !== 'Start Time' && item !== 'End Time'  && item !== 'Created At'"
                    matColumnDef="{{ item }}"
                  >
                    <th mat-header-cell *matHeaderCellDef>{{ item }}</th>
                    <td mat-cell  *matCellDef="let element">{{ element[item] }}</td>
                  </ng-container>
                  <ng-container
                  *ngIf="item === 'Name'"
                   matColumnDef="{{ item }}"
                 >
                   <th mat-header-cell *matHeaderCellDef>{{ item }}</th>
                   <td mat-cell  *matCellDef="let element"><a class="link-text" (click)="launchIframe(element['Execution Url'])">{{element[item]}}</a></td>
                 </ng-container>

                 <ng-container
                  *ngIf="item === 'Start Time' || item === 'End Time'  || item === 'Created At'"
                   matColumnDef="{{ item }}"
                 >
                 <th mat-header-cell *matHeaderCellDef>{{ item }}</th>
                 <td mat-cell  *matCellDef="let element">{{ element[item] | date }}</td>
                 </ng-container>
                </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsEx"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsEx"></tr>
                </table>

                <mat-paginator
                  [pageSize]="15"
                  [pageSizeOptions]="[5, 10, 15, 20]"
                  aria-label="Select page"
                  [length]="dsLength"
                >
                </mat-paginator>
              </div>
            </div>
          </div>
      </div>
  </div>
</div>
<div class="n8n-iframe-div" *ngIf="isIframe">
  <button class="btn btn-back" (click)="launchIframe()" title="Back"><i class="fas fa-arrow-left"></i></button>
  <iframe width="100%" height="100%"   frameBorder="0" [src]="urlSafe"></iframe>
</div>
