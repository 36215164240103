import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-custom-filter',
  templateUrl: './custom-filter.component.html',
  styleUrls: ['./custom-filter.component.scss']
})
export class CustomFilterComponent implements OnInit {

  public isFilter = false;
  public isExpand = true;
  public filterForm: FormGroup;
  @Input() public gridDataKeys;
  @Input() gridData = [];
  @Output() simpleFilterEvent = new EventEmitter<any>();
  @Output() filterEvent = new EventEmitter<any>();
  constructor(private formBuilder: FormBuilder) {
    
  }

  public selectOperaterOptions = [
    { key: 'Has', value: 'has',operator :['string'] },
    { key: 'Do not contain ', value: 'dnc', operator :['string'] },
    { key: 'Equals', value: '===' ,operator :['number']},
    { key: 'Not Equals', value: '!==', operator : ['number']},
    { key: 'Less than ', value: '<' ,operator :['number', 'date'] },
    { key: 'Less than or equal to ', value: '<=' ,operator :['number']},
    { key: 'Greater than', value: '>',operator :['number', 'date'] },
    { key: 'Greater than or equal to', value: '>=' ,operator :['number']},
  ];

  ngOnInit(): void {
    this.filterForm = this.formBuilder.group({
      filters: this.formBuilder.array([this.addFilterFormGroup()]),
    });
  }
  private addFilterFormGroup() {
    return this.formBuilder.group({
      selcolumn: [],
      seloperator: [],
      selvalue: [],
    });
  }

  custfilter() {
    const filters = this.filterForm.get('filters') as FormArray;
    this.isFilter = !this.isFilter;
    filters.reset();
  }

  public removeFilter(i: number) {
    const filters = this.filterForm.get('filters') as FormArray;
    if (filters.length > 1) {
      filters.removeAt(i);
    } else {
      this.isFilter = false;
      filters.reset();
    }
    this.multiSearch();
  }

  public multiSearch() {
    let condition = this.filterForm.get('filters').value;
    let uniqueData= this.gridData;
    if(condition && condition.length > 0 && condition[0].selvalue !== null) {
      const filterdData = this.filterData(this.gridData, condition);
      uniqueData = filterdData.filter(
        (obj, index, self) =>
          index === self.findIndex((t) => t['Workflow Id'] === obj['Workflow Id'])
      );
    }

    this.filteredItem(uniqueData)
    // this.dataSourceEx = new MatTableDataSource<any>(uniqueData);
  }

  filteredItem(value) {
    this.filterEvent.emit(value);
  }

  public addFilter() {
    const filters = this.filterForm.get('filters') as FormArray;
    filters.push(this.addFilterFormGroup());
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.simpleFilterEvent.emit(filterValue);

    // simpleFilterEvent
    // this.dataSourceEx.filter = filterValue.trim().toLowerCase();

    // if (this.dataSourceEx.paginator) {
    //   this.dataSourceEx.paginator.firstPage();
    // }
  }

  filterData(data, filters) {
    let filteredData = [];
    let multipleFilteredData = [];
    for (const filter of filters) {
      filteredData = [];
      switch (filter.seloperator) {
        case 'has':
          filteredData = data.filter((item) =>
            item[filter.selcolumn.key].trim().toLowerCase().includes(filter.selvalue.trim().toLowerCase())
          );
          break;
        case 'dnc':
          filteredData = data.filter(
            (item) => !item[filter.selcolumn.key].includes(filter.selvalue)
          );
          break;
        case '===':
          filteredData = data.filter(
            (item) => item[filter.selcolumn.key] === filter.selvalue
          );
          break;
        case '!==':
          filteredData = data.filter(
            (item) => item[filter.selcolumn.key] !== filter.selvalue
          );
          break;
        case '>':
          filteredData = data.filter(
            (item) => item[filter.selcolumn.key] > filter.selvalue
          );
          break;
        case '<':
          filteredData = data.filter(
            (item) => item[filter.selcolumn.key] < filter.selvalue
          );
          break;
        case '>=':
          filteredData = data.filter(
            (item) => item[filter.selcolumn.key] >= filter.selvalue
          );
          break;
        case '<=':
          filteredData = data.filter(
            (item) => item[filter.selcolumn.key] <= filter.selvalue
          );
          break;
        default:
          console.log('Invalid operator');
          break;
      }
      multipleFilteredData = [...multipleFilteredData, ...filteredData];
    }
    return multipleFilteredData;
  }

  clearfilter() {
    this.custfilter()
    this.filterEvent.emit(this.gridData);
    // this.dataSourceEx = new MatTableDataSource<any>(this.gridData);
  }
}
