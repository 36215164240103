
<div id="load_screen" *ngIf="loading$ | async">
  <div class="loader">
      <div class="loader-content">
          <div class="loader_text">
              <p class="mb-0"><img src="{{environment.imagePrefixURI}}/utill/GoConstant.svg" alt="image"></p>
              LOADING <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          </div>
      </div>
  </div>
</div>
<!--  END LOADER -->
<router-outlet></router-outlet>
