<div class="container div-ai-home">
  <div class="div-ai-home-content" *ngIf="!showSecondQues">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-11">
        <img src="/assets/image/temp/ai-con-new.svg" alt="image" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-11">
        <div class="div-ques">Ask me anything</div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-11">
        <div class="div-ans-input">
          <input
            class="form-control input-ai-ans"
            placeholder="Please ask me anything. I will give my best answer"
            type="text"
            [(ngModel)]="inputText"
          />
          <button [disabled]="!inputText" (click)="getResultForInputText('QUES', inputText)">
            <i class="fak fa-numero-tele fa-lg mt-2"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="div-sug">You can also ask</div>
        <div class="div-sug-list">
          <ng-container *ngFor="let item of data?.top5">
            <button (click)="navigateToResult('PROMPT', item)">{{ item.name }}</button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!showSecondQues">
    <div class="col-md-1"></div>
    <div class="col-md-11">
      <div class="mt-40">
        <p class="txt-head-prompt">Prompt Library</p>
        <div class="row justify-content-md-center">
          <div
            *ngFor="let item of this.data?.library"
            class="col hover-prompt-box cursor-pointer"
            (click)="filterNavigation(item)"
          >
            <div class="outer-prompt-box">
              <div class="promptcard">
                <i class="fak fa-ai-{{ item.icon.toLowerCase()}} fa-3x"></i>
                <h5 class="prmpt-txt mt-2">{{ item.name }}</h5>
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-content-center mt-40 cursor-pointer"
          routerLink="/ai/prompt-library"
        >
          <p class="view-all-txt">View all Prompt</p>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="div-ai-home-content" *ngIf="showSecondQues">
    <div class="row">
      <div class="col-md-1 text-center">
        <img src="/assets/image/temp/ai-con-new.svg" alt="image" />
      </div>
      <div class="col-md-10 div-ques-overdue">
        <p>
          Give me Overdue outstanding customer details that are more than 30
          days?
        </p>
        <span
          >Here is your outstanding customer list who are yet to pay for more
          than 30 days</span
        >
        <img src="/assets/image/temp/flag.svg" alt="image" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-5 div-tbl-list">
        <table>
          <thead>
            <tr>
              <th class="text-left">
                <p>Customers</p>
              </th>
              <th class="text-right">
                <p>Amount</p>
              </th>
              <th>Invoice</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of tblData">
              <td class="text-left">{{ item.customer }}</td>
              <td class="text-right amt">{{ item.amount }}</td>
              <td>{{ item.invoice }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-1 div-out-btns">
        <div>
          <button type="button"><i class="fak fa-numero-download"></i></button>
        </div>
        <div>
          <button type="button">
            <img src="/assets/image/temp/share.svg" alt="image" />
          </button>
        </div>
        <div>
          <button type="button" class="btn-slack">
            <img src="/assets/image/temp/slack.svg" alt="image" />
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="div-ans-input mb-5">
          <input
            class="form-control input-ai-ans"
            placeholder=""
            type="text"
            [(ngModel)]="inputText"
          />
          <button [disabled]="!inputText">
            <i class="fak fa-numero-tele fa-lg mt-2"></i>
          </button>
        </div>
      </div>
    </div>
  </div> -->
</div>
