import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { handleError } from '../core/handle-error';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import accountsPayableData from 'assets/config/accounts_payable.json';
import accountsReceivableData from 'assets/config/accounts_receivable.json';
import accountsPayableV2Data from 'assets/config/accounts_payable_v2.json';
import { DashboardService } from '../dashboard/dashboard.service';

@Injectable({
  providedIn: 'root',
})
export class WorkbenchService {
  private p2pKeySubject = new Subject<string>();
  private triggerCloseEvent = new Subject<void>();
  private editButtonClickSource = new Subject<void>();


  p2pKey$ = this.p2pKeySubject.asObservable();
  triggerCloseEvent$ = this.triggerCloseEvent.asObservable();
  editButtonClick$ = this.editButtonClickSource.asObservable();


  public accountDP = false;

  constructor(private http: HttpClient,
    private _dashboardService: DashboardService,
    ) {}

  getWorkbench = (entityId: string): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/ap-dashboard/workbench/open?entityId=${entityId}`
      )
      .pipe(catchError(handleError));
  };

  getConnectedEmailIds = (entityId: string): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/ap-bills/get-connected-gmail-accounts?entity_key=${entityId}`
      )
      .pipe(catchError(handleError));
  };

  postConnectedEmail = (mailBody): Observable<any> => {
    return this.http
      .post(`${environment.server.baseURL}/ap-bills/email-selection`, mailBody)
      .pipe(catchError(handleError));
  };

  getWorkbenchPaid = (entityId: string): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/ap-dashboard/workbench/close?entityId=${entityId}`
      )
      .pipe(catchError(handleError));
  };

  getWorkbenchVendor = (entityId: string): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/ap-dashboard/workbench/vendors?entityId=${entityId}`
      )
      .pipe(catchError(handleError));
  };

  getWorkbenchAll = (entityId: string): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/ap-dashboard/workbench/all?entityId=${entityId}`
      )
      .pipe(catchError(handleError));
  };

  getWorkbenchInvoice = (entityId: string): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/ar-dashboard/workbench/invoice?entityId=${entityId}`
      )
      .pipe(catchError(handleError));
  };
  getWorkbenchInvoicev2 = (
    entityId: string,
    accountId: string,
    userEmail: string
  ): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/v2/ar-invoice?entityId=${entityId}&accountId=${accountId}&userEmail=${userEmail}`
      )
      .pipe(catchError(handleError));
  };
  getWorkbenchInvoicev2filter = (
    entityId: string,
    accountId: string,
    filter: string,
    companyId:string,
  ):
  Observable<any> => {
    let uri=`${environment.server.baseURL}/v2/ar-invoice?entityId=${entityId}&accountId=${accountId}&filter=${filter}`;

    if (companyId) {

      uri += `&customerId=${companyId}`;
    }
    return this.http
      .get(
        uri
      )
      .pipe(catchError(handleError));
  };
  getWorkbenchInvoicev2filterVendor = (
    entityId: string,
    accountId: string,
    filter: string,
    customerId:string,
  ): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/v2/ar-invoice?entityId=${entityId}&accountId=${accountId}&filter=${filter}&customerId=${customerId}`
      )
      .pipe(catchError(handleError));
  };
  getWorkbenchCustomerv2 = (
    entityId: string,
    accountId: string
  ): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/v2/ar-customer?entityId=${entityId}&accountId=${accountId}`
      )
      .pipe(catchError(handleError));
  };
  getWorkbenchpaymentv2 = (
    entityId: string,
    accountId: string,
    filter: string,
    companyId:string,
  ): Observable<any> => {
    // let uri=`${environment.server.baseURL}/v2/ar-payment?entityId=${entityId}&accountId=${accountId}`;
    let uri=`${environment.server.baseURL}/v2/ar-invoice?entityId=${entityId}&accountId=${accountId}&filter=${filter}`;
    if(companyId)
    {
      uri += `&customerId=${companyId}`;
    }
    return this.http
      .get(
        uri
      )
      .pipe(catchError(handleError));
  };
  getWorkbenchpaymentv2filter = (
    entityId: string,
    accountId: string,
    filter: string,
    companyId:string,
  ): Observable<any> => {
    let uri=`${environment.server.baseURL}/v2/ar-invoice?entityId=${entityId}&accountId=${accountId}&filter=${filter}`;
    if(companyId)
    {
      uri += `&customerId=${companyId}`;
    }
    return this.http
      .get(
        uri
      )
      .pipe(catchError(handleError));
  };
  getWorkbenchArDashboardv2 = (
    entityId: string,
    accountId: string
  ): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/v2/ar-dashboard?entityId=${entityId}&accountId=${accountId}`
      )
      .pipe(catchError(handleError));
  };
  getWorkbenchActivityV2 = (
    entityId: string,
    accountId: string,
    userEmail: string,
    filter: string
  ): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/v2/ar-activity?entityId=${entityId}&accountId=${accountId}&userEmail=${userEmail}&filter=${filter}`
      )
      .pipe(catchError(handleError));
  };
  getWorkbenchActivityV2Filter = (
    entityId: string,
    accountId: string,
    userEmail: string,
    filter: string
  ): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/v2/ar-activity?entityId=${entityId}&accountId=${accountId}&userEmail=${userEmail}&filter=${filter}`
      )
      .pipe(catchError(handleError));
  };
  getMailDetails = (invoice_key: string, userEmail: string): Observable<any> => {
    // return of([{"from_email":"mukul@numero.app","to_email":"mouli@dataflo.io","cc_email":"ap-numero@numero.app","subject":"Overdue Invoices Reminder","body":"<div>Hi there, <br/> We hope you're well, <br/> We haven't received your payment for your invoice 516871 for a total of £24,144.04, which is now overdue <br/> You can check the status of your invoices and pay them in a few clicks from your customer portal. We would appreciate a prompt payment upon receipt of this email, if you have already paid, please ignore this nation<br/><br/>Feel free to reach out if you have any questions</div>","type":"OUTGOING","email_date":"2023-09-18","email_time":"00:00","email_datetime":"2023-09-18T00:00"},{"from_email":"mouli@dataflo.io","to_email":"mukul@numero.app","cc_email":"ap-numero@numero.app","subject":"Thanks for reminding","body":"</div> Hey Mukul, <br/> Thanks for reminding me on the due invoice. <br/><br/> I will be initiating the payment by early tomorrow. <br/> Thanks and regards, <br/> Mouli <br/> Dataflo  </div>","type":"INCOMING","email_date":"2023-09-18","email_time":"00:00","email_datetime":"2023-09-18T00:00"},{"subject":"Invoice Paid","type":"INCOMING","email_date":"2023-09-19","email_time":"00:00","email_datetime":"2023-09-19T00:00"}])
    return this.http
      .get(
        `${environment.server.baseURL}/ar-invoice/conversation?invoice_key=${invoice_key}&userEmail=${userEmail}`
      )
      .pipe(catchError(handleError));
  };
  getWorkbenchActivityCustomer = (
    entityId: string,
    accountId: string,
    customerId: string,
    userEmail: string
  ): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/v2/ar-invoice?entityId=${entityId}&accountId=${accountId}&customerId=${customerId}&userEmail=${userEmail}`
      )
      .pipe(catchError(handleError));
  };
  getWorkbenchCreditNotes = (entityId: string): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/ar-dashboard/workbench/credit-note?entityId=${entityId}`
      )
      .pipe(catchError(handleError));
  };

  getWorkbenchCustomer = (entityId: string): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/ar-dashboard/workbench/customer?entityId=${entityId}`
      )
      .pipe(catchError(handleError));
  };

  getWorkbenchActivity = (entityId: string): Observable<any> => {
    // return this.http
    // .get(`${environment.server.baseURL}/ar-dashboard/workbench/customer?entityId=${entityId}`)
    // .pipe(catchError(handleError));
    return of(accountsReceivableData.activity);
  };

  getWorkbenchPayments = (entityId: string): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/ar-dashboard/workbench/payment?entityId=${entityId}`
      )
      .pipe(catchError(handleError));
  };

  getWorkbenchBills = (entityId: string): Observable<any> => {
    return this.http
      .get(`${environment.server.baseURL}/ap-bills?entity_key=${entityId}`)
      .pipe(catchError(handleError));
  };

  getAutomation = (entityId: string): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/connections/automation?entity_key=${entityId}`
      ).pipe(
        tap((data) => this._dashboardService.setDashboard(data)),
        catchError(handleError)
      );
  };

  getWorkbenchBillsDetail = (
    entityId: string,
    billKey: string
  ): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/ap-bills?entity_key=${entityId}&bill_key=${billKey}`
      )
      .pipe(catchError(handleError));
  };

  putWorkbenchBillsDetail = (billDetail): Observable<any> => {
    return this.http
      .put(`${environment.server.baseURL}/ap-bills`, billDetail, {
        responseType: 'text' as 'json',
      })
      .pipe(catchError(handleError));
  };

  deleteWorkbenchBillsDetail = (
    entityId: string,
    billKey: string,
    email: string
  ): Observable<any> => {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'text' as 'json',
    };
    return this.http
      .delete(
        `${environment.server.baseURL}/ap-bills?entity_key=${entityId}&bill_key=${billKey}&user_email=${email}`,
        httpOptions
      )
      .pipe(catchError(handleError));
  };

  patchWorkbenchBillsDetail = (billDetail): Observable<any> => {
    return this.http
      .patch(`${environment.server.baseURL}/ap-bills`, billDetail, {
        responseType: 'text' as 'json',
      })
      .pipe(catchError(handleError));
  };

  getWorkbenchTab = (entityId: string, tab: string): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/ap-bills/table?entity_key=${entityId}&tab=${tab}`
      )
      .pipe(catchError(handleError));
  };

  getVendorDetail = (entityId: string, vendorId: string): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/vendor?vendor_id=${vendorId}&entity_key=${entityId}`
      )
      .pipe(catchError(handleError));
  };

  getVendorExport = (entityId: string): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/ap-bills/export-vendors?entity_key=${entityId}`
      )
      .pipe(catchError(handleError));
  };

  patchWorkbenchApprovalStatus = (billDetail): Observable<any> => {
    return this.http
      .patch(`${environment.server.baseURL}/ap-bills/payment`, billDetail, {
        responseType: 'text' as 'json',
      })
      .pipe(catchError(handleError));
  };

  getWorkbenchMyApproval = (entityId: string, email): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/ap-bills/my-approval?entity_key=${entityId}&approver_email=${email}`
      )
      .pipe(catchError(handleError));
  };

  postExportVendorDetail = (exportData): Observable<any> => {
    return this.http
      .post(`${environment.server.baseURL}/ap-bills/upload`, exportData)
      .pipe(catchError(handleError));
  };

  getAPAutomationDashboard = (entityId: string): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/ap-bills/dashboard?entityId=${entityId}`
      )
      .pipe(catchError(handleError));
  };

  postP2PForm =(p2pBody): Observable<any> => {
    return this.http.post(`${environment.server.baseURL}/p2p`, p2pBody).pipe(catchError(handleError));
  }

  postEmailConversation=(emailBody): Observable <any> =>
  {
    return this.http.post(`${environment.server.baseURL}/conversation-email`, emailBody).pipe(catchError(handleError))
  }
  postNotes=(notesBody): Observable <any> =>
  {
    return this.http.post(`${environment.server.baseURL}/conversation-notes`, notesBody).pipe(catchError(handleError))
  }

  updateP2PFormDetail = (p2pBody): Observable<any> => {
    return this.http.put(`${environment.server.baseURL}/p2p`, p2pBody).pipe(catchError(handleError));
  }

  getP2PDetail = (p2p_key: string): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/p2p?p2p_key=${p2p_key}`
      )
      .pipe(catchError(handleError));
  };

  sendP2PKey(p2pKey: string) {
    this.p2pKeySubject.next(p2pKey);
  }
  triggerDrilldownClose(): void {
    this.triggerCloseEvent.next();
  }

  notifyEditButtonClick() {
    this.editButtonClickSource.next();
  }

  getTagDetail = (entity_id: string,type: string): Observable<any> => {
    return this.http
    .get(
      `${environment.server.baseURL}/tag?entity_id=${entity_id}&type=${type}`
    )
    .pipe(catchError(handleError));
  };

  postTag =(tagBody): Observable<any> => {
    return this.http.post(`${environment.server.baseURL}/tag`,tagBody).pipe(catchError(handleError));
  }

  updateTag =(invoiceKey: string, tagName: string, userEmail: string): Observable<any> => {
    return this.http.put(`${environment.server.baseURL}/ar-invoice/tag?invoiceKey=${invoiceKey}&tagName=${tagName}&userEmail=${userEmail}`,{}).pipe(catchError(handleError));
  }

  triggerCMSworkflow(workFLowBody) {
    return this.http.post(`${environment.server.baseURL}/ar-sheet-ingestion`,workFLowBody).pipe(catchError(handleError));
  }

  getCustomerActionArdashboard=(
    entityId:String,
    userEmail: string,
    companyId:string
  ): Observable<any> =>{
     return this.http
     .get(
      `${environment.server.baseURL}/v2/ar-dashboard/action?entityId=${entityId}&userEmail=${userEmail}&customerId=${companyId}`
     )
     .pipe(catchError(handleError));
  };

  getCustomerTeamActivity=(
    entityId:string,
    userEmail:string,
    companyId:string,
    accountId:string,
    filter:string
  ): Observable<any> =>{
     return this.http
     .get(
      `${environment.server.baseURL}/v2/ar-invoice?entityId=${entityId}&userEmail=${userEmail}&customerId=${companyId}&accountId=${accountId}&filter=${filter}`
     )
     .pipe(catchError(handleError));
  };

  getCustomerCompanyDetail = (entityId: string,accountId: string,customerKey: string): Observable<any> => {
    return this.http
    .get(
      `${environment.server.baseURL}/ar-customer?entityId=${entityId}&accountId=${accountId}&customerKey=${customerKey}`
    )
    .pipe(catchError(handleError));
  };

  updateCustomerCompanyDetail =(customerBody): Observable<any> => {
    return this.http.put(`${environment.server.baseURL}/ar-customer/customer/edit`,customerBody).pipe(catchError(handleError));
  }

  updateFlag =(entityId:string,accountId:string,customerKey:string,riskFlag:string): Observable<any> =>{
    return this.http.put(`${environment.server.baseURL}/ar-customer/risk-flag?entityId=${entityId}&accountId=${accountId}&customerKey=${customerKey}&riskFlag=${riskFlag}`,{}).pipe(catchError(handleError));
  }

  getCustomerDetailsById = (entityId: string,accountId: string,customerId: string): Observable<any> => {
    return this.http
    .get(
      `${environment.server.baseURL}/v2/ar-customer?entityId=${entityId}&accountId=${accountId}&customerId=${customerId}`
    )
    .pipe(catchError(handleError));
  }

  getPaymentsListByInvoiceKey = (invoiceKey: string): Observable<any> => {
    return this.http
    .get(
      `${environment.server.baseURL}/v2/ar-invoice/invoice-payments?invoiceKey=${invoiceKey}`
    )
    .pipe(catchError(handleError));
  }

  updateWriteOff =(invoiceKey:string,writtenOff:boolean): Observable<any> =>{
    return this.http.put(`${environment.server.baseURL}/ar-invoice/written-off?invoiceKey=${invoiceKey}&writtenOff=${writtenOff}`,{}).pipe(catchError(handleError));
  }
}
