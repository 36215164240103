import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { handleError } from '../core/handle-error';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {

  public DASHBOARD_STATUS:any;

  constructor(private http: HttpClient) {}

  setDashboard(dStatus){
    this.DASHBOARD_STATUS = dStatus
  }

  getDashboardStatus() {
    return this.DASHBOARD_STATUS || {};
  }

  getARdashboard = (
    entityId: string,
    start?: string,
    end?: string,
    account?: string
  ): Observable<any> => {
    if (!account) {
      return this.http
        .get(
          `${environment.server.baseURL}/ar-dashboard?entityId=${entityId}`
        )
        .pipe(catchError(handleError));
    }
    return this.http
      .get(
        `${environment.server.baseURL}/ar-dashboard?entityId=${entityId}&start_date=${start}&end_date=${end}&accountId=${account}`
      )
      .pipe(catchError(handleError));
  };

  getApdashboard = (
    entityId: string,
    start?: string,
    end?: string,
    account?: string
  ): Observable<any> => {

    if (!account) {
      return this.http
        .get(
          `${environment.server.baseURL}/ap-dashboard?entityId=${entityId}`
        )
        .pipe(catchError(handleError));
    }
    return this.http
      .get(
        `${environment.server.baseURL}/ap-dashboard?entityId=${entityId}&start_date=${start}&end_date=${end}&accountId=${account}`
      )
      .pipe(catchError(handleError));
  };

  getActionApdashboard=(
    entityId:String,
  ): Observable<any> =>{
     return this.http
     .get(
      `${environment.server.baseURL}/ap-bills/dashboard/action?entityId=${entityId}`
     )
     .pipe(catchError(handleError));
  };
  getV2Apdashboard=(
    entityId:String,
  ): Observable<any> =>{
     return this.http
     .get(
      `${environment.server.baseURL}/ap-bills/dashboard?entityId=${entityId}`
     )
     .pipe(catchError(handleError));
  };
  getActionArdashboard=(
    entityId:String,
    userEmail: string,
    accountId : string
  ): Observable<any> =>{
     return this.http
     .get(
      `${environment.server.baseURL}/v2/ar-dashboard/action?entityId=${entityId}&userEmail=${userEmail}&accountId=${accountId}`
     )
     .pipe(catchError(handleError));
  };
  getV2Ardashboard=(
    entityId:String,
  ): Observable<any> =>{
     return this.http
     .get(
      `${environment.server.baseURL}/v2/ar-dashboard?entityId=${entityId}`
     )
     .pipe(catchError(handleError));
  };
}
