<!-- Modal -->
<div class="modal-header">
  <h5 class="modal-title fw600">Inter Company Elimination Settings</h5>
  <button type="button" class="close btn-close" (click)="closePopup()">
    <!-- <span aria-hidden="true">&times;</span> -->
  </button>
</div>
<div class="modal-body">
  <div class="row div-mat-card">
    <div class="col-md-7 brd2d">
      <div class="div-left-side">
        <div class="row">
          <div class="col-md-12 fs14 col555761 mb-2">
            <div>Select the accounts to configure</div>
          </div>

          <div class="col-md-6 mb-4">
            <select
              class="form-select"
              [(ngModel)]="selAccountIndetifier"
              (change)="fetchKnockOff()"
            >
              <option
                *ngFor="let obj of accounts"
                [disabled]="obj.name.includes('Select')"
                [ngValue]="obj"
              >
                {{ obj.name }}
              </option>
            </select>
          </div>
        </div>
        <div
          class="no-data-div"
          *ngIf="selAccountIndetifier.name.includes('Select')"
        ></div>
        <ng-container *ngIf="!selAccountIndetifier.name.includes('Select')">
          <div class="row" *ngIf="acclist?.length !== 0">
            <div class="col-md-6 fs14 col555761">
              Select Ledgers from Chart of Accounts
            </div>
            <div class="col-md-6 d-flex">
              <div class="input-group mb-3 div-search-mat">
                <div class="input-group-prepend">
                  <i class="far fa-search"></i>
                </div>
                <mat-form-field>
                  <mat-label>Search</mat-label>
                  <input
                    matInput
                    placeholder=""
                    [(ngModel)]="inputTest"
                    (input)="filterInput()"
                    #input
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row acc-list-div" *ngIf="acclist?.length > 0">
            <div class="col-md-6" *ngFor="let acc of filteredAcclist">
              <div class="div-blue">
                <label class="container-checkbox"
                  >{{ acc.name }}
                  <input
                    type="checkbox"
                    [value]="acc.account_id"
                    [checked]="acc.knock_off"
                    (change)="onCheckboxChange($event, acc)"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row acc-list-div" *ngIf="acclist?.length === 0">
            <div class="col-md-12">No Data Available</div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="col-md-5">
      <div class="fs18 fw600">Selected Accounts</div>
      <div class="row mt-2 h-100">
        <div class="no-data-sel-list" *ngIf="!noSeletedData">
          <div class="text-center">
            <img src="{{ env.imagePrefixURI }}/utill/no-data.svg" />
            <p class="fs14 col555761 mt-2">No Selected Accounts</p>
          </div>
        </div>
        <div class="col-md-11" *ngIf="noSeletedData">
          <ngb-accordion
            activeIds="{{ panelId }}"
            [closeOthers]="true"
            (panelChange)="beforeChange($event)"
            #acc="ngbAccordion"
          >
            <ng-container *ngFor="let dataMap of fullAccDataMap | keyvalue">
              <ngb-panel
                id="{{ dataMap.key }}"
                class="elimination-list"
                *ngIf="dataMap.value.selectedData.length > 0"
              >
                <ng-template ngbPanelTitle>
                  <span class="accordion-btn-head">{{
                    accontMap.get(dataMap.key).name
                  }}</span>
                  <span class="ms-2 badge bg-secondary">{{
                    dataMap.value.selectedData.length
                  }}</span>
                  <span
                    class="accordion-close"
                    (click)="removeSpecificMapData(dataMap.key)"
                    ><i class="fas fa-times-circle" style="color: #cccccc"></i
                  ></span>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div
                    class="col-md-12"
                    *ngFor="let sel of dataMap.value.selectedData"
                  >
                    <div class="div-blue">
                      <label class="container-checkbox"
                        >{{ sel.name }}
                        <input
                          type="checkbox"
                          [value]="sel.account_id"
                          [checked]="sel.knock_off"
                          (change)="onCheckboxChange($event, sel)"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
            </ng-container>
          </ngb-accordion>

          <!-- <div class="div-acc">
                        <div class="div-acc-header">
                            <div class="row">
                                <div class="col-md-10">
                                    Argoid - Quikbooks (US) <span>19</span>
                                </div>
                                <div class="col-md-2 text-right">
                                    <i class="far fa-angle-down"></i>
                                </div>
                            </div>
                        </div>
                        <div class="div-acc-content">
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <button type="button" class="btn btn-acc-clear">Clear All</button>
                                </div>
                                <div class="col-md-12">
                                    <div class="div-blue">
                                        <label class="container-checkbox">Intercompany receivable
                                            <input type="checkbox" id="#intercomsub" name="intercomsub" value="intercomsub">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
        </div>
        <!-- <div class="col-md-1 div-acc-close">
                    <i class="fas fa-times-circle"></i>
                </div> -->
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="row">
    <div class="col-md-5">
      <!-- <button
        type="button"
        class="btn btn-outline-dark"
        (click)="resetDefault()"
      >
        Reset to Default
      </button> -->

      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="clearAll()"
      >
      Reset to Default
      </button>
    </div>
    <div class="col-md-7 text-right">
      <button
        type="button"
        class="btn btn-outline-dark me-2"
        (click)="closePopup()"
      >
        Close
      </button>
      <button
        type="button"
        [ngClass]="submitEnable ? '' : 'disabled-btn'"
        class="btn accordion-submit"
        (click)="submitElimination()"
      >
        Save
      </button>
    </div>
  </div>
</div>
