import { Component, OnInit } from '@angular/core';
import { JWTToken } from 'app/core/service/auth/auth';
import { GrowthbookService } from 'app/core/service/growthBook/growthbook.service';
import { environment } from 'environments/environment';
import { AuthServices } from '../../service/auth/auth.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'app/service/shared/common.service';
import { RoleService } from 'app/service/role/role.service';
import { WorkbenchService } from 'app/service/workbench/workbench.service';
import {  Router } from '@angular/router';
import { DashboardService } from 'app/service/dashboard/dashboard.service';


@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {

  public _AP_ = false;
  public _AR_ = false;
  public _CONNECTOR_ = false;
  public _REPORT_ = false;
  public _WORKFLOW_ = false;
  public _CONTRACT_ = false;
  public _PROCESSFLOW_ = false;

  public environment = environment;
  // public menuminimaze = false;
  public isClientlogin = true;
  public switchWorkspaceEnabled = false;
  public tokenDetail: JWTToken = Object();
  public orgList: Array<Object>;
  public currentUrl=''
  public _DBSTAT_;

  constructor(
    public authService: AuthServices,
    public growthBookServ: GrowthbookService,
    public _commonService: CommonService,
    private _roleService : RoleService,
    public workbenchService: WorkbenchService,
    public _router: Router,
    public _dashboardService: DashboardService
  ) {}

  async ngOnInit() {
    this.tokenDetail = await this.authService.getJWTDetail();
    this.getGrowthBook();
    this.roleStatus()
    this.basicConfig();
    const sAcc = this._commonService.getPageHeader();
    const sAccStatus = this._commonService.getsubPageHeader();
    this._DBSTAT_= this._dashboardService.getDashboardStatus();
    const cClaim = localStorage.getItem('is-custom');
    if(cClaim) {
      this.navigateRoute('workbench/ar');
      localStorage.removeItem('is-custom');
      console.log('inside cClaim')
    }
  }


  async basicConfig() {
    this.orgList = this.tokenDetail.orgList;
    this.switchWorkspaceEnabled =  this.orgList.length > 1;
    // this.getAutomation()
  }

  public gbReconciliation = false;
  public gbConnectors = false;
  public gbContract = false;
  public gbGlobalSearchDashboard = false;
  public gbProcessFlow = false;
  public gbWorkFlow = false;
  public gbWorkFlowImport = false;
  public gbDashboard = false;
  public gbReceivable = false;
  public gbSettings = false;
  public gbWorkbenchAP = false;
  public gbWorkbenchAR = false;
  public gbreport = false;
  // public gbFeedback = false;
  public gbGlobalSearch = false;
  public gbClientlogin = false;
  public gbWorkbenchV2 = false;
  public gbAi = false;
  public gbAPv3 = false;
  public gbARv3 = false;
  public gbVendor = false;
  public imageAvailable: boolean = true;
  public imageAvailableLogo: boolean = true;
  // public dashboardFlag: any;

  async getGrowthBook(): Promise<any> {
    // const tokenDetail = await this.authService.getJWTDetail();
    // console.log('tokenDetail',tokenDetail.org.id)
    // this.growthBookServ.setGBookAttr({
    //   entityId: tokenDetail.org.id.toString()
    // });
    // this.growthBookServ.getGrowthBook().subscribe((data) => {
    // this.growthBookServ.setGBookFeature(data.features);
    this.gbReconciliation =
    this.growthBookServ.getGBookStatus('reconciliation');
    this.gbConnectors = this.growthBookServ.getGBookStatus('connectors');
    this.gbContract = this.growthBookServ.getGBookStatus('contract');
  this.gbGlobalSearchDashboard = this.growthBookServ.getGBookStatus('global_search_dashboard');
    this.gbProcessFlow = this.growthBookServ.getGBookStatus('process_flow');
    this.gbWorkFlow = this.growthBookServ.getGBookStatus('work_flow');
    this.gbWorkFlowImport = this.growthBookServ.getGBookStatus('work_flow_import');
    this.gbReceivable = this.growthBookServ.getGBookStatus('analytics');
    this.gbDashboard = this.growthBookServ.getGBookStatus('dashboard');
    this.gbSettings = this.growthBookServ.getGBookStatus('settings');
    this.gbWorkbenchAP = this.growthBookServ.getGBookStatus('work_bench_ap');
    this.gbWorkbenchAR = this.growthBookServ.getGBookStatus('work_bench_ar');
    this.gbreport = this.growthBookServ.getGBookStatus('report');
    this.gbClientlogin = this.growthBookServ.getGBookStatus('client_login');
    this.gbWorkbenchV2 = this.growthBookServ.getGBookStatus('workbench_v2');
    this.gbAi = this.growthBookServ.getGBookStatus('ai_module');
    this.gbAPv3 = this.growthBookServ.getGBookStatus('work_bench_ap_filter');
    this.gbARv3 = this.growthBookServ.getGBookStatus('work_bench_ar_filter');
    this.gbVendor = this.growthBookServ.getGBookStatus('vendor_management');
    // this.gbFeedback = this.growthBookServ.getGBookStatus('feedback');
    this.gbGlobalSearch = this.growthBookServ.getGBookStatus('global_search');

    // });
  }

  async switchWorkspace(org: any): Promise<void> {
    let claimBody = org;
    claimBody.userEmail = this.tokenDetail.userEmail;
    const res = await this.authService.switchWorkspace(claimBody);
    console.log('switchWorkspacce',res)
    localStorage.setItem('is-custom', 'yes')
    window.location.reload();
  }

  navigateRoute(route) {
    if(route.includes('workbench/ap')) {
      if(this._DBSTAT_.ap_enabled===true)
      {
        this._router.navigateByUrl('workbench/ap/automation/dashboard')
      }
      else
      {
        this._router.navigateByUrl('workbench/ap/report/dashboard')
      }
    } else if(route.includes('workbench/ar')) {
      if(this._DBSTAT_.ar_enabled===true)
      {
        this._router.navigateByUrl('workbench/ar/automation/dashboard')
      }
      else
      {
        this._router.navigateByUrl('workbench/ar/report/dashboard')
      }
    } else if(route.includes('workbench/new-ap')) {
      if(this._DBSTAT_.ap_enabled===true)
      {
        this._router.navigateByUrl('workbench/new-ap/automation/bills')
      }
      else
      {
        this._router.navigateByUrl('workbench/new-ap/automation/bills')
      }
    }
    // else if(route.includes('workbench/ar')) {
    //   this._router.navigateByUrl('workbench/ar/automation/invoice')
    // }
    // this._router.navigate([route])
  }

  over(drop: NgbDropdown) {
    drop.open();
  }
  out(drop: NgbDropdown) {
    setTimeout(() => {
      drop.close();
    }, 1000);
  }


  imageErrorHandler() {
    this.imageAvailable = false;
  }


  imageErrorHandlerLogo() {
    this.imageAvailableLogo = false;
  }

  roleStatus() {
    this._AP_ = this._roleService.checkRole("ACCOUNTS_PAYABLE","VIEW");
    this._AR_ = this._roleService.checkRole("ACCOUNTS_RECEIVABLE",'MANAGE');
    this._CONNECTOR_ = this._roleService.checkRole("CONNECTOR",'VIEW');
    this._REPORT_ = this._roleService.checkRole("REPORT",'VIEW');
    this._WORKFLOW_ = this._roleService.checkRole("WORKFLOW",'VIEW');
    this._CONTRACT_ = this._roleService.checkRole("CONTRACT",'VIEW');
    this._PROCESSFLOW_ = this._roleService.checkRole("PROCESSFLOW",'VIEW');
  }

  // getAutomation() {
  //   this.workbenchService.getAutomation(this.tokenDetail.org.id).subscribe((automation) => {
  //     this.dashboardFlag=automation;
  //     if(this.dashboardFlag.ap_enabled===true)
  //     {
  //       this._router.navigateByUrl('workbench/ap/automation/dashboard')
  //     }
  //     else
  //     {
  //       this._router.navigateByUrl('workbench/ap/v1/dashboard')
  //     }
  //   });
  // }
}
