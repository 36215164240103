
<app-account-list-widget [widgetData]="widgetData"
(itemEvent)="childButtonClicked($event)" *ngIf="showWidget === true"></app-account-list-widget>
<div class="div-numero-container div-main-content"  *ngIf="showWidget === false && mapLoad === true">
    <div class="row">
        <!-- <div class="col-md-2 div-head">
            <p>Connectors</p>

        </div> -->
        <div class="col-md-10 div-head">
            <div class="div-slider">
                <div class='tabs slide-tabs' *ngIf="typeMap && typeMap.size > 0">
                    <ng-container *ngFor="let type of typemapKeys();let i= index">
                        <input type='radio' id='r{{i}}' name='{{type}}' [checked] ="activeTabType === type" (click)="filterTabs(type)">
                        <label for='r{{i}}'><div> {{type | titlecase}} </div></label>
                    </ng-container>
                <div id='slider'></div>
                </div>
            </div>

          </div>
        <div class="col-md-12 div-top-menu" *ngIf="typeMap && typeMap.size > 0">
            <ng-container *ngFor="let category of activeTabKeys(activeTabType); let i = index">
                <button class="btn-menu" [ngClass]="{'active' : activeTabCategory === category}" type="button" (click)="filterSources(category)"><p>{{category}}</p></button>
            </ng-container>
        </div>
    </div>
    <!-- <button (click)="onOpenModalClick()"> click</button> -->
    <app-connector-card
        *ngIf="typeMap && typeMap.size > 0"
        [connectors] = "typeMap.get(activeTabType).get(activeTabCategory)"
        (authPopupSubmitted) = "authPopupSubmitted($event)"
        [_CONNECTOR_]="_CONNECTOR_"
        >
    </app-connector-card>
</div>
