import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgbAccordionModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartModule } from 'angular-highcharts';
import { ToastrModule } from 'ngx-toastr';
import { MaterialModule } from 'material.module';
import { SyncfusionModule } from 'syncfusion.module';
import { WidgetComponent } from './widget/widget.component';
import { AvatarModule } from 'ngx-avatars';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridComponent } from './grid/grid.component';
import { CustomFilterComponent } from './custom-filter/custom-filter.component';
import { NumberCardComponent } from './metric-card/number-card/number-card.component';
import { ChartCardComponent } from './metric-card/chart-card/chart-card.component';
import { BarChartComponent } from './metric-card/charts/bar-chart/bar-chart.component';
import { LineChartComponent } from './metric-card/charts/line-chart/line-chart.component';
import { PieChartComponent } from './metric-card/charts/pie-chart/pie-chart.component';
import { TableChartComponent } from './metric-card/charts/table-chart/table-chart.component';
import { DataCardComponent } from './metric-card/data-cards/data-card/data-card.component';
import { AccountListWidgetComponent } from './account-list-widget/account-list-widget.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { NumeroDropdownComponent } from './numero-dropdown/numero-dropdown.component';
import { InputRegexDirective } from './directive/input-regex.directive';
import { ApDashboardDemoComponent } from 'app/dashboard/action-dashboard/ap-action-dashboard/ap-action-dashboard-component.component';
import { ArDashboardDemoComponent } from 'app/dashboard/action-dashboard/ar-action-dashboard/ar-action-dashboard.component';
import { CustomCurrencyPipe } from './utills/currency.pipe';
import { NumberFormatDirective } from './utills/number-format.directive';
import { StringToNumberPipe } from './utills/string-to-number.pipe';
import { PromiseToPayComponent } from 'app/workbench-ar-automation-filter/promise-to-pay/promise-to-pay.component';
import { WorkbenchArInvoiceConversationsComponent } from 'app/workbench-ar-automation-filter/workbench-ar-invoice-conversations/workbench-ar-invoice-conversations.component';
import { MultiTypeChartComponent } from './metric-card/charts/multi-type-chart/multi-type-chart.component';
import { ResizeColumnDirective } from './directive/resize-column.directive';
import { UtcToLocalTimePipe } from './utills/utcToLocalTime.pipe';
import { LottieModule } from 'ngx-lottie';
import { MarkedPipe } from './utills/marked.pipe';
import { PspdfViewerComponent } from './pspdf-viewer/pspdf-viewer.component';
import { CommonPopupAlertComponent } from './common-popup-alert/common-popup-alert.component';
import { TextHihglightPipe } from './pipe/text-hihglight.pipe';
import { NavigationComponent } from './component/navigation/navigation.component';
import { CannyWidgetComponent } from './external-tool/canny-widget/canny-widget.component';
import { AutosizeModule } from 'ngx-autosize';
import { DragOverHighlightDirective } from './directive/drag-over-highlight.directive';


export function playerFactory(): any {
  return import('lottie-web');
}

@NgModule({
  declarations: [
    WidgetComponent,
    GridComponent,
    CustomFilterComponent,
    NumberCardComponent,
    ChartCardComponent,
    BarChartComponent,
    LineChartComponent,
    PieChartComponent,
    TableChartComponent,
    DataCardComponent,
    AccountListWidgetComponent,
    PdfViewerComponent,
    PromiseToPayComponent,
    NumeroDropdownComponent,
    ApDashboardDemoComponent,
    ArDashboardDemoComponent,
    WorkbenchArInvoiceConversationsComponent,
    MultiTypeChartComponent,
    PspdfViewerComponent,
    CommonPopupAlertComponent,
    NavigationComponent,
    CustomCurrencyPipe,
    MarkedPipe,
    StringToNumberPipe,
    UtcToLocalTimePipe,
    InputRegexDirective,
    NumberFormatDirective,
    ResizeColumnDirective,
    DragOverHighlightDirective,
    TextHihglightPipe,
    CannyWidgetComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NgbModule,
    NgbAccordionModule,
    ChartModule,
    AvatarModule,
    FormsModule,
    AutosizeModule,
    ReactiveFormsModule,
    SyncfusionModule,
    LottieModule.forRoot({ player: playerFactory }),
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      closeButton: true,
      progressBar: true,
      iconClasses: {
          error: 'far fa-exclamation-triangle',
          info: 'fa fa-info',
          success: 'far fa-check-circle',
          warning: 'far fa-exclamation-circle'
      },
  }),
  ],
  exports: [
    WidgetComponent,
    GridComponent,
    NumberCardComponent,
    ChartCardComponent,
    BarChartComponent,
    LineChartComponent,
    PieChartComponent,
    AccountListWidgetComponent,
    WorkbenchArInvoiceConversationsComponent,
    PdfViewerComponent,
    PromiseToPayComponent,
    NumeroDropdownComponent,
    ApDashboardDemoComponent,
    ArDashboardDemoComponent,
    PspdfViewerComponent,
    NavigationComponent,
    CommonPopupAlertComponent,
    NumberFormatDirective,
    InputRegexDirective,
    ResizeColumnDirective,
    DragOverHighlightDirective,
    CustomCurrencyPipe,
    MarkedPipe,
    UtcToLocalTimePipe,
    StringToNumberPipe,
    TextHihglightPipe,
    ChartModule,
    FormsModule,
    AutosizeModule,
    ReactiveFormsModule,
    ToastrModule,
    MaterialModule,
    SyncfusionModule,
    AvatarModule,
    NgbModule,
    NgbAccordionModule,
    LottieModule
  ]
})
export class SharedModule { }
