import { Component, Input } from '@angular/core';
import { ILegendRenderEventArgs } from '@syncfusion/ej2-angular-charts';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent {
  @Input() metricData;
  @Input() chartHeight;

  primaryXAxis: Object = {};
  chartData: any;
  pieData: any;
  count = 0;
  title: string = "";
  pointColorMapping = "";
  public legendSettings = {};
  public totExp = 124948;
  public palettes = ['#4D81E5','#C65368', '#32B19A', '#E7854D', '#369F73', '#4773A7', '#E87D65', '#725E16', '#8268A5', '#0D3D86' ];
  public pieTooltipSetting: Object = { enable: true, format: '${point.x} : ${point.y}%' };
  data: any;
  public legendRender(args: ILegendRenderEventArgs): void {
    var data = this.chartData[this.count];
    args.text = args.text + " : " + data.gold;
    this.count++;
  }
  ngOnInit() {
    console.log(this.metricData.chart.data);
    this.chartData = this.formatDataForChart();
    this.legendSettings = { visible: true, position: "Right" };
  }

  public formatDataForChart() : any {
    let obj:any ={}
    let chartData =[]
    let totVendorExp = 0;
    this.metricData.chart.data.forEach((gD:any) =>{
      if(gD.category !== 'Total') {
        obj= {}
        obj.name = gD.category
        obj.y = gD.value
        obj.TextMapping = gD.category+" : "+ gD.total
        chartData.push(obj)
        totVendorExp = totVendorExp + obj.y;
       }
    });
    this.totExp = totVendorExp;
    return chartData;
  }

}
