import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import moment from 'moment-timezone';
import { Observable, Subject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { handleError } from '../core/handle-error';
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private http: HttpClient) {}

  public pageHeader: any = '';
  public currentRoute: any = '';
  public subPageHeader: any = '';
  public hideAiIcon: boolean = true;
  public environment = environment;

  setPageHeader(pageHeader) {
    this.pageHeader = pageHeader;
  }

  setsubPageHeader(subPageHeader) {
    this.subPageHeader = subPageHeader;
  }

  getPageHeader(): any {
    return this.pageHeader;
  }

  getsubPageHeader(): any {
    return this.subPageHeader;
  }

  setCurrentRoute(currentRoute) {
    this.currentRoute = currentRoute;
  }

  getCurrentRoute(): any {
    return this.currentRoute;
  }

  setHideAiIcon(hideAiIcon) {
    this.hideAiIcon = hideAiIcon;
  }

  getHideAiIcon(): any {
    return this.hideAiIcon;
  }
  getDocImage(doc: any): string {
    if (doc && doc.doc_name && doc.doc_name.toLowerCase().includes('.docx')) {
        return `${this.environment.imagePrefixURI}/utill/docx.svg`;
    } else {
        return `${this.environment.imagePrefixURI}/utill/pdf.svg`;
    }
  }
  public timeRange = new Map([
    [
      'YTD',
      {
        displayName: 'YTD',
        startDate: moment().startOf('year').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      },
    ],
    [
      'MTD',
      {
        displayName: 'MTD',
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      },
    ],
    [
      'QTD',
      {
        displayName: 'QTD',
        startDate: moment().startOf('quarter').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      },
    ],
    [
      'M24',
      {
        displayName: 'M24',
        startDate: moment()
          .subtract(24, 'months')
          .startOf('month')
          .format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      },
    ],
  ]);

  getTimeRange(range) {
    let dR = [];
    range.forEach((r) => {
      if (this.timeRange.has(r)) {
        dR.push(this.timeRange.get(r));
      }
    });
    return dR;
  }
  handleEvent = new Subject;
  changedEventValue: any;
  getEventDetuction() {
    return this.handleEvent.asObservable();
  }

  getKeyMaster = (type: string): Observable<any> => {
    return this.http
      .get(`${environment.server.baseURL}/key-master?type=${type}`)
      .pipe(catchError(handleError));
  };

  getKeyMasterEntityId = (type: string, entity_id: string): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/key-master-strict?type=${type}&entity_id=${entity_id}`
      )
      .pipe(catchError(handleError));
  };
  getKeyMasterByType = (type:string):Observable<any> =>{
    return this.http
    .get(`${environment.server.baseURL}/key-master-type?type=${type}`)
    .pipe(catchError(handleError));
  }

  getFeatureosJWT() {
    return this.http
      .get(`${environment.server.baseURL}/user/featureostoken`)
      .pipe(catchError(handleError));
  }

  openPdfInNewTab(base64String: string) {
    // Convert base64 string to a Blob
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    const url = URL.createObjectURL(blob);

    window.open(url, '_blank');
  }

}
