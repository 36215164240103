import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this._loading.asObservable();

  show(): void{
    setTimeout(() => {
      this._loading.next(true);
    }, 1);
  }

  hide(): void{
    setTimeout(() => {
    this._loading.next(false);
  }, 1);
  }
}
