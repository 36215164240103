import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'app/service/shared/common.service';
import { environment } from 'environments/environment';
import PSPDFKit, { Rect } from 'pspdfkit';
import { isArray } from 'rxjs/internal-compatibility';

@Component({
  selector: 'app-pspdf-viewer',
  templateUrl: './pspdf-viewer.component.html',
  styleUrls: ['./pspdf-viewer.component.scss'],
})
export class PspdfViewerComponent implements OnInit {
  @Input() pdfSrc!: string;
  @Input() cHeight: number =100;
  @Input() showToolbar: boolean =true;
  public environment = environment
  public PDFInstance: any;
  public annotationIdList = [];
  public toolbarItems: any = [
    { type: 'sidebar-thumbnails' },
    { type: 'pager' },
    { type: "zoom-in" },
    { type: "zoom-out" },
    { type: "zoom-mode" },
    { type: 'custom',
     icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 39 39" fill="none">
  <path d="M27.5498 2.01416H37.4859V11.9503" stroke="black" stroke-width="2.12917" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M11.9498 37.4859H2.01367V27.5498" stroke="black" stroke-width="2.12917" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M37.4859 27.5498V37.4859H27.5498" stroke="black" stroke-width="2.12917" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M37.5006 2L27.5645 11.9361" stroke="black" stroke-width="2.12917" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M2 37.5006L11.9361 27.5645" stroke="black" stroke-width="2.12917" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M37.5006 37.5006L27.5645 27.5645" stroke="black" stroke-width="2.12917" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M2 2L11.9361 11.9361" stroke="black" stroke-width="2.12917" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M2.01367 11.9503V2.01416H11.9498" stroke="black" stroke-width="2.12917" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`,
    onPress: () => this.toggleFullScreen() },
    { type: 'spacer' },
    { type: 'search' },

    // { type: 'highlighter' },
  ];
  ngOnInit(): void {
    PSPDFKit.load({
      // Use the assets directory URL as a base URL. PSPDFKit will download its library assets from here.
      initialViewState: new PSPDFKit.ViewState({
        zoom: PSPDFKit.ZoomMode.FIT_TO_WIDTH,
        showToolbar: this.showToolbar,
      }),
      // styleSheets: ["./pspdf-viewer.component.scss"],
      toolbarItems: this.toolbarItems,
      baseUrl: location.protocol + '//' + location.host + '/assets/',
      document: `data:application/pdf;base64,${this.pdfSrc}`,
      container: '#pspdfkit-container',
      licenseKey: this.environment.pspdf.key,
    }).then((instance) => {
      this.PDFInstance = instance;
    });
  }

  toggleFullScreen() {
    const element = document.getElementById('pspdfkit-container');
    if (this.isFullScreen()) {
        this.exitFullScreen();
    } else {
        this.enterFullScreen(element);
    }
}

enterFullScreen(element: HTMLElement) {
  if (element.requestFullscreen) {
      element.requestFullscreen();
  }
}
exitFullScreen() {
  if (document.fullscreenElement) {
      document.exitFullscreen();
  } else if (document.exitFullscreen) {
      document.exitFullscreen();
  }
}


isFullScreen(): boolean {
  return document.fullscreenElement !== null;
}




  async createnewRect(pageNo, coord) {
    await this.deleteAnnotations();
    this.annotationIdList = [];
    await this.mapCoordinate(pageNo, coord);
  }

  async mapCoordinate(pageNo, coord) {
    let rectArray: Rect[] = [];
    if (isArray(coord)) {
      coord.forEach((element,i) => {
        let rectData = this.getRectangleStyles(element);
        rectArray.push(new PSPDFKit.Geometry.Rect(rectData));
        if(i===0) {
          this.jumpTopageRect(pageNo, new PSPDFKit.Geometry.Rect(rectData))
        }
      });
    } else {
      let rectData = this.getRectangleStyles(coord);
      rectArray.push(new PSPDFKit.Geometry.Rect(rectData));
      this.jumpTopageRect(pageNo, new PSPDFKit.Geometry.Rect(rectData))
    }

    let rects = PSPDFKit.Immutable.List(rectArray);

    const createdAnnotations = await this.PDFInstance.create(
      new PSPDFKit.Annotations.HighlightAnnotation({
        pageIndex: pageNo,
        rects: rects,
        boundingBox: PSPDFKit.Geometry.Rect.union(rects),
        action: new PSPDFKit.Actions.GoToAction({ pageIndex: pageNo }),
      })
    );
    const [savedAnnotation] = await this.PDFInstance.ensureChangesSaved(
      createdAnnotations
      );
      this.annotationIdList.push(savedAnnotation.id);
    console.log(savedAnnotation.id);
  }

  scrollToPageNo(pageNo) {
    const newState = this.PDFInstance.viewState.set("currentPageIndex", pageNo);
    this.PDFInstance.setViewState(newState);
  }

  async jumpTopageRect(pageNo ,rectData) {
    this.PDFInstance.jumpToRect(pageNo,new PSPDFKit.Geometry.Rect(rectData));

  }

  async deleteAnnotations() {
    this.annotationIdList.forEach(async (element) => {
      const del = await this.PDFInstance.delete(element);
    });
  }

  getRectangleStyles(coord: any) {
    return {
      top: coord.y1-2,
      left: coord.x1 -7,
      width: coord.x2 - coord.x1+15,
      height: coord.y2 - coord.y1+4,
    };
  }
}
