import { Component, Input } from '@angular/core';
import {
  ILoadedEventArgs,
  ChartTheme,
  IAxisLabelRenderEventArgs,
} from '@syncfusion/ej2-angular-charts';
@Component({
  selector: 'app-chart-card',
  templateUrl: './chart-card.component.html',
  styleUrls: ['./chart-card.component.scss']
})
export class ChartCardComponent {
  @Input() metricData;
  @Input() chartHeight;
}
