<div class="div-mat-card" *ngIf="noBills" >
  <div class="div-no-data">
    <img src="{{environment.imagePrefixURI}}/nobills.svg" alt="image" />
  </div>
</div>
<div class="mt-3"*ngIf="!noBills  && isDemo == false">
<!-- <div class="div-numero-container div-main-content" *ngIf="arRes!== undefined"> -->
  <!-- <div class="row">
    <div class="col-md-6 div-head-ar"> -->
      <!-- <p class="primary-text">Account Receivable Dashboard</p> -->
    <!-- </div>
    <div class="col-md-6 div-export-sec">
      <select class="form-select sel-cust" [(ngModel)]="selectedAccount">
        <option *ngFor="let obj of accounts" [ngValue]="obj.value">{{obj.name}}</option>
      </select>
      <div class="div-sel-period"> {{ currentDate | date: 'MMM dd, yyyy' }}</div>
    </div> -->
    <!-- <div class="col-md-12 div-divider">
      <hr>
    </div> -->
  <!-- </div> -->
  <!-- {{ arRes | json }} -->
  <div *ngFor="let arD of arRes">
    <div>
      <!-- -----ROW---- {{ arD.rows | json }} -->
      <div class="row nu-card-row"  [style.height.px]="arDRow.height" *ngFor="let arDRow of arD.rows">
        <!-- ----COLS--- {{ arDRow.cols | json }} -->
        <div
          class="col-{{ arDRCard.colSize }} nu-metric-card"
          *ngFor="let arDRCard of arDRow.cols"
        >
          <!-- ----CARDS---
          {{arDRCard.cards | json}} -->
          <div [ngClass]="{'h-92': arDRCard.cards.length === 1, 'h-48': arDRCard.cards.length === 2, 'h-33': arDRCard.cards.length === 3}" *ngFor="let arDRCardMetric of arDRCard.cards" class="nu-metric-card-div ">
            <ng-container
              *ngIf="
                arDRCardMetric.type !== 'verticalbarchart' &&
                arDRCardMetric.type !== 'verticalareachart' &&
                arDRCardMetric.type !== 'tablecard' &&
                arDRCardMetric.type !== 'verticalChartDataCard' &&
                arDRCardMetric.type !== 'horizontalChartDataCard'
              "
            >
              ----Metric--- {{ arDRCardMetric | json }}
            </ng-container>
            <ng-container *ngIf="arDRCardMetric.type === 'verticalbarchart'">
              <div class="ff-ngc div-card-title mb-2">
                <p>{{ arDRCardMetric.name }}</p>
              </div>
              <app-chart-card [metricData]="arDRCardMetric" [chartHeight]="arDRow.height - 60"></app-chart-card>
            </ng-container>
            <ng-container *ngIf="arDRCardMetric.type === 'verticalareachart'">
              <div class="ff-ngc div-card-title mb-2">
                <p>{{ arDRCardMetric.name }}</p>
              </div>
              <app-chart-card [metricData]="arDRCardMetric" [chartHeight]="arDRow.height - 60"></app-chart-card>
            </ng-container>

            <ng-container *ngIf="arDRCardMetric.type === 'tablecard'">
              <div class="ff-ngc div-card-title  mb-2">
                <p>{{ arDRCardMetric.name }}</p>
              </div>
              <app-chart-card [metricData]="arDRCardMetric" [chartHeight]="arDRow.height - 60"></app-chart-card>
            </ng-container>


            <ng-container *ngIf="arDRCardMetric.type === 'verticalChartDataCard' || arDRCardMetric.type === 'horizontalChartDataCard'">
              <div class="data-card">
                <app-number-card [metricData]="arDRCardMetric" [chartHeight]="arDRow.height + 40" [cardCount]="arDRCard.cards.length"></app-number-card>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

