import { Component } from '@angular/core';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { AiService } from 'app/service/ai/ai.service';
import { CommonService } from 'app/service/shared/common.service';
import { error } from 'logrocket';
import { Router } from '@angular/router';
import { JWTToken } from 'app/core/service/auth/auth';
import { AuthServices } from 'app/core/service/auth/auth.service';

@Component({
  selector: 'app-ai-chat',
  templateUrl: './ai-chat.component.html',
  styleUrls: ['./ai-chat.component.scss'],
})
export class AiChatComponent {
  public answerText: string;
  public inputText: string;
  public tblData: any = {};
  public showSecondQues: boolean = false;
  public promptData: any;
  //  [
  //   { icon: 'fa-receivable-prompt', title: 'Recievables' },
  //   { icon: 'fa-numero-profile', title: 'Customer Contracts' },
  //   { icon: 'fa-numero-invoice', title: 'Invoice' },
  //   { icon: 'fa-cash-flow', title: 'Cash Flow'},
  //   { icon: 'fa-vendor-prompt', title: 'Vendor' },
  // ];
  public data: any;
  jwtToken: JWTToken = undefined;

  constructor(
    public _commonService: CommonService,
    public _aiService: AiService,
    private _loader: LoaderService,
    private _router: Router,
    public _authServices: AuthServices,
  ) {}

  ngOnInit(): void {
    this._authServices.getJWTDetail().then(data=>{
      this.jwtToken = data
      this.getAITableData();
      this.getAiDasboard();
    });
    this._commonService.setPageHeader('Numero Copilot');
    this._commonService.setsubPageHeader('');

  }

  getAITableData() {
    this._loader.show();
    this._aiService.getAIData().subscribe(
      (data) => {
        this.tblData = data;
        this._loader.hide();
      },
      (err) => {
        this._loader.hide();
      }
    );
  }
  getAiDasboard() {
    this._loader.show();
    this._aiService.getAidashboard().subscribe(
      (data) => {
        this.data = data;
        this._loader.hide();
      },
      (err) => {
        this._loader.hide();
      }
    );
  }
  filterNavigation(item) {
    let targetRoute;
    targetRoute = '/ai/prompt';
    this._router.navigateByUrl(targetRoute, { state: item });
    console.log('item',item)
  }

  getResultForInputText(questionType) {
    this._loader.show();
    const qData =  {name:this.inputText  , value: this.inputText };
    this.navigateToResult(questionType, qData)
  }

  navigateToResult(questionType, questionData){
    let targetRoute='/ai/chat';
    let quesData = questionData;
    quesData.quesType = questionType;
    this._router.navigateByUrl(targetRoute, { state: questionData });
  }

}
