<div class="div-preview-modal">
    <div class="div-preview-modal-header">
        <div class="row">
            <div class="col-11 pr-0 d-inline-flex chart-header-left mb-4">  
              <img class="ml-1 img-ht al-self-center"  src="{{ connection.image_url }}" alt="image"/>         
                <div
                class="chart-header-title cursor-default al-self-center"
                title="{{ connection.name }}"
                >{{ connection.name }}
                </div>
            </div>
            <div class="col-1 text-right">
                <button class="btn btn-close-preview" type="button" (click)="onDismiss('Close Modal on Click')">
                    <i class="far fa-times"></i>
                </button>
            </div>
        </div>
        <div class="row">
          <ng-container *ngFor="let con of connection.connectedItems">
            <div class="col-11 mb-2"> {{con.identifier}} </div>
            <div class="col-1 mb-2" (click)="deleteConnector(connection)"> <i class="far fa-trash"></i></div>
          </ng-container>
        </div>
    </div>
</div>
