import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent {
  @Input() widgetData: any = {};
  @Output() itemEvent = new EventEmitter<string>();

  buttonClick(value: string) {
    this.itemEvent.emit(value);
  }

}
