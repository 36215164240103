<!-- https://stackblitz.com/run?file=src%2Fapp.component.ts -->
<div class="bs-div">
  <div class="bs-div-header container-fluid">
      <div class="div_filter">
        <div class="row">
          <div class="col-md-6 d-flex">
            <!-- <select class="form-select sel-cust" [(ngModel)]="selectedPeriod" (change)="fetchBalanceSheet()">
              <option *ngFor="let obj of timeranges" [ngValue]="obj">{{obj.name}}</option>
            </select> -->

            <div class="div-cust-dropdown-time">
              <app-numero-dropdown [dropdownlist]="timeranges" [selAcc]="selectedPeriod" [type]="'none'" [setWidth]="'none'" (dropdownSelValue)="dropdownTimeChangedEvent($event)"></app-numero-dropdown>
            </div>

            <div class="div-sel-period" *ngIf="selectedPeriod.name !== 'Custom'">{{selectedPeriod.start_date | date : "MMM dd, yyyy"}} <i class="fas fa-arrow-right"></i>{{selectedPeriod.end_date | date : "MMM dd, yyyy"}}</div>

            <div class="div-sel-period cust-month-picker cursor-pointer" *ngIf="selectedPeriod.name === 'Custom'">
              <ejs-daterangepicker #datePicker [start]='start' [format]="format" [depth]='depth' class="hidden-field" (change)="onDateChange($event)"></ejs-daterangepicker>
              <span class="cntr-align" (click)="openDatePicker()">
                <i class="far fa-calendar-alt fts-cal-icon"></i>
                <span style="padding: 0px 6px;">Select Month</span>
                <i class="fak fa-vector fts-vector-icon"></i>
              </span>
            </div>
          </div>
          <div class="col-md-6 div-export-sec">
            <!-- <select class="form-select sel-cust" [(ngModel)]="selAcc" (change)="fetchBalanceSheet()">
              <option *ngFor="let obj of acc" [ngValue]="obj">{{obj.name}}</option>
            </select> -->
            <select class="form-select sel-cust d-none" [(ngModel)]="selectedAccount">
              <option *ngFor="let obj of accounts" [ngValue]="obj.value">{{obj.name}}</option>
            </select>
            <div class="div-cust-dropdown">
              <app-numero-dropdown [dropdownlist]="acc" [selAcc]="selAcc" [type]="'image'" [setWidth]="'dynamic'" (dropdownSelValue)="dropdownChangedEvent($event)"></app-numero-dropdown>
            </div>
            <div class="div-export-pdf-excel" ngbDropdown container="body" routerLinkActive="isActive" display="dynamic" placement="bottom-end">
              <div class="div-export-btn" ngbDropdownToggle>
                <i class="fak fa-numero-download"></i>
              </div>
              <div class="div-export-dropdown" ngbDropdownMenu>
                <div><button title="Export Excel" (click)="excelDownload()"><img src="./assets/image/excel.svg"> Excel</button></div>
                <div class="mtop_06"><button title="Export PDF" (click)="pdfDownload()"><img src="./assets/image/pdf.svg"> PDF</button></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <div class="row">
          <div class="col-md-6">
            <div class="row m0">
              <div class="col-md-12 div-chart">
                <div class="row">
                  <div class="col-md-12 div-chart-title">
                    Assets: <span>{{totAssets | currencypipe: this.selAcc.currency}}</span>
                  </div>
                  <div class="col-md-4 text-center">
                    <div class="div-tot-expense ff-ngc">
                      Bank Accounts
                    </div>
                    <div class="div-amt">
                      {{totBankAcc | currencypipe: this.selAcc.currency}}
                    </div>
                  </div>
                  <div class="col-md-4 text-center">
                    <div class="div-tot-expense ff-ngc">
                      AR
                    </div>
                    <div class="div-amt">
                      {{totAR | currencypipe: this.selAcc.currency}}
                    </div>
                  </div>
                  <div class="col-md-4 text-center">
                    <div class="div-tot-expense ff-ngc">
                      Fixed Assets
                    </div>
                    <div class="div-amt">
                      {{totFixedAssets | currencypipe: this.selAcc.currency}}
                    </div>
                  </div>
                  <div class="col-md-12" *ngIf="showChart">
                    <div class="div-bar-chart mt-3" [chart]="assetsbarchart" style="height:250px;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row m0">
              <div class="col-md-12 div-chart">
                <div class="row">
                  <div class="col-md-12 div-chart-title">
                    Liabilities & Equity: <span>{{totLiaEquity | currencypipe: this.selAcc.currency}}</span>
                    <div class="div-acc-btns">
                      <button class="btn-acc" type="button" (click)="hidechart()" *ngIf="showChart"><i class="fas fa-chevron-up"></i></button>
                      <button class="btn-acc" type="button" (click)="showchart()" *ngIf="!showChart"><i class="fas fa-chevron-down"></i></button>
                    </div>
                  </div>
                  <div class="col-md-4 text-center">
                    <div class="div-tot-expense ff-ngc">
                      Accounts Payable
                    </div>
                    <div class="div-amt">
                      {{totAccPayable | currencypipe: this.selAcc.currency}}
                    </div>
                  </div>
                  <div class="col-md-4 text-center">
                    <div class="div-tot-expense ff-ngc">
                      Liabilities
                    </div>
                    <div class="div-amt">
                      {{totLia | currencypipe: this.selAcc.currency}}
                    </div>
                  </div>
                  <div class="col-md-4 text-center">
                    <div class="div-tot-expense ff-ngc">
                      Equity
                    </div>
                    <div class="div-amt">
                      {{totEquity | currencypipe: this.selAcc.currency}}
                    </div>
                  </div>
                  <div class="col-md-12" *ngIf="showChart">
                    <div class="div-bar-chart mt-3" [chart]="liabarchart" style="height:250px;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    <!-- </div> -->
  </div>
  <div class="control-section">
    <ejs-treegrid
      #treegrid
      [allowResizing]='true'
      [dataSource]="gridData"
      sizeSettings.height="auto"
      hideScroll="true"
      childMapping="subAccount"
      [(height)]="height"
      [treeColumnIndex]="0"
      [toolbar]='toolbar'
      [allowSelection]="false"
      [pageSettings]="pageSettings"
      enableCollapseAll="true"
      (rowDataBound)="rowDataBound($event)"
      (dataBound)='dataBound($event)'
      (queryCellInfo)="queryCellInfo($event)"
      allowExcelExport="true"
      allowPdfExport="true"
    >
      <e-columns>
        <ng-container  *ngFor="let col of tableHeader">
        <e-column
          *ngIf="col.fieldName === 'accountName'"
          field="{{ col.fieldName }}"
          headerText="{{ col.displayName }}"
          width="{{ col.width }}"
          textAlign="{{ col.textAlign }}"
          [customAttributes]="customAttributes"
        ></e-column>
        <e-column
          *ngIf="col.fieldName !== 'accountName'"
          field="{{ col.fieldName }}"
          headerText="{{ col.displayName }}"
          width="{{ col.width }}"
          textAlign="{{ col.textAlign }}"
          [customAttributes]="customAttributes"
        ></e-column>
      </ng-container>
      </e-columns>
    </ejs-treegrid>
  </div>
</div>
