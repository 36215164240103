import { Component } from '@angular/core';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { AiService } from 'app/service/ai/ai.service';
import { CommonService } from 'app/service/shared/common.service';

@Component({
  selector: 'app-ai-prompt-library',
  templateUrl: './ai-prompt-library.component.html',
  styleUrls: ['./ai-prompt-library.component.scss']
})
export class AiPromptLibraryComponent {
  public answerText: string;
  public answerOutText: string;
  public showSecondQues: boolean = false;
  public tblData: any = {};
  public promptData:any;
  constructor (
    private _loader: LoaderService,
    public _commonService: CommonService,
    public _aiService: AiService,
  ){}


  ngOnInit(): void {
    this._loader.show();
    this._commonService.setPageHeader("Numero Copilot");
    this._commonService.setsubPageHeader("");
    this.getAITableData();
    setTimeout(() => {
      this._loader.hide();
    }, 1000);
  }

 getAITableData() {
    this._loader.show();
    this._aiService
      .getAIData()
      .subscribe(
        (data) => {
          this.tblData = data;
          this._loader.hide();
        },
        (err) => {
          this._loader.hide();
        }
      );
  }

  showOutQues() {
    this.showSecondQues = true;
  }
}
