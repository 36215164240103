<div class="landing-bgimage container-fluid {{ widgetData.widgetClassname }}">
  <div class="landing-widget text-center">
    <div class="landing-widget-container">
      <div class="landing-widget-container-image container" *ngIf="widgetData.widgetImage">
        <img src="{{ widgetData.widgetImage }}" />
      </div>
      <div *ngIf="widgetData.widgetIcon"
        class="landing-widget-container-icon {{ widgetData.widgetIcon }}"
        [style.font-size.px]="widgetData.iconFont"
      ></div>
      <div class="landing-widget-container-content">
        <p class="landing-widget-container-icon-title">
          {{ widgetData.ImageTitle }}
        </p>
        <p class="landing-widget-container-header">
          {{ widgetData.widgetHeading }}
        </p>
        <p class="landing-widget-container-desc">
          {{ widgetData.widgetDesc }}
        </p>
        <a
          *ngIf="widgetData.widgetBtnText"
          class="btn mb-4 landing-widget-container-btn text-white"
          (click)="buttonClick(widgetData.widgetBtnText)"
          [attr.id]="widgetData.widgetClassname"
        >
          {{ widgetData.widgetBtnText }}
        </a>
      </div>
    </div>
  </div>
</div>
