import { Component, Input, OnInit } from '@angular/core';
import {
  ChartTheme,
  IAxisLabelRenderEventArgs,
  ILoadedEventArgs,
} from '@syncfusion/ej2-angular-charts';
import { Browser } from '@syncfusion/ej2-base';
import { ChartService } from 'app/service/shared/chart.service';
@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit {
  @Input() metricData;
  @Input() chartHeight;
  public chartArea: Object = {
    border: {
      width: 0,
    },
  };
  public palette;
  public tooltip: Object;
  public marker: Object;
  public legend: Object = {
    visible: false,
    enableHighlight: false,
  };

  public width: string = Browser.isDevice ? '100%' : '100%';
  public data: Object[] = [{}];
  //Initializing Primary X Axis
  public primaryXAxis: Object;
  //Initializing Primary Y Axis
  public primaryYAxis: Object;
  // public circleMarker: Object = {
  //   visible: true,
  //   height: 6,
  //   width: 6,
  //   shape: 'Circle',
  //   isFilled: true,
  // };
  // public diamondMarker: Object = {
  //   visible: true,
  //   height: 7,
  //   width: 7,
  //   shape: 'Diamond',
  //   isFilled: true,
  // };
  public border: Object = {
    width: 2,
  };
  // custom code start
  public load(args: ILoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.chart.theme = <ChartTheme>(
      (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(
        /-dark/i,
        'Dark'
      )
    );
  }
  // custom code end
  //Initializing Chart Title
  public title: string = '';
  public unit = '';
  constructor(
    private chartService: ChartService,
  ) {
    // code
  }
  ngOnInit(): void {
    const MIN = Math.min(...this.metricData.chart.data.map((o) => o.value)) - 2;
    this.unit = this.metricData.chart.unit;
    this.palette = [
      this.metricData.chart.color,
      '#F9A03F',
      '#6DC190',
      '#C3DFE0',
    ];

    this.primaryYAxis = {
      labelFormat: `${this.unit === '$' ? this.unit : ''}{value}`,
      lineStyle: { width: 0 },
      // rangePadding: 'None',
      minimum: MIN < 3 ? 0 : MIN,
      // maximum: 4,
      // interval: 1,
      majorTickLines: { width: 0 },
      majorGridLines: { color: '#C5C5C5', width: 0, dashArray: 0 },
      visible: this.metricData.chart.yAxisLine,
      labelStyle: {
        fontFamily: 'Inter,sans-serif',
        color: '#8D8F95',
      },
    };
    this.primaryXAxis = {
      majorGridLines: { width: 0 },
      minorGridLines: { width: 0 },
      majorTickLines: { width: 0 },
      minorTickLines: { width: 0 },
      interval: 1,
      lineStyle: { width: 0 },
      // visible: this.metricData.chart.dataLabel,
      // labelIntersectAction: 'Rotate45',
      visible: this.metricData.chart.xAxisLine,
      valueType: 'Category',
      labelStyle: {
        fontFamily: 'Inter,sans-serif',
        color: '#8D8F95',
      },
    };
    this.marker = {
      visible: true,
      height: 4,
      width: 4,
      shape: 'Circle',
      isFilled: true,
      dataLabel: {
        visible: this.metricData.chart.dataLabel,
        position: 'Outer',
        template: '<div style="color: #8D8F95; font-family: Inter, sans-serif">${point.y}</div>',
      },
    };
    this.tooltip = {
      enable: this.metricData.chart.tooltip,
      header: '',
      format: '${point.x} : ${point.y}',
      textStyle : {
        fontFamily: "Inter, sans-serif"
      }
    };
  }

  OnChartAxisLabeRender(args: IAxisLabelRenderEventArgs) {
    this.chartService.formatAxisLabel(args);
  }
}
