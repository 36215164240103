import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output, Renderer2 } from '@angular/core';

import { environment } from 'environments/environment';

@Component({
  selector: 'app-numero-dropdown',
  templateUrl: './numero-dropdown.component.html',
  styleUrls: ['./numero-dropdown.component.scss']
})
export class NumeroDropdownComponent {
  @Input() dropdownlist;
  @Input() selAcc;
  @Input() type;
  @Input() setWidth;
  @Input() isDisabled = false;
  @Input() iconTxt;
  @Input() stringCase :string = 'lowercase';

  @Output() dropdownSelValue = new EventEmitter<string>();

  public environment = environment;

  constructor(
    @Inject(DOCUMENT) private _document: any,
    private _renderer: Renderer2
  ) { }

  dropdownSelected(appitem) {
    this.selAcc = appitem;
    this.dropdownSelValue.emit(appitem);
  }

  fixDropdownWidth(hostElement: HTMLDivElement) {
    setTimeout(() => {
      if(this.setWidth !== 'dynamic'){
        let bodyDD: HTMLDivElement[] = <HTMLDivElement[]>Array.from(this._document.body.children).filter((child: HTMLDivElement) => child.className.indexOf('dropdown') >= 0);
        if (bodyDD && bodyDD.length) {
          if (this.setWidth === 'section')
          {
            this._renderer.setStyle(bodyDD[0], 'width', '400px');
          } else {
            this._renderer.setStyle(bodyDD[0], 'width', hostElement.clientWidth + 'px');
          }
            this._renderer.addClass(bodyDD[0].children[0], 'fixed');
        }
      }
    }, 0);
  }
}
