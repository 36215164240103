import { Injectable } from '@angular/core';
import { IAxisLabelRenderEventArgs } from '@syncfusion/ej2-angular-charts';

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  formatAxisLabel(args: IAxisLabelRenderEventArgs, unit = ''): void {
    unit = unit === '$' ? unit : '';
    const value = args.value;
    const absValue = Math.abs(value);
    if (absValue >= 1e9) {
      // 1 billion or more
      args.text = unit + Number.parseFloat((value / 1e9).toFixed(1)) + 'B';
    } else if (absValue >= 1e6) {
      // 1 million or more
      args.text = unit + Number.parseFloat((value / 1e6).toFixed(1)) + 'M';
    } else if (absValue >= 1e3) {
      // 1 thousand or more
      args.text = unit + Number.parseFloat((value / 1e3).toFixed(1)) + 'K';
    }
  }
}
