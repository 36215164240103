import { Pipe, PipeTransform } from '@angular/core'; 


export enum UtcToLocalTimeFormat {
    FULL        = 'full',        // 'EEEE, MMMM d, y, h:mm:ss a zzzz'   - Monday, June 15, 2015 at 9:03:01 AM GMT+01:00
    SHORT       = 'short',       // 'd/M/yy, h:mm'                      - 15/6/15, 9:03
    SHORT_DATE  = 'shortDate',   // 'd/M/yy'                            - 15/6/15
    SHORT_TIME  = 'shortTime',   // 'h:mm'                              - 9:03
    CUSTOM_FULL_DATE_TIME = 'customFullDateTime' // 'MMM DD, YYYY h:mm:ss'    
}

@Pipe({
    name: 'utcToLocalTime',
})
export class UtcToLocalTimePipe implements PipeTransform {
    transform( 
        utcDate: string,    // UTC ISO-8601
        format: UtcToLocalTimeFormat | string ): string {

        var browserLanguage = navigator.language; 

        if (format === UtcToLocalTimeFormat.SHORT) {
            let date = new Date(utcDate).toLocaleDateString(browserLanguage);
            let time = new Date(utcDate).toLocaleTimeString(browserLanguage);

            return `${date}, ${time}`;
        } 
        else if (format === UtcToLocalTimeFormat.SHORT_DATE) {
            return new Date(utcDate).toLocaleDateString(browserLanguage);
        } 
        else if (format === UtcToLocalTimeFormat.SHORT_TIME) {
            return new Date(utcDate).toLocaleTimeString(browserLanguage);
        } 
        else if (format === UtcToLocalTimeFormat.FULL) {
            return new Date(utcDate).toString();
        } 
        else if (format === UtcToLocalTimeFormat.CUSTOM_FULL_DATE_TIME) {
            const months = [
                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
            ];
            const dateObj = new Date(utcDate);
            const month = months[dateObj.getMonth()];
            const day = dateObj.getDate();
            const year = dateObj.getFullYear();
            const hours = dateObj.getHours();
            const minutes = dateObj.getMinutes();
            const seconds = dateObj.getSeconds();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
            const formattedTime = `${formattedHours}:${minutes}:${seconds} ${ampm}`;
            return `${month} ${day}, ${year} ${formattedTime}`;
        } 
        else {
            console.error(
                `Do not have logic to format utc date, format:${format}`
            );
            return new Date(utcDate).toString();
        }
    }

}
    