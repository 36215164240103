import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-account-list-widget',
  templateUrl: './account-list-widget.component.html',
  styleUrls: ['./account-list-widget.component.scss']
})
export class AccountListWidgetComponent {


  constructor() {
  }
  selectedOption : any;
  @Input() widgetData: any = {};
  @Output() itemEvent = new EventEmitter<string>();




  buttonClick(value: string) {
    const data = this.widgetData.list.filter(element => {
      return element.organization_id === this.selectedOption
    });
    const obj ={ key: data[0].organization_id , name : data[0].name, currency: data[0].currency_code, country: data[0].country_code, state: data[0].state_code}
    this.itemEvent.emit(JSON.stringify(obj));
  }
}
