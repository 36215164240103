import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-chart',
  templateUrl: './table-chart.component.html',
  styleUrls: ['./table-chart.component.scss']
})
export class TableChartComponent {
  @Input() metricData;
  @Input() chartHeight;

}
