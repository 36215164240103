<div class="div-numero-container div-main-content">
    <app-widget
*ngIf="showHideWidget === true"
[widgetData]="widgetData"
(itemEvent)="childButtonClicked($event)"
></app-widget>
    <div class="row div-tab-margin" [ngClass]="{'d-none': (showHideWidget === true || showHideWidget === undefined)  }">
        <!-- <div class="col-md-2 div-head">
        </div> -->
        <!-- <div class="col-md-10 div-head">
            <div class="div-slider">
                <div class='tabs slide-tabs'>
                    <ng-container *ngFor="let type of typeMap;let i= index">
                        <input type='radio' id='r{{i}}' name='{{type}}' [checked] ="type === 'Ram'" >
                        <label for='r{{i}}'><div> {{type | titlecase}} </div></label>
                    </ng-container>
                <div id='slider'></div>
                </div>
            </div>
          </div> -->
        <div class="col-md-12 div-head p0">
            <div class="div-btns-tabs">
                <button class='btn ff-ngc' type="button" routerLink="/report/profitandloss" routerLinkActive="isActive" #rla="routerLinkActive" >Profit & Loss</button>
                <button class='btn ff-ngc' type="button" routerLink="/report/expense" routerLinkActive="isActive" #rla="routerLinkActive">Expense</button>
                <button class=' btn ff-ngc' type="button" *ngIf="gbFluxanalysis" routerLink="/report/fluxanalysis" routerLinkActive="isActive" #rla="routerLinkActive" >Flux Analysis</button>
                <button class=' btn ff-ngc' type="button" routerLink="/report/cashflow" routerLinkActive="isActive" #rla="routerLinkActive" >Cash Flow</button>
                <button class=' btn ff-ngc' type="button" routerLink="/report/balance-sheet" routerLinkActive="isActive" #rla="routerLinkActive" >Balance Sheet</button>


                <!-- <ng-container *ngFor="let category of typeMap.get(activeTabType).keys(); let i = index"> -
                    <button class="btn-menu" [ngClass]="{'active' : activeTabCategory === category}" type="button" (click)="filterSources(category)">{{category}}</button>
                </ng-container> -->
            </div>
        </div>
    </div>
    <div class="" *ngIf="showHideWidget === false" [ngClass]="{'d-none': (showHideWidget === true || showHideWidget === undefined)  }">
       <router-outlet></router-outlet>
    </div>
</div>
