<!-- <app-side-bar></app-side-bar> -->

<div class="div-numero-container div-main-content">
  <div class="row div-tab-margin">
    <!-- <div class="col-md-3">
      <div class="input-group mb-3 div-search">
        <div class="input-group-prepend">
          <i class="far fa-search"></i>
        </div>
        <input type="text"  class="form-control" id="globalSearchContext" placeholder="Search" aria-label="Search" (keyup)="typedSearch($event)">        
      </div>
    </div>
    <div class="col-md-9 text-right">
      <button type="button" class="btn btn-numero btn-add-workflow-plus" (click)="handleClickCreateFlow()"><i class="far fa-plus"></i></button>
    </div> -->
    <!-- <div class="col-md-12 div-top-menu"> -->
      <div class="col-md-12 div-head p0">
        <div class="row m0">
          <div class="div-btns-tabs col-md-8">
            <button [ngClass] = "isWorkflowVisible ? 'btn ff-ngc active' : 'btn ff-ngc'" type="button" (click)="clickOnWorkflows()">Workflows</button>
            <button [ngClass] = "isTemplateVisible ? 'btn ff-ngc active' : 'btn ff-ngc'" type="button" (click)="clickOnTemplates()">Templates</button>
            <!-- <button [ngClass] = "isCreatedByMe ? 'btn ff-ngc active' : 'btn ff-ngc'"  type="button" (click)="clickOnCreatedByMe()">Created by me</button>
            <button [ngClass] = "isCreatedByOthers ? 'btn ff-ngc active' : 'btn ff-ngc'" type="button" (click)="clickOnCreatedByOthers()">Created by others</button> -->
            <button *ngIf="gbExecutionDashboard" [ngClass] = "isExecutionDashboard ? 'btn ff-ngc active' : 'btn ff-ngc'"  type="button" (click)="clickOnExecutionHistory()">Execution History</button>
          </div>
          <div class="col-md-4 div-export-sec pr0">
              <!-- <select class="form-select sel-cust" [(ngModel)]="selectedAccount">
              <option *ngFor="let obj of accounts" [ngValue]="obj.value">{{obj.name}}</option>
              </select> -->
              <div class="div-sel-period"> {{ currentDate | date: 'MMM dd, yyyy' }}</div>
          </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div *ngIf = "isWorkflowVisible" class="col-md-12 div-top-menu">
      <div class="">
        <!-- <div class="div-side-sub-header">
          WORKFLOWS
        </div> -->
        <button [ngClass]="selectedWorkflowFilter === 'all' ? 'btn-menu active': 'btn-menu'" type="button" (click)="filterWorkflows('all')"><p class="br">All</p></button>
        <button [ngClass]="selectedWorkflowFilter === 'active' ? 'btn-menu active': 'btn-menu'" type="button" (click)="filterWorkflows('active')"><p class="br">Active</p></button>
        <button [ngClass]="selectedWorkflowFilter === 'inactive' ? 'btn-menu active': 'btn-menu'" type="button" (click)="filterWorkflows('inactive')"><p>Inactive</p></button>
      </div>
    </div>
    <div *ngIf = "isTemplateVisible" class="col-md-12 div-top-menu">
      <div class="">
        <!-- <div class="div-side-sub-header">
          TEMPLATES
        </div> -->
        <button [ngClass]="selectedTemplateFilter === 'all' ? 'btn-menu active': 'btn-menu'" type="button" (click)="filterTemplate('all')"><p class="br">All</p></button>
        <button *ngFor="let category of categoriesList; let i = index" [ngClass]="selectedTemplateFilter === category.tagName ? 'btn-menu active': 'btn-menu'" type="button" (click)="filterTemplate(category.tagName)"><p class="br">{{category.tagName}}</p></button>
        <!-- <button  [ngClass]="selectedFilter === 'Accounts Receivables' ? 'btn-filter active': 'btn-filter'" type="button" (click)="filterTemplate('Accounts Receivables')">Accounts Receivables</button> -->
      </div>
    </div>
    <div class="col-md-3 mt-4">
      <div class="input-group mb-3 div-search">
        <div class="input-group-prepend">
          <i class="far fa-search"></i>
        </div>
        <input type="text" class="form-control" id="globalSearchContext" placeholder="Search" aria-label="Search" (keyup)="typedSearch($event)">        
      </div>
    </div>
    <div class="col-md-9 text-right mt-4">
      <button type="button" class="btn btn-numero btn-add-workflow-plus" (click)="handleClickCreateFlow()" *ngIf="addNewWorkflow"><i class="far fa-plus"></i> Add New</button>
    </div>
    <div class="div-right-cards" [ngClass] = "(isTemplateVisible || isWorkflowVisible) ? 'col-md-12' : 'col-md-12'">
      <app-workflow-page
      *ngIf="!isExecutionDashboard"
          [workflows] = "workflowMap.get(selectedKey)"
          [workflowaddnew] ="addNewWorkflow"
          (workflowToggled)="handleToggle($event)"
          (loadworkflow)="loadWorkflows($event)"
          [workflowselectedKey] ="selectedKey"
          [activeworkflow] = "workflowMap.get('workflow#active')"
          [inactiveworkflow] = "workflowMap.get('workflow#inactive')"
          [isItWorkflow] = "isWorkflowVisible"
          [selectedWorkflowFilter] = "selectedWorkflowFilter"></app-workflow-page>
          <app-execution-history
            *ngIf="isExecutionDashboard"
            [token]="jwtToken"
          ></app-execution-history>
    </div>
  </div>
</div>
