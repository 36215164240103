import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { handleError } from '../core/handle-error';
import { CreateConnectorBody } from './connector';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConnectorService {

  constructor(private http: HttpClient) { }

  getConnectionConfiguration = () : Observable<any> => {
    return this.http
      .get(`${environment.server.baseURL}/connection-config`)
      .pipe(catchError(handleError));
  }

  createConnection = (body: CreateConnectorBody) : Observable<any> => {
    return this.http
      .post(`${environment.server.baseURL}/connections`, body)
      .pipe(catchError(handleError));
  }

  getConnectedOrgList = (code, location, entityKey: string, type) : Observable<any> => {
    return this.http
      .get(`${environment.server.baseURL}/connections/zb-list-org?code=${code}&location=${location}&entity_id=${entityKey}&type=${type}`)
      .pipe(catchError(handleError));
  }

  getConnectedSources = (entityKey: string) : Observable<any> => {
    return this.http
      .get(`${environment.server.baseURL}/connections?entity_key=${entityKey}`)
      .pipe(catchError(handleError));
  }

  deleteConnection = (connectionKey: string, entityKey: string, userEmail: string) : Observable<any> => {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .delete(`${environment.server.baseURL}/connections?connection_key=${connectionKey}&entity_key=${entityKey}&email=${userEmail}`, httpOptions)
  }

  fetchWebhook = (entityKey: string, source: string) : Observable<any> => {
    return this.http
      .get(`${environment.server.baseURL}/webhook?entity_key=${entityKey}&source=${source}`,{responseType: 'text'})
      .pipe(catchError(handleError));
  }
}
