import { Component, OnInit } from '@angular/core';
import { AuthServices } from '../../service/auth/auth.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';
import { FormService } from 'app/service/shared/form.service';
import { DashboardService } from 'app/service/dashboard/dashboard.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [ FormBuilder ]
})


export class LoginComponent implements OnInit {
  signupForm: FormGroup;
  signinForm: FormGroup;
  forgotPasswordForm: FormGroup;
  public isLogin = true;
  public isSignUp = false;
  public isForgotPass = false;
  public environment = environment;
  public _DBSTAT_;

  constructor(public authServices: AuthServices,
    private _dashboardService: DashboardService,
    private formBuilder: FormBuilder, private router: Router, private toaster: ToastrService) {
  }
  ngOnInit(): void {
    this._DBSTAT_= this._dashboardService.getDashboardStatus();
    const jwt = this.authServices.getJWTFromCookie();
    if(jwt) {
      if(this._DBSTAT_.ap_enabled) {
      this.router.navigate(['workbench/ap/automation/dashboard']);
      } else {
      this.router.navigate(['workbench/ap/report/dashboard']);
      }
      return;
    }
    this.signinForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.signupForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: [
        null,
        Validators.compose([
          Validators.required,
          FormService.patternValidator(/\d/, {
            hasNumber: true
          }),
          FormService.patternValidator(/[A-Z]/, {
            hasCapitalCase: true
          }),
          FormService.patternValidator(/[a-z]/, {
            hasSmallCase: true
          }),
          FormService.patternValidator(
            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            {
              hasSpecialCharacters: true
            }
          ),
          Validators.minLength(8)
        ])
      ],
    },
    );

    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', Validators.required]
    });

  }

  signIn(): void {
    let formData = this.signinForm.value;
    if (!this.signinForm.valid) {
      this.toaster.error(this.validateForm(formData), 'ERROR', {
        timeOut: 3000,
      });
      return;
    }
    this.authServices.signInClicked(formData.email, formData.password);
  }

  signUp(): void {
    let formData = this.signupForm.value;
    if (!this.signupForm.valid) {
      this.toaster.error(this.validateForm(formData), 'ERROR', {
        timeOut: 3000,
      });
      return;
    }
    this.authServices.signUpClicked(formData.email, formData.password);
  }

  validateForm(formData): string {
    let errorMsg = 'Bad credentials';
      if (!formData.email || formData.email === '') {
        errorMsg = 'Email id cannot be empty';
      } else if (!formData.password || formData.password === '') {
        errorMsg = 'Password cannot be empty';
      }
      return errorMsg;
  }


  showSignIn(): void {
    this.isLogin = true;
    this.isSignUp = false;
    this.isForgotPass = false;
  }

  showSignUp(): void {
    this.isLogin = false;
    this.isSignUp = true;
    this.isForgotPass = false;
  }

  showForgotPass(): void {
    this.isLogin = false;
    this.isSignUp = false;
    this.isForgotPass = true;
  }

  googleSignIn(): void {
    this.authServices.googleSignInClicked();
  }

  forgotPassword(): void {
    if (!this.forgotPasswordForm.valid) {
      this.toaster.error('Cannot process your request.', 'ERROR', {
        timeOut: 3000,
      });
    } else {
      let response = this.authServices.resetPasswordClicked(this.forgotPasswordForm.value.email);
      console.log(response)
    }
  }

  get signin(): any {
    return this.signinForm.controls;
  }

  get signup(): any {
    return this.signupForm.controls;
  }

}

