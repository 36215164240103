<div class="div-numero-container div-main-content">
    <div class="div-welcome">
        <div class="row">
          <div class="col-md-10">
            <div class="div-wel-head">
              <img src="/assets/image/gift.svg" alt="image" /> Welcome {{jwtToken.userName}}!
            </div>
            <div class="div-sub-head">
                Here’s your Vendor portal at a glance.
            </div>
          </div>
            <div class="col-md-2 div-btn-request">
                <button type="button" class="btn-request"><i class="fal fa-plus"></i> Request Payment</button>
            </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-4">
          <div class="div-out-card">
            <div class="row">
              <div class="col-md-12 div-o-head">
                Outstanding
              </div>
              <div class="col-md-12 div-o-sub-head d-flex">
                <div class="">
                  <img src="/assets/image/out.svg" alt="image" />
                </div>
                <div>
                  <p>$428,281</p>
                  <span>18 Invoice</span>
                </div>
              </div>
            </div>
           </div>
        </div>
        <div class="col-md-4">
         <div class="div-out-card">
           <div class="row">
             <div class="col-md-12 div-o-head">
               Overdue
             </div>
             <div class="col-md-12 div-o-sub-head d-flex div-overdue">
               <div class="">
                 <img src="/assets/image/temp/time.svg" alt="image" />
               </div>
               <div>
                 <p>$78,392</p>
                 <span>12 Invoice</span>
               </div>
             </div>
           </div>
          </div>
       </div>
       <div class="col-md-4">
         <div class="div-out-card">
           <div class="row">
             <div class="col-md-12 div-o-head">
               Due this Week
             </div>
             <div class="col-md-12 div-o-sub-head d-flex div-due">
               <div class="">
                 <img src="/assets/image/temp/job.svg" alt="image" />
               </div>
               <div>
                 <p>$78,392</p>
                 <span>19 Invoice</span>
               </div>
             </div>
           </div>
          </div>
       </div>       
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="div-mat-card" *ngIf="!noBills">
                <div class="row">
                  <div class="col-md-3 d-flex">
                    <div class="input-group mb-3 div-search-mat">
                      <div class="input-group-prepend">
                        <i class="far fa-search"></i>
                      </div>
                      <mat-form-field>
                        <mat-label>Search</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="" #input />
                      </mat-form-field>
                    </div>
                    <button class="btn-ru" (click)="refreshpage()" placement="top" ngbTooltip="Refresh">
                      <i id="refresh-icon" class="fak fa-numero-refresh"></i>
                    </button>
                  </div>
                  <div class="col-md-9 div-export-sec mb-3">
                    <!-- <select class="form-select sel-cust" [(ngModel)]="selectedFilterType">
                      <option *ngFor="let obj of filterType" [ngValue]="obj.value">{{obj.name}}</option>
                    </select> -->
                    <div class="div-for-sel" *ngIf="filterType?.length > 1">
                      <app-numero-dropdown [dropdownlist]="filterType" [selAcc]="selectedFilterType" [type]="'image'" [setWidth]="'none'" (dropdownSelValue)="dropdownChangedEvent($event)"></app-numero-dropdown>
                    </div>
                     <!-- [ngClass]="isButtonEnable ? 'enabled': 'disabled'" -->
                    <div class="div-export-pdf-excel" ngbDropdown container="body" routerLinkActive="isActive" display="dynamic" placement="bottom-end">
                      <button class="div-export-btn" ngbDropdownToggle [disabled]="isButtonEnable">
                        <i class="fak fa-numero-download"></i>
                      </button>
                      <div class="div-export-dropdown" ngbDropdownMenu>
                        <div><button title="Export Excel" (click)="excelDownload()"><img src="./assets/image/excel.svg"> Excel</button></div>
                        <div class="mtop_06"><button title="Export PDF" (click)="pdfDownload()"><img src="./assets/image/pdf.svg"> PDF</button></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="mat-elevation-z8">
                      <mat-table [dataSource]="dataSourceEx" class="workbench-ap-open" id="ExampleTable">
                        <ng-container matColumnDef="select" sticky>
                          <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox
                              (change)="$event ? toggleAllRows() : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()"
                              [aria-label]="checkboxLabel()"
                            >
                            </mat-checkbox>
                          </th>
                          <td mat-cell *matCellDef="let row">
                            <mat-checkbox
                              (click)="$event.stopPropagation()"
                              (change)="$event ? selection.toggle(row) : null"
                              [checked]="selection.isSelected(row)"
                              [aria-label]="checkboxLabel(row)"
                            >
                            </mat-checkbox>
                          </td>
                        </ng-container>
                        <ng-container  *ngFor="let item of dsHeaderEx">
                          <ng-container *ngIf="item.fieldName === 'bills'" matColumnDef="{{ item.fieldName }}" sticky>
                            <th mat-header-cell *matHeaderCellDef class="table-column-{{ item.width }} text-{{ item.textAlign }}"><p>{{ item.displayName }}</p></th>
                            <td mat-cell *matCellDef="let element" (click)="showDrilldown(element)" class="table-column-{{ item.width }} td-bills text-{{ item.textAlign }} cursor-pointer">{{ element[item.fieldName] }}</td>
                          </ng-container>
                          <ng-container *ngIf="item.fieldName === 'status'" matColumnDef="{{ item.fieldName }}">
                            <th mat-header-cell *matHeaderCellDef class="table-column-{{ item.width }} text-{{ item.textAlign }}"><p>{{ item.displayName }}</p></th>
                            <td mat-cell *matCellDef="let element" class="table-column-{{ item.width }} {{ element[item.fieldName] }} text-{{ item.textAlign }}">
                              <span *ngIf="(element[item.fieldName] === 'payment_due' || element[item.fieldName] === 'due')">Due</span>
                              <span *ngIf="element[item.fieldName] === 'overdue'">Overdue</span>
                              <span *ngIf="element[item.fieldName] === 'paid'">Paid</span>
                              <span *ngIf="element[item.fieldName] === '-'">-</span>
                              <span *ngIf="element[item.fieldName] === 'due-partiallypaid'">Due - Partially Paid</span>
                              <span *ngIf="element[item.fieldName] === 'overdue-partiallypaid'">Overdue - Partially Paid</span>
                            </td>
                        </ng-container>
                          <ng-container *ngIf="item.fieldName !== 'select'  && item.fieldName !== 'bills' && item.fieldName !=='status' && item.fieldName !== 'billAmount'"
                                  matColumnDef="{{ item.fieldName }}">
                              <th mat-header-cell *matHeaderCellDef class="table-column-{{ item.width }} text-{{ item.textAlign }}"><p>{{ item.displayName }}</p></th>
                              <td mat-cell *matCellDef="let element" class="table-column-{{ item.width }} text-{{ item.textAlign }}">{{ element[item.fieldName] }}</td>
                          </ng-container>
                          <ng-container *ngIf="item.fieldName === 'billAmount'"
                                  matColumnDef="{{ item.fieldName }}">
                              <th mat-header-cell *matHeaderCellDef class="table-column-{{ item.width }} text-{{ item.textAlign }}"><p>{{ item.displayName }}</p></th>
                              <td mat-cell *matCellDef="let element" class="table-column-{{ item.width }} text-{{ item.textAlign }} bl-clr">{{ element[item.fieldName] | currencypipe: element.currencyId }}</td>
                          </ng-container>
                      </ng-container>
            
                        <tr mat-header-row *matHeaderRowDef="displayedColumnsEx"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsEx"></tr>
                      </mat-table>
                      <div [hidden]="vendorData?.data.length < 9">
                      <mat-paginator
                        class="workbench-ap-open-page"
                        [pageSize]="15"
                        [pageSizeOptions]="[5, 10, 15, 20]"
                        aria-label="Select page"
                      >
                      </mat-paginator>
                    </div>
                    </div>
                  </div>
                </div>
            </div>
            
            <div class="div-mat-card" *ngIf="noBills">
                <div class="div-no-data">
                  <img src="{{environment.imagePrefixURI}}/nobills.svg" alt="image" />
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Vendor Right Side Box - Starts -->

<div class="div-right-side" [ngClass]="{'slide-left': showinvoice}">
  <div class="row">
    <div class="col-md-12">
      <div class="div-vendor-head">
        Invoice 20230706-004 <span>Paid</span>
      </div>
      <div class="div-close" (click)="closeDrilldown()">
        <i class="fal fa-times"></i>
      </div>
    </div>    
  </div>  
  <div class="row">
    <div class="col-md-6">
      <div class="div-in-head">
        Invoice Details
      </div>
      <div class="div-in-con-head">
        Invoice No
      </div>
      <div class="div-in-con">
        20230706-004
      </div>
      <div class="div-in-con-head">
        Invoice Date
      </div>
      <div class="div-in-con">
        Jan 19,2023
      </div>
      <div class="div-in-con-head">
        Due Date
      </div>
      <div class="div-in-con">
        Apr 23, 2023
      </div>
    </div>
    <div class="col-md-6">
      <div class="div-in-banner paid">
        <i class="fak fa-numero-paid"></i>
        <div class="div-banner-amt">
          95,399
        </div>
        <div class="div-banner-date">
          Apr 23, 2023
        </div>
        <div class="div-banner-head">
          UTR
        </div>
        <div class="div-banner-copy">
          376136263112 <i class="fal fa-clone"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <hr>
    </div>
  </div>
  <div class="row div-in-tbl">
    <div class="col-md-12">
      <div class="div-in-head">
        Amount Details
      </div>
    </div>
    <div class="col-md-6 div-in-tbl-head">
        Subtotal
    </div>
    <div class="col-md-6 text-right">
        88,333.00
    </div>
    <div class="col-md-6 div-in-tbl-head">
        GST
    </div>
    <div class="col-md-6 text-right">
        15,899.94
    </div>
    <div class="col-md-6 div-in-tbl-head">
        Total
    </div>
    <div class="col-md-6 text-right">
        104,232.00
    </div>
    <div class="col-md-6 div-in-tbl-head">
        TDS Amount
    </div>
    <div class="col-md-6 text-right">
        8,833.00
    </div>
    <div class="col-md-6 div-in-tbl-head">
        Amount to be Received
    </div>
    <div class="col-md-6 text-right">
        95,399.00
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <hr>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-center">
      <div class="div-up">
        <button type="button" class="btn-down-upload">
          <i class="fak fa-numero-download"></i> Download Invoice
      </button>
    </div>
    </div>
  </div>
</div>

<!-- Vendor Right Side Box - Ends -->