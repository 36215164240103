import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { JWTToken } from 'app/core/service/auth/auth';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { WorkflowService } from 'app/service/workflow/workflow.service';
import csvDownload from 'json-to-csv-export'
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-workflow-page',
  templateUrl: './workflow-page.component.html',
  styleUrls: ['./workflow-page.component.scss']
})
export class WorkflowPageComponent {

  constructor(private router: Router, private workflowService: WorkflowService, public authServices: AuthServices, public loader: LoaderService, private toastr: ToastrService,) { }
  @Input() workflows;
  @Input() activeworkflow;
  @Input() inactiveworkflow;
  @Input() workflowaddnew;
  @Input() workflowselectedKey;
  @Input() selectedWorkflowFilter;
  @Input() isItWorkflow;
  @Output() workflowToggled = new EventEmitter<string>();
  @Output() loadworkflow = new EventEmitter<string>();
  public jwtToken : JWTToken = undefined;
  public deleteConfText: string;
  public deleteworkflowtext = "Delete"
  public showAlert: boolean = true;
  public deleteworkflowId: string;
  public environment = environment;

  ngOnInit() {
    this.basicConfig();
  }

  async basicConfig() {
    if (this.jwtToken === undefined) {
      this.jwtToken = await this.authServices.getJWTDetail();
    }
  }


  async navigate(id: string, configKey: string, isTemplate: boolean) {
    this.loader.show();
    if(isTemplate) {
      if (this.jwtToken === undefined) {
        this.jwtToken = await this.authServices.getJWTDetail();
      }
      this.workflowService.createWorkflowInN8N(configKey, this.jwtToken.org.id, this.jwtToken.userEmail).subscribe((data) => {
        console.log(data);
        this.workflows.map((workflow) => {
          if (workflow.workflowConfigKey === configKey) {
            workflow.workflowEngineId = data.workflowId;
          }
          return workflow;
        });
        setTimeout(() => {
          this.loader.hide();
        }, 2000);
        this.router.navigate(['workflow', data.workflowId])
      }, err=>{
        console.log('Error')
        setTimeout(() => {
          this.loader.hide();
        }, 2000);
      });
    } else {
      this.router.navigate(['workflow',id])
      setTimeout(() => {
        this.loader.hide();
      }, 2000);
    }

  }

  async handleToggleWorkflow(event) {
    this.workflowService.toggleWorkflow(event.target.id, this.jwtToken.org.id).subscribe((data) => {
        let obj = {
          toggledWorkflowId: event.target.id,
          isToggledOn: event.target.checked
        }
        this.workflowToggled.emit(JSON.stringify(obj));
    });
  }

  handleClickCreateFlow = () => {
    this.router.navigate(['workflow', 'new']);
  }

  downloadSampleData = (workflow) =>  {
    let headerData : string[] = workflow.csvHeaders.split(",");
    csvDownload({data: [],filename: workflow.name + '.csv',delimiter: ',',headers:headerData})
  }

  confirmDeleteFlow(workflowId) {
    this.showAlert = true
    this.deleteworkflowId = workflowId;
    this.deleteConfText = '';
  }

  validateConfirmationMessage = () => {
    if(this.deleteConfText && this.deleteConfText === this.deleteworkflowtext) {
      this.showAlert = false;
    } else {
      this.showAlert = true;
    }
  }

  removeWorkflow() {
    if (this.deleteConfText === this.deleteworkflowtext) {
      this.loader.show();
      this.workflowService.deleteWorkflow(this.jwtToken.org.id , this.deleteworkflowId).subscribe(data=>{
        this.loadworkflow.emit(this.selectedWorkflowFilter);
        this.toastr.success('Deleted Successfully', 'Success', {
          timeOut: 2000,
        });
        this.loader.hide();
      }, err=>{
        this.loader.hide();
      })
    } else {
      this.toastr.error('Cannot Delete. Try Again', 'Error', {
        timeOut: 2000,
      });
    }
  }

}
