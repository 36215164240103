<button id="btn-set-pop-close" class="d-none" type="button">Close</button>
<div id="content-wrapper" class="page-content-wrapper">
  <div class="row left-side-dashboard-icons">
    <div
      class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 d-lg-block d-md-block d-none"
    >
      <div
        class="left-side-nav d-flex flex-column justify-content-between h-100"
      >
        <div class="left-side-start-icons">
          <div class="div-icon-left-logo logo-div" routerLink="/redirect">
            <img
              class="regular"
              class="tab-icon l-img"
              alt=""
              src="{{ environment.imagePrefixURI }}/utill/GoConstant.svg"
            />
          </div>
          <div class="div-menu-divider"></div>
          <!-- <div class="left-icon-link" *ngIf="gbDashboard"  ngbDropdown container="body" routerLinkActive="isActive" #iDashboard="ngbDropdown" display="dynamic" placement="bottom-end" (mouseover)='over(iDashboard)' (mouseout)='out(iDashboard)'>
            <button type="button" class="btn" ngbDropdownToggle><i class="fak fa-dataflo-dashboard"></i></button>
            <div class="div-side-bar-sub-menu" ngbDropdownMenu (mouseout)='out(iDashboard)'>
              <div class="div-side-bar-sub-menu-child">
                <button ngbDropdownItem [routerLink]="['dashboard','ap']" routerLinkActive="isActive" *ngIf="gbDashboard" #rla="routerLinkActive">Account Payable</button>
                <button ngbDropdownItem routerLink="dashboard/ar" routerLinkActive="isActive" *ngIf="gbReceivable" #rla="routerLinkActive">Account Receivable</button>
                <button ngbDropdownItem routerLink="/subscription/subscription" routerLinkActive="isActive" *ngIf="gbReceivable" #rla="routerLinkActive">Subscription</button>
              </div>
            </div>
          </div> -->

          <!-- <div class="left-icon-link cust-tooltip" *ngIf="gbDashboard" placement="end" ngbTooltip="Dashboard">
          <span class="tooltiptext">Account Payable</span>
            <i class="fak fa-dataflo-dashboard"></i>
          </div> -->
          <!-- <div class="left-icon-link cust-tooltip" *ngIf="gbWorkbench && _AP_"
          [routerLink]="['/workbench/ap']" routerLinkActive="isActive" #rla="routerLinkActive" placement="end" ngbTooltip="Accounts Payable">
          <!- <span class="tooltiptext">Account Payable</span> ->
          <i class="fak fa-ap-numero"></i>
          </div> -->
          <!-- <div
            class="left-icon-link cust-tooltip"
            routerLink="/document-dashboard"
            routerLinkActive="isActive"
            #rla="routerLinkActive"
            *ngIf="gbGlobalSearchDashboard"
            placement="end"
            ngbTooltip="Dashboard"
          >
            <i class="fa-kit fa-dataflo-dashboard"></i>
          </div> -->
          <!-- <div
            *ngIf="gbGlobalSearch"
            class="left-icon-link cust-tooltip"
            routerLink="/doc-copilot"
            routerLinkActive="isActive"
            #rla="routerLinkActive"
            placement="end"
            ngbTooltip="Global Search"
          >
            <!-- <i class="fa-kit fa-global-search"></i> ->
            <img
              class="glo-search-img"
              src="{{
                environment.imagePrefixURI
              }}/utill/left-menu-global-search.svg"
            />
          </div> -->
          <!-- *ngIf="gbContract && _CONTRACT_" -->

          <!-- <div
            class="left-icon-link cust-tooltip"
            *ngIf="gbContract"
            routerLink="/doc-repo"
            routerLinkActive="isActive"
            #rla="routerLinkActive"
            placement="end"
            ngbTooltip="Doc Repo"
          >
            <i class="fal fa-file-alt"></i>
          </div> -->

          <!-- <div
            class="left-icon-link cust-tooltip"
            *ngIf="gbAi"
            routerLink="/ai"
            routerLinkActive="isActive"
            #rla="routerLinkActive"
            placement="end"
            ngbTooltip="Copilot"
          >
            <i class="fa-kit fa-ai-menu ai-icon-clr"></i>
          </div> -->

          <div
            class="left-icon-link cust-tooltip"
            *ngIf="gbWorkbenchAR"
            (click)="navigateRoute('/workbench/ar')"
            [ngClass]="{
              isActive: _commonService.currentRoute.includes('/workbench/ar')
            }"
            placement="end"
            ngbTooltip="Accounts Receivable"
          >
            <!-- <span class="tooltiptext">Account Receivable</span> -->
            <i class="fak fa-ar-numero"></i>
          </div>
          <div
            class="left-icon-link cust-tooltip"
            *ngIf="gbWorkbenchAP && _AP_"
            (click)="navigateRoute('/workbench/ap')"
            [ngClass]="{
              isActive: _commonService.currentRoute.includes('/workbench/ap')
            }"
            placement="end"
            ngbTooltip="Accounts Payable"
          >
            <!-- <span class="tooltiptext">Account Payable</span> -->
            <i class="fak fa-ap-numero"></i>
          </div>
          <!-- <div class="left-icon-link cust-tooltip" *ngIf="gbWorkbench"
          routerLink="/workbench" routerLinkActive="isActive" #rla="routerLinkActive"><span class="tooltiptext">Workbench</span>
            <i class="fal fa-receipt"></i>
          </div> -->

          <!-- <div class="left-icon-link" *ngIf="gbWorkbench" ngbDropdown container="body" routerLinkActive="isActive" #iWorkbench="ngbDropdown" display="dynamic"
          placement="bottom-end" (mouseover)='over(iWorkbench)' (mouseout)='out(iWorkbench)'>
            <button type="button" class="btn" ngbDropdownToggle><i class="fal fa-receipt"></i></button>
            <div class="div-side-bar-sub-menu" ngbDropdownMenu (mouseout)='out(iWorkbench)'>
              <div class="div-side-bar-sub-menu-child">
                <button ngbDropdownItem [routerLink]="['/workbench/ap/open']" routerLinkActive="isActive" *ngIf="gbWorkbench" #rla="routerLinkActive">Account Payable</button>
                <button ngbDropdownItem [routerLink]="['/workbench/ar/report/dashboard']" routerLinkActive="isActive" *ngIf="gbWorkbench" #rla="routerLinkActive">Account Receivable</button>
              </div>
            </div>
          </div> -->

          <div
            class="left-icon-link"
            *ngIf="gbWorkFlow && _WORKFLOW_"
            ngbDropdown
            container="body"
            routerLinkActive="isActive"
            #iWorkflow="ngbDropdown"
            display="dynamic"
            placement="bottom-end"
            (mouseover)="over(iWorkflow)"
            (mouseout)="out(iWorkflow)"
          >
            <button type="button" class="btn" ngbDropdownToggle>
              <i class="fal fa-network-wired"></i>
            </button>
            <div
              class="div-side-bar-sub-menu"
              ngbDropdownMenu
              (mouseout)="out(iWorkflow)"
            >
              <div class="div-side-bar-sub-menu-child">
                <button
                  ngbDropdownItem
                  [routerLink]="['/workflow']"
                  routerLinkActive="isActive"
                  *ngIf="gbWorkFlow"
                  #rla="routerLinkActive"
                >
                  Automation
                </button>
                <button
                  ngbDropdownItem
                  routerLink="/import"
                  routerLinkActive="isActive"
                  *ngIf="gbWorkFlowImport"
                  #rla="routerLinkActive"
                >
                  Import
                </button>
              </div>
            </div>
          </div>

          <div
            class="left-icon-link cust-tooltip"
            *ngIf="gbReconciliation"
            [routerLink]="['/reconciliation']"
            routerLinkActive="isActive"
            #rla="routerLinkActive"
            placement="end"
            ngbTooltip="Reconciliation"
          >
            <!-- <span class="tooltiptext">Reconciliation</span> -->
            <i class="fak fa-numero-recon"></i>
          </div>

          <div
            class="left-icon-link cust-tooltip"
            *ngIf="gbProcessFlow && _PROCESSFLOW_"
            routerLink="/process-model"
            routerLinkActive="isActive"
            #rla="routerLinkActive"
            placement="end"
            ngbTooltip="Process"
          >
            <!-- <span class="tooltiptext"
          >Process</span> -->
            <i class="fak fa-numero-process"></i>
          </div>

          <div
            class="left-icon-link"
            *ngIf="gbDashboard && _REPORT_"
            ngbDropdown
            container="body"
            routerLinkActive="isActive"
            #iDashboard="ngbDropdown"
            display="dynamic"
            placement="bottom-end"
            (mouseover)="over(iDashboard)"
            (mouseout)="out(iDashboard)"
          >
            <button type="button" class="btn" ngbDropdownToggle>
              <i class="fak fa-numero-report"></i>
            </button>
            <div
              class="div-side-bar-sub-menu"
              ngbDropdownMenu
              (mouseout)="out(iDashboard)"
            >
              <div class="div-side-bar-sub-menu-child">
                <!-- <button ngbDropdownItem [routerLink]="['dashboard','ap']" routerLinkActive="isActive" *ngIf="gbDashboard" #rla="routerLinkActive">Account Payable</button> -->
                <button
                  ngbDropdownItem
                  routerLink="/report"
                  routerLinkActive="isActive"
                  *ngIf="gbReceivable"
                  #rla="routerLinkActive"
                >
                  Reports
                </button>
                <button
                  ngbDropdownItem
                  routerLink="/subscription/subscription"
                  routerLinkActive="isActive"
                  *ngIf="gbReceivable"
                  #rla="routerLinkActive"
                >
                  Subscription
                </button>
              </div>
            </div>
          </div>

          <!-- <div class="left-icon-link cust-tooltip" *ngIf="gbConnectors" routerLink="/report" routerLinkActive="isActive" #rla="routerLinkActive"><span class="tooltiptext">Report</span>
            <i class="fak fa-numero-report"></i>
          </div> -->
            <!-- *ngIf="gbConnectors && _CONNECTOR_" -->

          <div
            class="left-icon-link cust-tooltip"
            *ngIf="gbConnectors"
            routerLink="/connectors"
            routerLinkActive="isActive"
            #rla="routerLinkActive"
            placement="end"
            ngbTooltip="Connectors"
          >
            <!-- <span class="tooltiptext">Connectors</span> -->
            <i class="fak fa-dataflo-datasource"></i>
          </div>

          <!-- <div class="left-icon-link cust-tooltip"
          routerLink="/vendor" routerLinkActive="isActive" #rla="routerLinkActive" placement="end" ngbTooltip="Vendor Management">
            <i class="fal fa-file-alt"></i>
          </div> -->
          <div
            class="left-icon-link cust-tooltip"
            *ngIf="gbAPv3 && _AP_"
            (click)="navigateRoute('/workbench/new-ap')"
            [ngClass]="{
              isActive:
                _commonService.currentRoute.includes('/workbench/new-ap')
            }"
            placement="end"
            ngbTooltip="Accounts Payable"
          >
            <i class="fak fa-ap-numero"></i>
          </div>

          <!-- <div class="left-icon-link cust-tooltip" *ngIf="gbARv3 && _AR_"
          (click)="navigateRoute('/workbench/ar')"
          [ngClass]="{'isActive': _commonService.currentRoute.includes('/workbench/ar')}"
          placement="end" ngbTooltip="Accounts Receivable">
          <i class="fak fa-ar-numero"></i>
          </div> -->
          <!--
          <div class="left-icon-link cust-tooltip" *ngIf="gbWorkbenchV2 && _AP_"
          [routerLink]="['/workbench-v2/ap']" routerLinkActive="isActive" #rla="routerLinkActive"  placement="end" ngbTooltip="Account Payable V2">
          <span class="tooltiptext">Accounts Payable V2</span>
            <i class="fal fa-receipt"></i>
          </div> -->
          <!-- <div
            class="left-icon-link cust-tooltip"
            *ngIf="gbFeedback"
            routerLink="/feedback"
            routerLinkActive="isActive"
            #rla="routerLinkActive"
            container="body"
            ngbTooltip="Feedback"
            placement="end"
          >
            <i class="fa-kit fa-nu-feedback"></i>
          </div> -->
        </div>
        <div class="left-side-end-icons middle-last">
          <div
            class="left-icon-link cust-tooltip"
            routerLink="/settings/users"
            routerLinkActive="isActive"
            #rla="routerLinkActive"
            *ngIf="gbSettings"
            placement="end"
            ngbTooltip="Settings"
          >
            <!-- <span class="tooltiptext">Settings</span> -->
            <i class="fak fa-dataflo-settings"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8">
      <div
        class="div-top-bar-heading"
        [ngClass]="{ pad172: _commonService.pageHeader === 'Workflows' }"
      >
        <div>
          <span
            class="word-wrap"
            placement="bottom"
            ngbTooltip="{{ _commonService.pageHeader }}"
            >{{ _commonService.pageHeader }}</span
          >
          <!-- SUB{{_commonService.subPageHeader}} -->
          <ng-container>
            <span
              class="span-overdue"
              *ngIf="_commonService.subPageHeader === 'overdue'"
              >Overdue</span
            >
            <span
              class="span-due"
              *ngIf="_commonService.subPageHeader === 'due'"
              >Due</span
            >
            <span
              class="span-paid"
              *ngIf="_commonService.subPageHeader === 'paid'"
              >Paid</span
            >
            <span
              class="span-approved"
              *ngIf="_commonService.subPageHeader === 'approved'"
              >Approved</span
            >
            <span
              class="span-rejected"
              *ngIf="_commonService.subPageHeader === 'rejected'"
              >Rejected</span
            >
            <span
              class="span-anomaly"
              *ngIf="_commonService.subPageHeader === 'anomaly'"
              >Anomaly</span
            >
            <span
              class="span-overdue-partiallypaid"
              *ngIf="_commonService.subPageHeader === 'overdue-partiallypaid'"
              >Overdue Partially Paid</span
            >
          </ng-container>
          <ng-container
            *ngIf="
              _commonService.currentRoute === '/doc-copilot/preview' &&
              _commonService.subPageHeader
            "
          >
            <span
              class="span-docai"
              [ngClass]="{
                inactive: _commonService.subPageHeader === 'INACTIVE',
                active: _commonService.subPageHeader === 'ACTIVE'
              }"
              >{{ _commonService.subPageHeader | titlecase }}</span
            >
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="div-top-bar">
        <div class="div-top-bar-content">
          <!--<div class="div-top-ai" *ngIf="gbAi">
            <button
              class="btn-ai"
              [routerLink]="['/ai']"
              routerLinkActive="isActive"
              *ngIf="_commonService.hideAiIcon"
            >
              <img src="/assets/image/temp/ai.svg" alt="image" />
              <div></div>
            </button>
          </div>-->
          <div
            class="div-top-header"
            *ngIf="gbClientlogin && switchWorkspaceEnabled"
            ngbDropdown
            container="body"
            routerLinkActive="isActive"
            display="dynamic"
            placement="bottom-end"
          >
            <div
              class="d-flex"
              ngbDropdownToggle
              [disabled]="!switchWorkspaceEnabled"
            >
              <!-- <img *ngIf="imageAvailableLogo" src="{{environment.imagePrefixURI}}/avatar/{{tokenDetail.org.domainName}}.svg" (error)="imageErrorHandlerLogo()">
              <ngx-avatars *ngIf="!imageAvailableLogo" [name]="tokenDetail.org.domainName" size="24"></ngx-avatars> -->
              <div class="div-span">
                {{ tokenDetail.org.domainName }}
                <i class="fas fa-chevron-down"></i>
              </div>
              <ngx-avatars
                [name]="tokenDetail?.userName"
                initialsSize="2"
                size="40"
              ></ngx-avatars>
            </div>
            <div
              class="div-top-bar-sub-menu"
              ngbDropdownMenu
              *ngIf="switchWorkspaceEnabled"
            >
              <div class="drop-head">Organization</div>
              <ng-container *ngFor="let org of orgList">
                <button
                  class="btn-head-org"
                  ngbDropdownItem
                  *ngIf="org.isAgency"
                  (click)="switchWorkspace(org)"
                >
                  <i class="fal fa-home-alt"></i>{{ org.organisationName }}
                  <i
                    class="fas fa-badge-check fa-xs selected-badge-right"
                    *ngIf="org.entityId === tokenDetail.org.id"
                  ></i>
                </button>
              </ng-container>
              <div class="drop-head">Clients</div>
              <ng-container *ngFor="let org of orgList">
                <button
                  ngbDropdownItem
                  *ngIf="!org.isAgency"
                  (click)="switchWorkspace(org)"
                >
                  <!-- <img *ngIf="imageAvailable" src="{{environment.imagePrefixURI}}/avatar/{{org.organisationName}}.svg" (error)="imageErrorHandler()">
                  <ngx-avatars *ngIf="!imageAvailable" [name]="org.organisationName" size="24"></ngx-avatars>
                  <span>{{org.organisationName}}</span> -->
                  <i class="fal fa-building"></i>{{ org.organisationName }}
                  <i
                    class="fas fa-badge-check fa-xs selected-badge-right"
                    *ngIf="org.entityId === tokenDetail.org.id"
                  ></i>
                </button>
              </ng-container>
            </div>
          </div>

          <div
            class="left-icon-link left-icon-logout"
            ngbDropdown
            container="body"
            routerLinkActive="isActive"
            #iuserLogout="ngbDropdown"
            display="dynamic"
            placement="bottom-end"
            autoClose="true"
          >
            <button type="button" class="btn btn-menu-avatar" ngbDropdownToggle>
              <span *ngIf="tokenDetail?.userEmail === 'karthik@trynumero.com'">
                <img class="img-hei" src="{{environment.imagePrefixURI}}/avatar/karthik.jpeg"/>
              </span>
              <span *ngIf="tokenDetail?.userEmail === 'jason.pikoos@trynumero.com'">
                <img class="img-hei" src="{{environment.imagePrefixURI}}/avatar/jason.png"/>
              </span>
              <span *ngIf="(tokenDetail?.userEmail !== 'karthik@trynumero.com' && tokenDetail?.userEmail !== 'jason.pikoos@trynumero.com')">
                <ngx-avatars
                  [name]="tokenDetail?.userName"
                  size="32"
                  initialsSize="2"
                ></ngx-avatars>
              </span>
            </button>
            <div
              class="div-side-bar-bot-menu"
              ngbDropdownMenu
            >
              <div class="d-flex" ngbDropdownItem>
                <div class="me-2">
                  <span *ngIf="tokenDetail?.userEmail === 'karthik@trynumero.com'">
                    <img class="img-hei" src="{{environment.imagePrefixURI}}/avatar/karthik.jpeg"/>
                  </span>
                  <span *ngIf="tokenDetail?.userEmail === 'jason.pikoos@trynumero.com'">
                    <img class="img-hei" src="{{environment.imagePrefixURI}}/avatar/jason.png"/>
                  </span>
                  <span  *ngIf="(tokenDetail?.userEmail !== 'karthik@trynumero.com' && tokenDetail?.userEmail !== 'jason.pikoos@trynumero.com')">
                    <ngx-avatars
                      [name]="tokenDetail?.userName"
                      initialsSize="2"
                      size="40"
                    ></ngx-avatars>
                  </span>
                </div>
                <div>
                  <p>{{ tokenDetail.userName }}</p>
                  <span>{{ tokenDetail.userEmail }}</span>
                </div>
              </div>
              <!-- <div ngbDropdownItem>
      <button><i class="fal fa-user"></i> Profile</button>
    </div> -->
              <div ngbDropdownItem>
                <button routerLink="/settings/users">
                  <i class="fak fa-dataflo-settings"></i> Settings
                </button>
              </div>
              <hr />
              <div ngbDropdownItem>
                <button
                  routerLinkActive="isActive"
                  #rla="routerLinkActive"
                  (click)="authService.logout()"
                >
                  <i class="far fa-sign-out"></i> Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
