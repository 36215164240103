import { Component, OnInit } from '@angular/core';
import { JWTToken } from 'app/core/service/auth/auth';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { Router } from '@angular/router';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { WorkflowDetail } from 'app/service/workflow/workflow';
import { WorkflowService } from 'app/service/workflow/workflow.service';
import { forkJoin } from 'rxjs';
import { GrowthbookService } from 'app/core/service/growthBook/growthbook.service';
import { CommonService } from 'app/service/shared/common.service';


@Component({
  selector: 'app-workflow-home',
  templateUrl: './workflow-home.component.html',
  styleUrls: ['./workflow-home.component.scss']
})
export class WorkflowHomeComponent implements OnInit {

  constructor(
    public workflowService: WorkflowService,
    public loader: LoaderService,
    private authServices: AuthServices,
    private router: Router,
    public growthBookServ: GrowthbookService,
    public commonService: CommonService
    ) { }

  public gbExecutionDashboard: boolean = false;
  public isTemplateVisible: boolean = false;
  public isWorkflowVisible: boolean = true;
  public isCreatedByMe: boolean = false;
  public isCreatedByOthers = false;
  public isExecutionDashboard = false;
  public addNewWorkflow: boolean = false;

  typedSearchText ='all';
  public jwtToken: JWTToken = undefined;
  public categoriesList = [];
  public workflowMap = new Map<string, any[]>();
  public tabSelected = 'workflow';
  public selectedTemplateFilter: string = 'all';
  public selectedWorkflowFilter: string = 'active';
  public selectedKey = 'workflow#active';

  public selectedAccount = 'asondate';
  public currentDate = new Date();
  public accounts = [
    {name: "As on Date", value: 'asondate'}
  ];

  ngOnInit(): void {
    this.getGrowthBook()
    this.loadWorkflows();
    this.loadTemplates();
    this.commonService.setPageHeader("Workflows");
    this.commonService.setHideAiIcon(true);
  }

  async getGrowthBook(): Promise<any> {
    this.growthBookServ.getGrowthBook().subscribe((data) => {
         this.gbExecutionDashboard = this.growthBookServ.getGBookStatus('execution_dashboard');
    });
  }

  async loadWorkflows(selectedWorkflowFilter = 'active'): Promise<void> {
    this.loader.show()
    if (this.jwtToken === undefined) {
      this.jwtToken = await this.authServices.getJWTDetail();
    }
    this.workflowMap.clear();
    this.workflowService.getWorkflows(this.jwtToken.org.id).subscribe((workflows) => {
      this.workflowMap.set('workflow#all', workflows);
      workflows.map(workflow => {
        let statusWorkflow = [];
        if (this.workflowMap.has("workflow#" + workflow.connectionStatus)) {
          statusWorkflow = this.workflowMap.get("workflow#" + workflow.connectionStatus);
        } else {
          statusWorkflow = [];
        }
        statusWorkflow.push(workflow);
        this.workflowMap.set("workflow#" + workflow.connectionStatus, statusWorkflow);

        let isLoggedInUser = this.jwtToken.userEmail === workflow.createdBy;
        let createdWorkflows = [];
        let key = isLoggedInUser? 'byme' : 'byothers';
        if (this.workflowMap.has(key)) {
          createdWorkflows = this.workflowMap.get(key);
        } else {
          createdWorkflows = [];
        }
        createdWorkflows.push(workflow);
        this.workflowMap.set(key, createdWorkflows);
      });
      this.selectedWorkflowFilter = selectedWorkflowFilter;
      this.addNewWorkflow = true;
      this.loader.hide()
    } , err=>{
      this.loader.hide();
    });
  }

  filterWorkflows = async (workflowStatus: string) => {
    this.selectedWorkflowFilter = workflowStatus;
    if (this.selectedWorkflowFilter === 'inactive') {
      this.addNewWorkflow = false;
    } else {
      this.addNewWorkflow = true;
    }
    this.selectedKey = this.tabSelected + "#" + this.selectedWorkflowFilter;
  };

  async loadTemplates(): Promise<void> {
    this.loader.show()
    if (this.jwtToken === undefined) {
      this.jwtToken = await this.authServices.getJWTDetail();
    }
    forkJoin([this.workflowService.getFilterCategories(), this.workflowService.getConfigurations(this.jwtToken.org.id)]).subscribe(([categoryRes, templateRes]) => {
      this.workflowMap.set('template#all', templateRes.data);
      Object.keys(categoryRes).forEach((key) => {
        const obj = {
          tagId: categoryRes.key,
          tagName: key
        };

        let dataArr = templateRes.data.filter((obj) => {
          const tags = obj.tags;
          const tagsArr = tags.split(",");
          return tagsArr.includes(key.toLowerCase());
        });

        this.workflowMap.set("template#" + key, dataArr);
        this.categoriesList.push(obj);
      });
      this.loader.hide();
    },err=>{
      this.loader.hide();
    });
  }

  async filterTemplate(filterCategory: string): Promise<void> {
    this.selectedTemplateFilter = filterCategory;
    this.selectedKey = this.tabSelected + "#" + this.selectedTemplateFilter;
    this.addNewWorkflow = false;
  }

  typedSearch = async (event) => {
    if (this.jwtToken === undefined) {
      this.jwtToken = await this.authServices.getJWTDetail();
    }
    const val = event.target.value;
    if(event.keyCode === 13){
      this.loader.show();
        this.typedSearchText = val;
        this.workflowMap = new Map<string, any[]>;

        this.workflowService.searchFlows('workflow', val, this.jwtToken.org.id).subscribe((data) => {
          let workflows = data.data;
          this.workflowMap.set('workflow#all', workflows);
          workflows.map(workflow => {
            let workflows = [];
            if (this.workflowMap.has("workflow#" + workflow.connectionStatus)) {
              workflows = this.workflowMap.get("workflow#" + workflow.connectionStatus);
            } else {
              workflows = [];
            }
            workflows.push(workflow);
            this.workflowMap.set("workflow#" + workflow.connectionStatus, workflows);

            let isLoggedInUser = this.jwtToken.userEmail === workflow.createdBy;
            let createdWorkflows = [];
            let key = isLoggedInUser? 'byme' : 'byothers';
            if (this.workflowMap.has(key)) {
              createdWorkflows = this.workflowMap.get(key);
            } else {
              createdWorkflows = [];
            }
            createdWorkflows.push(workflow);
            this.workflowMap.set(key, createdWorkflows);
          });
        });

        this.workflowService.searchFlows('template', val, this.jwtToken.org.id).subscribe((data) => {
          let templates = data.data;
          this.workflowMap.set('template#all', templates);
          if (templates != null && templates.length > 0) {
            this.categoriesList.forEach(category => {
              let templateArr = templates.filter((obj) => {
                const tags = obj.tags;
                const tagsArr = tags.split(",");
                console.log('the tags arr is ', tagsArr);
                return tagsArr.includes(category.tagName.toLowerCase());
              });
              this.workflowMap.set('template#' + category.tagName, templateArr);
            });
          }
            // this.workflowMap.set("template#" + key, dataArr);
            this.loader.hide()
        }, err =>{
          this.loader.hide()
        });
        console.log('map ', this.workflowMap)
    }
  }

  handleClickCreateFlow = () => {
    this.loader.show();
    this.router.navigate(['workflow', 'new']);
    setTimeout(() => {
      this.loader.hide();
    }, 3000);
  }

  handleToggle = (toggleObject) => {
    let toggledObject = JSON.parse(toggleObject);
    this.workflowMap.get(this.selectedKey).forEach((entity) => {
      if(entity.workflowConfigKey === toggledObject.toggledWorkflowId) {
        if(toggledObject.isToggledOn) {
          entity.connectionStatus = 'active'
        } else {
          entity.connectionStatus = 'inactive'
        }
      }
    });
  }

  clickOnWorkflows = () => {
    this.addNewWorkflow = true;
    this.isCreatedByOthers = false;
    this.isExecutionDashboard = false;
    this.isCreatedByMe = false;
    this.isWorkflowVisible = true;
    this.isTemplateVisible = false;
    this.tabSelected = 'workflow';
    this.selectedWorkflowFilter = 'active';
    this.selectedKey = this.tabSelected + "#" + this.selectedWorkflowFilter;
  }

  clickOnTemplates = () => {
    this.addNewWorkflow = false;
    this.isTemplateVisible = true;
    this.isCreatedByMe = false;
    this.isCreatedByOthers = false;
    this.isExecutionDashboard = false;
    this.isWorkflowVisible = false;
    this.tabSelected = 'template';
    this.selectedTemplateFilter = 'all';
    this.selectedKey = this.tabSelected + "#" + this.selectedTemplateFilter;

  }

  clickOnCreatedByMe = () => {
    this.addNewWorkflow = true;
    this.isCreatedByOthers = false;
    this.isExecutionDashboard = false;
    this.isCreatedByMe = true;
    this.isWorkflowVisible = false;
    this.isTemplateVisible = false;
    this.tabSelected = 'byme'
    this.selectedKey = this.tabSelected;
  }

  clickOnCreatedByOthers = () => {
    this.addNewWorkflow = false;
    this.isCreatedByOthers = true;
    this.isExecutionDashboard = false;
    this.isCreatedByMe = false;
    this.isWorkflowVisible = false;
    this.isTemplateVisible = false;
    this.tabSelected = 'byothers';
    this.selectedKey = this.tabSelected;

  }

  clickOnExecutionHistory= () =>{
    this.addNewWorkflow = false;
    this.isCreatedByOthers = false;
    this.isExecutionDashboard = true;
    this.isCreatedByMe = false;
    this.isWorkflowVisible = false;
    this.isTemplateVisible = false;
    this.tabSelected = 'executionDashboard';
    this.selectedKey = this.tabSelected;
  }

  clearSearch = () => {
    const search = document.getElementById('globalSearchContext') as HTMLInputElement | null;
    search.value = '';
  }
}
