import {
  Component,
  ViewEncapsulation,
  ViewChild,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import {
  Diagram,
  UndoRedo,
  ConnectorModel,
  PointPortModel,
  Connector,
  FlowShapeModel,
  IDragEnterEventArgs,
  SnapSettingsModel,
  MarginModel,
  TextStyleModel,
  StrokeStyleModel,
  OrthogonalSegmentModel,
  Node,
  PaletteModel,
  BasicShapeModel,
  SymbolInfo,
  NodeModel,
  SnapConstraints,
  PortConstraints,
  PortVisibility,
  Segments,
  ZoomOptions,
  ICollectionChangeEventArgs,
  IDropEventArgs,
} from '@syncfusion/ej2-diagrams';
import { ExpandMode } from '@syncfusion/ej2-navigations';
import { paletteIconClick } from './diagram-common';
Diagram.Inject(UndoRedo);

import { registerLicense } from '@syncfusion/ej2-base';

import { ChangeEventArgs as DropDownChangeEventArgs } from '@syncfusion/ej2-dropdowns';
import { ChangeEventArgs, ColorPickerEventArgs } from '@syncfusion/ej2-inputs';
import {
  DiagramComponent,
  ShapeAnnotationModel,
  VerticalAlignment,
  HorizontalAlignment,
  ISelectionChangeEventArgs,
  ConnectorConstraints,
  AnnotationConstraints,
} from '@syncfusion/ej2-angular-diagrams';
import { CheckBoxChangeEventArgs, load } from '@syncfusion/ej2-grids';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { FlowchartService } from '../flowchart.service';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { FlowChartModel } from '../flowchart';
import { JWTToken } from 'app/core/service/auth/auth';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { ConnectorService } from 'app/service/connectorconfig/connector.service';
import { ConnectionDetail } from 'app/service/connectorconfig/connector';
// import {environment}  from 'environments/environment';

registerLicense(
  'Ngo9BigBOggjHTQxAR8/V1NAaF5cWWJCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWX5fcXRVRmFcU0RxWkI='
);
let node: ConnectorModel;
let connector: ConnectorModel;
@Component({
  selector: 'app-flowchart-editor',
  templateUrl: './flowchart-editor.component.html',
  styleUrls: ['./flowchart-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FlowchartEditorComponent implements OnInit {
  // @Input() connectorsList ;
  // @Input() flowKey;
  public flowKey;

  @ViewChild('diagram')
  public diagram: DiagramComponent;
  public versionList = [];
  public currentVersion = 'Latest';
  public connectorsList: ConnectionDetail[] = [];
  public showHideToolBar = true;
  public dsList = [];
  public flowTitle;
  public flowChartData: FlowChartModel = Object();
  public saveData: any;
  public jwtToken: JWTToken;

  constructor(
    public loader: LoaderService,
    public flowChartService: FlowchartService,
    public authServices: AuthServices,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    public connectorService: ConnectorService
  ) {}
  async ngOnInit() {
    if (!this.jwtToken) {
      this.jwtToken = await this.authServices.getJWTDetail();
    }
    this.getConnector();
    this.activatedRoute.paramMap.subscribe((param) => {
      this.flowKey = param.get('process-key');
      this.fetchFlowchart(this.jwtToken.org.id, this.flowKey, null);
    });
  }
  // public terminator: FlowShapeModel = { type: 'Flow', shape: 'Terminator' };
  // public process: FlowShapeModel = { type: 'Flow', shape: 'Process' };
  // public decision: FlowShapeModel = { type: 'Flow', shape: 'Decision' };
  // public data: FlowShapeModel = { type: 'Flow', shape: 'Data' };
  // public directdata: FlowShapeModel = { type: 'Flow', shape: 'DirectData' };

  public margin: MarginModel = { left: 25, right: 25 };
  public connAnnotStyle: TextStyleModel = { fill: 'white' };
  public strokeStyle: StrokeStyleModel = { strokeDashArray: '2,2' };

  public segments: OrthogonalSegmentModel = [
    { type: 'Orthogonal', direction: 'Top', length: 120 },
  ];
  public segments1: OrthogonalSegmentModel = [
    { type: 'Orthogonal', direction: 'Right', length: 100 },
  ];

  public changeLogo(connector) {
    if (
      this.diagram.selectedItems.nodes.length > 0 &&
      connector.name !== 'no-logo'
    ) {
      this.diagram.selectedItems.nodes[0].annotations[1].template = `<div class="annotation-div"><img src="${connector.image_url}" width="50" height="50" class="annotation-img"></div>`;
      this.diagram.selectedItems.nodes[0].addInfo = {
        source: connector.source_key,
      };
    } else {
      this.diagram.selectedItems.nodes[0].annotations[1].template = ``;
    }
  }

  public onVersionChange(value) {
    let vVal = value;
    this.currentVersion = value;
    if (vVal === 'Latest') {
      vVal = null;
    }
    this.fetchFlowchart(this.jwtToken.org.id, this.flowKey, vVal, true);
  }
  public fetchFlowchart(
    entityKey,
    flowKey,
    version = null,
    onVersionChange = false
  ) {
    this.loader.show();
    this.flowChartService.getFlowchart(entityKey, flowKey, version).subscribe(
      (data) => {
        this.flowChartData = data && data[0];
        if (onVersionChange === false) {
          this.flowTitle = this.flowChartData.name;
          this.versionList = [];
          for (let index = 0; index < this.flowChartData.version; index++) {
            this.versionList.push(index);
          }
          this.versionList.push('Latest');
        }
        const obj = {
          nodes: this.flowChartData?.nodes
            ? JSON.parse(this.flowChartData?.nodes)
            : [],
          connectors: data[0]?.edges ? JSON.parse(data[0]?.edges) : [],
        };
        this.diagram.loadDiagram(JSON.stringify(obj));
        this.diagram.fitToPage();
        this.loader.hide();
      },
      (err) => {
        this.loader.hide();
      }
    );
  }

  public getConnector = () => {
    this.connectorService.getConnectionConfiguration().subscribe((data) => {
      this.connectorsList = data;
      this.connectorsList = this.connectorsList.filter((d) => {
        return d.type === 'workflow';
      });
    });
  };
  public removeNode() {
    this.diagram.remove(this.diagram.selectedItems.nodes[0]);
  }

  public remoConnector() {
    this.diagram.remove(this.diagram.selectedItems.connectors[0]);
  }

  private applyAnnotationStylenotationStyle(
    propertyName: string,
    propertyValue: string
  ): void {
    let nC =
      this.diagram.selectedItems.connectors.length > 0 ? 'connectors' : 'nodes';

    for (let i: number = 0; i < this.diagram.selectedItems[nC].length; i++) {
      node = this.diagram.selectedItems[nC][i];
      if (propertyName === 'bgcolor') {
        node.style.fill = propertyValue;
        node.style.strokeColor = propertyValue;
      }
      for (let j: number = 0; j < node.annotations.length; j++) {
        let textStyle: TextStyleModel = node.annotations[j].style;
        if (propertyName === 'fontsize') {
          textStyle.fontSize = Number(propertyValue);
        } else if (propertyName === 'underline') {
          textStyle.textDecoration =
            textStyle.textDecoration === 'Underline' ? 'None' : 'Underline';
        } else if (propertyName === 'fontfamily') {
          textStyle.fontFamily = propertyValue;
        } else if (propertyName === 'bold') {
          textStyle.bold = !textStyle.bold;
        } else if (propertyName === 'italic') {
          textStyle.italic = !textStyle.italic;
        } else if (propertyName === 'color') {
          textStyle.color = propertyValue;
        } else if (propertyName === 'template') {
          if (propertyValue === 'none') {
            node.annotations[j].template = '';
            node.annotations[j].width = undefined;
            node.annotations[j].height = undefined;
          } else {
            node.annotations[j].width = 25;
            node.annotations[j].height = 25;
            node.annotations[j].template =
              '<img src="./assets/diagram/Images/annotation/' +
              propertyValue +
              '.svg" style="width:100%;height:100%" />';
          }
        } else if (propertyName === 'interaction') {
          node.annotations[j].constraints =
            node.annotations[j].constraints ^ AnnotationConstraints.Interaction;
        }
        this.diagram.dataBind();
      }
    }
  }

  public btnBoldClick(): void {
    this.applyAnnotationStylenotationStyle('bold', '');
  }

  public btnItalicClick(): void {
    this.applyAnnotationStylenotationStyle('italic', '');
  }

  public btnUnderlineClick(): void {
    this.applyAnnotationStylenotationStyle('underline', '');
  }

  public onFontColorChange(args: ColorPickerEventArgs): void {
    this.applyAnnotationStylenotationStyle('color', args.currentValue.rgba);
  }
  public onBgColorChange(args: ColorPickerEventArgs): void {
    this.applyAnnotationStylenotationStyle('bgcolor', args.currentValue.rgba);
  }
  public onFontSizeChange(args: ChangeEventArgs): void {
    if (args.event) {
      this.applyAnnotationStylenotationStyle('fontsize', args.value.toString());
    }
  }

  public onFontFamilyChange(args: DropDownChangeEventArgs): void {
    if (args.element) {
      this.applyAnnotationStylenotationStyle(
        'fontfamily',
        args.value.toString()
      );
    }
  }

  public nodeDefaults(node: NodeModel): NodeModel {
    let obj: NodeModel = {};
    if (obj.width === undefined) {
      obj.width = 100;
    } else {
      let ratio: number = 100 / obj.width;
      obj.width = 100;
      obj.height *= ratio;
    }
    obj.style = { fill: '#4d4d4d', strokeColor: '#1C1F2C' };
    obj.annotations = [
      { style: { color: 'black', fill: 'transparent', textWrapping: 'Wrap' } },
    ];
    obj.ports = getPorts(node);
    return obj;
  }
  public connDefaults(obj: Connector): void {
    if (obj.id.indexOf('connector') !== -1) {
      obj.targetDecorator = { shape: 'Arrow', width: 10, height: 10 };
      obj.style = { strokeColor: '#4d4d4d', strokeWidth: 2 };
    }
  }
  public created(): void {
    this.diagram.fitToPage();
    this.diagram.serializationSettings = {
      preventDefaults: true,
    };
  }
  public interval: number[] = [
    1, 9, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75, 0.25,
    9.75, 0.25, 9.75, 0.25, 9.75, 0.25, 9.75,
  ];

  public snapSettings: SnapSettingsModel = {
    constraints: SnapConstraints.None,
    // horizontalGridlines: { lineColor: '#e0e0e0', lineIntervals: this.interval },
    // verticalGridlines: { lineColor: '#e0e0e0', lineIntervals: this.interval }
  };

  public dragEnter(args: IDragEnterEventArgs): void {
    let obj: NodeModel = args.element as NodeModel;
    if (obj && obj.width && obj.height) {
      let oWidth: number = obj.width;
      let oHeight: number = obj.height;
      let ratio: number = 100 / obj.width;
      obj.width = 100;
      obj.height *= ratio;
      obj.offsetX += (obj.width - oWidth) / 1;
      obj.offsetY += (obj.height - oHeight) / 1;
      obj.style = {
        fill: 'transparent',
        strokeColor: '#1C1F2C',
        strokeWidth: 2,
      };
      if (obj.annotations[0]) {
        obj.annotations[0].style = { bold: true };
      }
    }
  }

  //SymbolPalette Properties
  public symbolMargin: MarginModel = {
    left: 15,
    right: 15,
    top: 15,
    bottom: 15,
  };
  public expandMode: ExpandMode = 'Multiple';
  //Initialize the flowshapes for the symbol palatte
  private flowshapes: NodeModel[] = [
    {
      id: 'Rectangle',
      shape: { type: 'Basic', shape: 'Rectangle' },
      style: { strokeWidth: 1 },
      minHeight: 40,
      minWidth: 40,
      offsetX: 25,
      offsetY: 25,
      annotations: [
        { content: 'Node' },
        {
          width: 30,
          height: 30,
          offset: { x: 0, y: 0 },
          template: '<div></div>',
        },
      ],
    },
    {
      id: 'Terminator',
      shape: { type: 'Flow', shape: 'Terminator' },
      style: { strokeWidth: 1 },
      offsetX: 25,
      offsetY: 25,
      minHeight: 40,
      minWidth: 40,
      annotations: [
        { content: 'Node' },
        {
          width: 30,
          height: 30,
          offset: { x: 0, y: 0 },
          template: '<div></div>',
        },
      ],
    },
    {
      id: 'Process',
      shape: { type: 'Flow', shape: 'Process' },
      style: { strokeWidth: 1 },
      offsetX: 25,
      offsetY: 25,
      minHeight: 40,
      minWidth: 40,
      annotations: [
        { content: 'Node' },
        {
          width: 30,
          height: 30,
          offset: { x: 0, y: 0 },
          template: '<div></div>',
        },
      ],
    },
    {
      id: 'Decision',
      shape: { type: 'Flow', shape: 'Decision' },
      style: { strokeWidth: 1 },
      offsetX: 25,
      offsetY: 25,
      minHeight: 40,
      minWidth: 40,
      annotations: [
        { content: 'Node' },
        {
          width: 30,
          height: 30,
          offset: { x: 0, y: 0 },
          template: '<div></div>',
        },
      ],
    },
    {
      id: 'Extract',
      shape: { type: 'Flow', shape: 'Extract' },
      style: { strokeWidth: 1 },
      offsetX: 25,
      offsetY: 25,
      minHeight: 40,
      minWidth: 40,
      annotations: [
        { content: 'Node' },
        {
          width: 30,
          height: 30,
          offset: { x: 0, y: 0 },
          template: '<div></div>',
        },
      ],
    },
    {
      id: 'Ellipse',
      shape: { type: 'Basic', shape: 'Ellipse' },
      style: { strokeWidth: 1 },
      offsetX: 25,
      offsetY: 25,
      minHeight: 40,
      minWidth: 40,
      annotations: [
        { content: 'Node' },
        {
          width: 30,
          height: 30,
          offset: { x: 0, y: 0 },
          template: '<div></div>',
        },
      ],
    },
  ];


  public palettes: PaletteModel[] = [
    {
      id: 'flow',
      expanded: true,
      symbols: this.flowshapes,
      iconCss: 'shapes',
      title: 'Shapes',
    },
    // {
    //   id: 'connectors',
    //   expanded: true,
    //   symbols: this.connectorSymbols ,
    //   iconCss: 'shapes',
    //   title: 'Edges',
    // },
  ];

  public getSymbolInfo(symbol: NodeModel): SymbolInfo {
    return { fit: true };
  }

  public getSymbolDefaults(symbol: NodeModel): void {
    if (symbol.id === 'Terminator' || symbol.id === 'Process') {
      symbol.width = 130;
      symbol.height = 65;
    } else {
      symbol.width = 90;
      symbol.height = 90;
    }
  }

  public collectionChange(args: ICollectionChangeEventArgs) {
    if (args.state === 'Changed') {
      this.dropFn(args);
    }
  }
  public dropFn(args: any) {
    let tar: any;
    for (let i: number = 0; i < this.diagram.nodes.length; i++) {
      let node = args.element;
      let r1 = node.wrapper.bounds;
      let r2 = this.diagram.nodes[i].wrapper.bounds;
      if (
        !(
          r2.left >= r1.right ||
          r2.right <= r1.left ||
          r2.top >= r1.bottom ||
          r2.bottom <= r1.top
        )
      ) {
        if (this.diagram.nodes[i].children !== undefined) {
          tar = this.diagram.nodes[i];
        }
      }
    }
    if (tar && (tar as NodeModel).children !== undefined) {
      if ((args.element as NodeModel).id !== (tar as NodeModel).id) {
        if (
          (tar as NodeModel).children.indexOf(
            (args.element as NodeModel).id
          ) === -1
        ) {
          this.diagram.addChild(
            tar as NodeModel,
            (args.element as NodeModel).id
          );
        }
      }
    }
  }

  public drop(args: IDropEventArgs) {
    if ((args.source as DiagramComponent).nodes !== undefined) {
      this.dropFn(args);
    }
  }

  public selectionChange(args: ISelectionChangeEventArgs) {
    if (args.state === 'Changed') {
      if (args.type === 'Removal') {
        if (args.oldValue[0] instanceof Connector) {

          if (args.oldValue[0]?.annotations[0]?.content.length >= 1) {
            args.oldValue[0].annotations[0].style.fill = '#fcfcff';
          } else {
            args.oldValue[0].annotations[0].style.fill = 'transparent';
          }
        }
      }
    }
  }

  public diagramCreate(args: Object): void {
    paletteIconClick();
  }

  public undo(): void {
    this.diagram.undo();
  }

  public redo(): void {
    this.diagram.redo();
  }

  public showSideBar(): void {
    this.showHideToolBar = !this.showHideToolBar;
  }

  public clear() {
    this.diagram.clear();
  }

  updateChart() {
    this.saveData = JSON.parse(this.diagram.saveDiagram());
    const obj = {
      name: this.flowTitle,
      key: this.flowChartData.key,
      nodes: JSON.stringify(this.saveData.nodes || []),
      edges: JSON.stringify(this.saveData.connectors || []),
      active: true,
      login_user: this.jwtToken.userEmail,
      entity_key: this.jwtToken.org.id,
    };
    this.flowChartService.putFlowchart(obj).subscribe(
      (res) => {
        this.toastr.success('Saved Successfully', 'Success', {
          timeOut: 2000,
        });
        this.fetchFlowchart(this.jwtToken.org.id, this.flowKey);
      },
      (err) => {
        this.toastr.error('Retry', 'ERROR', {
          timeOut: 3000,
        });
        this.loader.hide();
      }
    );
  }

  private applyConnectorStyle(type: Segments): void {
    for (let i: number = 0; i < this.diagram.connectors.length; i++) {
      this.diagram.connectors[i].type = type;

      this.diagram.dataBind();
    }
  }
  onPageToolClick(iconText): void {
    switch (iconText) {
      case 'Zoom In':
        let zoomin: ZoomOptions = { type: 'ZoomIn', zoomFactor: 0.2 };
        this.diagram.zoomTo(zoomin);
        break;
      case 'Zoom Out':
        let zoomout: ZoomOptions = { type: 'ZoomOut', zoomFactor: 0.2 };
        this.diagram.zoomTo(zoomout);
        break;
      case 'Reset':
        this.diagram.reset();
        this.diagram.fitToPage();
        break;
    }
  }
}

function getPorts(obj: NodeModel): PointPortModel[] {
  let ports: PointPortModel[] = [
    {
      id: 'port1',
      shape: 'Circle',
      offset: { x: 0, y: 0.5 },
      visibility: PortVisibility.Hover,
      constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
      id: 'port2',
      shape: 'Circle',
      offset: { x: 0.5, y: 1 },
      visibility: PortVisibility.Hover,
      constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
      id: 'port3',
      shape: 'Circle',
      offset: { x: 1, y: 0.5 },
      visibility: PortVisibility.Hover,
      constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
      id: 'port4',
      shape: 'Circle',
      offset: { x: 0.5, y: 0 },
      visibility: PortVisibility.Hover,
      constraints: PortConstraints.Default | PortConstraints.Draw,
    },
  ];
  return ports;
}
