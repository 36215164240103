import { Directive, ElementRef, HostListener, Input, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[numberFormat]'
})
export class NumberFormatDirective {
  @Input() currency: string = 'USD';

  constructor(private el: ElementRef, @Optional() private ngControl: NgControl) {}


  @HostListener('input', ['$event']) onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    const newValue = value.replace(/[^0-9.]/g, '');

    this.el.nativeElement.value = newValue;

    if (this.ngControl) {
      // For reactive forms
      this.ngControl.control.setValue(newValue);
    }
  }

  @HostListener('blur', ['$event']) onBlur(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    const newValue = value.replace(/[^0-9.]/g, '');
    const numericValue = parseFloat(newValue);
    const loc = this.currency === 'INR' ? 'en-IN' : 'en-US';

    if (!isNaN(numericValue)) {
      input.value = numericValue.toLocaleString(loc, { style: 'decimal' ,maximumFractionDigits:2});

      if (this.ngControl) {
        // For reactive forms
        this.ngControl.control.setValue(input.value);
      }
    }
  }
  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent): void {
    // Prevent the default paste behavior
    event.preventDefault();

    // Get the clipboard data as plain text
    const pastedData = event.clipboardData.getData('text/plain');

    // Remove non-numeric and non-dot characters from the pasted data
    const cleanedData = pastedData.replace(/[^0-9.]/g, '');

    // Insert the cleaned data into the input
    document.execCommand('insertText', false, cleanedData);
  }


}
