<div [style.height.px]="chartHeight">  
  <ejs-chart
    style="display: block;height: inherit !important;width: 100%;"
    [chartArea]="chartArea"
    align="center"
    [title]="title"
    [palettes]="palette"
    [primaryXAxis]="primaryXAxis"
    [primaryYAxis]="primaryYAxis"
    (load)="load($event)"
    [tooltip]="tooltip"
    (axisLabelRender)="OnChartAxisLabeRender($event)"
    [legendSettings]="legend"
  >
    <e-series-collection>
      <e-series
        [dataSource]="metricData.chart.data"
        type="Area"
        xName="category"
        yName="value"
        [marker]="marker"
        [name]="metricData.name"
        opacity="0.1"
        width="1"
        [border]="border"
      >
      </e-series>
    </e-series-collection>
  </ejs-chart>
</div>
