import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AiHomeComponent } from './ai-home/ai-home.component';
import { AiChatComponent } from './ai-chat/ai-chat.component';
import { AiPromptLibraryComponent } from './ai-prompt-library/ai-prompt-library.component';
import { AiPromptComponent } from './ai-prompt/ai-prompt.component';
import { AiResultComponent } from './ai-result/ai-result.component';
import { AiLandingComponent } from './ai-landing/ai-landing.component';

const routes: Routes = [
  {
    path: '',
    component: AiHomeComponent,
    children: [
      {
        path: '',
        component: AiLandingComponent,
      },
      {
        path: 'old',
        component: AiChatComponent,
      },
      {
        path: 'prompt-library',
        component: AiPromptLibraryComponent,
      },
      {
        path: 'prompt',
        component: AiPromptComponent,
      },
      { path: 'chat', component: AiResultComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AiRoutingModule {}
