import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { handleError } from '../core/handle-error';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BulkUploadService {
  constructor(private http: HttpClient) {}
  getBulkUploadConfig = (
    entityId: string
  ): Observable<any> => {

    return this.http
      .get(`${environment.server.baseURL}/file-upload?entity_key=${entityId}`)
      .pipe(catchError(handleError));
  };
}
