import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';

import {
  ILoadedEventArgs,
  ChartTheme,
  IAxisLabelRenderEventArgs,
  ChartComponent,
} from '@syncfusion/ej2-angular-charts';
import { ITooltipRenderEventArgs } from '@syncfusion/ej2-angular-circulargauge';
import { Internationalization } from '@syncfusion/ej2-base';
import { ChartService } from 'app/service/shared/chart.service';
import { CustomCurrencyPipe } from 'app/shared/utills/currency.pipe';
@Component({
  selector: 'app-multi-type-chart',
  templateUrl: './multi-type-chart.component.html',
  styleUrls: ['./multi-type-chart.component.scss'],
  providers:[CustomCurrencyPipe]
})
export class MultiTypeChartComponent {

     @Input() metricData : any= { };
    @Input() chartHeight;

    @ViewChild('chart')
    public chart: ChartComponent;
    public pointColorMapping: string;
    public unit = '';
    public lineMarker: Object;

    constructor(
      private chartService: ChartService,
    private _customCurrencyPipe: CustomCurrencyPipe
    ) {}
    ngOnInit(): void {
      this.unit = this.metricData.chart.unit;
      this.pointColorMapping = 'color';
      this.markMaxBar();
      this.primaryXAxis = {
        majorGridLines: { width: 0 },
        minorGridLines: { width: 0 },
        majorTickLines: { width: 0 },
        minorTickLines: { width: 0 },
        interval: 1,
        lineStyle: { width: 0 },
        visible: this.metricData.chart.xAxisLine,
        // visible: this.metricData.chart.dataLabel,
        // labelIntersectAction: 'Rotate45',
        valueType: 'Category',
        labelStyle: {
          fontFamily: 'Inter,sans-serif',
          color: '#8D8F95',
        },
      };
      this.primaryYAxis = {
        labelFormat: `${this.unit === '$' ? this.unit : ''}{value}`,
        title: '',
        lineStyle: { width: 0 },

        majorTickLines: { width: 0 },
        majorGridLines: { color: '#C5C5C5', width: 0, dashArray: 0 },
        minorGridLines: { width: 0 },
        minorTickLines: { width: 0 },
        visible: this.metricData.chart.yAxisLine,
        labelStyle: {
          fontFamily: 'Inter,sans-serif',
          color: '#8D8F95',
        },
      };
      this.palette = [
        '#4D81E5',
        '#32B19A',
        '#6DC190',
        '#C3DFE0',
      ];
      this.marker = {
        dataLabel: {
          visible: this.metricData.chart.dataLabel,
          position: 'Outer',
          template: '<div style="color: #8D8F95; font-family: Inter, sans-serif">${point.y}</div>'
        }
      };
      this.tooltip = {
        enable: this.metricData.chart.tooltip,
        header: '',
        format: '${point.x}: ${point.y}',
        textStyle : {
          fontFamily: "Inter, sans-serif"
        }
      };
      this.lineMarker = {
        visible: true,
        height: 4,
        width: 4,
        shape: 'Circle',
        isFilled: true,
        dataLabel: {
          visible: this.metricData.chart.dataLabel,
          position: 'Outer',
          template: '<div style="color: #32B19A; font-family: Inter, sans-serif">${point.y}</div>',
        },
      };
      setTimeout(() => {
        this.chart.refresh();
      }, 1500);
    }
    public chartArea: Object = {
      border: {
        width: 0,
      },

    };
    public primaryXAxis: Object;
    //Initializing Primary Y Axis
    public primaryYAxis: Object;
    public palette;
    public title: string = '';
    public tooltip: Object;
    public marker: Object;
    public load(args: ILoadedEventArgs): void {
      let selectedTheme: string = location.hash.split('/')[1];
      selectedTheme = selectedTheme ? selectedTheme : 'Material';
      args.chart.theme = <ChartTheme>(
        (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(
          /-dark/i,
          'Dark'
        )
      );
    }

    OnChartAxisLabeRender(args: IAxisLabelRenderEventArgs) {
      this.chartService.formatAxisLabel(args, this.metricData.chart.unit);
    }

    markMaxBar() {
      if (this.metricData.chart.marker) {
        // const max = Math.max(...this.metricData.chart.data.map((o) => o.value));
        this.metricData.chart.data.forEach((element) => {
          element.color = '#4D81E5';
          // if (element.value === max) {
          //   element.color = this.metricData.chart.color;
          // }
        });
      }
    }
    public legend: Object = {
      visible: true,
      enableHighlight: true,
      textStyle: {
        fontFamily: 'Inter, sans-serif'
      }
    };
    // public barData: Object[] =  [
    //   {
    //     "category": "Jan",
    //     "value": 2,
    //     color: 'red'
    //   },
    //   {
    //     "category": "Feb",
    //     "value": 5,
    //     color: 'blue'
    //   },
    //   {
    //     "category": "Mar",
    //     "value": 31
    //   },
    //   {
    //     "category": "Apr",
    //     "value": 10
    //   },
    //   {
    //     "category": "May",
    //     "value": 7
    //   }
    // ];
    cornerRadius: Object = {
      bottomLeft: 4,
      bottomRight: 4,
      topRight: 4,
      topLeft: 4,
    };
    public border: object = {
      width: 1,
      radius: 20,
    };



    public loaded(args: ILoadedEventArgs): void {
      for (var i = 0; i < args.chart.series[0].dataSource["length"]; i++) {
        if (args.chart.series[0].dataSource[i].y == null) {
          document
            .getElementById("chart_Series_0_Point_" + i)
            .setAttribute("stroke-dasharray", "5,5");
        }
      }
    }

    public tooltipRender(args: ITooltipRenderEventArgs | any): void {
      let form = this._customCurrencyPipe.transform(
        args.point.y as string | number,
        '', false,undefined
      );
      args.text = args.point.x+ ' : ' + form;
  };
}
