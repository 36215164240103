import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { handleError } from '../core/handle-error';
import aiData from 'assets/config/ai.json';

@Injectable({
  providedIn: 'root',
})
export class AiService {
  constructor(private http: HttpClient) {}

  getAIData = (): Observable<any> => {
    return of(aiData.ai);
  };
  getAidashboard = (): Observable<any> => {
    return this.http
      .get(`${environment.server.baseURL}/prompts/library`)
      .pipe(catchError(handleError));
  };

  getPromptLibrary = (lib_id: string): Observable<any> => {
    return this.http
      .get(`${environment.server.baseURL}/prompts?lib_id=${lib_id}`)
      .pipe(catchError(handleError));
  };

  postQuestion = (questionData): Observable<any> => {
    return this.http
      .post(`${environment.server.baseURL}/prompts`, questionData)
      .pipe(catchError(handleError));
  };
}
