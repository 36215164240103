import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs-compat';

export interface RoleData {
  action: string;
  section: string;
}

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private _RoleDataMap = new Map<string, RoleData>();
  get RoleDataMap(): ReadonlyMap<string, RoleData> {
    return this._RoleDataMap;
  }

  private _RoleData$ = new BehaviorSubject<ReadonlyMap<string, RoleData>>(this.RoleDataMap);
  get RoleData$(): Observable<ReadonlyMap<string, RoleData>> {
    return this._RoleData$.asObservable();
  }

  setRoleToMap(roleList) {
    const defaultRole = [
      "VIEW::PROCESSFLOW",
      "VIEW::CONTRACT",
    ]

    // const role = [
    //   "MANAGE::USER",
    //   "MANAGE_APPROVALS::ACCOUNTS_PAYABLE",
    //   "VIEW::ACCOUNTS_PAYABLE",
    //   "MANAGE::ACCOUNTS_RECEIVABLE",
    //   "MANAGE::CONNECTOR",
    //   "VIEW::CONNECTOR",
    //   "VIEW::WORKFLOW",
    //   "MANAGE::WORKFLOW",
    //   "VIEW::REPORT"
    // ]

    const finalRoles= defaultRole.concat(roleList)
    finalRoles.forEach(objStr => {
      var role = objStr.split("::");
      this._RoleDataMap.set(objStr, { section: role[0], action: role[1]});
    });
    this._RoleData$.next(this._RoleDataMap);
  }

  checkRole(sectionAction : string, action) {
    const roleData = this._RoleDataMap.has(action + '::' + sectionAction);
    return roleData;
  }
}
