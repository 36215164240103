<!-- <div class="row">
    <div class="col-md-12">
      <div class="div-vendor-head">
        Promise to Pay
      </div>
      <div class="div-close" (click)="closeDrilldown()">
        <i class="fal fa-times"></i>
      </div>
    </div>
</div> -->

<!-- Right side Box - Starts -->
<div>
  <!-- Form group for promise to pay -->
  <form [formGroup]="p2pForm" (ngSubmit)="onSubmit()">
    <div class="div-vendor-body">
      <div class="row">
        <div class="col-md-12">
          <div class="div-content">
            <div class="form-group">
              <label for="vendor-name">Person Name</label>
              <input class="form-control" placeholder="Enter Name" formControlName="person_name" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="div-content">
            <div class="form-group">
              <label for="vendor-name">Date</label>
              <div class="date-picker-container">
                <i class="fa fa-calendar" (click)="picker.open()"></i>
                <input class="form-control" placeholder="Select date" formControlName="promise_date"
                  [matDatepicker]="picker" (click)="picker.open()" style="padding-left:30px;" readonly/>
                <mat-datepicker matPrefix #picker></mat-datepicker>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="div-content">
            <div class="form-group">
              <label for="vendor-name">Mode of P2P</label><br>

              <div *ngFor="let option of modeOfP2POptions">
                <input type="radio" class="cursor-pointer" [value]="option" formControlName="p2p_mode" [attr.disabled]="isPatched && !isEditing ? true : null">
                <label class="radio-p2p-txt" [for]="option">{{ option }}</label><br>
              </div>
              <input class="form-control" *ngIf="p2pForm?.get('p2p_mode').value === 'Others'" type="text"
                [(ngModel)]="p2pModeOther" [ngModelOptions]="{standalone: true}" placeholder="Specify Other" [attr.disabled]="isPatched && !isEditing ? true : null">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="div-content">
            <div class="form-group">
              <label for="vendor-name">P2P Payment Method (optional)</label><br>
              <div *ngFor="let option of p2pPaymentOptions">
                <input type="radio" class="cursor-pointer" [value]="option" formControlName="payment_mode" [attr.disabled]="isPatched && !isEditing ? true : null">
                <label class="radio-p2p-txt" [for]="option">{{ option }}</label><br>
              </div>
              <input class="form-control" *ngIf="p2pForm?.get('payment_mode').value === 'Others'" type="text"
                [(ngModel)]="paymentModeOther" [ngModelOptions]="{standalone: true}" placeholder="Specify Other" [attr.disabled]="isPatched && !isEditing ? true : null">
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <!-- <button type="submit" class="btn btn-submit" [disabled]="!p2pForm.valid">Save</button> -->
        <button type="submit" class="btn btn-numero" [disabled]="!p2pForm.valid || (p2pForm.get('payment_mode').value === 'Others' && !paymentModeOther)" *ngIf="!isPatched && !isEditing">Save</button>
        <button type="submit" class="btn btn-numero" [disabled]="!p2pForm.valid || (p2pForm.get('p2p_mode').value === 'Others' && !p2pModeOther)" *ngIf="isPatched && isEditing">Update</button>
      </div>
    </div>
  </form>
</div>

<!-- Right side Box - Ends -->
