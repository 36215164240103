import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  ILoadedEventArgs,
  ChartTheme,
  IAxisLabelRenderEventArgs,
  ChartComponent,
} from '@syncfusion/ej2-angular-charts';
import { ChartService } from 'app/service/shared/chart.service';
@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit {
  @Input() metricData;
  @Input() chartHeight;

  @ViewChild('chart')
  public chart: ChartComponent;
  public pointColorMapping: string;
  public unit = '';

  constructor(
    private chartService: ChartService,
  ) {}
  ngOnInit(): void {
    this.unit = this.metricData.chart.unit;
    this.pointColorMapping = 'color';
    this.markMaxBar();
    this.primaryXAxis = {
      majorGridLines: { width: 0 },
      minorGridLines: { width: 0 },
      majorTickLines: { width: 0 },
      minorTickLines: { width: 0 },
      interval: 1,
      lineStyle: { width: 0 },
      visible: this.metricData.chart.xAxisLine,
      // visible: this.metricData.chart.dataLabel,
      // labelIntersectAction: 'Rotate45',
      valueType: 'Category',
      labelStyle: {
        fontFamily: 'Inter,sans-serif',
        color: '#8D8F95',
      },
    };
    this.primaryYAxis = {
      labelFormat: `${this.unit === '$' ? this.unit : ''}{value}`,
      title: '',
      lineStyle: { width: 0 },

      majorTickLines: { width: 0 },
      majorGridLines: { color: '#C5C5C5', width: 0, dashArray: 0 },
      minorGridLines: { width: 0 },
      minorTickLines: { width: 0 },
      visible: this.metricData.chart.yAxisLine,
      labelStyle: {
        fontFamily: 'Inter,sans-serif',
        color: '#8D8F95',
      },
    };
    this.palette = [
      this.metricData.chart.color,
      '#F9A03F',
      '#6DC190',
      '#C3DFE0',
    ];
    this.marker = {
      dataLabel: {
        visible: this.metricData.chart.dataLabel,
        position: 'Outer',
        template: '<div style="color: #8D8F95; font-family: Inter, sans-serif">${point.y}</div>'
      }
    };
    this.tooltip = {
      enable: this.metricData.chart.tooltip,
      header: '',
      format: '${point.x}: ${point.y}',
      textStyle : {
        fontFamily: "Inter, sans-serif"
      }
    };
    setTimeout(() => {
      this.chart.refresh();
    }, 1500);
  }
  public chartArea: Object = {
    border: {
      width: 0,
    },
  };
  public primaryXAxis: Object;
  //Initializing Primary Y Axis
  public primaryYAxis: Object;
  public palette;
  public title: string = '';
  public tooltip: Object;
  public marker: Object;
  public load(args: ILoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.chart.theme = <ChartTheme>(
      (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(
        /-dark/i,
        'Dark'
      )
    );
  }

  OnChartAxisLabeRender(args: IAxisLabelRenderEventArgs) {
    this.chartService.formatAxisLabel(args,this.unit);
  }

  markMaxBar() {
    if (this.metricData.chart.marker) {
      const max = Math.max(...this.metricData.chart.data.map((o) => o.value));
      this.metricData.chart.data.forEach((element) => {
        element.color = '#E7E9EC';
        if (element.value === max) {
          element.color = this.metricData.chart.color;
        }
      });
    }
  }
  public legend: Object = {
    visible: false,
    enableHighlight: false,
  };
  // public barData: Object[] =  [
  //   {
  //     "category": "Jan",
  //     "value": 2,
  //     color: 'red'
  //   },
  //   {
  //     "category": "Feb",
  //     "value": 5,
  //     color: 'blue'
  //   },
  //   {
  //     "category": "Mar",
  //     "value": 31
  //   },
  //   {
  //     "category": "Apr",
  //     "value": 10
  //   },
  //   {
  //     "category": "May",
  //     "value": 7
  //   }
  // ];
  cornerRadius: Object = {
    bottomLeft: 4,
    bottomRight: 4,
    topRight: 4,
    topLeft: 4,
  };
  public border: object = {
    width: 1,
    radius: 20,
  };
}
