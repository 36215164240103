<div class="div-in-det-drill">
    <div class="div-table">                            
        <div class="div-table-22">
            <div class="div-table-head">Invoice Date</div>
            <div class="div-table-child">{{invoiceData?.invoiceDate | date :'MMM dd, yyyy' }}</div>
        </div>
        <div class="div-table-22">
            <div class="div-table-head">Due Date</div>
            <div class="div-table-child">{{invoiceData?.dueDate | date :'MMM dd, yyyy' }}</div>
        </div>
        <div class="div-table-22">
            <div class="div-table-head">Outstanding</div>
            <div class="div-table-child">{{invoiceData?.baseBalance |currency: invoiceData?.currencyId}}</div>
            <p class="mb-0 mt-0 new-btn-write cursor-pointer"  (click)="showPopupWrittenOff('Are you sure you want to write-off this amount?',true)" *ngIf="(invoiceData?.invoiceStatus !== 'paid' && invoiceData?.invoiceStatus !== 'written-off')">Write-off</p>
        </div>                            
        <div class="div-table-17">
            <div class="div-table-head">Aging</div>
            <div class="div-table-out" *ngIf="invoiceData?.daysOutstanding !==0">{{invoiceData?.daysOutstanding}}</div>
            <div class="div-table-out" *ngIf="invoiceData?.daysOutstanding === 0">-</div>
        </div>                            
        <div class="div-table-17 text-center">
            <div class="div-table-head">Dunning</div>
            <div class="div-table-out">
                <div class="custom-control custom-switch custom-switch-md">
                    <input type="checkbox" class="custom-control-input" id="activitydunning" [checked]="invoiceData?.dunning"  disabled>
                    <label class="custom-control-label" for="activitydunning"></label>
                </div>
            </div>
        </div>
    </div>
    <div class="div-in-line"></div>

    <div class="alert alert-warning p2p-alert" role="alert" *ngIf="(invoiceData?.p2pStatus != undefined && invoiceData?.p2pStatus === 'Open')" (click)="showP2Pdrawer()">
        <span class="p2p-alert-txt d-flex justify-content-center cursor-pointer">The customer has promised to pay on {{invoiceData?.p2pDate | date :'MMM dd, yyyy'}}. All dunning emails for this invoice has been paused.</span>
      </div>

    <div class="div-filter-actions">
        <div class="row">
            <div class="col-md-10">
                <div class="div-activity-filter">
                    <label class="container-checkbox ff-ngc">
                        <input type="radio" id="ckall" name="quickfil" value="all" checked
                            (change)="onCheckboxChange($event,'all')">
                        <span class="checkmark"></span>
                        <p>All</p>
                    </label>
                    <label class="container-checkbox ff-ngc">
                        <input type="radio" id="ckemail" name="quickfil" value="email"
                            (change)="onCheckboxChange($event,'email')">
                        <span class="checkmark"></span>
                        <p>Email ({{emailCount}}) </p>
                    </label>
                    <label class="container-checkbox ff-ngc">
                        <input type="radio" id="cknotes" name="quickfil" value="notes"
                            (change)="onCheckboxChange($event,'notes')">
                        <span class="checkmark"></span>
                        <p>Notes ({{noteCount}})</p>
                    </label>
                    <label class="container-checkbox ff-ngc">
                        <input type="radio" id="ckpromise" name="quickfil" value="promise"
                            (change)="onCheckboxChange($event,'promise')">
                        <span class="checkmark"></span>
                        <p>Promise to Pay ({{p2pCount}})</p>
                    </label>
                    <label class="container-checkbox ff-ngc">
                        <input type="radio" id="cktags" name="quickfil" value="tags"
                            (change)="onCheckboxChange($event,'tags')">
                        <span class="checkmark"></span>
                        <p>Tags ({{tagCount}})</p>
                    </label>
                    <label class="container-checkbox ff-ngc">
                        <input type="radio" id="ckpayments" name="quickfil" value="payments"
                            (change)="onCheckboxChange($event,'payments')">
                        <span class="checkmark"></span>
                        <p>Payments ({{paymentCount}})</p>
                    </label>
                </div>
            </div>
            <div class="col-md-2 text-right">
                <div class="div-dropdown-list" ngbDropdown container="body"
                    routerLinkActive="isActive" display="dynamic" placement="bottom-end">
                    <button class="div-dropdown-btn" ngbDropdownToggle>
                        <div>
                            <div class="av-text">
                                CREATE
                            </div>
                            <i class="far fa-chevron-down"></i>
                        </div>
                    </button>
                    <ng-container>
                        <div class="div-dropdown" ngbDropdownMenu>
                            <div ngbDropdownItem>
                                <button type="button" (click)="openCreateMail()">
                                    <i class="fak fa-numero-mail la-icon"></i> Email
                                </button>
                            </div>
                            <div ngbDropdownItem>
                                <button type="button" (click)="openCreateNote()">
                                    <i class="fak fa-numero-notes la-icon"></i> Notes
                                </button>
                            </div>
                            <div ngbDropdownItem>
                                <button type="button" (click)="openPromise()">
                                    <i class="fak fa-numero-promise la-icon"></i> Promise to Pay
                                </button>
                            </div>
                            <div ngbDropdownItem>
                                <button type="button" (click)="openPayment()">
                                    <i class="fak fa-numero-payment la-icon"></i> Tag
                                </button>
                            </div>

                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="div-mes-body" *ngIf="emaildata?.length >= 1">
        <ng-container *ngFor="let emailData of getSelectedData() | keyvalue">
            <div class="div-in-content-date d-none">
                <!-- <div class="div-in-content-date"
                *ngIf="(emailobj.type === 'INCOMING' ||emailobj.type === 'OUTGOING'|| emailobj.type === 'remainder') && emailobj.email_date !== 'same'"> -->
                <div class="div-in-line"></div>
                <!-- <span class="span-date">{{emailobj.email_date}}</span> -->
                <span class="span-date">{{emailData?.key}}</span>
            </div>
            <div class="div-in-content-box" *ngFor="let emailobj of emailData.value">
                <div class="row"
                    *ngIf="emailobj.type === 'OUTGOING'">
                    <div class="col-md-1 div-mes-icon">
                        <div class="div-mes-icon-body">
                            <div class="div-icon">
                                <i class="fak fa-numero-mail la-icon"></i>
                            </div>
                            <div class="div-line">
                                <div></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-10">
                        <div class="div-in-mes-box">
                            <div class="div-in-mes-head">{{emailobj?.subject}}</div>
                            <div class="div-in-mes-to">To: {{emailobj?.to_email}}</div>
                            <div *ngIf="emailobj?.cc_email">
                                <div class="div-in-mes-to">Cc: {{emailobj?.cc_email}}</div>
                            </div>
                            <div class="div-in-mes-content p-mb-none" [innerHTML]="emailobj.body"></div>
                        </div>
                        <div class="div-in-mes-time">
                            {{ emailobj.created_on | utcToLocalTime:'customFullDateTime'  }}
                        </div>
                    </div>
                    <div class="col-md-1 div-in-avatar">
                        <!-- <img src="./assets/image/avatar/{{emailobj.imageURL}}.png"> -->
                        <ngx-avatars [name]="emailobj.from_email" size="30"  initialsSize="2"></ngx-avatars>
                    </div>
                </div>
                <div class="row"
                    *ngIf="emailobj.type === 'INCOMING'">
                    <div class="col-md-1 div-mes-icon">
                        <div class="div-mes-icon-body">
                            <div class="div-icon">
                                <i class="fak fa-numero-mail la-icon"></i>
                            </div>
                            <div class="div-line">
                                <div></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-10">
                        <div class="div-in-mes-box reply-box">
                            <div class="div-in-mes-head">{{emailobj?.subject}}</div>
                            <div class="div-in-mes-to">To: {{emailobj?.to_email}}</div>
                            <div class="div-in-mes-content p-mb-none" [innerHTML]="emailobj.body"></div>
                        </div>
                        <div class="div-in-mes-time">
                            {{ emailobj.created_on | utcToLocalTime:'customFullDateTime'  }}
                        </div>
                    </div>
                    <div class="col-md-1 div-in-avatar">
                        <!-- <img src="./assets/image/avatar/{{emailobj.imageURL}}.png"> -->
                        <ngx-avatars [name]="emailobj.from_email" size="30"  initialsSize="2"></ngx-avatars>
                    </div>
                </div>
                <div class="row"
                    *ngIf="emailobj.type === 'NOTES'">
                    <div class="col-md-1 div-mes-icon">
                        <div class="div-mes-icon-body">
                            <div class="div-icon">
                                <i class="fak fa-numero-notes la-icon"></i>
                            </div>
                            <div class="div-line">
                                <div></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-10">
                        <div class="div-in-mes-box-notes">
                            <div class="div-in-mes-content p-mb-none" [innerHTML]="emailobj.body"></div>
                        </div>
                        <div class="div-in-mes-time">
                            {{ emailobj.created_on | utcToLocalTime:'customFullDateTime' }}
                        </div>
                    </div>
                    <div class="col-md-1 div-in-avatar">
                        <!-- <img src="{{environment.imagePrefixURI}}/avatar/tree.com.svg"> -->
                        <ngx-avatars [name]="emailobj.created_by" size="30"  initialsSize="2"></ngx-avatars>
                        <!-- <img src="./assets/image/avatar/{{emailobj.imageURL}}.png"> -->

                      </div>
                </div>
                <div class="row"
                *ngIf="emailobj.type === 'PROMISE_TO_PAY'">
                <div class="col-md-1 div-mes-icon">
                    <div class="div-mes-icon-body">
                        <div class="div-icon">
                            <i class="fak fa-numero-promise la-icon"></i>
                        </div>
                        <div class="div-line">
                            <div></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-10">
                    <div class="div-in-mes-box-p2p">
                        <div class="div-in-mes-head">{{emailobj?.subject}}</div>
                        <div class="div-in-mes-to">FROM: {{emailobj?.from_email}}</div>
                        <div class="div-in-mes-content p-mb-none"> {{emailobj.body}}</div>
                    </div>
                    <div class="div-in-mes-time">
                        {{ emailobj.created_on | utcToLocalTime:'customFullDateTime' }}
                      </div>
                </div>
                <div class="col-md-1 div-in-avatar">
                    <!-- <img src="{{environment.imagePrefixURI}}/avatar/tree.com.svg"> -->
                    <ngx-avatars [name]="emailobj.created_by" size="30"  initialsSize="2"></ngx-avatars>
                    <!-- <img src="./assets/image/avatar/{{emailobj.imageURL}}.png"> -->

                </div>
            </div>
            <div class="row"
            *ngIf="emailobj.type === 'TAGS'">
            <div class="col-md-1 div-mes-icon">
                <div class="div-mes-icon-body">
                    <div class="div-icon">
                        <i class="fal fa-tags la-icon"></i>
                    </div>
                    <div class="div-line">
                        <div></div>
                    </div>
                </div>
            </div>
            <div class="col-md-10">
                <div class="div-in-mes-box-p2p tags-box">
                    <div class="div-in-mes-content p-mb-none"> {{emailobj.body}}</div>
                </div>
                <div class="div-in-mes-time">
                    {{ emailobj.created_on | utcToLocalTime:'customFullDateTime' }}
                  </div>
            </div>
            <div class="col-md-1 div-in-avatar">
                <!-- <img src="./assets/image/avatar/{{emailobj.imageURL}}.png"> -->
                <ngx-avatars [name]="emailobj.created_by" size="30"  initialsSize="2"></ngx-avatars>

            </div>
        </div>
        <div class="row"
        *ngIf="emailobj.type === 'PAYMENTS'">
        <div class="col-md-1 div-mes-icon">
            <div class="div-mes-icon-body">
                <div class="div-icon">
                    <i class="fa-kit fa-payment-paid la-icon"></i>
                </div>
                <div class="div-line">
                    <div></div>
                </div>
            </div>
        </div>
        <div class="col-md-10">
            <div class="div-in-mes-box-paid">
                <div class="div-in-mes-head">{{emailobj?.subject}}<i class="fa-kit fa-payment paid-icon-clr"></i></div>
                <!-- <div class="div-in-mes-head">{{emailobj?.subject}}</div> -->
                <div class="div-in-mes-content p-mb-none"> {{emailobj.body}}</div>
            </div>
            <div class="div-in-mes-time">
                {{ emailobj.created_on | utcToLocalTime:'customFullDateTime' }}
              </div>
        </div>
        <div class="col-md-1 div-in-avatar">
            <!-- <img src="{{environment.imagePrefixURI}}/avatar/tree.com.svg"> -->
            <ngx-avatars [name]="emailobj.created_by" size="30"  initialsSize="2"></ngx-avatars>
            <!-- <img src="./assets/image/avatar/{{emailobj.imageURL}}.png"> -->

        </div>
    </div>
                <div class="row" *ngIf="emailobj.type === 'remainder'">
                    <div class="col-md-1"></div>
                    <div class="col-md-10">
                        <div class="div-in-mes-box">
                            <div class="div-in-mes-head mb-0"><i
                                    class="fal fa-envelope la-icon"></i>{{emailobj?.subject}}</div>
                        </div>
                        <div class="div-in-mes-time">
                            <!-- {{emailobj.email_time | date:'shortTime'}} -->
                            {{ emailobj.created_on | utcToLocalTime:'customFullDateTime' }}
                        </div>
                    </div>
                    <div class="col-md-1 div-in-avatar">
                        <ngx-avatars [name]="emailobj.to_email" size="30"  initialsSize="2"></ngx-avatars>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="container no-activity-container" *ngIf="emaildata?.length === 0">
        <ng-container >
            <div>
                <div class="d-flex justify-content-center">
                <img src="{{environment.imagePrefixURI}}/bg-image/no-mail.svg" alt="noActivities" />
                </div>
                <div class="d-flex justify-content-center">
                    <p class="no-activity-txt">No Activities Available</p>
                </div>
            </div>
        </ng-container>
    </div>
    <ng-container>
        <!-- <div class="row">
            <div class="col-md-12 text-right">
                <button class="btn btn-mes-send" type="button"
                    (click)="showcompose()">Compose</button>
            </div>
        </div> -->
        <div class="div-text-mes" *ngIf="composeMail">
            <div class="div-text-content">
                <div class="row">
                    <div class="col-md-12">
                        <div class="div-to">
                            <span>To</span>
                            <input type="text" />
                            <button type="button" class="" (click)="showbcc()">Bcc</button>
                            <button type="button" class="" (click)="showcc()">Cc</button>
                        </div>
                        <div class="div-cc-bcc" *ngIf="mailCc">
                            <span>Cc</span>
                            <input type="text" />
                            <button type="button" class="" (click)="cancelcc()"><i
                                    class="fal fa-times"></i></button>
                        </div>
                        <div class="div-cc-bcc" *ngIf="mailBcc">
                            <span>Bcc</span>
                            <input type="text" />
                            <button type="button" class="" (click)="cancelbcc()"><i
                                    class="fal fa-times"></i></button>
                        </div>
                        <div class="div-sub">
                            <input placeholder="Subject" type="text" />
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="control-section">
                            <ejs-richtexteditor id="mention_integration"
                                placeholder="Type @ and tag the name"
                                (actionBegin)="onActionBegin($event)">
                                <ng-template #valueTemplate>
                                    <p>Hello <span contenteditable="false" class="e-mention-chip"><a
                                                title="maria@gmail.com">@Maria</a></span>&#8203;</p>
                                    <p>Welcome to the mention integration with rich text editor
                                        demo. Type
                                        <code>@</code> character and tag user from the suggestion
                                        list.
                                    </p>
                                </ng-template>
                            </ejs-richtexteditor>
                        </div>

                        <ejs-mention [dataSource]='data' target='#mention_integration_rte-edit-view'
                            [fields]='fieldsData' [suggestionCount]="8" [showMentionChar]="false"
                            [allowSpaces]="true" popupWidth='250px' popupHeight='200px'>
                            <ng-template #itemTemplate let-data>
                                <table>
                                    <tr>
                                        <td>
                                            <div id="mention-TemplateList">
                                                <img class="mentionEmpImage"
                                                    src="./assets/rich-text-editor/images/{{data.Eimg}}.png"
                                                    alt="employee" />
                                                <span
                                                    class="e-badge e-badge-success e-badge-overlap e-badge-dot e-badge-bottom {{data.Status}}"></span>
                                            </div>
                                        </td>
                                        <td class="mentionNameList">
                                            <span class="person">{{data.Name}}</span>
                                            <span class="email">{{data.EmailId}}</span>
                                        </td>
                                    </tr>
                                </table>
                            </ng-template>
                            <ng-template #displayTemplate let-data>
                                <a title="{{data.EmailId}}">@{{data.Name}}</a>
                            </ng-template>
                        </ejs-mention>
                    </div>
                    <div class="col-md-12 text-right">
                        <button class="btn btn-cancel" (click)="composecancel()">Cancel</button>
                        <button class="btn btn-numero">Send <i
                                class="fab fa-telegram-plane"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>




<div class="div-back-drop" *ngIf="showBackDrop"></div>

<!-- Backdrop - Ends -->

<!-- Right side Email Box - Starts -->
<div class="div-right-side" [ngClass]="{'slide-left': showComposeEmail}">
    <div class="row">
      <div class="col-md-12">
        <div class="div-vendor-head">
          Create Email
        </div>
        <div class="div-close" (click)="closeEmailDrilldown()">
          <i class="fal fa-times la-icon-size"></i>
        </div>
      </div>
    </div>
    <div class="div-body-content">
        <div class="div-text-mes">
            <div class="div-text-content">
                <div class="row">
                    <div class="col-md-12">
                        <div>
                            <form [formGroup]="subjectForm">
                                <div class="div-sub">
                                    <input formControlName="cc" placeholder="Cc" type="text"/>
                                </div>
                                <mat-chip-listbox aria-label="Dog selection">
                                    <mat-chip *ngFor="let email of emailCCList" (click)="appendCCMail(email)">{{email.name}}</mat-chip>
                                </mat-chip-listbox>
                                <div class="div-sub">
                                    <input formControlName="subject" placeholder="Subject" type="text"  maxlength="60"/>
                                </div>
                                <div class="invoice-txt d-flex mt-4 mb-0" *ngIf="selectedArSource?.source_name === 'chargebee' || selectedArSource?.source_name === 'quickbooks'">
                                    <input type="checkbox" class="me-1" formControlName="attachInvoice"><span class="attach-invoice-text">Attach Invoice</span>
                                </div>
                              </form>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="control-section">
                            <ejs-richtexteditor #editor [toolbarSettings]='toolbarSettings'
                                placeholder="Enter the email content"
                                (valueChange)="onEditorContentChange()"
                                (keypress)="onKeyPressmail($event)"
                                [(value)]="editorContent"
                                class="dunning-te"
                                [ngClass]="{'invalid-border' : invalidBorder}">
                            </ejs-richtexteditor>
                        </div>

                        <!-- <ejs-mention [dataSource]='data' target='#mention_integration_rte-edit-view'
                            [fields]='fieldsData' [suggestionCount]="8" [showMentionChar]="false"
                            [allowSpaces]="true" popupWidth='250px' popupHeight='200px'>
                            <ng-template #itemTemplate let-data>
                                <table>
                                    <tr>
                                        <td>
                                            <div id="mention-TemplateList">
                                                <img class="mentionEmpImage"
                                                    src="./assets/rich-text-editor/images/{{data.Eimg}}.png"
                                                    alt="employee" />
                                                <span
                                                    class="e-badge e-badge-success e-badge-overlap e-badge-dot e-badge-bottom {{data.Status}}"></span>
                                            </div>
                                        </td>
                                        <td class="mentionNameList">
                                            <span class="person">{{data.Name}}</span>
                                            <span class="email">{{data.EmailId}}</span>
                                        </td>
                                    </tr>
                                </table>
                            </ng-template>
                            <ng-template #displayTemplate let-data>
                                <a title="{{data.EmailId}}">@{{data.Name}}</a>
                            </ng-template>
                        </ejs-mention> -->
                    </div>
                    <div class="col-md-12">
                        <button (click)="sendMessage()" [disabled]="!subjectForm.valid" class="btn btn-numero">Send <i
                                class="fab fa-telegram-plane"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Right side Email Box - Ends -->

<!-- Right side Note Box - Starts -->
<div class="div-right-side" [ngClass]="{'slide-left': showCreateNote}">
    <div class="row">
      <div class="col-md-12">
        <div class="div-vendor-head">
          Create Note
        </div>
        <div class="div-close" (click)="closeCreateNoteDrilldown()">
          <i class="fal fa-times la-icon-size"></i>
        </div>
      </div>
    </div>
    <div class="div-body-content">
        <div class="div-text-mes">
            <div class="div-text-content">
                <div class="row">
                    <div class="col-md-12">
                        <div class="control-section">
                            <ejs-richtexteditor #editor [toolbarSettings]='toolbarSettings'
                                placeholder="Type @ and tag the name"
                                [(value)]="notesContent"
                                (valueChange)="onNotesContentChange()"
                                (keypress)="onKeyPress($event)"
                                class="dunning-te"
                                [ngClass]="{'invalid-border' : invalidBorder}"
                                >
                            </ejs-richtexteditor>
                        </div>

                        <!-- <ejs-mention [dataSource]='data' target='#mention_integration_rte-edit-view'
                            [fields]='fieldsData' [suggestionCount]="8" [showMentionChar]="false"
                            [allowSpaces]="true" popupWidth='250px' popupHeight='200px'>
                            <ng-template #itemTemplate let-data>
                                <table>
                                    <tr>
                                        <td>
                                            <div id="mention-TemplateList">
                                                <img class="mentionEmpImage"
                                                    src="./assets/rich-text-editor/images/{{data.Eimg}}.png"
                                                    alt="employee" />
                                                <span
                                                    class="e-badge e-badge-success e-badge-overlap e-badge-dot e-badge-bottom {{data.Status}}"></span>
                                            </div>
                                        </td>
                                        <td class="mentionNameList">
                                            <span class="person">{{data.Name}}</span>
                                            <span class="email">{{data.EmailId}}</span>
                                        </td>
                                    </tr>
                                </table>
                            </ng-template>
                            <ng-template #displayTemplate let-data>
                                <a title="{{data.EmailId}}">@{{data.Name}}</a>
                            </ng-template>
                        </ejs-mention> -->
                    </div>
                    <div class="col-md-12">
                        <button (click)="sendNotes()" class="btn btn-numero" >Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Right side Note Box - Ends -->

<!-- PROMISE TO PAY -->
<!-- Right side Note Box PROMISE TO PAY - Starts -->
<div class="div-right-side" [ngClass]="{'slide-left': showPromise}">
    <div class="row">
      <div class="col-md-12">
        <div class="div-vendor-head mb-16">
            Promise to Pay
            <span class="p2p-edit-btn ms-1 cursor-pointer"  (click)="onEditButtonClick()" *ngIf="invoiceData?.p2pKey"><i class="fal fa-pen fa-sm me-1"></i>Edit</span>
        </div>
        <div class="div-close" (click)="closePromiseDrilldown()">
          <i class="fal fa-times la-icon-size"></i>
        </div>
      </div>
    </div>

    <div class="row">
        <div class="col-md-12">
          <div class="invoice-txt">
            Invoice no #{{invoiceData?.documentNumber}}
          </div>
        </div>
    </div>

      <div class="row">
        <div class="col-md-12">
          <div>
            <p class="amount-txt">{{ invoiceData?.balance | currencypipe:invoiceData?.currencyId}}</p>
          </div>
        </div>
      </div>

    <app-promise-to-pay [invoiceKey]="invoiceData?.invoiceKey"
     (onSubmitSuccess)="closePromiseDrilldown()" (getMailDetailsEmitter)="getMailDetailsFromChild()"></app-promise-to-pay>
</div>
<!-- Right side Note Box PROMISE TO PAY - Ends -->

<!-- TAGS -->
<!-- Right side Note Box TAGS - Starts -->
<div class="div-right-side" [ngClass]="{'slide-left': showPayment}">
    <div class="row">
      <div class="col-md-12">
        <div class="div-vendor-head mb-16">
            Tags
        </div>
        <div class="div-close" (click)="closePaymentDrilldown()">
          <i class="fal fa-times la-icon-size"></i>
        </div>
      </div>
    </div>

    <div class="row">
        <div class="col-md-12">
          <div class="invoice-txt">
            Invoice no #{{invoiceData?.documentNumber}}
          </div>
        </div>
    </div>

      <div class="row">
        <div class="col-md-12">
          <div>
            <p class="amount-txt">{{ invoiceData?.balance | currencypipe:invoiceData?.currencyId}}</p>
          </div>
        </div>
      </div>
 
      <div class="invoice-txt d-flex" *ngFor="let item of paymentTagData">
        <input type="radio" class="me-1"  [(ngModel)]="selectedTag" [value]="item.tag">  {{ item.tag }}
      </div>

    <form>
        <div class="amount-txt d-flex" style="flex-direction: column-reverse;">
            <p class="amount-txt d-flex" (click)="toggleNewTag()"  type="submit"><span><i class="fal fa-plus fa-sm"></i> Add
                    New</span></p>
            <br>
            <div *ngIf="showNewTagInput">
                <!-- <input type="radio" style="margin-right: 5px;"> -->
                <input type="text" [(ngModel)]="newTagText" name="newTagText" placeholder="Add tag name"
                    style="border: none; background: none;">
            </div>
        </div>

        <div class="d-flex justify-content-center">
            <button class="btn btn-numero" *ngIf="!selectedTag ||  newTagText !== ''" [disabled]="newTagText === ''"  (click)="saveTagData()">Save</button>
            <button class="btn btn-numero" *ngIf="selectedTag && newTagText  === ''" (click)="updateTagData()">Update</button>
        </div>
    </form>
</div>
<!-- Right side Note Box - Ends -->
