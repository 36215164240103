import { AfterViewInit, Component, ViewChild } from '@angular/core';
import {
  PageService,
  PageSettingsModel,
  QueryCellInfoEventArgs,
  RowDataBoundEventArgs,
  ToolbarService,
  ExcelExportService,
  PdfExportService
} from '@syncfusion/ej2-angular-grids';
import { ITreeData, TreeGridExcelExportProperties, TreeGridPdfExportProperties } from '@syncfusion/ej2-treegrid';
import { TreeGridComponent } from '@syncfusion/ej2-angular-treegrid';
import { ReportService } from 'app/service/report/report.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { JWTToken } from 'app/core/service/auth/auth';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { Chart } from 'angular-highcharts';
import { GrowthbookService } from 'app/core/service/growthBook/growthbook.service';
import { DatePipe } from '@angular/common';
import { CalendarView, DateRangePickerComponent } from '@syncfusion/ej2-angular-calendars';

@Component({
  selector: 'app-balance-sheet',
  templateUrl: './balance-sheet.component.html',
  styleUrls: ['./balance-sheet.component.scss'],
  providers: [ ToolbarService, PageService, ExcelExportService, PdfExportService,DatePipe]
})
export class BalanceSheetComponent  implements AfterViewInit {
  constructor(
    private _reportService: ReportService,
    public authServices: AuthServices,
    private loader: LoaderService,
    // public growthBookServ: GrowthbookService,
    public datepipe: DatePipe,
  ) {}

  @ViewChild('datePicker') datePicker: DateRangePickerComponent;
  @ViewChild('treegrid') public treegrid: TreeGridComponent;
  public gridData: Object[] = [];
  public pageSettings: PageSettingsModel;
  public toolbar: string[];
  public tableHeader = [];
  public timeranges = [{name: 'YTD', end_date: '2023-05-20' , start_date : '2023-01-01'}];
  public selectedPeriod = {name: 'YTD', end_date: '2023-05-20' , start_date : '2023-01-01'};
  public customAttributes: Object;
  public selectedAccount = 'airbox';
  public height: number;
  public screenHeight: any;
  jwtToken: JWTToken = undefined;
  public collapseStatePersist: boolean = true;
  public totalAccPay = 288595;
  public totalLia = 239466;
  public totalEquity = 49129;
  public assetData: Object[] = [];

  public assetsbarchart: Chart;
  public liabarchart: Chart

  // public selPeriod = "yeartodate";
  // public timePeriod = [
  //   {name:"Year-to-date", value: 'yeartodate'},
  //   {name: "Quarter-to-date", value: 'quartertodate'},
  //   {name: "Last Quarter", value: 'lastquarter'},
  //   {name: "Last Month", value: 'lastmonth'}
  // ];
  public selAcc = {name : '', value : '',currency:''};
  public acc = [];
  public accounts = [
    {name: "Airbox", value: 'airbox'},
    {name: "Mindtickle", value: 'mindtickle'}
  ];
  public selExport = "excel";
  public exports = [
    {name: "Excel", value:'excel'},
    {name: "Pdf", value:'pdf'}
  ];

  public showChart = false;

  public totBankAcc = '0';
  public totAR = '0';
  public totFixedAssets = '0';
  public totAssets = '0';

  public totAccPayable = '0';
  public totLia = '0';
  public totEquity = '0';
  public totLiaEquity = '0';

  public assetscat = [];
  public assetsdata = [];

  public liacat = [];
  public liadata = [];

  public start: CalendarView = 'Year';
  public depth: CalendarView = 'Year';
  public format: string = 'MMM yyyy';

  public startDate : any;
  public endDate : any;

  //  public growthBookCustom = this.growthBookServ.getGBookStatus('reports_custom_date');

  ngOnInit() {
    this.loader.show();
    this.pageSettings = { pageSize: 10 };
    this.customAttributes = { class: 'customcss' };
    this.fetchTimeRange();
    this.screenHeight = window.innerHeight;
  }

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   this.treegrid.grid.height = 500;
    // },1000);
  }

  exportexcelpdf() {
    if(this.selExport === "excel") {
      this.excelDownload();
    } else if(this.selExport === "pdf") {
      this.pdfDownload();
    }
  }

  pdfDownload() {
    let pdfExportProperties: TreeGridPdfExportProperties = {
      isCollapsedStatePersist: this.collapseStatePersist
  };
    setTimeout(() => {
      this.treegrid.pdfExport(pdfExportProperties);
    }, 1000);
  }

  excelDownload() {
    let excelExportProperties: TreeGridExcelExportProperties = {
      isCollapsedStatePersist: this.collapseStatePersist
  };
    setTimeout(() => {
      this.treegrid.excelExport(excelExportProperties);
    }, 1000);
  }

  queryCellInfo(args: QueryCellInfoEventArgs) {
    if (!(args.data as ITreeData).hasChildRecords) {
      if ((args.cell as HTMLElement).classList.contains('e-unboundcell')) {
        (
          (args.cell as HTMLElement).querySelector(
            '.e-unboundcelldiv'
          ) as HTMLElement
        ).style.display = 'none';
      }
    }
  }
  rowDataBound(args: RowDataBoundEventArgs) {
    if (!(args.data as ITreeData).hasChildRecords) {
      // (args.row as HTMLElement).style.backgroundColor = 'green';
      (args.row as HTMLElement).classList.add('child-row');
    }
    (args.row as HTMLElement).classList.add('class-level-' + (args.data as ITreeData).level);
  }
  dataBound(event): void {
    this.treegrid.grid.hideScroll();
  }

  async fetchTimeRange() {
    this.jwtToken = await this.authServices.getJWTDetail();
    this._reportService
      .getBalanceSheetFilter(this.jwtToken.org.id)
      .subscribe(
        (data) => {
          this.acc = data.accounts;
          this.timeranges = data.timerange;
          this.selectedPeriod = this.timeranges[0];
          // if (this.growthBookCustom) {
            this.timeranges.push({ name:'Custom',end_date: this.endDate, start_date: this.startDate});
          // }
          if(this.acc.length > 0) {
            this.selAcc = this.acc.filter(d=> d.value === this._reportService.getAccount().value)[0]
          }
          this.fetchBalanceSheet()
        },
        (err) => {
          this.loader.hide();
        }
      );

  }

  async fetchBalanceSheet() {
    this._reportService.setAccount(this.selAcc)
    this.loader.show();
    let assetcat = [];
    let assetdata = [];
    let liacat = [];
    let liadata = [];
    this._reportService
      .getBalanceSheet(this.jwtToken.org.id, this.selectedPeriod.start_date, this.selectedPeriod.end_date, this.selAcc.value)
      .subscribe(
        (data) => {
          this.treegrid.refresh()
          this.tableHeader = data.header;
          this.gridData = data.data;
          this.treegrid.dataSource = this.gridData;
          if(data.asset) {
            this.totAssets = data.asset.amount;
            this.totBankAcc = data.asset.subItemList[0].amount;
            this.totAR = data.asset.subItemList[1].amount;
            this.totFixedAssets = data.asset.subItemList[2].amount;
            data.asset.subItemList.forEach((aD:any) =>{
              assetcat.push(aD.name);
              assetdata.push(parseFloat(aD.amount));
            })
          }

          if(data.liablityAndEquity) {
            this.totLiaEquity = data.liablityAndEquity.amount;
            this.totAccPayable = data.liablityAndEquity.subItemList[0].amount;
            this.totLia = data.liablityAndEquity.subItemList[1].amount;
            this.totEquity = data.liablityAndEquity.subItemList[2].amount;
            data.liablityAndEquity.subItemList.forEach((liaD:any) =>{
              liacat.push(liaD.name);
              liadata.push(parseFloat(liaD.amount));
            })
          }
          setTimeout(() => {
            this.treegrid.expandAtLevel(0);
          }, 1000);

          this.assetsbarchart = this.createChartLine(assetcat, assetdata);
          this.liabarchart = this.createChartLine(liacat, liadata);
          this.loader.hide();
        },
        (err) => {
          this.loader.hide();
        }
      );
  }

  public createChartLine(category , chartData): any {
      const barchart = new Chart({
      chart: {
        type: 'column',
        backgroundColor: 'transparent'
      },
      title: {
        text: '',
      },
      yAxis: {
        title: {
          text: null,
        },
        gridLineDashStyle: 'longdash',
        gridLineWidth: 1,
        labels: {
          style: {
            color: '#8D8F95',
            fontSize: '14px',
            fontFamily: 'Inter,sans-serif',
          }
        },
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: category,
        labels: {
          style: {
            color: '#8D8F95',
            fontSize: '14px',
            fontFamily: 'Inter,sans-serif',
          }
        },
        lineWidth: 0
      },
      tooltip: {
            headerFormat: `<div style="font-family: Inter, sans-serif"><strong>{point.key}</strong></div>`,
            pointFormat: `<div style="font-family: Inter, sans-serif"><strong>{point.key}</strong>{point.y}</div>`,
            shared: true,
            useHTML: true,
          },
          colors: [
            '#4D81E5',
            '#E7854D',
            '#32B19A',
            '#80699B',
            '#3D96AE',
            '#DB843D',
            '#92A8CD',
            '#A47D7C',
            '#B5CA92'
            ],
            plotOptions: {
              bar: {
                borderRadius: 5,
                visible: true,
                stacking: 'normal'
              },
              column: {
                borderRadius: 5,
                visible: true,
                stacking: 'normal',
                colorByPoint: true
              },
            },
      series: [
        {
          showInLegend: false,
          data: chartData,
          maxPointWidth: 25,
        }
      ]
    } as any);
    return barchart;
  }

  hidechart() {
    this.showChart = false;
  }
  showchart() {
    this.showChart = true;
  }

  dropdownChangedEvent(selaccount) {
    this.selAcc = selaccount;
    this.fetchBalanceSheet();
  }

  dropdownTimeChangedEvent(seltime) {
    this.selectedPeriod = seltime;
    if (this.selectedPeriod.name !== 'Custom') {
      this.fetchBalanceSheet();
    } else {
      this.openDatePicker();
    }
  }

  openDatePicker() {
    setTimeout(() => {
      this.datePicker.show();
    }, 1);
  }

  onDateChange(event: any) {
    if (event && event.value) {
      const selectedDates = event.value;
      this.startDate = this.datepipe.transform(selectedDates[0], 'yyyy-MM-dd');
      this.endDate = this.datepipe.transform(selectedDates[1], 'yyyy-MM-dd');
      this.selectedPeriod = {
        name: 'Custom',
        end_date: this.endDate,
        start_date: this.startDate,
      };
      this.fetchBalanceSheet();
    }
  }
}
