import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { catchError } from 'rxjs/operators';
import { handleError } from '../core/handle-error';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {
  constructor(private http: HttpClient) { }

  getWorkflows = (entityKey: string) : Observable<any> => {
    return this.http
      .get(`${environment.server.baseURL}/workflow?entity_key=${entityKey}`)
      .pipe(catchError(handleError));
  }

  initializeN8N = async (loggedInUserEmail: string, domainName: string) : Promise<void> => {
    try {
      let isLoggedIn = await this.isLoggedIn();
    } catch(err) {
      let token = await this.getN8NCookie(loggedInUserEmail, domainName);
      let response = await this.setCookieinWorkflowDomain(token);
    }
  }

  getN8NCookie = async (email: string, domainName: string) : Promise<string | undefined> => {
    let response = await this.http.get(
      environment.server.baseURL + `/n8n/login?user_email=${email}&domain_name=${domainName}`, { responseType: 'text' }
    ).toPromise();
    return response;
  }

  setCookieinWorkflowDomain = async(token) : Promise<any> => {
    return await this.http.post(environment.n8n.baseURL +`/rest/authentication?token=${token}`, null, {withCredentials: true}).toPromise();
  }

  isLoggedIn = async(): Promise<Object | undefined> => {
    return await this.http.get(
      `${environment.n8n.baseURL}/rest/login`
    ).toPromise();
  }

  getConfigurations = (entityKey: string): Observable<any> => {
    return this.http.get(environment.server.baseURL + `/config?entityId=` + entityKey);
  }

  toggleWorkflow = (key: string, entityKey: string) : Observable<any> => {
    return this.http.get(environment.server.baseURL + `/workflow/toggle?entityKey=` + entityKey + `&key=${key}`);
  }

  searchFlows = (category:string, tag: string, entityKey: string): Observable<any> => {
    return this.http.get(`${environment.server.baseURL}/search?filter_type=${category}&filter_value=${tag}&entity_id=${entityKey}`)
  }

  createWorkflowInN8N = (key: string, entityKey: string, userEmail) : Observable<any> => {
    return this.http.get(environment.server.baseURL + `/config/connect?entityKey=${entityKey}&key=${key}&createdBy=${userEmail}`);
  }

  getFilterCategories = (): Observable<any> => {
    return this.http.get(environment.server.baseURL + `/categories`)
  }

  getExecutionDashboardData= (entityId) : Observable<any> =>{
    return this.http.get(environment.server.baseURL +`/workflow/execution?entity_key=${entityId}`)
  }

  getWorkflowEngineUrl = (id: string) : string => {
    return `${environment.n8n.baseURL}/workflow/${id}`
  }

  deleteWorkflow = (entityKey: string, workflowId: string) : Observable<any> => {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http
      .delete(`${environment.server.baseURL}/webhook/workflow?entity_id=${entityKey}&workflow_id=${workflowId}`, httpOptions)
  }


  
}
