<!-- https://stackblitz.com/run?file=src%2Fapp.component.ts -->
<div class="div_profit_loss">
  <div class="div_chart_sec">
    <div class="div_filter">
      <div class="row">
        <div class="col-md-6 d-flex">
          <!-- <select
            class="form-select sel-cust"
            [(ngModel)]="selectedPeriod"
            (change)="fetchCashFlow()"
          >
            <option *ngFor="let obj of timeranges" [ngValue]="obj">
              {{ obj.name }}
            </option>
          </select> -->
          <div class="div-cust-dropdown-time">
            <app-numero-dropdown [dropdownlist]="timeranges" [selAcc]="selectedPeriod" [type]="'none'"  [setWidth]="'none'" (dropdownSelValue)="dropdownTimeChangedEvent($event)"></app-numero-dropdown>
          </div>
          <div class="div-sel-period" *ngIf="selectedPeriod.name !== 'Custom'">
            {{ selectedPeriod.start_date | date : "MMM dd, yyyy" }}
            <i class="fas fa-arrow-right"></i
            >{{ selectedPeriod.end_date | date : "MMM dd, yyyy" }}
          </div>
          <div class="div-sel-period cust-month-picker cursor-pointer" *ngIf="selectedPeriod.name === 'Custom'">
            <ejs-daterangepicker #datePicker [start]='start' [format]="format" [depth]='depth' class="hidden-field" (change)="onDateChange($event)"></ejs-daterangepicker>
            <span class="cntr-align" (click)="openDatePicker()">
              <i class="far fa-calendar-alt fts-cal-icon"></i>
              <span style="padding: 0px 6px;">Select Month</span>
              <i class="fak fa-vector fts-vector-icon"></i>
            </span>
          </div>
        </div>
        <div class="col-md-6 div-export-sec">
          <!-- <select
            class="form-select sel-cust"
            [(ngModel)]="selAcc"
            (change)="fetchCashFlow()"
          >
            <option *ngFor="let obj of accounts" [ngValue]="obj">
              {{ obj.name }}
            </option>
          </select> -->
          <div class="div-cust-dropdown">
            <app-numero-dropdown [dropdownlist]="accounts" [selAcc]="selAcc" [type]="'image'"  [setWidth]="'dynamic'" (dropdownSelValue)="dropdownChangedEvent($event)"></app-numero-dropdown>
          </div>
          <div
            class="div-export-pdf-excel"
            ngbDropdown
            container="body"
            routerLinkActive="isActive"
            display="dynamic"
            placement="bottom-end"
          >
            <div class="div-export-btn" ngbDropdownToggle>
              <i class="fak fa-numero-download"></i>
            </div>
            <div class="div-export-dropdown" ngbDropdownMenu>
              <div>
                <button title="Export Excel" (click)="excelDownload()">
                  <img src="./assets/image/excel.svg" /> Excel
                </button>
              </div>
              <div class="mtop_06">
                <button title="Export PDF" (click)="pdfDownload()">
                  <img src="./assets/image/pdf.svg" /> PDF
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="div-chart">
      <div class="row">
        <div class="col-md-12">
          <div class="div-chart-cust-legend alignjustify">
            <div class="div-cash-in">
              <div class="div-cash-title ff-ngc">Cash Inflow</div>
              <div class="div-amt text-center">{{ cashInflow | currencypipe: this.selAcc.currency }}</div>
            </div>

            <div class="div-op text-center self-center">
              <span class="div-operator">-</span>
            </div>

            <div class="div-cash-in">
              <div class="div-cash-title ff-ngc">Cash Outflow</div>
              <div class="div-amt text-center">{{ cashOutflow | currencypipe: this.selAcc.currency }}</div>
            </div>

            <div class="div-op text-center self-center">
              <span class="div-operator">=</span>
            </div>

            <div class="div-cash-in">
              <div class="div-cash-title ff-ngc">Operating cashflow</div>
              <div class="div-amt text-center">
                {{ operatingCashflow | currencypipe: this.selAcc.currency }}
              </div>
            </div>

            <div class="div-arrow-btns text-right">
              <button
                class="btn-acc"
                type="button"
                (click)="hidechart()"
                *ngIf="showChart"
              >
                <i class="fas fa-chevron-up"></i>
              </button>
              <button
                class="btn-acc"
                type="button"
                (click)="showchart()"
                *ngIf="!showChart"
              >
                <i class="fas fa-chevron-down"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="showChart">
        <div class="col-md-12" style="height: 300px">
          <ejs-chart
            style="display: block"
            (axisLabelRender)="OnChartAxisLabeRender($event)"
            [chartArea]="chartArea"
            [width]="width"
            align="center"
            [legendSettings]="legendSettings"
            [tooltip]="tooltip"
            id="chart-vertical"
            [primaryXAxis]="primaryXAxis"
            [primaryYAxis]="primaryYAxis"
            [palettes]="palette"
            [axes]="axis"
            [title]="title"
            [isTransposed]="vertical"
            (load)="load($event)"
          >
            <e-series-collection>
              <e-series
                *ngFor="let chartitem of chartData"
                [dataSource]="chartitem.data"
                [type]="chartitem.type"
                xName="year"
                yName="column"
                [name]="chartitem.name"
                columnSpacing="0.5"
                columnWidth="0.5"
                [cornerRadius]="cornerRadius"
                [animation]="animation1"
                [marker]="chartitem.marker"
              >
              </e-series>
            </e-series-collection>
          </ejs-chart>
        </div>
      </div>
    </div>
  </div>
  <div [ngClass]="{'col-md-12': setdateperiod === 'twelwe', 'col-md-910': setdateperiod === 'two', 'col-md-1110': setdateperiod === 'three', 'col-md-710': setdateperiod === 'one' }">
  <div class="div-table-pl">
    <div class="control-section">
      <ejs-treegrid
        #treegrid
        [allowResizing]='true'
        [dataSource]="formatedData"
        sizeSettings.height="auto"
        [frozenColumns]="1"
        hideScroll="true"
        childMapping="subAccount"
        [(height)]="height"
        [treeColumnIndex]="0"
        [toolbar]="toolbar"
        [allowSelection]="false"
        [pageSettings]="pageSettings"
        enableCollapseAll="true"
        (rowDataBound)="rowDataBound($event)"
        (dataBound)="dataBound($event)"
        (queryCellInfo)="queryCellInfo($event)"
        allowExcelExport="true"
        allowPdfExport="true"
      >
        <e-columns>
          <ng-container *ngFor="let col of tableHeader">
            <e-column
              *ngIf="col.fieldName === 'accountName'"
              field="{{ col.fieldName }}"
              headerText="{{ col.displayName }}"
              width="300"
              [freeze]="Left"
              textAlign="{{ col.textAlign }}"
              [customAttributes]="customAttributes"
            ></e-column>
            <e-column
              *ngIf="col.fieldName !== 'accountName'"
              field="{{ col.fieldName }}"
              headerText="{{ col.displayName }}"
              width="{{ col.width }}"
              textAlign="{{ col.textAlign }}"
              [customAttributes]="customAttributes"
            ></e-column>
          </ng-container>
        </e-columns>
      </ejs-treegrid>
    </div>
  </div>
  </div>
</div>
