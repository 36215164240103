<div class="section">
  <div class="div-numero-container">
      <div class="row">
          <div class="col-md-7 div-login-left-side">
              <div class="row">
                  <div class="col-md-12 div-left-login">

                      <div class="div-logo">
                          <img  src="{{environment.imagePrefixURI}}/utill/GoConstant.svg" alt="image"/>
                      </div>
                      <div class="div-login-header">
                          Finance Operations Automation Platform
                      </div>
                      <div class="div-login-desc">
                          <p>Measure, Act and Automate with a no-code platform</p>
                          <p>Built for Accountants</p>
                      </div>
                      <div class="div-login-img">
                        <img src="{{environment.imagePrefixURI}}/utill/login-right.svg.svg" alt="image"/>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-md-5 div-login-right-side">
             <div class="row">
                    <div class="col-md-12 div-login-box">
                        <div class="div-login-box-cen">
                            <div class="wid50">

                                <div class="row mb-4" >
                                    <div class="col-md-12">
                                        <div class="div-login-txt">
                                            <h3>Change Password </h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <form [formGroup]="confirmPasswordForm" (submit)="confirmPassword()">
                                            <div class="form-group">
                                                <label>Password</label>
                                                <input type="password" maxlength="50" class="form-style" placeholder="New Password" formControlName="password" (focusin)="show = true" (focusout)="show = false" [ngClass]="{ 'is-invalid': confirmPass.password.errors }">
                                                <i class="input-icon uil uil-at"></i>
                                                <div class="div-condition-popup" *ngIf="!confirmPasswordForm.valid && show">
                                                    <label class="col" [ngClass]="confirmPasswordForm.controls['password'].hasError('required') || confirmPasswordForm.controls['password'].hasError('minlength')  ? 'text-danger' : 'text-success'">
                                                    <i class="material-icons">{{ confirmPasswordForm.controls['password'].hasError('required') ||
                                                        confirmPasswordForm.controls['password'].hasError('minlength') ? 'cancel' :
                                                        'check_circle' }}</i>
                                                    Must be at least 8 characters!
                                                    </label>
                                                    <label class="col" [ngClass]="confirmPasswordForm.controls['password'].hasError('required') || confirmPasswordForm.controls['password'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                                                    <i class="material-icons">{{ confirmPasswordForm.controls['password'].hasError('required') ||
                                                        confirmPasswordForm.controls['password'].hasError('hasNumber') ? 'cancel' :
                                                        'check_circle' }}</i>
                                                    Must contain at least 1 number!
                                                    </label>
                                                    <label class="col" [ngClass]="confirmPasswordForm.controls['password'].hasError('required') || confirmPasswordForm.controls['password'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                                                    <i class="material-icons">{{ confirmPasswordForm.controls['password'].hasError('required') ||
                                                        confirmPasswordForm.controls['password'].hasError('hasCapitalCase') ? 'cancel' :
                                                        'check_circle' }}</i>
                                                    Must contain at least 1 in Capital Case!
                                                    </label>
                                                    <label class="col" [ngClass]="confirmPasswordForm.controls['password'].hasError('required') || confirmPasswordForm.controls['password'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
                                                    <i class="material-icons">{{ confirmPasswordForm.controls['password'].hasError('required') ||
                                                        confirmPasswordForm.controls['password'].hasError('hasSmallCase') ? 'cancel' :
                                                        'check_circle' }}</i>
                                                    Must contain at least 1 Letter in Small Case!
                                                    </label>
                                                    <label class="col" [ngClass]="confirmPasswordForm.controls['password'].hasError('required') || confirmPasswordForm.controls['password'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
                                                    <i class="material-icons">{{ confirmPasswordForm.controls['password'].hasError('required') ||
                                                        confirmPasswordForm.controls['password'].hasError('hasSpecialCharacters') ? 'cancel' :
                                                        'check_circle' }}</i>
                                                    Must contain at least 1 Special Character!
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="form-group mt-4 mb-4">
                                                <label>Confirm Password</label>
                                                <input type="password" maxlength="50" class="form-style" placeholder="Confirm Password" formControlName="confirmPassword" [ngClass]="{ 'is-invalid': confirmPass.confirmPassword.errors }">
                                                <i class="input-icon uil uil-lock-alt"></i>
                                                <label  class="text-danger" *ngIf="confirmPasswordForm.controls['confirmPassword'].hasError('NoPassswordMatch')">
                                                    Password do not match
                                                </label>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <button type="submit" class="btn btn-login mt-3 mb-2">Update</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
          </div>
      </div>
  </div>

</div>
