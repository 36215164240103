<!-- https://stackblitz.com/run?file=src%2Fapp.component.ts -->
<div class="div_profit_loss">
  <div class="col-md-12 div-top-menu" *ngIf="isDepartmentPLEnabled">
    <ng-container *ngFor="let tab of topmenu; let i = index">
        <button class="btn-menu" [ngClass]="{'active' : activeTab === tab.value}" type="button" (click)="filterSources(tab.value)"><p>{{tab.name}}</p></button>
    </ng-container>
  </div>
  <div class="div_chart_sec">
    <div class="div_filter">
      <div class="row">
        <div class="col-md-6 d-flex">
          <!-- <select class="form-select sel-cust" [(ngModel)]="selectedPeriod" (change)="fetchProfitandLoss()">
            <option *ngFor="let obj of timeranges" [ngValue]="obj">{{obj.name}}</option>
          </select> -->
          <div class="div-cust-dropdown-time">
            <app-numero-dropdown [dropdownlist]="timeranges" [selAcc]="selectedPeriod" [type]="'none'" [setWidth]="'none'" (dropdownSelValue)="dropdownTimeChangedEvent($event)"></app-numero-dropdown>
          </div>
          <div class="div-sel-period" *ngIf="selectedPeriod.name !== 'Custom'">{{selectedPeriod.start_date | date : "MMM dd, yyyy"}} <i class="fas fa-arrow-right"></i>{{selectedPeriod.end_date | date : "MMM dd, yyyy"}}</div>
          <div class="div-sel-period cust-month-picker cursor-pointer" *ngIf="selectedPeriod.name === 'Custom'">
          <ejs-daterangepicker #datePicker [start]='start' [format]="format" [depth]='depth' class="hidden-field" (change)="onDateChange($event)"></ejs-daterangepicker>
          <span class="cntr-align" (click)="openDatePicker()">
            <i class="far fa-calendar-alt fts-cal-icon"></i>
            <span style="padding: 0px 6px;">Select Month</span> 
            <i class="fak fa-vector fts-vector-icon"></i>
          </span>
        </div>
        </div>
        
        <div class="col-md-6 div-export-sec">
          <!-- <select class="form-select sel-cust" [(ngModel)]="selAcc" (change)="fetchProfitandLoss()">
            <option *ngFor="let obj of accounts" [ngValue]="obj">{{obj.name}}</option>
          </select> -->
          <div class="div-cust-dropdown">
            <app-numero-dropdown [dropdownlist]="accounts" [selAcc]="selAcc" [type]="'image'" [setWidth]="'dynamic'" (dropdownSelValue)="dropdownChangedEvent($event)"></app-numero-dropdown>
          </div>
          <button class="btn btn-inter-settings" type="button" (click)="showModalInterElimination()">
            <i class="fak fa-dataflo-settings"></i>
          </button>
          <div class="div-export-pdf-excel" ngbDropdown container="body" routerLinkActive="isActive" display="dynamic" placement="bottom-end">
            <div class="div-export-btn" ngbDropdownToggle>
              <i class="fak fa-numero-download"></i>
            </div>
            <div class="div-export-dropdown" ngbDropdownMenu>
              <div><button title="Export Excel" (click)="excelDownload()"><img src="./assets/image/excel.svg"> Excel</button></div>
              <div class="mtop_06"><button title="Export PDF" (click)="pdfDownload()"><img src="./assets/image/pdf.svg"> PDF</button></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="div-chart">
      <div class="row">
        <div class="col-md-12">
          <div class="div-chart-cust-legend alignjustify"><!-- [ngClass]="{'alignjustify': setdateperiod !== 'twelwe'}" -->
            <div class="div-tot-in">
              <div class="div-blue">
                <label class="container-checkbox ff-ngc">Total Income
                    <!-- <input type="checkbox" id="#totincomechart" checked name="income" value="income">
                    <span class="checkmark"></span> -->
                </label>
              </div>
              <div class="div-amt text-center" *ngIf="totalIncome">
                {{ totalIncome | currencypipe: this.selAcc.currency }}
              </div>
            </div>

            <div class="div-op text-center self-center">
              <span class="div-operator">-</span>
            </div>

            <div class="div-tot-cost">
              <div class="div-orange">
                <label class="container-checkbox ff-ngc">Cost of Goods Sold
                    <!-- <input type="checkbox" id="#costsoldchart" checked name="costsold" value="costsold">
                    <span class="checkmark"></span> -->
                </label>
              </div>
              <div class="div-amt text-center" *ngIf="cogs">
                {{cogs| currencypipe: this.selAcc.currency}}
              </div>
            </div>

            <div class="div-op text-center self-center">
              <span class="div-operator">-</span>
            </div>

            <div class="div-tot-cost">
              <div class="div-lightbrown">
                <label class="container-checkbox ff-ngc">Operating Expenses
                    <!-- <input type="checkbox" id="#opexchart" checked name="opexchart" value="opexchart">
                    <span class="checkmark"></span> -->
                </label>
              </div>
              <div class="div-amt text-center" *ngIf="operatingExpense">
                {{operatingExpense | currencypipe: this.selAcc.currency}}
              </div>
            </div>
            <div class="div-op text-center self-center">
              <span class="div-operator">-</span>
            </div>

            <div class="div-tot-cost">
              <div class="div-brown">
                <label class="container-checkbox ff-ngc">Other Expenses
                    <!-- <input type="checkbox" id="#otherExpenseChart" checked name="other-expense" value="other-expense">
                    <span class="checkmark"></span> -->
                </label>
              </div>
              <div class="div-amt text-center" *ngIf="otherExpense">
                {{otherExpense | currencypipe: this.selAcc.currency}}
              </div>
            </div>


            <div class="div-op text-center self-center">
              <span class="div-operator">=</span>
            </div>

            <div class="div-net-in">
              <div class="div-green">
                <label class="container-checkbox ff-ngc">Net Income
                    <!-- <input type="checkbox" id="#netincome" checked name="netincome" value="netincome">
                    <span class="checkmark"></span> -->
                  </label>
              </div>
              <div class="div-amt green text-center" *ngIf="netIncome">
                {{netIncome | currencypipe: this.selAcc.currency}}
              </div>
            </div>

            <div class="div-arrow-btns text-right" *ngIf="(activeTab === 'general')">
              <button class="btn-acc" type="button" (click)="hidechart()" *ngIf="showChart"><i class="fas fa-chevron-up"></i></button>
              <button class="btn-acc" type="button" (click)="showchart()" *ngIf="!showChart"><i class="fas fa-chevron-down"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="(showChart && activeTab === 'general')">
        <div class="col-md-12 div-pl-chart">
          <ejs-chart
            style="display: block"
            (axisLabelRender)="OnChartAxisLabeRender($event)"
            [chartArea]="chartArea"
            [width]="width"
            align="center"
            [legendSettings]="legendSettings"
            [tooltip]="tooltip"
            id="chart-vertical"
            [primaryXAxis]="primaryXAxis"
            [primaryYAxis]="primaryYAxis"
            [palettes]="palette"
            [axes]="axis"
            [title]="title"
            [isTransposed]="vertical"
            (load)="load($event)"
          >
            <e-series-collection>
              <e-series
                *ngFor="let chartitem of chartData"
                [dataSource]="chartitem.data"
                [type]="chartitem.type"
                xName="year"
                yName="column"
                [name]="chartitem.name"
                columnSpacing="0.5"
                columnWidth="1"
                [cornerRadius]="cornerRadius"
                [animation]="animation1"
                [marker]="chartitem.marker"
              >
              </e-series>
            </e-series-collection>
          </ejs-chart>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <!-- <div class="col-md-3" [ngClass]="{'d-none': setdateperiod !== 'six'}"></div> -->
    <!-- <div class="col-md-4"  [ngClass]="{'d-none': setdateperiod !== 'four'}"></div> -->
    <div [ngClass]="{'col-md-12': setdateperiod === 'twelwe', 'col-md-910': setdateperiod === 'two', 'col-md-1110': setdateperiod === 'three', 'col-md-710': setdateperiod === 'one' }">
      <div class="div-table-pl">
        <div class="control-section">
          <ejs-treegrid
          #treegrid
          [allowResizing]='true'
          [dataSource]="formatedData"
          sizeSettings.height="auto"
          [frozenColumns]="1"
          hideScroll="true"
          childMapping="subAccount"
          [height]=height
          [treeColumnIndex]="0"
          [toolbar]='toolbar'
          [allowSelection]="false"
          [pageSettings]="pageSettings"
          enableCollapseAll="true"
          (rowDataBound)="rowDataBound($event)"
          (dataBound)='dataBound($event)'
          (queryCellInfo)="queryCellInfo($event)"
          allowExcelExport="true"
          allowPdfExport="true"
        >
          <e-columns>
            <ng-container *ngFor="let col of tableHeader">
            <e-column
              *ngIf="col.fieldName === 'accountName'"
              field="{{ col.fieldName }}"
              headerText="{{ col.displayName }}"
              width="280"
              [freeze]='Left'
              textAlign="{{ col.textAlign }}"
              [customAttributes]="customAttributes"
            ></e-column>
            <e-column
              *ngIf="col.fieldName !== 'accountName'"
              field="{{ col.fieldName }}"
              headerText="{{ col.displayName }}"
              width="200"
              textAlign="{{ col.textAlign }}"
              [customAttributes]="customAttributes"
            ></e-column>
          </ng-container>
          </e-columns>
        </ejs-treegrid>
        </div>
      </div>
    </div>
  </div>
</div>
