<ng-container *ngIf="(arRes | json) !== '{}'">
  <div class="div-welcome">
    <div class="row">
      <div class="col-md-12">
        <div class="div-wel-head">
          <img src="/assets/image/gift.svg" alt="image" /> Welcome
          {{ jwtToken?.userName }}!
        </div>
        <div class="div-sub-head">
          Here’s your Accounts Receivable at a glance.
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-2-4">
      <div class="div-out-card">
        <div class="row">
          <div class="col-md-12 div-o-head">Outstanding</div>
          <div class="col-md-12 div-o-sub-head d-flex">
            <div class="">
              <img src="/assets/image/out.svg" alt="image" />
            </div>
            <div
              class="cursor-pointer"
              (click)="filterNavigation(arResap?.outstanding_ar[0])"
            >
              <p>{{ arResap?.outstanding_ar[0]?.value1 }}</p>
              <span>{{ arResap?.outstanding_ar[0]?.value2 }} Invoices</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2-4">
      <div class="div-out-card">
        <div class="row">
          <div class="col-md-12 div-o-head">Overdue</div>
          <div class="col-md-12 div-o-sub-head d-flex div-overdue">
            <div class="">
              <img src="/assets/image/temp/time.svg" alt="image" />
            </div>
            <div
              class="cursor-pointer"
              (click)="filterNavigation(arResap?.overdue[0])"
            >
              <p>{{ arResap?.overdue[0]?.value1 }}</p>
              <span>{{ arResap?.overdue[0]?.value2 }} Invoices</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2-4">
      <div class="div-out-card">
        <div class="row">
          <div class="col-md-12 div-o-head">DUE</div>
          <div class="col-md-12 div-o-sub-head d-flex div-due">
            <div class="">
              <img src="/assets/image/temp/time.svg" alt="image" />
            </div>
            <div
              class="cursor-pointer"
              (click)="filterNavigation(arResap?.overall_due[0])"
            >
              <p>{{ arResap?.overall_due[0]?.value1 }}</p>
              <span>{{ arResap?.overall_due[0]?.value2 }} Invoices</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2-4">
      <div class="div-out-card">
        <div class="row">
          <div class="col-md-12 div-o-head">Due this Week</div>
          <div class="col-md-12 div-o-sub-head d-flex div-due-week">
            <div class="">
              <img src="/assets/image/temp/job.svg" alt="image" />
            </div>
            <div
              class="cursor-pointer"
              (click)="filterNavigation(arResap?.due_this_week[0])"
            >
              <p>{{ arResap?.due_this_week[0]?.value1 }}</p>
              <span>{{ arResap?.due_this_week[0]?.value2 }} Invoices</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2-4">
      <div class="div-out-card">
        <div class="row">
          <div class="col-md-12 div-o-head">DSO</div>
          <div class="col-md-12 div-o-sub-head d-flex">
            <div class="">
              <img src="/assets/image/temp/DPO.svg" alt="image" />
            </div>
            <div>
              <p>{{ arResap?.dso_ar[0]?.data }}</p>
              <span>Days</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-4">
      <div class="div-list-of-mes h-510">
        <div class="row">
          <div class="col-md-8">
            <div class="div-list-head">Actions</div>
          </div>
        </div>
        <div class="row" *ngIf="(arRes | json) !== '{}'; else noActoins">
          <div class="col-md-12">
            <div
              class="div-list-head-item div-bill-pay cursor-pointer"
              *ngIf="
                arRes?.NEW_AR_CONVERSATIONS.count > 0;
                else noNewArConveration
              "
              (click)="filterNavigation(arRes?.NEW_AR_CONVERSATIONS)"
            >
              <div class="row">
                <div class="col-md-1 pl-0">
                  <img src="/assets/image/temp/mes.svg" alt="image" />
                </div>
                <div class="col-md-11">
                  <span
                    >{{ arRes?.NEW_AR_CONVERSATIONS?.count }} New AR
                    conversations</span
                  >
                </div>
              </div>
            </div>
            <ng-template #noNewArConveration>
              <div class="div-list-head-item div-bill-pay">
                <div class="row">
                  <div class="col-md-1 pl-0">
                    <img src="/assets/image/temp/mes.svg" alt="image" />
                  </div>
                  <div class="col-md-11">
                    <span>No new AR conversations</span>
                  </div>
                </div>
              </div>
            </ng-template>
            <div
              class="div-list-head-item div-bill-pay cursor-pointer"
              *ngIf="arRes?.FOLLOW_UP_DUE.count > 0; else followUpDue"
              (click)="filterNavigation(arRes?.FOLLOW_UP_DUE)"
            >
              <div class="row">
                <div class="col-md-1 pl-0">
                  <img src="/assets/image/temp/invoice.svg" alt="image" />
                </div>
                <div class="col-md-11">
                  <span
                    >{{ arRes?.FOLLOW_UP_DUE?.count }} Invoices ready for
                    follow-up</span
                  >
                </div>
              </div>
            </div>
            <ng-template #followUpDue>
              <div class="div-list-head-item div-bill-pay">
                <div class="row">
                  <div class="col-md-1 pl-0">
                    <img src="/assets/image/temp/invoice.svg" alt="image" />
                  </div>
                  <div class="col-md-11">
                    <span> No invoices or follow up</span>
                  </div>
                </div>
              </div>
            </ng-template>
            <div
              class="div-list-head-item div-bill-pay cursor-pointer"
              *ngIf="
                arRes?.FOLLOW_UP_PARTIAL.count > 0;
                else noFollowUpOnPartial
              "
              (click)="filterNavigation(arRes?.FOLLOW_UP_PARTIAL)"
            >
              <div class="row">
                <div class="col-md-1 pl-0">
                  <img src="/assets/image/temp/partial.svg" alt="image" />
                </div>
                <div class="col-md-11">
                  <span
                    >{{ arRes?.FOLLOW_UP_PARTIAL?.count }} Follow up on partial
                    payments
                  </span>
                </div>
              </div>
            </div>
            <ng-template #noFollowUpOnPartial>
              <div class="div-list-head-item div-bill-pay">
                <div class="row">
                  <div class="col-md-1 pl-0">
                    <img src="/assets/image/temp/partial.svg" alt="image" />
                  </div>
                  <div class="col-md-11">
                    <span>No pending follow up on partial payments</span>
                  </div>
                </div>
              </div>
            </ng-template>
            <div
              class="div-list-head-item div-del-bill cursor-pointer"
              *ngIf="arRes?.MISSED_PROMISE.count > 0; else noMissedPromise"
              (click)="filterNavigation(arRes?.MISSED_PROMISE)"
            >
              <div class="row">
                <div class="col-md-1 pl-0">
                  <img src="/assets/image/temp/promise.svg" alt="image" />
                </div>
                <div class="col-md-11">
                  <span>
                    {{ arRes?.MISSED_PROMISE?.count }} Missed Promise to Pay
                  </span>
                </div>
              </div>
            </div>
            <ng-template #noMissedPromise>
              <div class="div-list-head-item div-del-bill">
                <div class="row">
                  <div class="col-md-1 pl-0">
                    <img src="/assets/image/temp/promise.svg" alt="image" />
                  </div>
                  <div class="col-md-11">
                    <span> There are no missed promised pays </span>
                  </div>
                </div>
              </div>
            </ng-template>
            <!-- <div class="div-list-head-item div-bill-pay" *ngIf="arRes?.MISSING_KEY_DETAILS.count > 0; else noMissingKeyDetails">
              <img src="/assets/image/temp/timebill.svg" alt="image" />
              <span> {{arRes?.MISSING_KEY_DETAILS?.count}} Key Missing details for the follow up</span>
            </div>
            <ng-template #noMissingKeyDetails>
              <div class="div-list-head-item div-bill-pay">
                <img src="/assets/image/temp/timebill.svg" alt="image" />
                <span > No key missing details to follow up </span>
              </div>
            </ng-template> -->
            <div
              class="div-list-head-item div-bill-pay cursor-pointer"
              *ngIf="
                arRes?.TOP_THREE_OUTSTANDING.count > 0;
                else noTopThreeOutstanding
              "
              (click)="filterNavigation(arRes?.TOP_THREE_OUTSTANDING)"
            >
              <div class="row">
                <div class="col-md-1 pl-0">
                  <img src="/assets/image/temp/noteexcep.svg" alt="image" />
                </div>
                <div class="col-md-11">
                  <span>
                    Top {{ arRes?.TOP_THREE_OUTSTANDING?.count }} Outstanding
                    Invoices
                  </span>
                </div>
              </div>
            </div>
            <ng-template #noTopThreeOutstanding>
              <div class="div-list-head-item div-bill-pay">
                <div class="row">
                  <div class="col-md-1 pl-0">
                    <img src="/assets/image/temp/noteexcep.svg" alt="image" />
                  </div>
                  <div class="col-md-11">
                    <span>No outstanding invoices </span>
                  </div>
                </div>
              </div>
            </ng-template>
            <div
              class="div-list-head-item div-bill-pay cursor-pointer"
              *ngIf="numericValue > 0; else noExpectedCollection30"
              (click)="filterNavigation(arRes?.EXPECTED_COLLECTION_30)"
            >
              <div class="row">
                <div class="col-md-1 pl-0">
                  <img src="/assets/image/temp/note.svg" alt="image" />
                </div>
                <div class="col-md-11">
                  <span>
                    {{ arRes?.EXPECTED_COLLECTION_30?.count }} Expected
                    Collection's in the next 30 days
                  </span>
                </div>
              </div>
            </div>
            <ng-template #noExpectedCollection30>
              <div class="div-list-head-item div-bill-pay">
                <div class="row">
                  <div class="col-md-1 pl-0">
                    <img src="/assets/image/temp/note.svg" alt="image" />
                  </div>
                  <div class="col-md-11">
                    <span>No expected collection's in the next 30 days </span>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
        <ng-template #noActoins>
          <div class="row div-no-actions">
            <div class="col-md-12">
              <img
                src="{{ environment.imagePrefixURI }}/bg-image/no-actions.svg"
                alt="image"
              />
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="col-md-4 num-div">
      <div class="h-30 div-list-of-items div-parent-num-card">
        <ng-container
          *ngIf="acPayable?.chart.data.length > 0; else noBillsPaid"
        >
          <app-number-card
            [metricData]="acPayable"
            [chartHeight]="450"
            [cardCount]="2"
          ></app-number-card>
        </ng-container>
        <ng-template #noBillsPaid>
          <div class="div-nodata-head">
            <p class="div-list-head">{{ acPayable?.name }}</p> 
          </div>
          <div class="empty-div h-100">
            <i class="fa-kit fa-no-data-available empty-div-icon"></i>
            <div class="empty-div-text">No Data Available</div>
          </div>
        </ng-template>
      </div>
      <div class="h-70 div-list-of-items mt-2">
        <div class="ff-ngc div-card-title mb-2">
          <p>{{ cashFlow?.name }}</p>
        </div>
        <ng-container *ngIf="cashFlow?.chart.data.length > 0; else noCashFlow">
          <app-chart-card
            [metricData]="cashFlow"
            [chartHeight]="250"
          ></app-chart-card>
        </ng-container>
        <ng-template  #noCashFlow>
          <div class="empty-div h-100">
            <i class="fa-kit fa-no-data-available empty-div-icon"></i>
            <div class="empty-div-text">No Data Available</div>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="col-md-4">
      <div class="div-list-of-items h-510">
        <div class="row">
          <div class="col-md-12">
            <div class="div-list-head">Top 5 Outstanding Customers</div>
          </div>
        </div>
        <ng-container *ngIf="topVendor?.length > 0; else noTopVendor">
          <div
            class="row mb-3 mb-clr"
            *ngFor="let vendor of topVendor?.slice(0, 5)"
          >
            <div class="col-md-8">
              <div class="d-flex">
                <div
                  class="div-items-sub-head cursor-pointer"
                  (click)="filterNavigationVendor(vendor)"
                >
                  <p>{{ vendor?.col1.split("_")[0] }}</p>
                  <span class="span-amt">{{ vendor?.col3 }} Invoices</span>
                </div>
              </div>
            </div>
            <div class="col-md-4 div-v-c-r-a">
              <span class="span-{{ vendor.status }}">{{ vendor?.col2 }}</span>
            </div>
          </div>
        </ng-container>
        <ng-template #noTopVendor>
          <div class="empty-div h-75">
            <i class="fa-kit fa-no-data-available empty-div-icon"></i>
            <div class="empty-div-text">No Data Available</div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>
