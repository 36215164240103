import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringToNumber'
})
export class StringToNumberPipe implements PipeTransform {

  transform(value:any ) {
    if(isNaN(value)) {
    const newValue = value.replace(/[^0-9.]/g, '');
    return Number(newValue);
    }
    return value;
  }

}
