<div  [style.height.px]="chartHeight">
  <ejs-chart
    style="display: block;height: inherit !important;width: 100%;"
    [chartArea]="chartArea"
    align="center"
    #chart
    [primaryXAxis]="primaryXAxis"
    [primaryYAxis]="primaryYAxis"
    [palettes]="palette"
    [title]="title"
    [tooltip]="tooltip"
    (load)="load($event)"
    (tooltipRender) = "tooltipRender($event)"
    (axisLabelRender)="OnChartAxisLabeRender($event)"
    [legendSettings]="legend"
  >

    <e-series-collection>
      <e-series
        [dataSource]="metricData.chart.data"
        [cornerRadius]="cornerRadius"
        type="StackingColumn"
        xName="weekNumber"
        yName="paidAmount"
        [pointColorMapping]='pointColorMapping'
        name="Actual"
        [marker]='marker'
        columnWidth="0.5"
        [border]="border"
      >
      </e-series>
      <e-series
      [dataSource]="metricData.chart.data"
      type="Line"
      xName="weekNumber"
      yName="dueAmount"
      [marker]='lineMarker'
      name="Expectation"
      dashArray="8,4"
      width="2"
    >
    </e-series>
    </e-series-collection>
  </ejs-chart>
  </div>
