<div class="row">
  <div class="col-md-3 d-flex" *ngIf="!isFilter">
    <div class="input-group mb-3 div-search-mat">
      <div class="input-group-prepend">
        <i class="far fa-search"></i>
      </div>
      <mat-form-field>
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="" #input />
      </mat-form-field>
    </div>
    <button type="button" class="btn btn-filter m_top2" (click)="custfilter()">
      <i class="far fa-bars"></i>
    </button>
  </div>

  <div class="col-md-6 m_top2" *ngIf="isFilter">
    <form
      [formGroup]="filterForm"
      (ngSubmit)="send(filterForm.value)"
      class="wid100"
    >
      <div formArrayName="filters">
        <div
          class="row"
          *ngFor="
            let filter of filterForm.get('filters').controls;
            let i = index
          "
          [formGroupName]="i"
        >
          <div class="col-md-12 d-flex div-filter-drill">
            <button
              type="button"
              class="btn btn-filter"
              [ngClass]="{ 'btn-trans': i !== 0 }"
            >
              <i class="far fa-bars"></i>
            </button>
            <select
              class="form-select"
              name="selcolumn{{ i }}"
              formControlName="selcolumn"
            >
              <option [ngValue]="null" [disabled]="true">Column</option>
              <br />
              <option *ngFor="let option of gridDataKeys" [ngValue]="option">
                {{ option.key }}
              </option>
            </select>
            <!-- {{option.key}} {{option.operator | json}} {{filter.value?.selcolumn?.type}} -->
            <select
              class="form-select"
              name="seloperator{{ i }}"
              formControlName="seloperator"
            >
              <option [ngValue]="null" [disabled]="true">Operator</option>
              <br />
              <ng-container *ngFor="let option of selectOperaterOptions">
                <option
                  *ngIf="
                    option.operator.includes(filter.value?.selcolumn?.type)
                  "
                  [ngValue]="option.value"
                >
                  {{ option.key }}
                </option>
              </ng-container>
            </select>
            <input
              class="form-control"
              *ngIf="filter.value?.selcolumn?.type !== 'date'"
              placeholder="Value"
              formControlName="selvalue"
            />
            <input
              type="date"
              class="form-control"
              *ngIf="filter.value?.selcolumn?.type === 'date'"
              placeholder="Date"
              formControlName="selvalue"
            />
            <button
              type="button"
              class="btn btn-clos"
              (click)="removeFilter(i)"
            >
              <i class="fas fa-times-circle"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="col-md-3 div-filter-drill m_top2" *ngIf="isFilter">
    <button type="button" class="btn btn-search" (click)="addFilter()">
      <i class="far fa-plus"></i>
    </button>
    <button type="button" class="btn btn-search" (click)="multiSearch()">
      Search
    </button>
    <button type="button" class="btn btn-clear" (click)="clearfilter()">
      Clear All
    </button>
  </div>
  <div class="col-md-6" *ngIf="!isFilter"></div>
  <div class="col-md-3 text-right div-btns d-none m_top2">
    <button type="button" class="btn">
      <i class="far fa-columns"></i> Manage Columns
    </button>
    <button type="button" class="btn" *ngIf="!isExpand" (click)="expand()">
      <i class="far fa-expand-alt"></i>
    </button>
    <button type="button" class="btn"><i class="fal fa-file-pdf"></i></button>
    <button type="button" class="btn">
      <i class="fal fa-file-spreadsheet"></i>
    </button>
  </div>
  <div class="col-md-12"></div>
</div>
