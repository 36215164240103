import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BulkUploadRoutingModule } from './bulk-upload-routing.module';
import { UploadHomeComponent } from './upload-home/upload-home.component';
import { CoreModule } from 'app/core/core.module';
import { SharedModule } from 'app/shared/shared.module';
import { MultiFileUploadComponent } from './multi-file-upload/multi-file-upload.component';


@NgModule({
  declarations: [
    UploadHomeComponent,
    MultiFileUploadComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    BulkUploadRoutingModule
  ]
})
export class BulkUploadModule { }
