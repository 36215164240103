import { Injectable, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import SuperTokens from 'supertokens-web-js';
import Session from 'supertokens-web-js/recipe/session';
import ThirdPartyEmailPassword, {
  thirdPartySignInAndUp,
} from 'supertokens-web-js/recipe/thirdpartyemailpassword';
import {
  getAuthorisationURLWithQueryParamsAndSetState,
  doesEmailExist,
  emailPasswordSignUp,
  emailPasswordSignIn,
  sendPasswordResetEmail,
  submitNewPassword,
} from 'supertokens-web-js/recipe/thirdpartyemailpassword';
import EmailVerification from 'supertokens-web-js/recipe/emailverification';
import { environment } from 'environments/environment';

import { CookieService } from 'ngx-cookie-service';
import jwt_decode from 'jwt-decode';
import { JWTToken } from './auth';
import { ToastrService } from 'ngx-toastr';
import { STAuthService } from 'app/service/st-auth/st-auth.service';
import { DashboardService } from 'app/service/dashboard/dashboard.service';
import { WorkbenchService } from 'app/service/workbench/workbench.service';
import { LoaderService } from '../loader/loader.service';

@Injectable({
  providedIn: 'root',
})
export class AuthServices {
  constructor(
    private _router: Router,
    private cookieService: CookieService,
    private toastr: ToastrService,
    private stAuthService: STAuthService,
    private _dashboardService: DashboardService,
    private _workbenchService: WorkbenchService,
    private _loader: LoaderService
  ) {
    this.initSuperTokens();
  }

  public initSuperTokens() {
    SuperTokens.init({
      appInfo: {
        apiDomain: environment.auth.gatewayURL,
        apiBasePath: '/auth',
        appName: 'Numero',
      },
      recipeList: [
        EmailVerification.init(),
        Session.init(),
        ThirdPartyEmailPassword.init(),
      ],
    });
  }

  async switchWorkspace(claimBody: any): Promise<any> {
    console.log('claimBody',claimBody)
    return this.stAuthService.resignJWTCustomClaim(claimBody);
  }

  async getJWTDetail(): Promise<JWTToken> {
    let jwt: JWTToken;
    if (await Session.doesSessionExist()) {
      const detail = (await Session.getAccessTokenPayloadSecurely());
      return detail
    } else {
      this.logout();
      return null;
    }
  }

  async getJWT(): Promise<any> {
    if (await Session.doesSessionExist()) {
      return (await Session.getAccessTokenPayloadSecurely()).jwt;
    } else {
      return null;
    }
  }

  async isAuthenticated(): Promise<boolean> {
    if (await Session.doesSessionExist()) {
      return true;
    } else {
      await this.logout();
      return false;
    }
  }

  async logout(): Promise<any> {
    this._loader.show();
    Session.signOut().then(res=>{
      window.location.href = '/';
    setTimeout(() => {
      this.cookieService.deleteAll();
      this.cookieService.delete("sFrontToken");
      this.cookieService.delete("st-access-token");
      localStorage.clear();
    }, 1);
    })
    setTimeout(() => {
      this._loader.hide()
    }, 2000);
  }
  // async logout(): Promise<any> {
  //   setTimeout(() => (Session.signOut()));
  //   localStorage.clear();
  //   this.cookieService.deleteAll();
  //   window.location.href = '/';
  //   console.log('Logout');
  // }

  getJWTFromCookie(): string {
    return this.cookieService.get('st-access-token');
  }

  async createUserAndSendEmail(email: string, password: string) {
    try {
      var inviteResponse = await this.stAuthService.createBasicUser(email);
      console.log('User invited successfully.');
      let response = await sendPasswordResetEmail({
        formFields: [
          {
            id: 'email',
            value: email,
          },
        ],
      });

      if (response.status === 'FIELD_ERROR') {
        // one of the input formFields failed validaiton
        response.formFields.forEach((formField) => {
          if (formField.id === 'email') {
            // Email validation failed (for example incorrect email syntax).
            // window.alert(formField.error);
            this.toastr.error(formField.error, 'ERROR', {
              timeOut: 3000,
            });
          }
        });
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        window.alert(err.message);
      } else {
        window.alert('Oops! Something went wrong.');
      }
    }
  }

  async signUpClicked(email: string, password: string) {
    try {
      let response = await emailPasswordSignUp({
        formFields: [
          {
            id: 'email',
            value: email,
          },
          {
            id: 'password',
            value: password,
          },
        ],
      });
      if (response.status === 'FIELD_ERROR') {
        // one of the input formFields failed validaiton
        response.formFields.forEach((formField) => {
          if (formField.id === 'email') {
            // Email validation failed (for example incorrect email syntax),
            // or the email is not unique.
          } else if (formField.id === 'password') {
            // Password validation failed.
            // Maybe it didn't match the password strength
            window.alert(formField.error);
          }
        });
      } else {
        // sign up successful. The session tokens are automatically handled by
        // the frontend SDK.
        //this.getAutomation()
        this._router.navigate(['numero']);;
        // this._router.navigate(['workbench/ap/automation/dashboard']);
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        window.alert(err.message);
      } else {
        window.alert('Oops! Something went wrong.');
      }
    }
  }

  async checkEmail(email: string) {
    try {
      let response = await doesEmailExist({
        email,
      });

      if (response.doesExist) {
        window.alert('Email already exists. Please sign in instead');
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        window.alert(err.message);
      } else {
        window.alert('Oops! Something went wrong.');
      }
    }
  }

  async signInClicked(email: string, password: string) {
    try {
      let response = await emailPasswordSignIn({
        formFields: [
          {
            id: 'email',
            value: email,
          },
          {
            id: 'password',
            value: password,
          },
        ],
      });
      if (response.status === 'FIELD_ERROR') {
        response.formFields.forEach((formField) => {
          if (formField.id === 'email') {
            this.toastr.error('Oops! Email or Password Incorrect.', 'ERROR', {
              timeOut: 3000,
            });
          }
        });
        this._loader.hide();
      } else if (response.status === 'WRONG_CREDENTIALS_ERROR') {
        this.toastr.error('Oops! Email or Password Incorrect.', 'ERROR', {
          timeOut: 3000,
        });
        this._loader.hide();
      } else {
        // this.getCookie(email).subscribe(data=>{
        //   console.log('data1',data)
        //   const  cookie = data
        //   this.setCookieinWorkflowDomain(cookie).subscribe(cookieRes=>{
        //     console.log('Success',cookieRes )
        //   }, error=>{
        //   console.log('setCookieinWorkflowDomain error',error)

        //   })
        // }, error=>{
        //   console.log('getCookie error',error)
        // })
        // const satat = this._dashboardService.getDashboardStatus()
        // console.log('satat',satat)
        // this._loader.hide()
        //this.getAutomation()
        this._router.navigate(['numero']); ;
        // this._router.navigate(['workbench/ap/automation/dashboard']);
      }
    } catch (err: any) {
      this._loader.hide();
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        window.alert(err.message);
      } else {
        window.alert('Oops! Something went wrong.');
      }
    }
  }

  async googleSignInClicked() {
    try {
      const authUrl = await getAuthorisationURLWithQueryParamsAndSetState({
        thirdPartyId: 'google',
        // This is where Google should redirect the user back after login or error.
        // This URL goes on the Google's dashboard as well.
        frontendRedirectURI: environment.auth.baseURL,
        redirectURIOnProviderDashboard: environment.auth.baseURL + '/auth/callback/google',
      });

      /*
          Example value of authUrl: https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&access_type=offline&include_granted_scopes=true&response_type=code&client_id=1060725074195-kmeum4crr01uirfl2op9kd5acmi9jutn.apps.googleusercontent.com&state=5a489996a28cafc83ddff&redirect_uri=https%3A%2F%2Fsupertokens.io%2Fdev%2Foauth%2Fredirect-to-app&flowName=GeneralOAuthFlow
          */

      // we redirect the user to google for auth.
      window.location.assign(authUrl);
      // this._loader.hide()
    } catch (err: any) {
      this._loader.hide();
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        window.alert(err.message);
      } else {
        window.alert('Oops! Something went wrong.');
      }
    }
  }

  async microSoftSignInClicked() {
    try {
      const authUrl = await getAuthorisationURLWithQueryParamsAndSetState({
        thirdPartyId: 'active-directory',
        frontendRedirectURI: environment.auth.baseURL,
        // This is where active-directory should redirect the user back after login or error.
        // This URL goes on the active-directory's dashboard as well.
        redirectURIOnProviderDashboard: environment.auth.baseURL + '/auth/callback/active-directory',
      });
      // window.alert(authUrl);
      /*
          Example value of authUrl: https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&access_type=offline&include_granted_scopes=true&response_type=code&client_id=1060725074195-kmeum4crr01uirfl2op9kd5acmi9jutn.apps.googleusercontent.com&state=5a489996a28cafc83ddff&redirect_uri=https%3A%2F%2Fsupertokens.io%2Fdev%2Foauth%2Fredirect-to-app&flowName=GeneralOAuthFlow
          */

      // we redirect the user to google for auth.
      window.location.assign(authUrl);
      // this._loader.hide()
    } catch (err: any) {
      this._loader.hide();
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        window.alert(err.message);
      } else {
        window.alert('Oops! Something went wrong.');
      }
    }
  }

  public _DBSTAT_;

  // async getAutomation(): Promise<void> {
  //   // setTimeout(() => {
  //   const JWT: any = await this.getJWTDetail();
  //   this._workbenchService.getAutomation(JWT.org.id).subscribe(
  //     (automation) => {
  //       this._dashboardService.setDashboard(automation);
  //       this._DBSTAT_ = automation;
  //       // if (this._DBSTAT_.ap_enabled === true) {
  //       //   this._router.navigate(['workbench/ap/automation/dashboard']);
  //       // } else {
  //       //   this._router.navigateByUrl('workbench/ap/report/dashboard');
  //       // }

  //       if (this._DBSTAT_.ar_enabled === true) {
  //         this._router.navigate(['workbench/ar/automation/dashboard']);
  //       } else {
  //         this._router.navigateByUrl('workbench/ar/report/dashboard');
  //       }
  //       // this._loader.hide();
  //     },
  //     (error) => {
  //       this._loader.hide();

  //       this._router.navigateByUrl('workbench/ar/report/dashboard');
  //     }
  //   );
  // }

  // async handleMicroSoftCallback() {
  //   try {
  //     const response = await thirdPartySignInAndUp();
  //     if (response.status === 'OK') {
  //       console.log(response);
  //       console.log(response.user);
  //       if (response) {
  //         console.log('sign up');
  //         // sign up successful
  //       } else {
  //         console.log('sign in');
  //         // sign in successful
  //       }
  //       // this._router.navigate(['workbench/ap/automation/dashboard']);
  //       //this.getAutomation()
  //       this._router.navigate(['numero']);;
  //     } else {
  //       // SuperTokens requires that the third party provider
  //       // gives an email for the user. If that's not the case, sign up / in
  //       // will fail.

  //       // As a hack to solve this, you can override the backend functions to create a fake email for the user.

  //       window.alert(
  //         'No email provided by social login. Please use another form of login'
  //       );
  //       window.location.assign('/'); // redirect back to login page
  //     }
  //   } catch (err: any) {
  //     if (err.isSuperTokensGeneralError === true) {
  //       // this may be a custom error message sent from the API by you.
  //       window.alert(err.message);
  //     } else {
  //       window.alert('Oops! Something went wrong.');
  //     }
  //     window.location.assign('/');
  //   }
  // }

  async handleGoogleCallback() {
    try {
      const response = await thirdPartySignInAndUp();
      if (response.status === 'OK') {
        console.log(response.user);
        if (response) {
          console.log('sign up');
          // sign up successful
        } else {
          console.log('sign in');
          // sign in successful
        }
        // this._router.navigate(['workbench/ap/automation/dashboard']);
        //this.getAutomation()
        this._router.navigate(['numero']);;
      } else {
        // SuperTokens requires that the third party provider
        // gives an email for the user. If that's not the case, sign up / in
        // will fail.

        // As a hack to solve this, you can override the backend functions to create a fake email for the user.

        window.alert(
          'No email provided by social login. Please use another form of login'
        );
        window.location.assign('/'); // redirect back to login page
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        window.alert(err.message);
      } else {
        window.alert('Oops! Something went wrong.');
      }
      window.location.assign('/');
    }
  }

  async newPasswordEntered(newPassword: string) {
    try {
      let response = await submitNewPassword({
        formFields: [
          {
            id: 'password',
            value: newPassword,
          },
        ],
      });
      if (response.status === 'FIELD_ERROR') {
        response.formFields.forEach((formField) => {
          if (formField.id === 'password') {
            // New password did not meet password criteria on the backend.
            this.toastr.error(formField.error, 'ERROR', {
              timeOut: 3000,
            });
          }
        });
      } else if (response.status === 'RESET_PASSWORD_INVALID_TOKEN_ERROR') {
        // the password reset token in the URL is invalid, expired, or already consumed
        this.toastr.error(
          'We regret to inform you that the password reset link  has expired. Try resetting your password again.',
          'ERROR',
          {
            timeOut: 3000,
          }
        );
        setTimeout(() => {
          window.location.assign('/login'); // back to the login scree.
        }, 3000);
      } else {
        this.toastr.success('Success! Your password has been reset', 'SUCCESS', {
          timeOut: 3000,
        });
        setTimeout(() => {
          window.location.assign('/login'); // back to the login scree.
        }, 3000);
      }
      this._loader.hide()
    } catch (err: any) {
      this._loader.hide()
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        window.alert(err.message);
      } else {
        window.alert('Oops! Something went wrong.');
      }
    }
  }

  async resetPasswordClicked(email: string) {
    try {
      let response = await sendPasswordResetEmail({
        formFields: [
          {
            id: 'email',
            value: email,
          },
        ],
      });
      if (response.status === 'FIELD_ERROR') {
        // one of the input formFields failed validaiton
        response.formFields.forEach((formField) => {
          if (formField.id === 'email') {
            // Email validation failed (for example incorrect email syntax).
            // window.alert(formField.error);
            this.toastr.error(formField.error, 'ERROR', {
              timeOut: 3000,
            });
          }
        });
      } else {
        // reset password email sent.
        this.toastr.success(
          'Please check your email for the password reset link!',
          'SUCCESS',
          {
            timeOut: 3000,
          }
        );
        setTimeout(() => {
          window.location.assign('/login'); // back to the login scree.
        }, 4000);
        // window.alert('Please check your email for the password reset link');
      }
      this._loader.hide()
    } catch (err: any) {
      this._loader.hide()
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        window.alert(err.message);
      } else {
        window.alert('Oops! Something went wrong.');
      }
    }
  }

  checkDataType(dataVal): any {
    let timestamp = Date.parse(dataVal);
    if (!isNaN(dataVal)) {
      return 'number';
    } else if (isNaN(timestamp)) {
      return 'date';
    } else {
      return 'string';
    }
  }
}
