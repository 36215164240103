
<app-widget
*ngIf="showHideWidget === true"
[widgetData]="widgetData"
(itemEvent)="childButtonClicked($event)"
></app-widget>
<div class="div-numero-container div-main-content"  [ngClass]="{'d-none': (showHideWidget === true || showHideWidget === undefined)  }">
  <div class="row">
    <div class="col-md-12 div-head">
      <!-- <p>Bulk Upload</p> -->
      <p class="sub-head">You can now manually upload your files here</p>
    </div>
  </div>
  <div class="row dd-div" *ngIf="bulkUploadRes!= undefined">
    <div class="col-3">
      <p class="dd-text">Select Datasource</p>
      <div class="dd-select">
        <select class="form-select" [(ngModel)]="selectedSource" (change)="updateDropDown()">
          <option disabled value="select">-Select-</option>
          <option *ngFor="let bR of bulkUploadRes"  [value]="bR.key">{{bR.name}}</option>
        </select>
      </div>
    </div>
    <div class="col-3">
      <p class="dd-text">Choose Account</p>
      <div class="dd-select">
        <select class="form-select" [(ngModel)]="selectedAccount" (change)="updateDropDown()">
          <option disabled value="select">-Select-</option>
          <option *ngFor="let aD of bulkUploadRes[ddIndex]?.additional_detail?.account" [value]="aD.identifier">{{aD.account_name}}</option>
        </select>
      </div>
    </div>
    <div class="col-3">
      <p class="dd-text">Function Type</p>
      <div class="dd-select">
        <select class="form-select"  [(ngModel)]="selectedFunction" (change)="updateDropDown()">
          <option disabled value="select">-Select-</option>
          <option *ngFor="let function of bulkUploadRes[ddIndex]?.additional_detail?.function" [value]="function.key">{{function.name}}</option>
        </select>
      </div>
    </div>
    <div class="col-3">
      <p class="dd-text">Year</p>
      <div class="dd-select year">
        <select class="form-select"  [(ngModel)]="selectedTimerange" (change)="updateDropDown()">
          <option *ngFor="let timeRange of timeRange" [ngValue]="timeRange">{{timeRange.start_date | date: 'dd-MMM-yyyy'}} to {{ timeRange.end_date| date: 'dd-MMM-yyyy' }} </option>
        </select>
      </div>
    </div>
  </div>
  <div class="control-section" [ngClass]="{'disable-upload' : (selectedSource === 'select' && selectedAccount  === 'select' || selectedFunction === 'select')  }">
    <div class="col-lg-12">
      <div class="dropControl_wrapper">
        <div class="sample_wrapper">
          <div class="dropArea_wrap" id="dropTarget">
            <div class="font-icons">
              <i class="fal fa-cloud-upload"></i>
            </div>
            <span class="dropText"
              >Drag & Drop or
              <span (click)="browseClick()" class="text-primary cursor-pointer"
                >Choose file</span
              >
              to upload</span
            >
          </div>
          <div id="dropArea">
            <ejs-uploader
              #defaultupload
              id="fileupload"
              class="dropFiles"
              [asyncSettings]="path"
              (success)="onUploadSuccess($event)"
              (failure)="onUploadFailed($event)"
              [allowedExtensions]="allowExtensions"
              (progress)="onUploadInProgress($event)"
              (selected)="onSelect($event)"
              (removing)="onFileRemove($event)"
              maxFileSize ="10000000"
            >
              <ng-template #template="" let-data>
                <span class="fileListwrapper">
                  <span class="icon-file"><img src="{{environment.imagePrefixURI}}/report/pdf-icon.svg"></span>
                  <span class="name file-name"
                    >{{ data.name }} / {{(data.size / (1024*1024)).toFixed(2)}}  MB</span
                  >
                  <span class="upload-status">{{ data.status }}</span>
                </span>
                <!-- <span class="e-icons file-remove" title="Remove"> -->

                  <i class="file-remove fal fa-times file-remove-i"></i>
                <!-- </span> -->
              </ng-template>
            </ejs-uploader>
            <div class="text-center">
              <button class="btn btn-numero-disabled" disabled>Upload</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
