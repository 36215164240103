import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { JWTToken } from 'app/core/service/auth/auth';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { DashboardService } from 'app/service/dashboard/dashboard.service';
import { ReportService } from 'app/service/report/report.service';

@Component({
  selector: 'app-dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.scss']
})
export class DashboardHomeComponent {

  jwtToken: JWTToken = undefined;
  public selAcc = {name : '', value : ''};
  public accounts = [];
  public timeranges = [{name: 'YTD', end_date: '2023-05-20' , start_date : '2023-01-01'}];
  public selectedPeriod = {name: 'YTD', end_date: '2023-05-20' , start_date : '2023-01-01'};
  public showHideWidget = undefined;
  public widgetData: any = {};

  constructor(
    private _dashboardService: DashboardService,
    public authServices: AuthServices,
    private loader: LoaderService,
    private _reportService: ReportService,
    public _router: Router,
  ) {}
  ngOnInit(): void {
    this.widgetData = {
      widgetImage: `https://s3.amazonaws.com/dev-workflow.numero.app/assets/image/bg-image/Report-sample.svg`,
      widgetClassname: 'report-widget',
      widgetHeading: 'Connect your Accounting system',
      widgetDesc:
        'Visualize the performance of your Finance team with Accounts Payable dashboard',
      widgetBtnText: 'Connect',
      widgetBtnNavigation: '',
      widgetBgImageUrl: `https://s3.amazonaws.com/live.dataflo.io/assets/image/dashboard-bg.png`,
    };
    this.fetchTimeRange()
  }

  async fetchTimeRange() {
    this.jwtToken = await this.authServices.getJWTDetail();
    this._reportService
      .getProfitAndLossFilter(this.jwtToken.org.id)
      .subscribe(
        (data) => {
          this.accounts = data.accounts;
          this.timeranges = data.timerange;
          this.selectedPeriod = this.timeranges[0];
          if(this.accounts.length > 0) {
            this.selAcc = this.accounts[0];
            this.showHideWidget = false;
            // this.fetchProfitandLoss()
          } else {
            this.loader.hide();
            this.showHideWidget = true
          }
        },
        (err) => {
          this.loader.hide();
        }
      );

  }

  childButtonClicked(event) {
    this._router.navigate(['connectors']);
  }
}
