import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { handleError } from '../core/handle-error';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private http: HttpClient) { }

  public account:any;

  setAccount(account) {
    this.account = account;
  }

  getAccount() : any {
    return this.account;
  }


  getProfitAndLossFilter = (entity: string): Observable<any> => {
    return this.http
      .get(`${environment.server.baseURL}/profit-and-loss-report/filter?entity_key=${entity}`)
      .pipe(catchError(handleError));
  };

  getExpenseFilter = (entity: string): Observable<any> => {
    return this.http
      .get(`${environment.server.baseURL}/expense-report/filter?entity_key=${entity}`)
      .pipe(catchError(handleError));
  };

  getCashflowFilter = (entity: string): Observable<any> => {
    return this.http
      .get(`${environment.server.baseURL}/cash-flow-statement/filter?entity_key=${entity}`)
      .pipe(catchError(handleError));
  };

  getBalanceSheetFilter = (entity: string): Observable<any> => {
    return this.http
      .get(`${environment.server.baseURL}/balance-sheet/filter?entity_key=${entity}`)
      .pipe(catchError(handleError));
  };

  getProfitLoss = (
    entityId: string,
    start: string,
    end: string,
    account: string,
    gbConst: boolean,
    activeTab: string
  ): Observable<any> => {
    if (!account) {
       return this.http
        .get(`${environment.server.baseURL}/profit-and-loss-report?entity_key=${entityId}&start_date=${start}&end_date=${end}&tab=${activeTab}`)
        .pipe(catchError(handleError));
    }
       return this.http
      .get(`${environment.server.baseURL}/profit-and-loss-report?entity_key=${entityId}&start_date=${start}&end_date=${end}&account_id=${account}&tab=${activeTab}`)
      .pipe(catchError(handleError));

  };

  getCashflow = (
    entityId: string,
    start: string,
    end: string,
    account: string
  ): Observable<any> => {
    if (!account) {
      return this.http
        .get(
          `${environment.server.baseURL}/cash-flow-statement?entity_key=${entityId}&start_date=${start}&end_date=${end}`
        )
        .pipe(catchError(handleError));
    }
    return this.http
      .get(
        `${environment.server.baseURL}/cash-flow-statement?entity_key=${entityId}&start_date=${start}&end_date=${end}&account_id=${account}`
      )
      .pipe(catchError(handleError));

  };

  getExpenseReport = (
    entityId: string,
    start: string,
    end: string,
    type: string,
    account: string
  ): Observable<any> => {
    return this.http
      .get(
        `${environment.server.baseURL}/expense-report?entity_key=${entityId}&start_date=${start}&end_date=${end}&drill_down=${type}&date_dim=month&account_id=${account}`
      )
      .pipe(catchError(handleError));
  };

  getBalanceSheet = (
    entityId: string,
    start: string,
    end: string,
    account: string
  ): Observable<any> => {
    return this.http
      .get(
       `${environment.server.baseURL}/balance-sheet?entity_key=${entityId}&start_date=${start}&end_date=${end}&account_id=${account}`
      )
      .pipe(catchError(handleError));
  };

  getKnockOffAcountList = ( entityId: string,account: string  ): Observable<any> => {
    return this.http.get(
       `${environment.server.baseURL}/settings/accounts?entity_key=${entityId}&account_identifier=${account}`
      )
      .pipe(catchError(handleError));
  };

  postKnockOffAcountList = (selectedAccount: any  ): Observable<any> => {
    return this.http.post(
       `${environment.server.baseURL}/settings/accounts`, selectedAccount
      )
      .pipe(catchError(handleError));
  };

  getSelectedPeriod(selectedPeriod,sDate,eDate,arrayLength :any ={}){
    let setdateperiod = 'twelwe'
    //   if (selectedPeriod.name === 'Year-to-date') {
    //     setdateperiod = 'twelwe';
    //   } else if (selectedPeriod.name=== 'Last Quarter') {
    //     setdateperiod = 'three';
    //   } else if (selectedPeriod.name=== 'Last Month') {
    //     setdateperiod = 'one';
    //   } else if (selectedPeriod.name=== 'Custom') {
    //     const startDate = new Date(sDate);
    //     const endDate = new Date(eDate);
    //     const diffInMonths = Math.abs(endDate.getMonth() - startDate.getMonth()) + 1;

    //     if (diffInMonths >= 1 && diffInMonths <= 2) {
    //       setdateperiod = 'one';
    //     } else if (diffInMonths >= 3 && diffInMonths <= 6) {
    //       setdateperiod = 'three';
    //     } else if (diffInMonths >=7) {
    //       setdateperiod = 'twelwe';
    //     }
    //   } else if (selectedPeriod.name === 'Quarter-to-date') {
    //   if (arrayLength === 5) {
    //     setdateperiod = 'three';
    //   } else if (arrayLength === 4) {
    //     setdateperiod = 'two';
    //   } else if (arrayLength === 3) {
    //     setdateperiod = 'one';
    //   }
    // } else {
    //   setdateperiod = 'twelwe';
    // }
    if (arrayLength >= 1 && arrayLength <= 4) {
      setdateperiod = 'one';
    } else if (arrayLength > 4 && arrayLength <= 7) {
      setdateperiod = 'three';
    } else if (arrayLength >=8) {
      setdateperiod = 'twelwe';
    }
    // if (selectedPeriod.name === 'Quarter-to-date') {
    //     if (arrayLength === 5) {
    //       setdateperiod = 'three';
    //     } else if (arrayLength === 4) {
    //       setdateperiod = 'two';
    //     } else if (arrayLength === 3) {
    //       setdateperiod = 'one';
    //     }
    //   }
    return setdateperiod;
  }



}
