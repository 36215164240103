<!-- inside{{metricData | json}} -->
<div class="hin">
<div [ngClass]="{'div-m-0' : metricData.value2}" *ngIf="metricData.type === 'verticalChartDataCard'">
  <div class="row" >
    <div class="col-7 pr0">
      <div class="ff-ngc div-card-title"> <p>{{metricData.title1}}</p></div>
      <div class="div-card-in-ver"> <p>{{ checkNumeric(metricData.value1) ?  metricData.value1 : (metricData.value1 | currency: 'USD' : '' :'1.0-2') }}
        <span *ngIf="metricData.previousValue">from {{ checkNumeric(metricData.previousValue) ? metricData.previousValue : (metricData.previousValue | currency: 'USD' : '' :'1.0-2')  }}</span></p> </div>
    </div>
    <div class="col-5">
      <div class="div-card-right-title"><p>{{metricData.title2}}</p></div>
      <div *ngIf="metricData.value2" class="div-card-right-amt"><p>{{checkNumeric(metricData.value2) ? metricData.value2 : (metricData.value2 | currency: 'USD' : '' :'1.0-2')}}</p></div>
      <div class="div-card-comp" *ngIf="metricData.comparison && !metricData.connotation">
        <span class="span-comp" [ngClass]="{'green' : metricData.comparison >= 0, 'red' : metricData.comparison < 0}">
          <i class="fas fa-play" [ngClass]="{'fa-rotate-270' : metricData.comparison >= 0, 'fa-rotate-90' : metricData.comparison < 0}"></i>
          <span>{{metricData.comparison | currency: 'USD' : '' :'1.0-2'}}%</span>
        </span>
      </div>
      <div class="div-card-comp" *ngIf="metricData.comparison && metricData.connotation">
        <span class="span-comp" [ngClass]="{'green' : metricData.comparison <= 0, 'red' : metricData.comparison > 0}">
          <i class="fas fa-play" [ngClass]="{'fa-rotate-270' : metricData.comparison >= 0, 'fa-rotate-90' : metricData.comparison < 0}"></i>
          <span>{{metricData.comparison | currency: 'USD' : '' :'1.0-2'}}%</span>
        </span>
      </div>
    </div>
  </div>
  <div class="col-12">
    <app-bar-chart *ngIf="metricData.chart.type === 'verticalbar'" [metricData]="metricData" [chartHeight]="chartHeight"></app-bar-chart>
    <app-line-chart *ngIf="metricData.chart.type === 'verticalarea'" [metricData]="metricData" [chartHeight]="chartHeight"></app-line-chart>
    <app-pie-chart *ngIf="metricData.chart.type === 'verticalpie'" [metricData]="metricData" [chartHeight]="chartHeight"></app-pie-chart>
  </div>
</div>
<div *ngIf="metricData.type === 'horizontalChartDataCard'">
  <div class="row">
    <div class="col-5 pr0">
      <div class="ff-ngc div-card-title mb0"> <p>{{metricData.name}}</p></div>
      <div class="div-vh-center">
        <div>
          <div class="div-card-in"> {{checkNumeric(metricData.value) ? metricData.value : (metricData.value | currency: 'USD' : '' :'1.0-2')}}
            <p *ngIf="metricData.comparison && !metricData.connotation">
              <span class="span-comp" [ngClass]="{'green' : metricData.comparison >= 0, 'red' : metricData.comparison < 0}">
                <i class="fas fa-play" [ngClass]="{'fa-rotate-270' : metricData.comparison >= 0, 'fa-rotate-90' : metricData.comparison < 0}"></i>
                <span>{{metricData.comparison | currency: 'USD' : '' :'1.0-2'}}%</span>
              </span>
            </p>
            <p *ngIf="metricData.comparison && metricData.connotation">
              <span class="span-comp" [ngClass]="{'green' : metricData.comparison <= 0, 'red' : metricData.comparison > 0}">
                <i class="fas fa-play" [ngClass]="{'fa-rotate-270' : metricData.comparison >= 0, 'fa-rotate-90' : metricData.comparison < 0}"></i>
                <span>{{metricData.comparison | currency: 'USD' : '' :'1.0-2'}}%</span>
              </span>
            </p>
          </div>
          <div class="div-card-amt" *ngIf="metricData.aggregate"> <p>{{ checkNumeric(metricData.aggregate) ? metricData.aggregate : (metricData.aggregate | currency: 'USD' : '' :'1.0-2')}}</p></div>
          <div class="div-card-from-amt" *ngIf="metricData.previousValue"> <p>from {{checkNumeric(metricData.previousValue ) ? metricData.previousValue : (metricData.previousValue | currency: 'USD' : '' :'1.0-2')}}</p></div>
        </div>
      </div>
    </div>
    <div class="col-7">
      <!-- {{metricData|json}} -->
      <app-bar-chart *ngIf="metricData.chart.type === 'verticalbar'" [metricData]="metricData" [chartHeight]="chartHeight"></app-bar-chart>
      <app-line-chart *ngIf="metricData.chart.type === 'horizontalarea'" [metricData]="metricData" [chartHeight]="chartHeight"></app-line-chart>
    </div>
  </div>
</div>
</div>
