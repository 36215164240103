<div class="container div-ai-home">
  <div class="div-ai-home-content">
    <!-- <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-11">
        <img src="/assets/image/temp/ai-con-new.svg" alt="image" />
      </div>
    </div> -->
    <div class="row">
      <!-- <div class="col-md-1"></div> -->
      <div class="col-md-12">
        <div class="div-ques">
          <img src="/assets/image/gift.svg" alt="image" />

          Hey {{jwtToken?.userName}}!
        </div>
        <p class="mt-2">
          Welcome to our AI-driven finance platform, where smart solutions meet
          your financial goals.
        </p>
      </div>
    </div>
    <div class="row">
      <!-- <div class="col-md-1"></div> -->
      <div class="col-md-12">
        <div class="div-ans-input">
          <!-- <button class="me-2"> -->
            <img
              class="me-2"
              src="{{ environment.imagePrefixURI }}/ai/copilot/copilot.svg"
            />
          <!-- </button> -->
          <input
            class="form-control input-ai-ans"
            placeholder=" How can we help?"
            (keyup.enter)="getResultForInputText('QUES', inputText)"
            type="text"
            [(ngModel)]="inputText"
          />
          <button
            [disabled]="!inputText"
            (click)="getResultForInputText('QUES', inputText)"
          >
            <i class="fak fa-numero-tele fa-lg mt-2"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- <div class="col-md-1"></div> -->
      <div class="div-sug mb-4">Quick Questions</div>
      <div class="col-md-{{allign[i]}} mb-4 cursor-pointer"  (click)="filterNavigation(pmt)" *ngFor="let pmt of promptData;let i = index">
        <div class="prompt-card">
          <div class="title">
            <img
              class="me-2"
              src="{{ environment.imagePrefixURI }}/dynamic/ai/{{pmt.icon}}.svg"
            /><span>{{pmt.config_name}}</span>
          </div>
          <p class="mb-0 sub">
            {{pmt.prompt}}
          </p>
        </div>
      </div>
      <!-- <div class="col-md-7 mb-4">
        <div class="prompt-card">
          <div class="title">
            <img
              class="me-2"
              src="{{ environment.imagePrefixURI }}/dynamic/cash-mang.svg"
            /><span>CASH MANAGEMENT</span>
          </div>
          <p class="mb-0 sub">
            Break down cash flow by department or project to identify areas of
            concern or success.
          </p>
        </div>
      </div>
      <div class="col-md-7 mb-4">
        <div class="prompt-card">
          <div class="title">
            <img
              class="me-2"
              src="{{ environment.imagePrefixURI }}/dynamic/vendors.svg"
            /><span>Vendors / Expenses</span>
          </div>
          <p class="mb-0 sub">
            Provide an overview of the performance metrics for our top vendors
            for the last quarter.
          </p>
        </div>
      </div>
      <div class="col-md-5 mb-4">
        <div class="prompt-card">
          <div class="title">
            <img
              class="me-2"
              src="{{ environment.imagePrefixURI }}/dynamic/contracts.svg"
            /><span>Contracts</span>
          </div>
          <p class="mb-0 sub">
            Find contracts mentioning 'confidentiality' or 'intellectual
            property'.
          </p>
        </div>
      </div> -->
    </div>
  </div>

  <!-- <div class="row" *ngIf="!showSecondQues">
    <div class="col-md-1"></div>
    <div class="col-md-11">
      <div class="mt-40">
        <p class="txt-head-prompt">Prompt Library</p>
        <div class="row justify-content-md-center">
          <div
            *ngFor="let item of this.data?.library"
            class="col hover-prompt-box cursor-pointer"
            (click)="filterNavigation(item)"
          >
            <div class="outer-prompt-box">
              <div class="promptcard">
                <i class="fak fa-ai-{{ item.icon.toLowerCase()}} fa-3x"></i>
                <h5 class="prmpt-txt mt-2">{{ item.name }}</h5>
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-content-center mt-40 cursor-pointer"
          routerLink="/ai/prompt-library"
        >
          <p class="view-all-txt">View all Prompt</p>
        </div>
      </div>
    </div>
  </div> -->

  <!-- <div class="div-ai-home-content" *ngIf="showSecondQues">
    <div class="row">
      <div class="col-md-1 text-center">
        <img src="/assets/image/temp/ai-con-new.svg" alt="image" />
      </div>
      <div class="col-md-10 div-ques-overdue">
        <p>
          Give me Overdue outstanding customer details that are more than 30
          days?
        </p>
        <span
          >Here is your outstanding customer list who are yet to pay for more
          than 30 days</span
        >
        <img src="/assets/image/temp/flag.svg" alt="image" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-5 div-tbl-list">
        <table>
          <thead>
            <tr>
              <th class="text-left">
                <p>Customers</p>
              </th>
              <th class="text-right">
                <p>Amount</p>
              </th>
              <th>Invoice</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of tblData">
              <td class="text-left">{{ item.customer }}</td>
              <td class="text-right amt">{{ item.amount }}</td>
              <td>{{ item.invoice }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-1 div-out-btns">
        <div>
          <button type="button"><i class="fak fa-numero-download"></i></button>
        </div>
        <div>
          <button type="button">
            <img src="/assets/image/temp/share.svg" alt="image" />
          </button>
        </div>
        <div>
          <button type="button" class="btn-slack">
            <img src="/assets/image/temp/slack.svg" alt="image" />
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="div-ans-input mb-5">
          <input
            class="form-control input-ai-ans"
            placeholder=""
            type="text"
            [(ngModel)]="inputText"
          />
          <button [disabled]="!inputText">
            <i class="fak fa-numero-tele fa-lg mt-2"></i>
          </button>
        </div>
      </div>
    </div>
  </div> -->
</div>
