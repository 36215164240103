import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConnectorsRoutingModule } from './connectors-routing.module';
import { ConnectorCardComponent } from './connector-card/connector-card.component';
import { AuthenticationPopupComponent } from './authentication-popup/authentication-popup.component';
import { AuthDetailPopupComponent } from './authdetail-popup/authdetail-popup.component';
import { CoreModule } from 'app/core/core.module';
import { SharedModule } from 'app/shared/shared.module';
import { ConnectorComponent } from './connector-home/connector-home.component';


@NgModule({
  declarations: [
    ConnectorComponent,
    ConnectorCardComponent,
    AuthenticationPopupComponent,
    AuthDetailPopupComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    ConnectorsRoutingModule
  ]
})
export class ConnectorsModule { }
