<script src="./diagram-common.ts"></script>
<style>
  .e-symbolpalette {
    display: block;
  }
</style>
<div class="diagram-parent div-main-content" >
  <div class="flow-chart-close-div">
    <i
    routerLink="/process-model"
      title="Back"
      class="fa fa-arrow-left cursor-pointer"
    ></i>
  </div>
<div class="control-section">
  <div style="width: 100%">
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid top-bar">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo03"
          aria-controls="navbarTogglerDemo03"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0 top-bar-left">

            <li  class="nav-item">
              <div class="dropdown">
                <input type="text" [(ngModel)]="flowTitle" class="flow-name" />/{{currentVersion}}
                <i
                  id="dropdownMenu2"
                  data-bs-toggle="dropdown"
                  class="fas fa-caret-down cursor-pointer mr-bd-1"
                ></i>
                <ul class="version dropdown-menu" aria-labelledby="dropdownMenu2">
                  <li *ngFor="let vl of versionList" [value]="vl">
                    <button
                      class="dropdown-item"
                      [ngClass]="currentVersion === vl ? 'active' : ''"
                      type="button"
                      aria-current="true"
                      (click)="onVersionChange(vl)"
                    >
                      {{ vl }}
                    </button>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item" (click)="undo()" title="undo">
              <img class="mr-bd-1" src="https://s3.amazonaws.com/dev-workflow.numero.app/assets/image/flowchart/undo.svg"/>
            </li>
            <li class="nav-item" (click)="redo()" title="redo">
              <img class="mr-bd-1" src="https://s3.amazonaws.com/dev-workflow.numero.app/assets/image/flowchart/redo.svg"/>
            </li>
            <li class="nav-item cursor-pointer ms-3" (click)="showSideBar()" title="shapes">
              <img class="" src="https://s3.amazonaws.com/dev-workflow.numero.app/assets/image/flowchart/shapes.svg"/>

            </li>
          </ul>
          <div class="m-auto top-bar-center">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">

            </ul>
          </div>
          <div class="d-flex ms-auto top-bar-right">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">

              <li class="nav-item cursor-pointer ms-3">
                <button
                  class="btn  me-3 btn-clear"
                  (click)="clear()"
                  type="button"
                >
                  Clear all
                </button>
              </li>
              <li class="nav-item cursor-pointer ms-3">
                <button
                  class="btn btn-succ"
                  (click)="updateChart()"
                  type="button"
                >
                  Save
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
    <div class="sb-mobile-palette-bar">
      <div
        id="palette-icon"
        style="float: right"
        role="button"
        class="e-ddb-icons1 e-toggle-palette"
      ></div>
    </div>
    <div id="palette-space" class="sb-mobile-palette " *ngIf="showHideToolBar">
      <ejs-symbolpalette
        id="symbolpalette"
        [expandMode]="expandMode"
        [palettes]="palettes"
        width="100%"
        height="700px"
        [symbolHeight]="80"
        [symbolWidth]="80"
        [symbolMargin]="symbolMargin"
        [getSymbolInfo]="getSymbolInfo"
        [getNodeDefaults]="getSymbolDefaults"
      >
      </ejs-symbolpalette>
      <div id="selection" class="mt-4">

        <div
          class="row mt-3"
          *ngIf="
            diagram!.selectedItems!.nodes[0]! ||
            diagram!.selectedItems!.connectors[0]!
          "
        >
          <div>
            <div class="row sb-child-row">
              <p class="f-head">Text Properties</p>
              <div class="col column-style mb-3">
                <button class="me-2" ejs-button #bold (click)="btnBoldClick()">
                  B
                </button>
                <button
                  class="me-2"
                  ejs-button
                  #italic
                  (click)="btnItalicClick()"
                >
                  I
                </button>
                <button
                  class="me-2"
                  ejs-button
                  #underline
                  (click)="btnUnderlineClick()"
                >
                  U
                </button>
              </div>


            </div>
            <div class="row sb-child-row">
              <div class="col-xs-4 column-style">
                <ejs-numerictextbox
                  #fontSize
                  id="fontSize"
                  width="80px"
                  value="12"
                  step="2"
                  format="##.##"
                  (change)="onFontSizeChange($event)"
                >
                </ejs-numerictextbox>
                <input
                  ejs-colorpicker
                  #colorpicker
                  id="fontcolor"
                  value="#000000"
                  type="color"
                  class="me-3"
                  (change)="onFontColorChange($event)"
                />
              </div>
            </div>
          </div>
          <div *ngIf="diagram!.selectedItems!.nodes[0]!">
            <div class="row sb-child-row mt-3">
              <p class="f-head">Background Properties</p>
              <div class="col-xs-4 column-style">
                <input
                  ejs-colorpicker
                  #colorpicker
                  id="fontcolor"
                  value="#000"
                  type="color"
                  class="me-3"
                  (change)="onBgColorChange($event)"
                />
              </div>
            </div>
            <div
              class="row sb-child-row ds-logo"
              *ngIf="diagram!.selectedItems!.nodes[0]!.annotations[1]"
            >
              <div
                class="col-2 my-3 mx-2"
                *ngFor="let cL of connectorsList"
                (click)="changeLogo(cL)"
              >
                <img
                  class="ds-logo-img"
                  src="{{cL.image_url}}"
                />
              </div>
              <div class="col-2 my-3 mx-2" (click)="changeLogo({name: 'no-logo'})">
                <img
                  class="ds-logo-img"
                  src="https://s3.amazonaws.com/dev-workflow.numero.app/assets/image/flowchart/none.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row property-panel-content mt-3" id="appearance" *ngIf="diagram!.selectedItems!.connectors[0]!">
          <p class="f-head mb-0">Edge type</p>
          <div class="row sb-child-row">
            <div class="col-3 my-3 mx-2"  id="straightConnectorWithStroke" (click)="applyConnectorStyle('Straight')">
              <img
                class="ds-logo-img"
                src="https://ej2.syncfusion.com/angular/demos/assets/diagram/Images/connector/Connectors_4.png"
              />
            </div>
            <div class="col-3 my-3 mx-2"  id="orthogonalConnectorWithStroke" (click)="applyConnectorStyle('Orthogonal')">
              <img
                class="ds-logo-img"
                src="https://ej2.syncfusion.com/angular/demos/assets/diagram/Images/connector/Connectors_5.png"
              />
            </div>
            <div class="col-3 my-3 mx-2"  id="bezierConnectorWithStroke" (click)="applyConnectorStyle('Bezier')">
              <img
                class="ds-logo-img"
                src="https://ej2.syncfusion.com/angular/demos/assets/diagram/Images/connector/Connectors_6.png"
              />
            </div>
            </div>
      </div>

      </div>
    </div>
    <div id="diagram-space" class="sb-mobile-diagram">
      <div class="content-wrapper">
        <ejs-diagram
          #diagram
          id="diagram"
          width="100%"
          height="100%"
          [snapSettings]="snapSettings"
          (created)="created()"
          [getConnectorDefaults]="connDefaults"
          [getNodeDefaults]="nodeDefaults"
          (dragEnter)="dragEnter($event)"
          (created)="diagramCreate($event)"
          (drop)="drop($event)"
          (selectionChange)="selectionChange($event)"
          (collectionChange)="collectionChange($event)"
        >
          <ng-template #annotationtemplate let-data> </ng-template>
        </ejs-diagram>
      </div>
    </div>
    <div id="tool-panel" class="sb-mobile-tool" >
      <ul class="list-group">
        <li class="list-group-item cursor-pointer" (click)="onPageToolClick('Zoom In')"><i class="far fa-search-plus"></i></li>
        <li class="list-group-item cursor-pointer" (click)="onPageToolClick('Zoom Out')"><i class="far fa-search-minus"></i></li>
        <li class="list-group-item cursor-pointer" (click)="onPageToolClick('Reset')"><i class="far fa-expand-wide"></i></li>
      </ul>
    </div>
  </div>
</div>
</div>
