import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { AiService } from 'app/service/ai/ai.service';
import { CommonService } from 'app/service/shared/common.service';
import { error } from 'logrocket';

@Component({
  selector: 'app-ai-prompt',
  templateUrl: './ai-prompt.component.html',
  styleUrls: ['./ai-prompt.component.scss'],
})
export class AiPromptComponent {
  public inputText: string;
  // public answerOutText: string;
  // public showSecondQues: boolean = false;
  public tblData: any = {};
  public promptData = [
    { icon: 'fa-receivable-prompt', title: 'Payables' },
    { icon: 'fa-numero-profile', title: 'Customer Contracts' },
    { icon: 'fa-numero-invoice', title: 'Invoice' },
    { icon: 'fa-cash-flow', title: 'Cash Flow' },
    { icon: 'fa-vendor-prompt', title: 'Vendor' },
  ];
  statedata: any;
  promptRes: any;

  constructor(
    private _loader: LoaderService,
    public _commonService: CommonService,
    public _aiService: AiService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this._loader.show();
    this._commonService.setPageHeader('Numero Copilot');
    this._commonService.setsubPageHeader('');
    this.statedata = window.history.state;
    // this.getAITableData();
    this.getPromptLibrary();
  }

  // getAITableData() {
  //   this._loader.show();
  //   this._aiService.getAIData().subscribe(
  //     (data) => {
  //       this.tblData = data;
  //       this._loader.hide();
  //     },
  //     (err) => {
  //       this._loader.hide();
  //     }
  //   );
  // }
  getPromptLibrary() {
    this._aiService.getPromptLibrary(this.statedata.value).subscribe(
      (data) => {
        this.promptRes = data;
        this._loader.hide();
      },
      (err) => {
        this._loader.hide();
      }
    );
  }

  getResultForInputText(questionType) {
    this._loader.show();
    const qData =  {name:this.inputText  , value: this.inputText };
    this.navigateToResult(questionType, qData)
  }

  navigateToResult(questionType, questionData){
    let targetRoute='/ai/chat';
    let quesData = questionData;
    quesData.quesType = questionType;
    this._router.navigateByUrl(targetRoute, { state: questionData });
  }

  // showOutQues() {
  //   this.showSecondQues = true;
  // }
}
