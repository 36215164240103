<div class="div-numero-container div-main-content flowchart-gutter">
  <div class="div-custom-table ml-4">
    <!-- <div class="row head-title">
      <div class="col-md-6">
        <h4>Process Flow</h4>
      </div>
    </div> -->
    <app-widget
      *ngIf="processFlowList?.length === 0"
      [widgetData]="widgetData"
      (itemEvent)="childButtonClicked($event)"
    ></app-widget>
    <div class="div-mat-card" *ngIf="processFlowList?.length > 0">
      <div class="row m0">
        <div class="col-md-3 p0">
          <div class="input-group mb-3 div-search-mat">
            <div class="input-group-prepend">
              <i class="far fa-search"></i>
            </div>
            <mat-form-field>
              <mat-label>Search</mat-label>
              <input
                matInput
                (keyup)="applyFilter($event)"
                placeholder="Search"
                #input
              />
            </mat-form-field>
          </div>
        </div>
        <div class="col-md-9 text-right"  *ngIf="processFlowList?.length !== 0">
          <button
            type="button"
            class="btn btn-numero mt-3"
            (click)="createFlowChart()"
          >
            <i class="fal fa-plus"></i>
          </button>

        </div>
        <div class="col-md-12 p0">
          <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSourcePF">
              <!-- name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>FLOW NAME</th>
                <td mat-cell *matCellDef="let element">{{ element.name }}</td>
              </ng-container>

              <!-- category Column -->
              <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef>CATEGORY</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element.category">{{ element.category }}</span>
                </td>
              </ng-container>

              <!-- tags Column -->
              <ng-container matColumnDef="tags">
                <th mat-header-cell *matHeaderCellDef>TOOL(S)</th>
                <td mat-cell *matCellDef="let element">
                  <div
                    class="div-tool"
                    *ngIf="element && element.tags && element.tags.length > 0"
                  >
                    <img   *ngFor="let tag of element.tags"
                    title="{{ tag }}"
                    class="me-2"
                    src="{{env.imagePrefixURI}}/{{
                      tag
                    }}.svg"/>

                  </div>
                  <div
                    class="div-tool"
                    *ngIf="element && element.tags && element.tags.length === 0"
                  >
                    -
                  </div>
                </td>
              </ng-container>

              <!-- owner Column -->
              <ng-container matColumnDef="owner">
                <th mat-header-cell *matHeaderCellDef>OWNER</th>
                <td mat-cell *matCellDef="let element">
                  <div class="div-avatar" title="{{ element.owner }}">
                    {{ element.owner.charAt(0).toUpperCase() }}
                  </div>
                </td>
              </ng-container>

              <!-- createdon Column -->
              <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef>CREATED ON</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element.createdAt">{{
                    element.createdAt | date : "MMM dd yyyy"
                  }}</span>
                </td>
              </ng-container>

              <!-- updatedAt Column -->
              <ng-container matColumnDef="updatedAt">
                <th mat-header-cell *matHeaderCellDef>UPDATED ON</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="element.updatedAt">{{
                    element.updatedAt | date : "MMM dd yyyy"
                  }}</span>
                </td>
              </ng-container>

              <!-- actions actions -->
              <ng-container matColumnDef="actions">
                <th class="text-center" mat-header-cell *matHeaderCellDef>
                  ACTIONS
                </th>
                <td class="text-center" mat-cell *matCellDef="let element">
                  <a class="cursor-pointer" (click)="launchIframe(element.key)"
                    ><i class="far fa-pen"></i
                  ></a>
                  &nbsp;
                  <a
                    class="cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteFlowModal"
                    (click)="confirmDeleteFlow(element)"
                    ><i class="far fa-trash"></i
                  ></a>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsPF"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsPF"
              ></tr>
            </table>

            <mat-paginator
            [pageSize]="15"
            [pageSizeOptions]="[5, 10, 15, 20]"
            aria-label="Select page"
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Delete confirm -->
  <div
    class="modal fade"
    id="deleteFlowModal"
    tabindex="-1"
    aria-labelledby="deleteFlowModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete Process flow</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>
            <strong> Are you sure you want to delete the process flow? </strong>
          </p>
          <p>
            Type <strong>“{{ deleteFlow.name }}”</strong> to proceed?
          </p>
          <div class="dialog-data-sources-name df-header text-center">
            <input
              class="form-control"
              type="text"
              [(ngModel)]="deleteConfText"
              placeholder="{{ deleteFlow.name }}"
              (keyup)="validateConfirmationMessage()"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            data-bs-toggle="modal"
            class="btn btn-numero mr-2"
            (click)="removeFlow()"
            [disabled]="showAlert !== false"
          >
            Delete
          </button>
          <button
            type="button"
            data-bs-dismiss="modal"
            class="btn btn-secondary"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
