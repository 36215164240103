import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { CreateConnectorBody } from 'app/service/connectorconfig/connector';
import { ConnectorService } from 'app/service/connectorconfig/connector.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-authentication-popup',
  templateUrl: './authentication-popup.component.html',
  styleUrls: ['./authentication-popup.component.scss']
})
export class AuthenticationPopupComponent implements OnInit {

  public environment = environment;
  public connection : any;
  public authForm : FormGroup;

  public dropdownValues =[];
  public selectedDropdownValues: any= {name:'select', value: 'select'};
  public isDropDown = false;
  public fieldValue ='';
  public sumbitDisable=false;
 

  public badRequestText = '';

  constructor(public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private connectorService: ConnectorService,
    private authService: AuthServices,
    private toaster: ToastrService,) { }

  ngOnInit(): void {

    const controls = {};
    this.connection.fields.forEach((element) => {
      if(element.field_type === 'drop-down') {
        this.dropdownValues.push(element)
      } else {
        controls[element.api_key] = new FormControl(
          '',
          Validators.required
        );
      }
    });
    if(this.connection.fields.length > 0) {
      this.isDropDown = this.connection?.fields?.length === this.dropdownValues?.length;
    }
    this.authForm= this.formBuilder.group(controls);

  }

  get f(): any {
    return this.authForm.controls;
  }

  onDismiss(reason: string): void {
    this.activeModal.dismiss(reason);
  }

  async formSubmit(con: any): Promise<any> {
    this.sumbitDisable=true;
    const tokenDetail = await this.authService.getJWTDetail();
    let body: CreateConnectorBody = {
      source_key: con.source_key,
      type: con.type,
      entity_key: tokenDetail.org.id,
      user_email: tokenDetail.userEmail,
      auth_detail: this.authForm.value
    }
    this.connectorService.createConnection(body).subscribe(
      (data) => {
        console.log('connection created');
        this.toaster.success('Successfully Connected!', 'Success', {
          timeOut: 3000,
        });
        this.activeModal.close(body.source_key);
      },
      err => {
        this.toaster.error('Ah! The connection failed. Please try connecting again.', 'ERROR', {
          timeOut: 3000,
        });
        this.badRequestText = 'Invalid details provided.'
          this.sumbitDisable=false; 
      });
    return Promise.resolve('start of new Promise');
  }

  async dropDownSubmit(con: any): Promise<any> {

    let authDetail = {};
    authDetail[this.selectedDropdownValues.api_key] = this.fieldValue;
    const tokenDetail = await this.authService.getJWTDetail();
    let body: CreateConnectorBody = {
      source_key: con.source_key,
      type: con.type,
      entity_key: tokenDetail.org.id,
      user_email: tokenDetail.userEmail,
      auth_detail: authDetail
    }
    this.connectorService.createConnection(body).subscribe(
      (data) => {
        console.log('connection created');
        this.activeModal.close(body.source_key);
      }
    );
    return Promise.resolve('start of new Promise');
  }

}
