import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportingHomeComponent } from './reporting-home/reporting-home.component';
import { ProfitAndLossComponent } from './profit-and-loss/profit-and-loss.component';
import { ExpenseReportComponent } from './expense-report/expense-report.component';
import { BalanceSheetComponent } from './balance-sheet/balance-sheet.component';
import { CashFlowComponent } from './cash-flow/cash-flow.component';

const routes: Routes =  [
  {
    path :'',
    component: ReportingHomeComponent,
    children: [
      // {
      //   path : '',
      //   component: ProfitAndLossComponent
      // },
      {
        path: 'profitandloss',
        component: ProfitAndLossComponent,
      },
      {
        path : 'expense',
        component: ExpenseReportComponent
      },
      {
        path : 'balance-sheet',
        component: BalanceSheetComponent
      },
        {
        path: 'cashflow',
        component: CashFlowComponent,
      },
      {
        path: 'fluxanalysis',
        component: ProfitAndLossComponent
      },
      {
        path: '',
        redirectTo: 'profitandloss',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportingRoutingModule { }
