import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GrowthBook } from '@growthbook/growthbook';
import { environment as env } from 'environments/environment';
import {  Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GrowthbookService {
  public gBook = new GrowthBook();

  constructor(private http: HttpClient) {}

  getGrowthBook = (): Observable<any> => {
    return this.http
      .get(`https://cdn.growthbook.io/api/features/${env.growthBook.clientKey}`);
  }

  setGBookAttr(gBookattr: any): void {
    this.gBook.setAttributes(gBookattr);
  }

  setGBookFeature(gBookfeat: any): void {
    this.gBook.setFeatures(gBookfeat);
  }

  getGBookStatus(featureName): boolean {
    if (this.gBook.isOn(featureName)) {
      return true;
    }
    return false;
  }
}
