import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { AiService } from 'app/service/ai/ai.service';
import { CommonService } from 'app/service/shared/common.service';

@Component({
  selector: 'app-ai-home',
  templateUrl: './ai-home.component.html',
  styleUrls: ['./ai-home.component.scss']
})
export class AiHomeComponent {
  panels = ['First', 'Second', 'Third'];
  constructor(public _commonService: CommonService) {

  }

  ngOnInit(): void {
    this._commonService.setHideAiIcon(false);
  }
}
