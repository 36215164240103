<div class="div-dropdown-list" ngbDropdown container="body" routerLinkActive="isActive" display="dynamic" placement="bottom-start" #hostElement (openChange)="fixDropdownWidth(hostElement)" [ngClass]="{'pe-none' : dropdownlist?.length < 2 && !selAcc?.name.includes('Select')}">
  <button class="div-dropdown-btn" ngbDropdownToggle [disabled]="isDisabled">
      <div>
          <div class="av-text" [ngClass]="{'av-text-select': selAcc?.name.includes('Select'),'text-lower':stringCase === 'lowercase','text-upper':stringCase ==='uppercase','text-capitialize':stringCase === 'capitalize'}">
              <i *ngIf="iconTxt" class="fak fa-{{iconTxt}} av-text-fav-icon"></i>
              <img *ngIf="type==='image' && selAcc?.source_name"src="{{environment.imagePrefixURI}}/dynamic/drop-down/{{selAcc?.source_name}}.svg">
              <ngx-avatars *ngIf="type==='avatar' && selAcc?.name !='Select'"
                           class="me-2"
                           [name]="selAcc?.name"
                           size="24"
                initialsSize="2"
              ></ngx-avatars>
              {{selAcc?.name}}
          </div>
          <i class="far fa-chevron-down" *ngIf="dropdownlist?.length > 1 || (dropdownlist?.length === 1 && selAcc?.name !== dropdownlist[0]?.name)"></i>
      </div>
  </button>
  <ng-container *ngIf="dropdownlist?.length > 0">
      <div class="div-dropdown" ngbDropdownMenu>
          <ng-container *ngFor="let appitem of dropdownlist">
              <div ngbDropdownItem>
                <button (click)="dropdownSelected(appitem)" [ngClass]="{'ai-base': type==='avatar', 'button-wrap': setWidth==='section','text-lower': stringCase === 'lowercase','text-upper':stringCase ==='uppercase','text-capitialize':stringCase ==='capitalize'}" *ngIf="appitem.name != selAcc?.name" [disabled]="appitem.disabled">
                      <img *ngIf="appitem.source_name && type==='image'" src="{{environment.imagePrefixURI}}/dynamic/drop-down/{{appitem.source_name}}.svg">
                  <ngx-avatars  *ngIf="type==='avatar'"
                                [name]="appitem?.name" 
                                class="me-2" 
                                initialsSize="2"
                                [round]="true"
                      size="28">
                    </ngx-avatars>{{appitem.name}}
                  </button>
              </div>
          </ng-container>
      </div>
  </ng-container>
</div>
