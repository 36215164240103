import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { GrowthbookService } from 'app/core/service/growthBook/growthbook.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { DashboardService } from 'app/service/dashboard/dashboard.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent  implements OnInit{
  public _DBSTAT_;
  public gbARdashboardNav = false;
  public gbDOCAINav = false;
  constructor(private _loader: LoaderService,
    private _dashboardService: DashboardService,
    public growthBookServ: GrowthbookService,
    public _router: Router,
    ) {

  }
  ngOnInit(): void {
    this._DBSTAT_= this._dashboardService.getDashboardStatus();
    this.gbARdashboardNav = this.growthBookServ.getGBookStatus('nav_ar_dashboard_home');
    this.gbDOCAINav = this.growthBookServ.getGBookStatus('nav_doc_ai_home');
    if(this.gbDOCAINav) {
      this._router.navigateByUrl('doc-copilot')
    } else if (this.gbARdashboardNav  || (this.gbDOCAINav === false && this.gbARdashboardNav === false)) {
      if (this._DBSTAT_.ar_enabled === true) {
        this._router.navigate(['workbench/ar/automation/dashboard']);
      } else {
        this._router.navigateByUrl('workbench/ar/report/dashboard');
      }
    } else {
      this._router.navigateByUrl('connectors');
    }
  }

}
