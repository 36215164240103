import { Component, OnDestroy } from '@angular/core';
import { JWTToken } from 'app/core/service/auth/auth';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { DashboardService } from 'app/service/dashboard/dashboard.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { WorkbenchService } from 'app/service/workbench/workbench.service';
import { CommonService } from 'app/service/shared/common.service';

@Component({
  selector: 'app-ar-dashboard-demo',
  templateUrl: './ar-action-dashboard.component.html',
  styleUrls: ['./ar-action-dashboard.component.scss'],
})
export class ArDashboardDemoComponent implements OnDestroy {
  public jwtToken: JWTToken = undefined;
  arRes: any;
  arResap: any;
  public acPayable;
  public cashFlow;
  public topVendor;
  public changeEventSubscription;
  public environment = environment;
  numericValue: number;
  currentRoute: string;
  constructor(
    public _authServices: AuthServices,
    private _dashboardService: DashboardService,
    private _workbenchService: WorkbenchService,
    private _commonService: CommonService,
    private _loader: LoaderService,
    private _router: Router
  ) {}

  async ngOnInit() {
    this._loader.show();
    this.jwtToken = await this._authServices.getJWTDetail();
    this.changeEventSubscription = this._commonService
      .getEventDetuction()
      .subscribe(() => {
        this._loader.show();
        this.fetchArDashboard();
        this.fetchActionArDashboard();
      });
    if(this._commonService.changedEventValue) {
      this.fetchArDashboard();
      this.fetchActionArDashboard();
    } else {
      this._loader.hide();
    }
  }
  async fetchActionArDashboard() {
    this._dashboardService
      .getActionArdashboard(
        this.jwtToken.org.id,
        this.jwtToken.userEmail,
        this._commonService.changedEventValue.value
      )
      .subscribe(
        (data) => {
          this.arRes = data || {};
          this.numericValue = parseFloat(
            this.arRes?.EXPECTED_COLLECTION_30.count.replace(/[^0-9.-]/g, '')
          );
          setTimeout(() => {
            this._loader.hide();
          }, 2000);
        },
        (err) => {
          this._loader.hide();
        }
      );
  }
  async fetchArDashboard() {
    this._workbenchService
      .getWorkbenchArDashboardv2(
        this.jwtToken.org.id,
        this._commonService.changedEventValue.value
      )
      .subscribe(
        (data) => {
          this.arResap = data?.data || [];
          if (Object.keys(this.arResap).length > 0) {
            this.acPayable = this.arResap?.accounts_receivable[0];
            this.cashFlow = this.arResap?.cashflow_chart[0];
            this.topVendor = this.arResap?.top_outstanding_deptors[0]?.data;
            setTimeout(() => {
              this._loader.hide();
            }, 2000);
          }
        },
        (err) => {
          this._loader.hide();
        }
      );
  }
  filterNavigation(arRes) {
    this.currentRoute = this._router.url;
    const stateData = arRes;
    let targetRoute;
    if (this.currentRoute === '/workbench/ar/automation/dashboard') {
      targetRoute = '/workbench/ar/automation/' + arRes.redirect_to;
    } else {
      targetRoute = '/workbench/ar/automation/' + arRes.redirect_to;
    }
    this._router.navigateByUrl(targetRoute, { state: stateData });
  }
  filterNavigationVendor(arRes) {
    this.currentRoute = this._router.url;
    const stateData = {
      ...arRes,
      redirect_to: 'invoice',
      filter_param: 'TOP_OUTSTANDING_DEPTORS',
    };
    let targetRoute;
    if (this.currentRoute === '/workbench/ar/automation/dashboard') {
      targetRoute = '/workbench/ar/automation/invoices';
    }
    this._router.navigateByUrl(targetRoute, { state: stateData });
  }

  ngOnDestroy(): void {
    this.changeEventSubscription.unsubscribe();
  }
}
