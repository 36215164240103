import { Component } from '@angular/core';
import { JWTToken } from 'app/core/service/auth/auth';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { ReportService } from 'app/service/report/report.service';
import { Router } from '@angular/router';
import { GrowthbookService } from 'app/core/service/growthBook/growthbook.service';
import { CommonService } from 'app/service/shared/common.service';
@Component({
  selector: 'app-reporting-home',
  templateUrl: './reporting-home.component.html',
  styleUrls: ['./reporting-home.component.scss']
})
export class ReportingHomeComponent {
  public widgetData: any = {};
  public gbFluxanalysis = false;

  async ngOnInit() {
    this.commonService.setPageHeader("Reports");
    this.fetchTimeRange();
    this.widgetData = {
      widgetImage: `https://s3.amazonaws.com/dev-workflow.numero.app/assets/image/bg-image/Report-sample.svg`,
      widgetClassname: 'report-widget',
      widgetHeading: 'Connect your Accounting system',
      widgetDesc:
        'Consolidate multiple accounts and visualise - P&L , Cash Flow, Expense & Balance Sheet',
      widgetBtnText: 'Connect',
      widgetBtnNavigation: '',
      widgetBgImageUrl: `https://s3.amazonaws.com/live.dataflo.io/assets/image/dashboard-bg.png`,
    };
    this.getGrowthBook();    
  }
  constructor(
    public authServices: AuthServices,
    private loader: LoaderService,
    private _reportService: ReportService,
    public _router: Router,
    public growthBookServ: GrowthbookService,
    public commonService: CommonService
  ) {}
  jwtToken: JWTToken = undefined;
  public showHideWidget = undefined;
  public accounts = [];
  public timeranges = [{name: 'YTD', end_date: '2023-05-20' , start_date : '2023-01-01'}];
  public selectedPeriod = {name: 'YTD', end_date: '2023-05-20' , start_date : '2023-01-01'};
  public selAcc = {name : '', value : ''};
  async fetchTimeRange() {
    this.jwtToken = await this.authServices.getJWTDetail();
    this.commonService.setHideAiIcon(true);
    this._reportService
      .getProfitAndLossFilter(this.jwtToken.org.id)
      .subscribe(
        (data) => {
          this.accounts = data.accounts;
          this.timeranges = data.timerange;
          this.selectedPeriod = this.timeranges[0];
          if(this.accounts.length > 0) {
            const sAcc =this._reportService.getAccount();
            if(!sAcc ) {
              if(this.accounts.length ===1 ){
                this._reportService.setAccount(this.accounts[0]);
              } else {
                this._reportService.setAccount(this.accounts[1]);
              }
            }
            this.showHideWidget = false;
          } else {
            this.loader.hide();
            this.showHideWidget = true
          }
        },
        (err) => {
          this.loader.hide();
        }
      );

  }
  childButtonClicked(event) {
    this._router.navigate(['connectors']);
  }

  async getGrowthBook(): Promise<any> {
         this.gbFluxanalysis = this.growthBookServ.getGBookStatus('flux-analysis');
  }

}
