import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlowchartHomeComponent } from './flowchart-home/flowchart-home.component';
import { CoreModule } from 'app/core/core.module';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { FlowchartEditorComponent } from './flowchart-editor/flowchart-editor.component';
import { ProcessFlowchartRoutingModule } from './process-flowchart-routing.module';
import { FlowchartListComponent } from './flowchart-list/flowchart-list.component';


@NgModule({
  declarations: [
    FlowchartHomeComponent,
    FlowchartEditorComponent,
    FlowchartListComponent
  ],
  imports: [
    // BrowserAnimationsModule,
    CommonModule,
    CoreModule,
    SharedModule,
    ProcessFlowchartRoutingModule
    // MaterialModule,
  ]
})
export class ProcessFlowchartModule { }
