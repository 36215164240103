import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { CreateConnectorBody } from 'app/service/connectorconfig/connector';
import { ConnectorService } from 'app/service/connectorconfig/connector.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-authdetail-popup',
  templateUrl: './authdetail-popup.component.html',
  styleUrls: ['./authdetail-popup.component.scss']
})
export class AuthDetailPopupComponent implements OnInit {

 
  public environment = environment;
  public connection : any;
  public authForm : FormGroup;
  constructor(public activeModal: NgbActiveModal,     
    private formBuilder: FormBuilder,
    private connectorService: ConnectorService,
    private authService: AuthServices,
    private loader : LoaderService) { }

  ngOnInit(): void {
    // console.log(this.connection)

    const controls = {};
    this.connection.fields.forEach((element) => {
      controls[element.api_key] = new FormControl(
        '',
        Validators.required
      );
    });
    this.authForm= this.formBuilder.group(controls);
      
  }
  
  get f(): any {
    return this.authForm.controls;
  }

  onSubmit(): any {
  }
  onDismiss(reason: string): void {
    this.activeModal.dismiss(reason);    
  }

  async formSubmit(con: any): Promise<any> {
    this.loader.show();
    const tokenDetail = await this.authService.getJWTDetail();
    let body: CreateConnectorBody = {
      source_key: con.source_key,
      type: con.type,
      entity_key: tokenDetail.org.id,
      user_email: tokenDetail.userEmail,
      auth_detail: this.authForm.value
    }
    await this.connectorService.createConnection(body).subscribe(
      (data) => {
        console.log('connection created');
        this.activeModal.dismiss();
        this.loader.hide();
      },
      (err) => {
        this.loader.hide();
      }
    );
    return Promise.resolve('start of new Promise');    
  }
  
}
