<div class="div-welcome">
  <div class="row">
    <div class="col-md-12">
      <div class="div-wel-head">
        <img src="/assets/image/gift.svg" alt="image" /> Welcome {{jwtToken?.userName}}!
      </div>
      <div class="div-sub-head">
        Here's your Accounts Payable at a glance.
      </div>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-3">
    <div class="div-out-card">
      <div class="row">
        <div class="col-md-12 div-o-head">
          Outstanding AP
        </div>
        <div class="col-md-12 div-o-sub-head d-flex">
          <div class="">
            <img src="/assets/image/out.svg" alt="image" />
          </div>
          <div>
            <p>{{arResap?.outstanding_ap[0].value1}}</p>
            <span>{{arResap?.outstanding_ap[0].value2}} Bills</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="div-out-card">
      <div class="row">
        <div class="col-md-12 div-o-head">
          Overdue
        </div>
        <div class="col-md-12 div-o-sub-head d-flex div-overdue">
          <div class="">
            <img src="/assets/image/temp/time.svg" alt="image" />
          </div>
          <div>
            <p>{{arResap?.overdue[0].value1}}</p>
            <span>{{arResap?.overdue[0].value2}} Bills</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="div-out-card">
      <div class="row">
        <div class="col-md-12 div-o-head">
          Due this Week
        </div>
        <div class="col-md-12 div-o-sub-head d-flex div-due">
          <div class="">
            <img src="/assets/image/temp/job.svg" alt="image" />
          </div>
          <div>
            <p>{{arResap?.due_this_week[0].value1}}</p>
            <span>{{arResap?.due_this_week[0].value2}} Bills</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="div-out-card">
      <div class="row">
        <div class="col-md-12 div-o-head">
          DPO
        </div>
        <div class="col-md-12 div-o-sub-head d-flex">
          <div class="">
            <img src="/assets/image/temp/DPO.svg" alt="image" />
          </div>
          <div>
            <p>{{arResap?.dpo_ap[0].data}}</p>
            <span> Days </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-3">
  <div class="col-md-4">
    <div class="div-list-of-mes h-510">
      <div class="row">
        <div class="col-md-8">
          <div class="div-list-head">
            Actions
          </div>
        </div>
      </div>
      <div class="row" *ngIf="(arRes | json) !== '{}'; else noActoins">
        <div class="col-md-12">
          <div class="div-list-head-item div-bill" *ngIf="arRes?.REJECTED_COUNT?.count > 0; else noRejectedBills">
            <img src="/assets/image/temp/noteexcep.svg" alt="image" />
            <span class="span-bill">Take a look into</span>
            <span class="span-billid">{{ arRes?.REJECTED_COUNT?.count }}</span>
            <span>rejected Bill(s)</span>
          </div>
          <ng-template #noRejectedBills>
            <div class="div-list-head-item div-bill">
              <img src="/assets/image/temp/noteexcep.svg" alt="image" />
              <span class="span-bill">You don't have any rejected bills to look into</span>
            </div>
          </ng-template>
          <div class="div-list-head-item div-del-bill" *ngIf="arRes?.ANOMALY?.count > 0; else noAnomalyBills">
            <img src="/assets/image/temp/delete.svg" alt="image" />
            <span>{{ arRes?.ANOMALY?.count }} Anomaly bills found. Fix it</span>
          </div>
          <ng-template #noAnomalyBills>
            <div class="div-list-head-item div-del-bill">
              <img src="/assets/image/temp/delete.svg" alt="image" />
              No anomaly bills found
            </div>
          </ng-template>
          <div class="div-list-head-item div-bill-pay"
            *ngIf="arRes?.TODAY_APPROVED_COUNT?.count > 0; else noTodayApprovedCount">
            <img src="/assets/image/temp/notepay.svg" alt="image" />
            <span>{{arRes?.TODAY_APPROVED_COUNT?.count}} Bills need to pay today </span>
          </div>
          <ng-template #noTodayApprovedCount>
            <div class="div-list-head-item div-bill-pay">
              <img src="/assets/image/temp/notepay.svg" alt="image" />
              <span>There are no bills to be paid today</span>
            </div>
          </ng-template>
          <div class="div-list-head-item div-bill-pay" *ngIf="arRes?.OVERDUE_60?.count > 0; else noOverdueBy60">
            <img src="/assets/image/temp/noteexcep.svg" alt="image" />
            <span># {{arRes?.OVERDUE_60?.count}} Bill overdue for more than 60 days </span>
          </div>
          <ng-template #noOverdueBy60>
            <div class="div-list-head-item div-bill-pay">
              <img src="/assets/image/temp/noteexcep.svg" alt="image" />
              <span> No overdue bills left >60 days </span>
            </div>
          </ng-template>
          <div class="div-list-head-item div-bill" *ngIf="arRes?.APPROVED_7?.count > 0; else noAPPROVED_7">
            <img src="/assets/image/temp/timebill.svg" alt="image" />
            <span class="span-bill"> {{arRes?.APPROVED_7?.count}} Bill</span> <span class="span-bill">have been approved in
              last 7 days </span>
          </div>
          <ng-template #noAPPROVED_7>
            <div class="div-list-head-item div-bill">
              <img src="/assets/image/temp/timebill.svg" alt="image" />
              <span class="span-bill"> No bills approved in last 7 days </span>
            </div>
          </ng-template>
          <div class="div-list-head-item div-bill-pay" *ngIf="arRes?.BILLS_7?.count > 0; else noBILLS_7">
            <img src="/assets/image/temp/note.svg" alt="image" />
            <span> {{arRes?.BILLS_7?.count}} Bills are due in 7 days </span>
          </div>
          <ng-template #noBILLS_7>
            <div class="div-list-head-item div-bill-pay">
              <img src="/assets/image/temp/note.svg" alt="image" />
              <span>No Bills due in next 7 days </span>
            </div>
          </ng-template>
        </div>
      </div>
        <ng-template #noActoins>
          <div class="row div-no-actions">
            <div class="col-md-12">
              <img src="{{environment.imagePrefixURI}}/bg-image/no-actions.svg" alt="image" />
            </div>
          </div>
        </ng-template>


    </div>
  </div>
  <div class="col-md-4 num-div">
    <div class="h-30 div-list-of-items div-parent-num-card">
      <ng-container *ngIf="acPayable?.chart.data.length > 0; else noBillsPaid">
        <app-number-card [metricData]="acPayable" [chartHeight]="450" [cardCount]="2"></app-number-card>
      </ng-container>
      <ng-template #noBillsPaid>
        <div class="div-nodata-head">
          <p>{{acPayable?.name}}</p>
          <div class="div-no-data">NO DATA AVAILABLE</div>
        </div>
      </ng-template>
    </div>
    <div class="h-70 div-list-of-items mt-2">
      <div class="ff-ngc div-card-title  mb-2">
        <p>{{ aging?.name }}</p>
      </div>
      <ng-container *ngIf="aging?.chart.data.length > 0; else noAgingPaid">
        <app-chart-card [metricData]="aging" [chartHeight]="250"></app-chart-card>
      </ng-container>
      <ng-template #noAgingPaid>
        <div class="div-nodata-head">
          <div class="div-no-data">NO DATA AVAILABLE</div>
        </div>
      </ng-template>
    </div>

  </div>
  <div class="col-md-4">
    <div class="div-list-of-items h-510">
      <div class="row">
        <div class="col-md-8">
          <div class="div-list-head">
            Top 5 Outstanding Vendors
          </div>
        </div>
        <!-- <div class="col-md-4">
          <div class="div-list-action">
            View All
          </div>
        </div> -->
      </div>

      <ng-container *ngIf="topVendor?.length > 0; else noTopVendor">
        <div class="row mb-3 mb-clr" *ngFor="let vendor of topVendor?.slice(0, 5)">
          <div class="col-md-8">
            <div class="d-flex">
              <!-- <div class="div-items-head">
                <ngx-avatars
                          [name]="vendor.col1"
                          size="25"
                        ></ngx-avatars>
              </div> -->
              <div class="div-items-sub-head">
                <p>{{vendor?.col1}}</p>
                <span class="span-bill-count">{{vendor?.col3}} Bills</span>
              </div>
            </div>
          </div>
          <div class="col-md-4 div-v-c-r-a">
            <span class="span-amt">{{vendor?.col2}}</span>
          </div>
        </div>
      </ng-container>
      <ng-template #noTopVendor>
        <div class="div-nodata-head-top">
          <div class="div-no-data">NO DATA AVAILABLE</div>
        </div>
      </ng-template>
    </div>
  </div>

</div>
