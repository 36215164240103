// import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { WorkbenchService } from 'app/service/workbench/workbench.service';
import { environment } from 'environments/environment';
import { PromiseToPayComponent } from '../promise-to-pay/promise-to-pay.component';
import { JWTToken } from 'app/core/service/auth/auth';
import { CommonService } from 'app/service/shared/common.service';
import { UtcToLocalTimePipe } from 'app/shared/utills/utcToLocalTime.pipe';
import { FormatPainterService, HtmlEditorService, ImageService, LinkService, ToolbarService } from '@syncfusion/ej2-angular-richtexteditor';
import { CommonPopupAlertComponent } from 'app/shared/common-popup-alert/common-popup-alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-workbench-ar-invoice-conversations',
  templateUrl: './workbench-ar-invoice-conversations.component.html',
  styleUrls: ['./workbench-ar-invoice-conversations.component.scss'],
  providers: [UtcToLocalTimePipe,FormatPainterService, ToolbarService,LinkService,ImageService, HtmlEditorService,],
})
export class WorkbenchArInvoiceConversationsComponent {

  public toolbarSettings: object = {
    items: ['FormatPainter', 'ClearFormat' ,'|', 'Bold', 'Italic', 'Underline', '|', 'FontName', 'FontSize', 'FontColor','BackgroundColor', '|','Formats', 'Alignments',
    'OrderedList', 'UnorderedList', '|', 'CreateLink', 'Image', '|', 'SourceCode', 'Undo', 'Redo']
}
  @Input() invoiceData: any;
  @ViewChild(PromiseToPayComponent)
  promiseToPayComponent: PromiseToPayComponent;
  @Output() invoiceKey = new EventEmitter<string>();
  @ViewChild('filterCheckboxes') filterCheckboxes: ElementRef;
  subjectForm: FormGroup;
  editorContent: string = '';
  element: any;
  jwtToken: JWTToken = undefined;
  automation: any;
  workbenchData: any;
  // selectedInvoice: any;
  emaildata: any;
  emailmap: any;
  composeMail: boolean = false;
  mailCc: boolean = false;
  mailBcc: boolean = false;
  currentRoute: string;
  targetRoute: string;
  showComposeEmail: boolean = false;
  showCreateNote: boolean = false;
  showPromise: boolean = false;
  showPayment: boolean = false;
  showBackDrop: boolean = false;
  notesContent: string = '';
  paymentTagData: any;
  newTagText: string = '';
  newTagChecked: boolean = false;
  showNewTagInput: boolean = false;
  type: string = 'invoice';
  selectedTag: any ;
  isNotesContentEmpty: boolean = true;
  isEmailContentEmpty: boolean = true;
  public emailCount: number = 0;
  public noteCount: number = 0;
  public p2pCount: number = 0;
  public tagCount: number = 0;
  public paymentCount: number = 0;
  public invalidBorder :boolean = false;
  public selectedArSource:any;
  customerDetails:any;


  allCount: any;
  tabData = {
    ALL: new Map<string, Array<any>>(),
    EMAIL: new Map<string, Array<any>>(),
    NOTES:  new Map<string, Array<any>>(),
    PROMISE_TO_PAY:  new Map<string, Array<any>>(),
    INVOICE_PAID:  new Map<string, Array<any>>(),
    TAGS:  new Map<string, Array<any>>(),
    PAYMENTS:  new Map<string, Array<any>>(),
  };
  public userImageMap =  new Map<string, string>();
  public selectedData: string = 'ALL';

  public emailCCList = [];

  constructor(
    public _workbenchService: WorkbenchService,
    public authServices: AuthServices,
    public loader: LoaderService,
    // public datepipe: DatePipe,
    private _router: Router,
    private _commonService: CommonService,
    private _NgbModal: NgbModal,
  ) {
    this.subjectForm = new FormGroup({
      subject: new FormControl('',Validators.required),
      cc: new FormControl(''),
      attachInvoice: new FormControl(false),
    });
  }
  ngOnInit() {
    this.loader.show();
    setTimeout(async () => {
      this.jwtToken = await this.authServices.getJWTDetail();
      // this._workbenchService.sendP2PKey(this.invoiceData?.p2pKey);
      this.getWorkbenchInvoicev2();
      this.selectedTag = this.invoiceData?.tag || '';
      // this.getMailDetails();
      this.retriveSelectedAccount();
      this.fetchCustomerDetailsByCustomerId();
    }, 1000);
  }

  async getMailDetails(): Promise<void> {
    this.loader.show();
    this._workbenchService
      .getMailDetails(this.invoiceData?.invoiceKey, this.jwtToken.userEmail)
      .subscribe(
        (email) => {
          this.emaildata = email || [];
          this.emailmap = new Map<string, Array<any>>();
          const emailsByType = {
            ALL: [],
            EMAIL: [],
            NOTES: [],
            PROMISE_TO_PAY: [],
            INVOICE_PAID: [],
            TAGS: [],
            PAYMENTS: [],
          };
          this.tabData = {
            ALL: new Map<string, Array<any>>(),
            EMAIL: new Map<string, Array<any>>(),
            NOTES: new Map<string, Array<any>>(),
            PROMISE_TO_PAY: new Map<string, Array<any>>(),
            INVOICE_PAID: new Map<string, Array<any>>(),
            TAGS: new Map<string, Array<any>>(),
            PAYMENTS: new Map<string, Array<any>>(),
          };

          let count= 1;
          this.emaildata.forEach(data=>{
            if(!this.userImageMap.has(data.to_email)) {
              this.userImageMap.set(data.to_email, 'img'+count )
              count++;
            }
            if(!this.userImageMap.has(data.created_by)) {
              this.userImageMap.set(data.created_by, 'img'+count )
              count++;
            }
          })
          this.emaildata.forEach((data) => {
            let list = [];
            data.imageURL = data?.to_email ? this.userImageMap.get(data.to_email) : this.userImageMap.get(data.created_by);
            let emailDate = data.email_datetime;
            if (this.emailmap.has(emailDate)) {
              list = this.emailmap.get(emailDate);
            }
            list.push(data);
            this.emailmap.set(emailDate, list);
            var type =
              data.type === 'INCOMING' || data.type === 'OUTGOING'
                ? 'EMAIL'
                : data.type;
            emailsByType[type].push(data);
            emailsByType['ALL'].push(data);
          });
          for (const type in emailsByType) {
            emailsByType[type].forEach((data) => {
              const emailDate = data.email_date;
              if (!this.tabData[type].has(emailDate)) {
                this.tabData[type].set(emailDate, []);
              }
              // if (!this.tabData['ALL'].has(emailDate)) {
              //   this.tabData['ALL'].set(emailDate, []);
              // }
              this.tabData[type].get(emailDate).push(data);
              // this.tabData['ALL'].get(emailDate).push(data);
            });
          }

          this.emailCount = emailsByType['EMAIL'].length;
          this.noteCount = emailsByType['NOTES'].length;
          this.p2pCount = emailsByType['PROMISE_TO_PAY'].length;
          this.tagCount = emailsByType['TAGS'].length;
          this.paymentCount = emailsByType['PAYMENTS'].length;
          this.loader.hide();
        },
        (err) => {
          this.loader.hide();
        }
      );
  }

  public environment = environment;

  public invoiceStatus = [
    { name: 'Due', value: 'due' },
    { name: 'Overdue', value: 'overdue' },
    { name: 'Paid', value: 'paid' },
  ];

  public mestxt =
    '<div>Hi there,</div> <br/>' +
    "<div>We hope you're well,</div> <br/>" +
    "<div>We haven't received your payment for your invoice 516871 for a total of £24,144.04, which is now overdue</div> <br/>" +
    '<div>You can check the status of your invoices and pay them in a few clicks from your customer portal. We would appreciate a prompt payment upon receipt of this email, if you have already paid, please ignore this nation</div> <br/>' +
    '<div>Feel free to reach out if you have any questions</div>';

  public data: { [key: string]: Object }[] = [
    {
      Name: 'Selma Rose',
      Status: 'active',
      Eimg: '2',
      EmailId: 'selma@gmail.com',
    },
    { Name: 'Maria', Status: 'active', Eimg: '1', EmailId: 'maria@gmail.com' },
    {
      Name: 'Russo Kay',
      Status: 'busy',
      Eimg: '8',
      EmailId: 'russo@gmail.com',
    },
    {
      Name: 'Camden Kate',
      Status: 'active',
      Eimg: '9',
      EmailId: 'camden@gmail.com',
    },
    { Name: 'Robert', Status: 'busy', Eimg: 'dp', EmailId: 'robert@gmail.com' },
    { Name: 'Garth', Status: 'active', Eimg: '7', EmailId: 'garth@gmail.com' },
    {
      Name: 'Andrew James',
      Status: 'away',
      Eimg: 'pic04',
      EmailId: 'noah@gmail.com',
    },
    { Name: 'Olivia', Status: 'busy', Eimg: '5', EmailId: 'olivia@gmail.com' },
    { Name: 'Sophia', Status: 'away', Eimg: '6', EmailId: 'sophia@gmail.com' },
    {
      Name: 'Margaret',
      Status: 'active',
      Eimg: '3',
      EmailId: 'margaret@gmail.com',
    },
    {
      Name: 'Ursula Ann',
      Status: 'active',
      Eimg: 'dp',
      EmailId: 'ursula@gmail.com',
    },
    {
      Name: 'Laura Grace',
      Status: 'away',
      Eimg: '4',
      EmailId: 'laura@gmail.com',
    },
    {
      Name: 'Albert',
      Status: 'active',
      Eimg: 'pic03',
      EmailId: 'albert@gmail.com',
    },
    {
      Name: 'William',
      Status: 'away',
      Eimg: '10',
      EmailId: 'william@gmail.com',
    },
  ];

  public fieldsData: { [key: string]: string } = { text: 'Name' };

  onActionBegin(args) {
    if (args.requestType === 'EnterAction') {
      args.cancel = true;
    }
  }

  retriveSelectedAccount() {
    const selAcc = JSON.parse(localStorage.getItem('ar-identifier'))
    this.selectedArSource =selAcc;
    return selAcc;
  }


  createCCArray() {
   
    const csmMail = this.customerDetails?.csmEmail ? {name: 'CSM',value: this.customerDetails.csmEmail} : null;
    const colOwner = this.customerDetails?.collectionOwnerEmail ? {name: 'Collection Owner',value: this.customerDetails.collectionOwnerEmail} : null;
    const accOwner = this.customerDetails?.accountOwnerEmail ? {name: 'Account Owner',value: this.customerDetails.accountOwnerEmail} : null;
    if(csmMail) this.emailCCList.push(csmMail)
    if(colOwner) this.emailCCList.push(colOwner)
    if(accOwner) this.emailCCList.push(accOwner)

    console.log(this.emailCCList)

  }

  appendCCMail(email) {
    let cc = this.subjectForm.value.cc.trim();

    if (cc === '') {
      cc = email.value;
    } else {
      const ccList = cc.split(',');
      const existingEmail = ccList.find(item => item.trim() === email.value);
      if (!existingEmail) {
        cc += ',' + email.value;
      }
    }
    this.subjectForm.patchValue({ cc:cc });
  }


  showcompose() {
    this.composeMail = true;
  }

  showcc() {
    this.mailCc = true;
  }

  showbcc() {
    this.mailBcc = true;
  }

  cancelcc() {
    this.mailCc = false;
    this.mailBcc = false;
  }

  cancelbcc() {
    this.mailBcc = false;
  }

  composecancel() {
    this.composeMail = false;
    this.mailCc = false;
    this.mailBcc = false;
  }

  openCreateMail() {
    this.showBackDrop = true;
    this.showComposeEmail = true;
    this.showComposeEmail = true;
  }

  openCreateNote() {
    this.showBackDrop = true;
    this.showCreateNote = true;
  }

  openPromise() {
    this.showBackDrop = true;
    this.showPromise = true;
    if (this.promiseToPayComponent) {
    this.promiseToPayComponent.enableFormControls();
    }
  }

  openPayment() {
    this.showBackDrop = true;
    this.showPayment = true;
    this.getTag();
  }

  closeEmailDrilldown() {
    this.showComposeEmail = false;
    this.showBackDrop = false;
    this.subjectForm.reset();
    this.editorContent = '';
    this.invalidBorder = false;
  }

  closeCreateNoteDrilldown() {
    this.showCreateNote = false;
    this.showBackDrop = false;
    this.notesContent = '';
    this.invalidBorder = false;
  }

  closePromiseDrilldown() {
    this.showPromise = false;
    this.showBackDrop = false;
    if (this.promiseToPayComponent) {
      this.promiseToPayComponent.resetFormValues();
      this.promiseToPayComponent.isEditing = false;
      this.promiseToPayComponent.isPatched = true;
      this.promiseToPayComponent.p2pModeOther = '';
      this.promiseToPayComponent.paymentModeOther = '';
      this.promiseToPayComponent.disableFormControls();
    }
  }

  closePaymentDrilldown() {
    this.showPayment = false;
    this.showBackDrop = false;
    this.showNewTagInput = false;
    this.newTagText = '';
    if(this.selectedTag){
      this.selectedTag = this.invoiceData?.tag
    }
    else {
      this.selectedTag ='';
    }
  }

  onCheckboxChange(e: any, selectedfilter) {
    if (selectedfilter === 'email') {
      this.selectedData = 'EMAIL';
    } else if (selectedfilter === 'notes') {
      this.selectedData = 'NOTES';
    } else if (selectedfilter === 'promise') {
      this.selectedData = 'PROMISE_TO_PAY';
    }else if (selectedfilter === 'tags') {
      this.selectedData = 'TAGS';
    }  else if (selectedfilter === 'all') {
      this.selectedData = 'ALL';
    }  else if (selectedfilter === 'payments') {
      this.selectedData = 'PAYMENTS';
    }
  }
  getSelectedData() {
    return this.tabData[this.selectedData];
  }

  sendMessage() {
    const emailBody = {
      invoice_key: this.invoiceData.invoiceKey,
      to_email: this.invoiceData.contactEmail,
      cc_email: this.subjectForm.get('cc').value,
      bcc_email: '',
      subject: this.subjectForm.get('subject').value,
      body: this.editorContent,
      is_add_attachment:this.subjectForm.get('attachInvoice').value,
      created_by: this.jwtToken.userEmail,
    };
    if(this.editorContent === null || this.editorContent.length === 0){
      this.invalidBorder =true;
      return
    }
    if (this.subjectForm.valid && (this.editorContent !== null ||  this.editorContent.length > 0)) {
    this._workbenchService.postEmailConversation(emailBody).subscribe(
      (response) => {
        this.loader.hide();
        this.invalidBorder =false;
        this.showBackDrop = false;
        this.editorContent = '';
        this.getMailDetails();
      },
      (error) => {
        this.loader.hide();
      }
    );
    }
    this.closeEmailDrilldown();
    this.subjectForm.reset();
    this.editorContent = '';
  }

  sendNotes() {
    const notes = {
      invoice_key: this.invoiceData.invoiceKey,
      body: this.notesContent,
      created_by: this.jwtToken.userName,
    };
    if(this.notesContent === null || this.notesContent.length === 0){
      this.invalidBorder =true;
      return
    }
    if (this.notesContent !== null ||  this.notesContent.length > 0) {
    this._workbenchService.postNotes(notes).subscribe(
      (response) => {
        this.loader.hide();
        this.invalidBorder =false;
        this.showBackDrop = false;
        this.notesContent = '';
        this.getMailDetails();
      },
      (error) => {
        this.loader.hide();
      }
    );
    }
    this.closeCreateNoteDrilldown();
    this.notesContent = '';
  }

  onKeyPress(event: KeyboardEvent) {
    const isPrintableCharacter = event.key.length > 0;

    if (isPrintableCharacter) {
        this.isNotesContentEmpty = false;
    } else {
        this.notesContent = '';
    }
  }

  onKeyPressmail(event: KeyboardEvent) {
    const isPrintableCharacter = event.key.length > 0;

    if (isPrintableCharacter) {
        this.isEmailContentEmpty = false;
        this.invalidBorder =false;
    } else {
        this.editorContent = '';
    }
  }

  onEditorContentChange() {
    this.isEmailContentEmpty = (this.editorContent?.trim() || '').length === 0;
  }

  onNotesContentChange() {
    this.isNotesContentEmpty = (this.notesContent?.trim() || '').length === 0;
  }


  async getTag(): Promise<void> {
    this.loader.show();
    this._workbenchService
      .getTagDetail(this.jwtToken.org.id, this.type)
      .subscribe((data) => {
        this.loader.hide();
        this.paymentTagData = data;
      });
  }

  toggleNewTag() {
    this.showNewTagInput = !this.showNewTagInput;
    if (!this.showNewTagInput) {
      this.newTagText = '';
    }
  }

  saveTagData() {
    if (this.newTagText) {
      const tagBody = {
        entity_id: this.jwtToken.org.id,
        tag: this.newTagText,
        type: this.type,
        loggedin_user: this.jwtToken.userEmail,
      };
      this.loader.show();
      this._workbenchService.postTag(tagBody).subscribe(
        (_response) => {
          this.loader.hide();
          this.getTag();
          this.showNewTagInput = false;
          this.newTagText = '';
          this.closePaymentDrilldown();
          this.getWorkbenchInvoicev2();
        },
        (_error) => {
          this.loader.hide();
        }
      );
    } else {
      this.newTagText = '';
    }
  }

  updateTagData() {
    if (this.selectedTag) {
      this.loader.show();
      this._workbenchService.updateTag(this.invoiceData.invoiceKey,this.selectedTag,this.jwtToken.userEmail).subscribe(
        (_response) => {
          this.loader.hide();
          this.getTag();
          this.showNewTagInput = false;
          this.newTagText = '';
          this.closePaymentDrilldown();
          this.getWorkbenchInvoicev2();
          this.getMailDetails();
        },
        (_error) => {
          this.loader.hide();
        }
      );
    } else {
      this.newTagText = '';
    }
  }

  showP2Pdrawer() {
    this.showPromise = true;
    this.showBackDrop = true;
  }

  onEditButtonClick() {
    this._workbenchService.notifyEditButtonClick();
    this.promiseToPayComponent.enableFormControls();
  }

  getMailDetailsFromChild(){
    this.getMailDetails();
    this.getWorkbenchInvoicev2();
  }
  getWorkbenchInvoicev2() {
    this._workbenchService.getWorkbenchInvoicev2(this.jwtToken.org.id, this._commonService.changedEventValue?.value, this.jwtToken.userEmail).subscribe(
      (workbench) => {
        this.workbenchData = workbench.data;

          this.workbenchData.forEach(data=>{
            if(this.invoiceData.invoiceKey === data.invoiceKey) {
              this.invoiceData = data;
              return
            }
        })
        this.showEmailChain(this.invoiceData);
        this.loader.hide();
      },
      (err) => {
        this.loader.hide();
      }
    );
  }

  showEmailChain(selInvoice) {
    selInvoice.unreadCount = 0;
    this.invoiceData = selInvoice;
    this.composeMail = false;
    this.mailCc = false;
    this.mailBcc = false;
    this.getMailDetails();
    this._workbenchService.sendP2PKey(this.invoiceData?.p2pKey);
    this.selectedTag = this.invoiceData?.tag || '';
  }

  fetchCustomerDetailsByCustomerId(){
    this._workbenchService.getCustomerDetailsById(this.jwtToken.org.id,this.selectedArSource.value,this.invoiceData?.customerId).subscribe(
      (data) => {
      this.customerDetails = data && data.data && data.data.length > 0 ? data.data[0] : null;
      this.createCCArray();
      this.loader.hide();
      },
      (error) => {
        this.loader.hide();
      });
    }

    showPopupWrittenOff(messageText,status) {
      const modelref = this._NgbModal.open(CommonPopupAlertComponent, {
        size: 'md',
        windowClass: 'nu-simple-popup-alert-modal',
        backdropClass: 'nu-simple-popup-alert-backdrop',
        centered: true,
      });
      modelref.componentInstance.messageText= messageText;
      modelref.componentInstance.showConfirmButton= true;
      modelref.componentInstance.showDeleteButton= false;
      modelref.result.then(data=>{
        if(data === 'success') {
          this.loader.show();
          this._workbenchService.updateWriteOff(this.invoiceData?.invoiceKey,status).subscribe(
            (_response) => {
              this.loader.hide();
              this._router.navigate(['workbench/ar/automation/invoices']);
              this.getWorkbenchInvoicev2();
              this.getMailDetails();
            },
            (_error) => {
              this.loader.hide();
            })
        }
      }).catch(err=>{
        console.log('err',err)
      })
    }
}

