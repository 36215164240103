import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { JWTToken } from 'app/core/service/auth/auth';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { GrowthbookService } from 'app/core/service/growthBook/growthbook.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { DashboardService } from 'app/service/dashboard/dashboard.service';
import { CommonService } from 'app/service/shared/common.service';

@Component({
  selector: 'app-ap-dashboard',
  templateUrl: './ap-dashboard.component.html',
  styleUrls: ['./ap-dashboard.component.scss']
})
export class ApDashboardComponent {
  constructor(
    public authServices: AuthServices,
    private _loader: LoaderService,
    private _dashboardService: DashboardService,
    public commonService: CommonService,
    public _router: Router,
    public _growthBookServ: GrowthbookService,

  ) {}

  // public isDemo = undefined;
  public selectedAccount = 'asondate';
  public currentDate = new Date();
  public accounts = [
    {name: "As on Date", value: 'asondate'}
  ];
  ngOnInit(): void {
    this._loader.show();
    // this.getGrowthBook();
    this.fetchArDashboard();
    this.commonService.setPageHeader("Accounts Payable ");
  }

  public arRes :any = undefined;
  jwtToken: JWTToken = undefined;

  async fetchArDashboard() {
    this.jwtToken = await this.authServices.getJWTDetail();
    this._dashboardService.getApdashboard(this.jwtToken.org.id,'2023-01-01','2023-06-15').subscribe(data =>{
      this.arRes = data.data?.groups
      setTimeout(() => {
        this._loader.hide();
      }, 2000);
    },err=>{
      this._loader.hide();
    })
  }

  // async getGrowthBook(): Promise<any> {

  //   this.isDemo =
  //     this._growthBookServ.getGBookStatus('ap_dashboard_demo');
  // }


  public topVendor = [
    {name: "Acme Corporation" , amount: "$781,321,430", billCount: "4 Bills", status:"Overdue", image: "./assets/image/hubspot.svg"},
    {name: "Global Logistics Ltd." , amount: "$694,294,133", billCount: "2 Bills", status:"Overdue", image: "./assets/image/lorem.svg"},
    {name: "Liberty Insurance Group" , amount: "$583,923,213", billCount: "3 Bills", status:"Overdue", image: "./assets/image/ipsm.svg"},
    {name: "Apex Software Technologies" , amount: "$532,413,930", billCount: "7 Bills", status:"Due", image: "./assets/image/hubspot.svg"},
    {name: "GreenEnergy Systems" , amount: "$493,920,201", billCount: "4 Bills", status:"Due", image: "./assets/image/lorem.svg"},
  ]

  public acPayable=     {
    "connotation": false,
    "name": "Cleared Bills",
    "type": "horizontalChartDataCard",
    "value": "14 Invoices",
    "chart": {
      "unit": "#",
      "data": [
        { "category": "Apr", "value": 2 },
        { "category": "May", "value": 3 },
        { "category": "Jun", "value": 2 },
        { "category": "Jly", "value": 3 },
        { "category": "Aug", "value": 5 },
        { "category": "Sep", "value": 3 },
      ],
      "color": "#4D81E5",
      "marker": true,
      "dataLabel": false,
      "tooltip": true,
      "yAxisLine": false,
      "type": "verticalbar",
      "xAxisLine": true
    },
    "aggregate": "$13,115.00"
  }

  public aging =

      {
        "name": "AP Aging Report",
        "type": "verticalbarchart",
        "chart": {
          "unit": "#",
          "data": [
            { "category": "0-30d", "value": 1185 },
            { "category": "31-60d", "value": 1185 },
            { "category": "61-90d", "value": 2570 },
            { "category": ">90d", "value": 1170 }
          ],
          "color": "#E7854D",
          "marker": true,
          "dataLabel": false,
          "tooltip": true,
          "yAxisLine": true,
          "type": "verticalbar",
          "xAxisLine": true
        }
      }
}
