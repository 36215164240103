import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JWTToken } from 'app/core/service/auth/auth';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import {
  ConnectionDetail,
  CreateConnectorBody,
} from 'app/service/connectorconfig/connector';
import { ConnectorService } from 'app/service/connectorconfig/connector.service';
import { RoleService } from 'app/service/role/role.service';
import { CommonService } from 'app/service/shared/common.service';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-connector',
  templateUrl: './connector-home.component.html',
  styleUrls: ['./connector-home.component.scss'],
})
export class ConnectorComponent implements OnInit {
  public _CONNECTOR_ = false;


  constructor(
    public connectorService: ConnectorService,
    public activatedRoute: ActivatedRoute,
    public authServices: AuthServices,
    public router: Router,
    private loader: LoaderService,
    private toaster: ToastrService,
    public commonService: CommonService,
    private _roleService : RoleService,
  ) {}

  sourceKey: string = undefined;
  code: string = undefined;
  realmId: string = undefined;
  location: string = undefined;
  accountsServer: string = undefined;
  jwtToken: JWTToken = undefined;
  activeTabCategory: string;
  activeTabType: string = undefined;
  typeMap: Map<string, Map<string, any[]>> = undefined;
  mapLoad = false;
  widgetData: any;
  orgList: any;
  selectedOrg: any;
  public nameFromModel: string;
  public isDynamicComponentVisible: boolean = false;
  public showWidget = false;

  async ngOnInit() {
    this.roleStatus();
    this.commonService.setHideAiIcon(false);
    this.widgetData = {
      widgetImage: `https://s3.amazonaws.com/dev-workflow.numero.app/assets/image/bg-image/Acc_NUMERO_ZOHOBOOKS.svg`,
      widgetClassname: 'widget-account-list',
      widgetHeading: 'Select organization',
      widgetDesc: '',
      widgetBtnText: 'Proceed',
      list: [
        { name: 'Cloudyn', id: '631' },
      ],
    };
    this.loader.show();
    setTimeout(() => {
      this.loader.hide();
    }, 6000);
    try {
      await this.getActivatedRoute();
    } catch (error) {}
    this.commonService.setPageHeader("Connectors");
  }

  async getActivatedRoute(): Promise<any> {
    if (this.jwtToken === undefined) {
      this.jwtToken = await this.authServices.getJWTDetail();
    }
    let state = this.activatedRoute.snapshot.queryParams.state;
    if (state) {
      this.sourceKey = state.split('-')[0];
      this.activeTabType = state.split('-')[1];
    }
    this.code = this.activatedRoute.snapshot.queryParams.code;
    this.router
      .navigate(['connectors'])
      .then(() => {})
      .catch((error) => {});
    if (this.sourceKey && this.code) {
      if (this.sourceKey === 'QUICKBOOKS') {
        this.realmId = this.activatedRoute.snapshot.queryParams.realmId;
      } else if (this.sourceKey === 'ZOHOBOOKS') {
        this.location = this.activatedRoute.snapshot.queryParams.location;
        this.accountsServer =
          this.activatedRoute.snapshot.queryParams['accounts-server'];
      }
      if (this.sourceKey === 'ZOHOBOOKS') {
        await this.fetchconnectedOrgList();
      } else {
        await this.createConnector();
      }
    } else {
      await this.getConnector();
    }

    return Promise.resolve('start of new Promise');
  }

  async getConnector(): Promise<any> {
    this.loader.show();
    const jwtToken = await this.authServices.getJWTDetail();
    this.typeMap = new Map<string, Map<string, ConnectionDetail[]>>();
    forkJoin([
      this.connectorService.getConnectionConfiguration(),
      this.connectorService.getConnectedSources(jwtToken.org.id),
    ]).subscribe(([connectorRes, connectionRes]) => {
      let connectedMap = new Map<string, any[]>();
      connectionRes.map((connection: any) => {
        connection.sources.forEach((source) => {
          connectedMap.set(
            connection.type + '#' + source.source_key,
            source.connection_items
          );
        });
      });

      connectorRes.map((connector) => {
        connector.webhook = connector.additional_details
          ? JSON.parse(connector.additional_details).webhook_enabled
          : false;

        connector.connectedItems = connectedMap.has(
          connector.type + '#' + connector.source_key
        )
          ? connectedMap.get(connector.type + '#' + connector.source_key)
          : [];
        connector.connectedCount = connector.connectedItems?.length;

        if (this.typeMap.has(connector.type)) {
          if (this.typeMap.get(connector.type).has(connector.category)) {
            this.typeMap
              .get(connector.type)
              .get(connector.category)
              .push(connector);
          } else {
            this.typeMap
              .get(connector.type)
              .set(connector.category, new Array(connector));
          }
          this.typeMap.get(connector.type).get('All').push(connector);
        } else {
          this.typeMap.set(
            connector.type,
            new Map().set('All', new Array(connector))
          );
          this.typeMap
            .get(connector.type)
            .set(connector.category, new Array(connector));
        }
      });
      this.activeTabCategory = 'All';
      if (this.activeTabType === undefined) this.activeTabType = 'workflow';
      this.loader.hide()
      this.mapLoad = true;
    },err=>{
      this.mapLoad = true;
      this.loader.hide()
    });
  }

  typemapKeys(){
    return Array.from(this.typeMap.keys());
  }

  activeTabKeys(activeTab){
    return Array.from( this.typeMap.get(activeTab).keys())
  }

  async fetchconnectedOrgList() {
    this.connectorService
      .getConnectedOrgList(
        this.code,
        this.location,
        this.jwtToken.org.id,
        this.activeTabType
      )
      .subscribe(
        (data) => {
          this.orgList = data;
          this.widgetData.list =
            this.orgList?.identifierResponse?.organizations;
          this.getConnector();
          this.showWidget = true;
        },
        (err) => {
          console.log(err);
          this.getConnector();
        }
      );
  }

  async createConnector(): Promise<any> {
    this.loader.show();
    if (this.jwtToken === undefined) {
      this.jwtToken = await this.authServices.getJWTDetail();
    }
    let body: CreateConnectorBody = {
      code: this.code,
      type: this.activeTabType,
      source_key: this.sourceKey,
      entity_key: this.jwtToken.org.id,
      user_email: this.jwtToken.userEmail,
    };

    if (this.realmId) {
      body.realm_id = this.realmId;
    }
    if (this.location) {
      body.location = this.location;
    }

    if (this.accountsServer) {
      body.accountsServer = this.accountsServer;
    }

    if (this.sourceKey === 'ZOHOBOOKS') {
      delete this.orgList.identifierResponse;
      delete body.code;
      body.exchangeCode = JSON.stringify(this.orgList);
      body.orgDetails = this.selectedOrg;
    }

    this.connectorService.createConnection(body).subscribe(
      (data) => {
        this.getConnector();
        this.toaster.success('Successfully connected!', 'Success', {
          timeOut: 3000,
        });
      },
      (err) => {
        this.getConnector();
        this.toaster.error(err.error.message, 'ERROR', {
          timeOut: 3000,
        });
      }
    );
    // try {
    //   console.log('connection create');
    //   const connector = await this.getConnector();
    //   console.log('connection create done');
    // } catch (err) {
    //   this.getConnector();
    //   this.toaster.error(err.error.message, 'ERROR', {
    //     timeOut: 3000,
    //   });
    // }
    // return Promise.resolve('start of new Promise');
  }

  async authPopupSubmitted(sourceKey: string): Promise<void> {
    console.log('auth 2...');
    const route = await this.getActivatedRoute();
  }

  filterTabs(type: string): void {
    this.activeTabType = type;
    this.activeTabCategory = 'All';
  }

  filterSources(category: string): void {
    this.activeTabCategory = category;
  }

  childButtonClicked(event) {
    this.selectedOrg = event;
    this.createConnector();
    this.showWidget = false;
  }


  roleStatus() {
    this._CONNECTOR_ = this._roleService.checkRole("CONNECTOR",'MANAGE');
  }
}
