import {
  AfterViewInit,
  Component,
  ElementRef,
  LOCALE_ID,
  ViewChild,
} from '@angular/core';
import {
  PageService,
  PageSettingsModel,
  QueryCellInfoEventArgs,
  RowDataBoundEventArgs,
  ToolbarService,
  ExcelExportService,
  PdfExportService,
} from '@syncfusion/ej2-angular-grids';
import {
  ITreeData,
  TreeGridExcelExportProperties,
  TreeGridPdfExportProperties,
} from '@syncfusion/ej2-treegrid';
import {
  FreezeService,
  ResizeService,
  TreeGridComponent,
} from '@syncfusion/ej2-angular-treegrid';
import { ReportService } from 'app/service/report/report.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { JWTToken } from 'app/core/service/auth/auth';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { Router } from '@angular/router';
import { Gridlines } from '@syncfusion/ej2-diagrams';
import {
  AccumulationTheme,
  ChartTheme,
  IAccLoadedEventArgs,
  IAxisLabelRenderEventArgs,
  ILoadedEventArgs,
} from '@syncfusion/ej2-angular-charts';
import { Browser } from '@syncfusion/ej2-base';
import { IntercompanyEliminationComponent } from '../intercompany-elimination/intercompany-elimination.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartService } from 'app/service/shared/chart.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { GrowthbookService } from 'app/core/service/growthBook/growthbook.service';
import {
  CalendarComponent,
  CalendarView,
  DateRangePickerComponent,
} from '@syncfusion/ej2-angular-calendars';
import { CustomCurrencyPipe } from 'app/shared/utills/currency.pipe';
@Component({
  selector: 'app-profit-and-loss',
  templateUrl: './profit-and-loss.component.html',
  styleUrls: ['./profit-and-loss.component.scss'],
  providers: [
    ToolbarService,
    PageService,
    ExcelExportService,
    PdfExportService,
    FreezeService,
    CustomCurrencyPipe,
    DecimalPipe,
    ResizeService,
    DatePipe,
  ],
})
export class ProfitAndLossComponent implements AfterViewInit {
  constructor(
    private _reportService: ReportService,
    public authServices: AuthServices,
    private loader: LoaderService,
    public _router: Router,
    private _NgbModal: NgbModal,
    private chartService: ChartService,
    public growthBookServ: GrowthbookService,
    private _customCurrencyPipe: CustomCurrencyPipe,
    private _decimalPipe: DecimalPipe,
    public datepipe: DatePipe
  ) {}

  @ViewChild('datePicker') datePicker: DateRangePickerComponent;
  @ViewChild('treegrid')
  public treegrid: TreeGridComponent;
  public gridData: Object[] = [];
  public formatedData: Object[] = [];
  public pageSettings: PageSettingsModel;
  public toolbar: string[];
  public widgetData: any = {};
  public isDepartmentPLEnabled: boolean = false;
  public tableHeader = [];
  public timeranges = [
    { name: 'YTD', end_date: '2023-05-20', start_date: '2023-01-01' },
  ];
  public selectedPeriod = {
    name: 'YTD',
    end_date: '2023-05-20',
    start_date: '2023-01-01',
  };
  public topmenu = [
    { name: 'General', value: 'general' },
    { name: 'Department', value: 'department' },
  ];
  public activeTab = 'general';
  public setdateperiod = 'twelwe';
  public customAttributes: Object;
  public selectedAccount = 'Customer';
  public height: number;
  public screenHeight: any;
  jwtToken: JWTToken = undefined;
  public collapseStatePersist: boolean = true;
  public showHideWidget = undefined;

  public totalIncome = 0;
  public cogs = 0;
  public operatingExpense = 0;
  public netIncome = 0;
  public otherExpense = 0;

  public gbplSubTab: boolean = false;

  public chartData: any;
  public marker: Object = {
    visible: true,
    width: 7,
    height: 7,
    isFilled: true,
  };
  public nonMarker: Object = {
    visible: false,
  };
  //Initializing Primary X Axis
  public primaryXAxis: Object = {
    majorGridLines: { width: 0 },
    valueType: 'Category',
    majorTickLines: { width: 0 },
    minorTickLines: { width: 0 },
    lineStyle: { width: 0 },
    labelStyle: {
      fontFamily: 'Inter,sans-serif',
      color: '#8D8F95',
    },
  };
  //Initializing Primary Y Axis
  public primaryYAxis: Object = {
    title: '',
    edgeLabelPlacement: 'Shift',
    majorGridLines: { color: '#C5C5C5', width: 0, dashArray: 0 },
    lineStyle: { width: 0 },
    majorTickLines: { width: 0 },
    minorTickLines: { width: 0 },
    labelStyle: {
      fontFamily: 'Inter,sans-serif',
      color: '#8D8F95',
    },
  };

  public tooltip: Object = {
    enable: true,
    shared: true,
  };
  public legendSettings: Object = {
    visible: false,
  };
  public animation1: Object = {
    enable: false,
  };
  public chartArea: Object = {
    border: {
      width: 0,
    },
  };

  public width: string = Browser.isDevice ? '100%' : '100%';
  public load(args: ILoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.chart.theme = <ChartTheme>(
      (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(
        /-dark/i,
        'Dark'
      )
    );
  }
  // custom code end
  public vertical: boolean = false;
  public count: number = 0;

  public palette = ['#4D81E5', '#E7854D', '#32B19A', '#C65368', '#E8B55A'];

  public selAcc = { name: '', value: '', source_name: '', currency: '' };
  public accounts = [];
  public color = ['#0D3D86', '#F4AC45', '#499167'];

  public showChart = true;
  show: boolean = false;

  public showChartDonut = true;
  public palettes = [
    '#F9A03F',
    '#499167',
    '#54A9C5',
    '#C24545',
    '#369F73',
    '#4773A7',
    '#E87D65',
    '#725E16',
    '#8268A5',
    '#0D3D86',
  ];

  public animation: Object = {
    enable: true,
  };
  public border: Object = { width: 3 };
  public ChartDataDonut = [
    { name: 'Others', y: 1267620, TextMapping: 'Others : 1267620' },
    { name: 'Workez', y: 499000, TextMapping: 'Workez : 499000' },
    { name: 'Linkedin', y: 143000, TextMapping: 'Linkedin : 143000' },
    { name: 'QED Corp', y: 105000, TextMapping: 'QED Corp : 105000' },
    { name: 'Regalos', y: 30000, TextMapping: 'Regalos : 30000' },
    { name: 'AWS India', y: 20000, TextMapping: 'AWS India : 20000' },
    { name: 'Xpayroll', y: 3100, TextMapping: 'Xpayroll : 3100' },
  ];
  public pieTooltipSetting: Object = {
    enable: true,
    format: '${point.x} : ${point.y}',
    textStyle: {
      fontFamily: 'Inter, sans-serif',
    },
  };
  public dataLabel: Object = {
    visible: true,
    position: 'Outside',
    name: 'TextMapping',
    connectorStyle: { length: '10px', type: 'Curve' },
    font: { fontFamily: 'Inter, sans-serif', color: '#8D8F95' },
    // template: '<div style="color: #8D8F95; font-family: Inter, sans-serif">${point.y}</div>',
  };
  public enableBorderOnMouseMove: boolean = false;
  public enableSmartLabels: boolean = true;
  public pielegendSettings: Object = {
    visible: true,
    textStyle: { fontFamily: 'Inter, sans-serif', color: '#8D8F95' },
  };
  public accumulationload(args: IAccLoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.accumulation.theme = <AccumulationTheme>(
      (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1))
        .replace(/-dark/i, 'Dark')
        .replace(/contrast/i, 'Contrast')
    );
  }

  public totExp = 124948;

  public start: CalendarView = 'Year';
  public depth: CalendarView = 'Year';
  public format: string = 'MMM yyyy';

  public startDate: any;
  public endDate: any;

  // public growthBookCustom = this.growthBookServ.getGBookStatus(
  //   'reports_custom_date'
  // );

  ngOnInit() {
    this.widgetData = {
      widgetImage: `https://s3.amazonaws.com/dev-workflow.numero.app/assets/image/bg-image/Report-sample.svg`,
      widgetClassname: 'report-widget',
      widgetHeading: 'Connect your Accounting system',
      widgetDesc:
        'Consolidate multiple accounts and visualise - P&L , Cash Flow, Expense & Balance Sheet',
      widgetBtnText: 'Connect',
      widgetBtnNavigation: '',
      widgetBgImageUrl: `https://s3.amazonaws.com/live.dataflo.io/assets/image/dashboard-bg.png`,
    };
    this.loader.show();
    this.pageSettings = { pageSize: 10 };
    this.customAttributes = { class: 'customcss' };
    this.fetchTimeRange();
    this.getGrowthBook();
    //this.screenHeight = window.innerHeight - 300;
  }

  async getGrowthBook(): Promise<any> {
    this.gbplSubTab = this.growthBookServ.getGBookStatus('pl_sub_tab');
  }

  ngAfterViewInit(): void {
    // this.treegrid.grid.height = 500;
    // setTimeout(() => {
    //   this.treegrid.grid.height = this.screenHeight;
    // },300);
  }

  pdfDownload() {
    let pdfExportProperties: TreeGridPdfExportProperties = {
      isCollapsedStatePersist: this.collapseStatePersist,
    };
    this.treegrid.pdfExport(pdfExportProperties);
  }

  excelDownload() {
    let excelExportProperties: TreeGridExcelExportProperties = {
      isCollapsedStatePersist: this.collapseStatePersist,
    };
    this.treegrid.excelExport(excelExportProperties);
  }
  childButtonClicked(event) {
    this._router.navigate(['connectors']);
  }
  queryCellInfo(args: QueryCellInfoEventArgs) {
    if (!(args.data as ITreeData).hasChildRecords) {
      if ((args.cell as HTMLElement).classList.contains('e-unboundcell')) {
        (
          (args.cell as HTMLElement).querySelector(
            '.e-unboundcelldiv'
          ) as HTMLElement
        ).style.display = 'none';
      }
    }
  }
  rowDataBound(args: RowDataBoundEventArgs) {
    if (!(args.data as ITreeData).hasChildRecords) {
      // (args.row as HTMLElement).style.backgroundColor = 'green';
      (args.row as HTMLElement).classList.add('child-row');
    }
    (args.row as HTMLElement).classList.add(
      'class-level-' + (args.data as ITreeData).level
    );
  }
  dataBound(event): void {
    this.treegrid.grid.hideScroll();
  }

  async fetchTimeRange() {
    this.jwtToken = await this.authServices.getJWTDetail();
    this._reportService.getProfitAndLossFilter(this.jwtToken.org.id).subscribe(
      (data) => {
        this.accounts = data.accounts;
        this.timeranges = data.timerange;
        this.selectedPeriod = this.timeranges[0];
        // if (this.growthBookCustom) {
          this.timeranges.push({
            name: 'Custom',
            end_date: this.endDate,
            start_date: this.startDate,
          });
        // }
        if (data['is_department_pl_enabled'] != null) {
          this.isDepartmentPLEnabled = data['is_department_pl_enabled'];
        } else {
          this.isDepartmentPLEnabled = false;
        }
        if (this.accounts.length > 0) {
          // console.log('1',this.accounts[0])
          // console.log('2',this._reportService.getAccount())
          this.selAcc = this.accounts.filter(
            (d) => d.value === this._reportService.getAccount().value
          )[0];
          this.showHideWidget = false;
          this.fetchProfitandLoss();
        } else {
          this.loader.hide();
          this.showHideWidget = true;
        }
      },
      (err) => {
        this.loader.hide();
      }
    );
  }

  fetchProfitandLoss(): void {
    this.loader.show();
    this.tableHeader = [];
    this.gridData = [];
    // this.setdateperiod = this._reportService.getSelectedPeriod(this.selectedPeriod,this.startDate,this.endDate);
    const growthBookVariable =
      this.growthBookServ.getGBookStatus('department_pl');
    this._reportService
      .getProfitLoss(
        this.jwtToken.org.id,
        this.selectedPeriod.start_date,
        this.selectedPeriod.end_date,
        this.selAcc.value,
        growthBookVariable,
        this.activeTab
      )
      .subscribe(
        (data) => {
          this.tableHeader = data.header;
          // if (
          //   this.selectedPeriod.name === 'Quarter-to-date' &&
          //   this.activeTab !== 'department'
          // ) {
            this.setdateperiod = this._reportService.getSelectedPeriod(this.selectedPeriod,this.startDate,this.endDate,this.tableHeader.length);
          // }
          this.gridData = data.data;
          this.chartData = this.formatDataForChart();
          this.formatedData = data.data.map((grid: any) => {
            const transformedGrid: any = { ...grid };
            if (!transformedGrid.subAccount) {
              transformedGrid.subAccount = [];
            }
            for (const key in transformedGrid) {
              if (
                !isNaN(Number(transformedGrid[key])) &&
                !Array.isArray(transformedGrid[key])
              ) {
                transformedGrid[key] = parseFloat(transformedGrid[key]);
              }
            }
            Object.entries(transformedGrid).forEach(([key, value]) => {
              if (key !== 'subAccount' && key !== 'accountName') {
                transformedGrid[key] = this._customCurrencyPipe.transform(
                  value as string | number,
                  this.selAcc.currency
                );
              } else if (key === 'subAccount') {
                const transformedSubAccount = transformedGrid[key].map(
                  (roll: any) => {
                    const transformedRoll: any = { ...roll };
                    Object.entries(transformedRoll).forEach(([key, value]) => {
                      if (key !== 'subAccount' && key !== 'accountName') {
                        transformedRoll[key] =
                          this._customCurrencyPipe.transform(
                            value as string | number,
                            this.selAcc.currency
                          );
                      }
                    });
                    return transformedRoll;
                  }
                );
                transformedGrid[key] = transformedSubAccount;
              }
            });
            return transformedGrid;
          });

          this.treegrid.dataSource = this.formatedData;
          setTimeout(() => {
            this.treegrid.expandAtLevel(0);
          }, 1000);
          this.loader.hide();
        },
        (err) => {
          this.loader.hide();
        }
      );
  }

  private handleProfitLossData(data: any): void {
    this.tableHeader = data.header;
    this.gridData = data.data;
    this.chartData = this.formatDataForChart();
    this.formatedData = this.transformGridData(data.data);

    this.treegrid.dataSource = this.formatedData;
    setTimeout(() => {
      this.treegrid.expandAtLevel(0);
    }, 1000);
  }

  private transformGridData(data: any[]): any[] {
    return data.map((grid: any) => {
      const transformedGrid: any = { ...grid };
      if (!transformedGrid.subAccount) {
        transformedGrid.subAccount = [];
      }
      for (const key in transformedGrid) {
        if (
          !isNaN(Number(transformedGrid[key])) &&
          !Array.isArray(transformedGrid[key])
        ) {
          transformedGrid[key] = parseFloat(transformedGrid[key]);
        }
      }
      Object.entries(transformedGrid).forEach(([key, value]) => {
        if (key !== 'subAccount' && key !== 'accountName') {
          transformedGrid[key] = this._customCurrencyPipe.transform(
            value as string | number,
            this.selAcc.currency
          );
        }
        if (key === 'subAccount') {
          const transformedSubAccount = transformedGrid[key].map(
            (roll: any) => {
              return this.transformSubAccount(roll);
            }
          );
          transformedGrid[key] = transformedSubAccount;
        }
      });
      return transformedGrid;
    });
  }

  private transformSubAccount(roll: any): any {
    const transformedRoll: any = { ...roll };
    Object.entries(transformedRoll).forEach(([key, value]) => {
      if (key !== 'subAccount' && key !== 'accountName') {
        transformedRoll[key] = this._customCurrencyPipe.transform(
          value as string | number,
          this.selAcc.currency
        );
      }
    });
    return transformedRoll;
  }

  public formatDataForChart(): any {
    const objKeys = Object.keys(this.gridData[0]);
    const itemTobeRemoved = ['total', 'subAccount', 'accountName'];
    const chartKeys = this.removeItems(objKeys, itemTobeRemoved);

    let obj: any = {};
    let lineData = [];
    let chartData = [];
    this.gridData.forEach((gD: any) => {
      lineData = [];
      obj = {};
      if (
        gD.accountName === 'Income' ||
        gD.accountName === 'Cost of Goods Sold' ||
        gD.accountName === 'Net Income' ||
        gD.accountName === 'Other Expenses' ||
        gD.accountName === 'Operating Expenses'
      ) {
        if (gD.accountName === 'Income') {
          // obj.color =  '#4D81E5';
          obj.type = 'Column';
          obj.marker = this.nonMarker;
          this.totalIncome = gD.total;
        }
        if (gD.accountName === 'Cost of Goods Sold') {
          this.cogs = gD.total;
          obj.type = 'Column';
          obj.marker = this.nonMarker;
          // obj.color =  '#E7854D';
        }
        if (gD.accountName === 'Net Income') {
          obj.type = 'Line';
          // obj.color =  '#32B19A';
          this.netIncome = gD.total;
          obj.marker = this.marker;
        }
        if (gD.accountName === 'Other Expenses') {
          // obj.color =  '#C65368';
          obj.marker = this.nonMarker;
          obj.type = 'Column';
          this.otherExpense = gD.total;
        }
        if (gD.accountName === 'Operating Expenses') {
          // obj.color =  '#E8B55A';
          obj.type = 'Column';
          obj.marker = this.nonMarker;
          this.operatingExpense = gD.total;
        }

        chartKeys.forEach((ck) => {
          lineData.push({ column: parseFloat(gD[ck]), year: ck });
        });
        obj.name = gD.accountName;
        obj.data = lineData;
        chartData.push(obj);
      }
    });
    return chartData;
  }
  removeItems = (objKeys, itemToRemitemTobeRemovedove) => {
    return objKeys.filter((v) => {
      return !itemToRemitemTobeRemovedove.includes(v);
    });
  };

  hidechart() {
    this.showChart = false;
  }
  showchart() {
    this.showChart = true;
  }
  hidechartDonut() {
    this.showChartDonut = false;
  }
  showchartDonut() {
    this.showChartDonut = true;
  }
  OnChartAxisLabeRender(args: IAxisLabelRenderEventArgs) {
    this.chartService.formatAxisLabel(args);
  }
  showModalInterElimination() {
    const modelref = this._NgbModal.open(IntercompanyEliminationComponent, {
      size: 'xl',
      windowClass: 'knock-off-modal',
      backdropClass: 'knock-off-backdrop',
      backdrop: 'static',
    });
    let accList = [];
    accList = this.accounts.filter((data) => {
      return !data.name.startsWith('All');
    });
    // accList.unshift({ name: 'Select account', value: null });
    modelref.componentInstance.accounts = accList;

    modelref.result.then((result) => {
      this.fetchTimeRange();
    });
  }

  dropdownChangedEvent(selaccount) {
    this.selAcc = selaccount;
    this.fetchProfitandLoss();
  }

  dropdownTimeChangedEvent(seltime) {
    this.selectedPeriod = seltime;
    if (this.selectedPeriod.name !== 'Custom') {
      this.fetchProfitandLoss();
    } else {
      this.openDatePicker();
    }
  }

  filterSources(seltab) {
    this.activeTab = seltab;
    this.fetchProfitandLoss();
  }

  openDatePicker() {
    setTimeout(() => {
      this.datePicker.show();
    }, 1);
  }

  onDateChange(event: any) {
    if (event && event.value) {
      const selectedDates = event.value;
      this.startDate = this.datepipe.transform(selectedDates[0], 'yyyy-MM-dd');
      this.endDate = this.datepipe.transform(selectedDates[1], 'yyyy-MM-dd');
      this.selectedPeriod=({
        name: 'Custom',
        end_date: this.endDate,
        start_date: this.startDate,
      });
      this.fetchProfitandLoss();
    }
  }
}
