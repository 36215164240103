import { Component, OnInit } from '@angular/core';
import { LoaderService } from './core/service/loader/loader.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loading$ = this.loader.loading$;
  constructor(public loader: LoaderService) {}

  public environment = environment;
  title = 'goconstant-ui';
}
