import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReconciliationRoutingModule } from './reconciliation-routing.module';
import { ReconciliationHomeComponent } from './reconciliation-home/reconciliation-home.component';
import { CoreModule } from 'app/core/core.module';
import { SharedModule } from 'app/shared/shared.module';


@NgModule({
  declarations: [
    ReconciliationHomeComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    ReconciliationRoutingModule
  ]
})
export class ReconciliationModule { }
