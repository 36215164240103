import { Component } from '@angular/core';
import { JWTToken } from 'app/core/service/auth/auth';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { AiService } from 'app/service/ai/ai.service';
import { CommonService } from 'app/service/shared/common.service';
import {
  FontModel,
  IAccTooltipRenderEventArgs,
  ILegendRenderEventArgs,
} from '@syncfusion/ej2-angular-charts';
import { environment } from 'environments/environment';
import { DatePipe } from '@angular/common';

const BANK_RES = {
  data: [
    {
      name: 'Acme Co.	',
      y: 24829,
    },
    {
      name: 'Abstergo Ltd.	',
      y: 35000,
    },
  ],
  // data: [
  //   {
  //     name: 'Bank of America',
  //     y: 58789.00
  //   },
  //   {
  //     name: 'Citibank',
  //     y:   26799.00,
  //   },
  //   {
  //     name: 'Chase',
  //     y:   10800.00,
  //   },
  //   {
  //     name: 'HDFC',
  //     y: 17073.17,
  //   },

  // ],
  headers: [
    { displayName: 'Inv No', fieldName: 'Inv_No' },
    { displayName: 'Customer Name', fieldName: 'Customer_Name' },
    { displayName: 'Invoice Amount', fieldName: 'Invoice_Amount' },
    { displayName: 'Currency', fieldName: 'Currency' },
    { displayName: 'Outstanding Amount', fieldName: 'Outstanding_Amount' },
  ],
  staticResponse:
    'As of today, the cumulative cash balance stands at USD 135,759.00. Refer to the following chart illustrating the distribution of balances across various banks.',
};
@Component({
  selector: 'app-ai-result',
  templateUrl: './ai-result.component.html',
  styleUrls: ['./ai-result.component.scss'],
  providers: [DatePipe]
})
export class AiResultComponent {
  active = 1;
  public followUp = [
    'Can you display based on Invoice Aging?',
    'Can you filter only for the last month?',
    'Send to Slack',
  ];

  public tblData: any;
  public tblHeader: any;
  public inputText: string;
  public promptRes: any;
  public jwtToken: JWTToken = undefined;
  public statedata: any;
  public chatHistory: any = [];
  //  = [
  //   {
  //     type: 'sent',
  //     question: 'List of customer',
  //     prompt_key: '',
  //   },
  //   {
  //     type: 'received',
  //     data: [
  //       { ENTITY_ID: 4047, ACCOUNT_IDENTIFIER: '123' },
  //       { ENTITY_ID: 4047, ACCOUNT_IDENTIFIER: '123' },
  //     ],
  //     headers: [
  //       { displayName: 'ENTITY ID', fieldName: 'ENTITY_ID' },
  //       { displayName: 'ACCOUNT IDENTIFIER', fieldName: 'ACCOUNT_IDENTIFIER' },
  //     ],
  //     staticResponse:
  //       'Sure, I can assist along with the details of the banks:',
  //   },
  //   {
  //     type: 'sent',
  //     question: 'List of ram',
  //     prompt_key: '',
  //   },
  //   {
  //     type: 'received',
  //     data: [
  //       { ENTITY_ID: 4047, ACCOUNT_IDENTIFIER: '123' },
  //       { ENTITY_ID: 4047, ACCOUNT_IDENTIFIER: '123' },
  //     ],
  //     headers: [
  //       { displayName: 'ENTITY ID', fieldName: 'ENTITY_ID' },
  //       { displayName: 'ACCOUNT IDENTIFIER', fieldName: 'ACCOUNT_IDENTIFIER' },
  //     ],
  //     staticResponse:
  //       'Yes, I can assist you with that. Here is the cash balance as of today, along with the details of the banks:',
  //   },
  //   {
  //     type: 'sent',
  //     question: 'List of tom',
  //     prompt_key: '',
  //   },
  //   {
  //     type: 'received',
  //     data: [
  //       { ENTITY_ID: 4047, ACCOUNT_IDENTIFIER: '123' },
  //       { ENTITY_ID: 4047, ACCOUNT_IDENTIFIER: '123' },
  //     ],
  //     headers: [
  //       { displayName: 'ENTITY ID', fieldName: 'ENTITY_ID' },
  //       { displayName: 'ACCOUNT IDENTIFIER', fieldName: 'ACCOUNT_IDENTIFIER' },
  //     ],
  //     staticResponse:
  //       'No, I can assist you with that. Here is the cash balance as of today, along with the details of the banks:',
  //   },
  // ];
  public environment = environment;
  constructor(
    private _loader: LoaderService,
    public _commonService: CommonService,
    public _aiService: AiService,
    public _authServices: AuthServices,
    public _datePipe: DatePipe
  ) { }

  // pie start
  primaryXAxis: Object = {};
  chartData: any;
  pieData: any;
  count = 0;
  title: string = '';
  pointColorMapping = '';
  public legendSettings = {};
  public totExp = 124948;
  public palettes = [
    '#4D81E5',
    '#C65368',
    '#32B19A',
    '#E7854D',
    '#369F73',
    '#4773A7',
    '#E87D65',
    '#725E16',
    '#8268A5',
    '#0D3D86',
  ];
  public pieTooltipSetting: Object = {
    enable: true,
    format: '${point.x} : ${point.y}%',
  };
  data: any;
  public showMsg: boolean;
  public conversation: any;
  public chart = false;

  // pie end

  ngOnInit(): void {
    this._loader.show();
    this._commonService.setPageHeader('Numero Copilot');
    this._commonService.setsubPageHeader('');
    this.statedata = window.history.state;
    this._authServices.getJWTDetail().then((data) => {
      this.jwtToken = data;
      this.getResult(this.statedata);
    });
    this.tblHeader = [
      // 'Customers','Contact Name','Email Id','Outstanding','Invoices'
    ];
    this.tblData = [
      // {customer: 'Acme Co.', cNAme:'Albert Flores',outStanding:24829,invoice: 12, email:'albert@exa'},
      // {customer: 'Abstergo Ltd.', cNAme:'Ronald Richards',outStanding:35000,invoice: 8, email:'ronald@exa'}
    ];

    this.chartData = BANK_RES.data;
    this.legendSettings = { visible: true, position: 'Right' };
  }

  ontooltipRender = (args: IAccTooltipRenderEventArgs) => {
    args.text =
      args.point.x +
      '' +
      ' : ' +
      args.point.y +
      '' +
      ' (' +
      args.point.percentage +
      ' %)';
  };

  getResultForInputText(searchText) {
    this._loader.show();
    // this._loader.show();
    const qData = { prompt: searchText, prompt_key: '' };
    this.statedata = qData;
    this.getResult(qData);
    // this._loader.show();
    // if (this.inputText.includes('slack')) {
    //   this.showMsg = true;
    //   this.conversation = {
    //     sent: 'Send this list to #ar-updates channel and @anand on slack.',
    //     received:
    //       "I've successfully shared this with #ar-updates and notified @anand on Slack. Anything else you need assistance with?",
    //   };
    // } else if (this.inputText.includes('current balance')) {
    //   this.showMsg = true;
    //   this.conversation = {
    //     sent: 'What is the runway based on current balance',
    //     received:
    //       'Upon reviewing your expenditures over the last 12 months and factoring in your current cash balance, the estimated runway is approximately 7 months.',
    //   };
    // }
    // setTimeout(() => {
    //   this._loader.hide();
    // }, 1000);
    // this.inputText = '';
  }

  getResult(questionData) {
    let quesData: any = {
      entityId: this.jwtToken.org.id.toString(),
      question: questionData.prompt,
      prompt_key: questionData.prompt_key,
    };
    const sentQues = { ...quesData, type: 'sent' }
    this.chatHistory.push(sentQues);
    // if (questionType === 'PROMPT') {
    //   quesData.prompt_key = questionData.value;
    //   if (questionData.name.includes('Bank balance')) {
    //     this.promptRes = BANK_RES;
    //     this.chart = true;
    //     setTimeout(() => {
    //       this._loader.hide();
    //     }, 1000);
    //     return;
    //   }
    // } else if (questionType === 'QUES') {
    //   quesData.question = questionData.value;
    //   this.statedata = questionData;
    // }
    this._aiService.postQuestion(quesData).subscribe(
      (data) => {
        data?.data?.forEach(res => {
          for (const [key, value] of Object.entries(res)) {
            if (key == 'CUSTOMER_CREATED_AT' || key.toLowerCase().includes('date')) {
              console.log(`${key}: ${value}`);
              const date = new Date(value as any);
              res[key] = this._datePipe.transform(date)
            }
          }
        });
        data.type = 'received'
        this.chatHistory.push(data)
        this.promptRes = data;
        this.tblData = data.data || [];
        this.tblHeader = data.headers || [];
        // this.inputText = '';
        this._loader.hide();
        this.inputText = '';
      },
      (err) => {
        //   const dataa = {data: [], headers: [ ], staticResponse : 'Here is your top 10 customers for the last quarter'}
        //   this.promptRes = dataa;
        //   this.tblData = [ { "amount": "$20,760.00", "Invoices": 3, "Customer": "Aim Inc_17" },
        //   { "amount": "$8,720.00", "Invoices": 2, "Customer": "Docutrue_15" },
        //   { "amount": "$6,937.00", "Invoices": 2, "Customer": "Cloudwall_8" },
        //   { "amount": "$5,500.00", "Invoices": 2, "Customer": "Oppenheimer LLC_42" },
        //   { "amount": "$3,528.00", "Invoices": 3, "Customer": "Zendo_5" },
        //   { "amount": "$2,100.00", "Invoices": 1, "Customer": "Mahesh 1_54" },
        //   { "amount": "$2,068.00", "Invoices": 3, "Customer": "Amaio_14" },
        //   { "amount": "$2,000.00", "Invoices": 1, "Customer": "Drake Corp_53" },
        //   { "amount": "$1,453.00", "Invoices": 2, "Customer": "Supertokens2_37" },
        //   { "amount": "$994.00", "Invoices": 3, "Customer": "Raj Inc_44" },
        //   { "amount": "$877.00", "Invoices": 4, "Customer": "Armani Ventures_18" }];
        //   this.tblHeader = [
        //     {
        //       "fieldName": "Customer",
        //       "textAlign": "left",
        //       "displayName": "Customer",
        //       "width": "210",
        //       "minWidth": "350",
        //       "maxWidth": "210"
        //     },
        //   {
        //       "fieldName": "amount",
        //       "textAlign": "left",
        //       "displayName": "Amount",
        //       "width": "210",
        //       "minWidth": "350",
        //       "maxWidth": "210"
        //     },
        //     {
        //       "fieldName": "Invoices",
        //       "textAlign": "left",
        //       "displayName": "Invoices",
        //       "width": "210",
        //       "minWidth": "350",
        //       "maxWidth": "210"
        //     },
        // ];
        this._loader.hide();
        this.resetValues();
      }
    );
  }

  resetValues() {
    this.tblData = [];
    this.tblHeader = [];
    this.promptRes = {};
  }
}
