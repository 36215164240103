import { Pipe } from '@angular/core';

@Pipe({
  name: 'currencypipe',
})
export class CustomCurrencyPipe {
  transform(
    val: string | number,
    currency: any = 'USD',
    showSymbol: boolean = true,
    locale: string = undefined,
    decimal: number = 2
  ) {
    // const numConv = Number(val);
    const numConv = typeof val === 'string' ? parseFloat(val.replace(/,/g, '')) : Number(val);
  
    if(!Number.isNaN(numConv) && val != null && val !== undefined) {
    currency = currency?.trim().length !== 3 ? 'USD' : currency;
    const loc = currency === 'INR' ? 'en-IN' : 'en-US';
    let value = new Intl.NumberFormat((locale || loc), {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: decimal,
    }).format(Number(numConv));
    let firstDigit = value.match(/\d/);
    let symbol = value.slice(0, firstDigit.index);
    let amount = value.slice(firstDigit.index);
    return showSymbol ? symbol + ' ' +amount:  amount;
  }
    return val
  }

  currencyToSymbol(
    currency: any = 'USD',
    val: string | number = 0,
  ) {
    const loc = 'en-US';
    let value = new Intl.NumberFormat((loc), {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(Number(val));
    let firstDigit = value.match(/\d/);
    let symbol = value.slice(0, firstDigit.index);
    return symbol;
  }
}
