<div *ngIf="pdfSrc" class="pdf-viewer">
  <div #pdfContainer></div>
  <div
    class="yellow-rectangle"
    [style.top.px]="boundingBoxData.y1"
    [style.left.px]="boundingBoxData.x1"
    [style.width.px]="boundingBoxData.x2 - boundingBoxData.x1"
    [style.height.px]="boundingBoxData.y2 - boundingBoxData.y1"
  ></div>
</div>
