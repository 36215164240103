export const environment = {
  production: false,
  imagePrefixURI:
    'https://s3.amazonaws.com/dev.goconstant.com/assets/image',
  auth: {
    baseURL: 'https://dev.goconstant.com',
    gatewayURL: 'https://api-dev.goconstant.com',
  },
  server: {
    baseURL:
      'https://pu25v7t8o5.execute-api.us-east-2.amazonaws.com/goconstant-core',
    searchLambdaUrl:
      'https://l73oqkk225usk257h2zqcsaz3e0cihjg.lambda-url.us-east-1.on.aws',
  },
  growthBook: {
    clientKey: 'sdk-kASZoPtuPXpQUKv',
  },
  n8n: {
    baseURL: 'https://dev-workflow.goconstant.com',
  },
  pspdf: {
    key: 'FaHqJ2chToRkNeWfCTa-9M4NZUoEMbM6BfFCT8-GknQskcpEFF-fl_UPvwTODakDBXAQKcjs8TSbrr75rQASjhlRI-X5KdQQ5nPeYMpdbloeCxioEzPr_agKy911-orSuTxjKVw2z6aMsV8Vu34Q_ynvxvIevMispmqwtI8uK8Te5a9aV1Xr8odgpPexM8ID2C5fblkat6RARkwhampvXdoStKHMNQ8-7guWnBU_RNwPcMhNsY9HpcFCDGgSz42ORRHSz8gl5QtfiPD9RtcjRYhZFh3SlfQu9z9kIofmn29i7fDvbqNkZEeuEGrA84OxtPfbjgOIa1nZYwSBgd_4lXCBCFfyhcpbLqctUxMpNhN2Bk4QHQZkU4dk-nE4IJvoNSTK17uX9sMLlGPzCjLYYcl5gxLzgM1lUrJ8WAzDshdT_wpHGmo_3bAj7SyDiCOPiOkBZ2IT5sOWYOC4siHSbPTl7eqjBaZcgjljMEQQKzormCntg8wu5aIGNSddvMd_Z2bHLy2UTNPxwCou_nYiNqIIWlhVPCXyeuS6AZHXG1LtcOJeO3JkqWBbI0W6M55RGNBVx-_VRVjlvg_IDWklJw==',
  },
};
