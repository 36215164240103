import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { CommonService } from 'app/service/shared/common.service';
const featureRequest = 'd7fe2140-905b-983b-9562-fed19f8185f7';
const Feedback = '6e578c82-c698-3715-7d66-19ac435a20e4';

declare var Canny: any;
declare var hellonext: any;

@Component({
  selector: 'app-canny-widget',
  templateUrl: './canny-widget.component.html',
  styleUrls: ['./canny-widget.component.scss']
})
export class CannyWidgetComponent implements OnInit{

  ssoToken =""
  navUrl;
  boardToken = ""
    constructor(public _commonService : CommonService ,public sanitizer: DomSanitizer, private _loader: LoaderService) {}
  ngOnInit(): void {
    this._loader.show()
    setTimeout(() => {
      this._loader.hide()
    }, 3000);
    // hellonext("init", {
    //   appId: "8d7039c3fbd58f68a51c59cc106034dd",
    //   domain: "feedback.numero.app",
    //   user: {
    //     email: "ram@numero.app",
    //     name: "Ram",
    //     custom_fields: {},
    //   }
    // })

    // this._loader.hide()
    // setTimeout(() => {

    //   hellonext.init({
    //     embedURL: 'https://feedback.numero.app/embed/roadmap/b/feedback?single_board=true',
    //     ssoToken: 'eyJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6InJhbUBudW1lcm8uYXBwIiwibmFtZSI6IlJhbSJ9.BFK1xvH5gsfmurbcOAvaGO5YY3b-ziz6Wg7R93-mRo8',
    //     height: '100%',
    //     width: '100%',
    //     selector: 'suos' // Optional: Embed will be loaded in this selector
    //   });
    // }, 1);


    this._commonService.setPageHeader('');
    this._commonService.setHideAiIcon(false);
    // if(this._commonService.currentRoute === '/feedback') {
    //   this.boardToken = Feedback;
    // } else if(this._commonService.currentRoute === '/feature') {
    //   this.boardToken = featureRequest;

    // }
    this.getSSOTOken();

  }

  getSSOTOken() {
    this._commonService.getFeatureosJWT().subscribe((data: any)=>{
      // console.log('data',data)
      // const jwt = 'eyJhbGciOiJIUzI1NiJ9.eyJuYW1lIjoiVG9tbXkiLCJlbWFpbCI6IlRvbW15QGFpZGVtby5jb20iLCJjdXN0b21fZmllbGRzIjp7ImNvbXBhYW55IjoiTnVtZXJvIiwibG9jYXRpb24iOiJjaGVubmFpIn19.rRbBKhN-2Sknj7ez8R4rhzgE1DlRhOt0tGcxmoWWAqE'
      // const URL: any= `https://feedback.numero.app/embed/roadmap/b/feedback?no_header=true&single_board=true&sso_token=${data.jwt}`
      const URL: any= `https://feedback.numero.app/embed/home?no_header=true&single_board=true&sso_token=${data.jwt}`
// https://feedback.numero.app/embed/roadmap/b/feedback?single_board=true
      // Canny('render', {
      //   boardToken: this.boardToken,
      //   basePath: '/feedback', // See 'web' tab
      //   ssoToken: data.jwt, // See 'web' tab
      //   onLoadCallback: () => console.log(),
      //   theme: 'light', // options: light [default], dark, auto
      // });
      this.navUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL);
      // window.open(URL, '_blank');

      setTimeout(() => {
        this._loader.hide()
      }, 1000);
    }, err=>{
      this._loader.hide()
    })
  }
}
