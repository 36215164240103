import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  AccumulationChartComponent,
  AccumulationChart,
  AccumulationDataLabel,
  IAccLoadedEventArgs,
  AccumulationTheme,
  IPointRenderEventArgs,
  ILoadedEventArgs,
  ChartTheme,
  IAxisLabelRenderEventArgs,
} from '@syncfusion/ej2-angular-charts';
import {
  FilterType,
  GridComponent,
  SortService,
} from '@syncfusion/ej2-angular-grids';
import { Browser } from '@syncfusion/ej2-base';
import { ChangeEventArgs } from '@syncfusion/ej2-dropdowns';
import { JWTToken } from 'app/core/service/auth/auth';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { ExecDashboard } from 'app/service/workflow/workflow';
import { WorkflowService } from 'app/service/workflow/workflow.service';
import { ChartService } from 'app/service/shared/chart.service';
@Component({
  selector: 'app-execution-history',
  templateUrl: './execution-history.component.html',
  styleUrls: ['./execution-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [SortService],

  // encapsulation: ViewEncapsulation.None
})
export class ExecutionHistoryComponent {
  public executioDashboardRes: ExecDashboard;
  public totalExecutionCount =0;
  @Input() token : JWTToken;
  constructor(
    public workflowService: WorkflowService,
    public loader: LoaderService,
    private chartService: ChartService,
  ) {}

  public pieData: Object[] = [
    {
      x: 'Gmail Zohobooks Invoice Creation',
      y: 73,
      DataLabelMappingName: Browser.isDevice
        ? 'Gmail Zohobooks Invoice Creation: <br> 61.3%'
        : 'Gmail Zohobooks Invoice Creation: 61.3%',
    },
    {
      x: 'chargebee-subscription-plan-change',
      y: 77,
      DataLabelMappingName: Browser.isDevice
        ? 'chargebee-subscription-plan-change: <br> 24.6%'
        : 'chargebee-subscription-plan-change: 24.6%',
    },
    {
      x: 'chargebee-Payment-Alert',
      y: 89,
      DataLabelMappingName: 'chargebee-Payment-Alert: 5.0%',
    },
  ];
  public pointRender(args: IPointRenderEventArgs): void {
    this.SetTheme(args);
  }
  public SetTheme(args: IPointRenderEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    let seriesColor = [
      '#FFE066',
      '#FAB666',
      '#F68F6A',
      '#F3646A',
      '#CC555A',
      '#9C4649',
    ];
    if (selectedTheme === 'fluent' || selectedTheme === 'bootstrap5') {
      args.fill = seriesColor[args.point.index % 10];
    }
    if (selectedTheme.indexOf('dark') > -1) {
      if (selectedTheme.indexOf('material') > -1) {
        args.border.color = '#303030';
      } else if (selectedTheme.indexOf('bootstrap5') > -1) {
        args.border.color = '#212529';
      } else if (selectedTheme.indexOf('bootstrap') > -1) {
        args.border.color = '#1A1A1A';
      } else if (selectedTheme.indexOf('tailwind') > -1) {
        args.border.color = '#1F2937';
      } else if (selectedTheme.indexOf('fluent') > -1) {
        args.border.color = '#252423';
      } else if (selectedTheme.indexOf('fabric') > -1) {
        args.border.color = '#201f1f';
      } else {
        args.border.color = '#222222';
      }
    } else if (selectedTheme.indexOf('highcontrast') > -1) {
      args.border.color = '#000000';
    } else {
      args.border.color = '#FFFFFF';
    }
  }
  //Initializing Legend
  public legendSettings: Object = {
    visible: false,
  };
  //Initializing DataLabel
  public dataLabel: Object = {
    visible: true,
    name: 'DataLabelMappingName',
    position: 'Outside',
    font: {
      fontWeight: '600',
    },
    connectorStyle: {
      length: '20px',
      type: 'Curve',
    },
  };
  public centerLabel: Object = {
    text: 'Mobile<br>Browsers<br>Statistics',
    hoverTextFormat: '${point.x}<br>Browser Share<br>${point.y}%',
    textStyle: {
      fontWeight: '600',
      size: Browser.isDevice ? '7px' : '15px',
    },
  };
  public border: object = {
    width: 1,
    radius: 20,
  };
  // custom code start
  public pLoad(args: IAccLoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.accumulation.theme = <AccumulationTheme>(
      (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1))
        .replace(/-dark/i, 'Dark')
        .replace(/contrast/i, 'Contrast')
    );
  }
  // custom code end
  public radius: string = Browser.isDevice ? '40%' : '70%';
  public startAngle: number = Browser.isDevice ? 30 : 62;

  public tableData = []
  public barData: Object[] = [
    // { Year: 'Gmail', success: 55, failed: 33 },
    // { Year: 'chargebee', success: 64, failed: 35 },
    // { Year: 'Zoho', success: 55, failed: 12 },
    // { Year: 'Quickbooks', success: 33, failed: 30 },
    // { Year: 'Hubspot', success: 21, failed: 43 },
  ];

  public barAvgRunData: Object[] = [
    // { Year: 'Gmail', time: 9.5 },
    // { Year: 'chargebee', time: 11 },
    // { Year: 'Zoho', time: 8 },
    // { Year: 'Quickbooks', time: 14 },
    // { Year: 'Hubspot', time: 9 },
  ];

  //Initializing Primary X Axis
  public primaryXAxis: Object = {
    majorGridLines: { width: 0 },
    minorGridLines: { width: 0 },
    majorTickLines: { width: 0 },
    minorTickLines: { width: 0 },
    interval: 1,
    lineStyle: { width: 0 },
    // labelIntersectAction: 'Rotate45',
    valueType: 'Category',
    labelStyle: {
      fontFamily : 'Inter,sans-serif'
     }

  };
  //Initializing Primary Y Axis
  public primaryYAxis: Object = {
    title: '',
    lineStyle: { width: 0 },
    majorTickLines: { width: 0 },
    majorGridLines: { width: 0 },
    minorGridLines: { width: 0 },
    minorTickLines: { width: 0 },
    labelStyle: {
      fontFamily : 'Inter,sans-serif'
     }
  };
  public tooltip: Object = {
    enable: true,

  };
  // custom code start
  public load(args: ILoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.chart.theme = <ChartTheme>(
      (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(
        /-dark/i,
        'Dark'
      )
    );
  }
  OnChartAxisLabeRender(args: IAxisLabelRenderEventArgs) {
    this.chartService.formatAxisLabel(args);
  }
  // custom code end
  public title: string = '';
  public chartArea: Object = {
    border: {
      width: 0,
    },
  };
  public BchartArea: Object = {
    border: {
      width: 0,
    },
  };

  public legend: Object = {
    visible: false,
    enableHighlight: false,
  };
  cornerRadius: Object = {
    bottomLeft: 10,
    bottomRight: 10,
    topRight: 10,
    topLeft: 10,
  };

  cornerRadiusTop: Object = {
    topRight: 10,
    topLeft: 10,
  };

  cornerRadiusBottom: Object = {
    bottomLeft: 10,
    bottomRight: 10,
  };
  // public border: Object = { color: '#ffffff', width:1 };
  // public width: string = Browser.isDevice ? '100%' : '75%';
  public width: string = '100%' ;
  public ddldata: Object[];
  public pageSettings: Object;
  public filterSettings: Object;
  public filteringType: Object[] = [
    { Id: 'Menu', type: 'Menu' },
    { Id: 'CheckBox', type: 'Checkbox' },
    { Id: 'Excel', type: 'Excel' },
  ];
  palette = ['#499167', '#F9A03F', '#6DC190', '#C3DFE0'];
  public ddlfields: Object = { text: 'type', value: 'Id' };
  public formatoptions: Object;

  @ViewChild('grid')
  public grid: GridComponent;

  ngOnInit(): void {
    this.loader.show();
    setTimeout(() => {
      this.loader.hide();
    }, 3000);
    this.pageSettings = { pageCount: 5 };
    this.filterSettings = { type: 'Menu' };
    this.ddldata = this.filteringType;
    this.formatoptions = { type: 'dateTime', format: 'M/d/y hh:mm a' };
    this.fetchExexutionDashboard();
  }
  public onChange(e: ChangeEventArgs): void {
    this.grid.filterSettings.type = <FilterType>e.value;
    this.grid.clearFiltering();
  }

  fetchExexutionDashboard() {
    this.workflowService.getExecutionDashboardData(this.token.org.id).subscribe((data) => {
      this.executioDashboardRes = data;
      this.tableData  = this.executioDashboardRes.tableData.map(data=>{
        data['Start Time'] = data['Start Time'].replace('[GMT]', '')
        data['End Time'] = data['Start Time'].replace('[GMT]', '')
        data['Created At'] = data['Start Time'].replace('[GMT]', '')

        return data
      });
      let exData = []
      this.executioDashboardRes.executionByStatus.forEach((element:any) => {
          if(element?.success || element?.failed) {
            exData.push(element)
          }

    });
    this.barData = exData
      this.barAvgRunData = this.executioDashboardRes.avgSuccessfulRunTime.map((element:any) => {
          element.seconds = parseFloat(element.seconds)
          return element
      });
      let sum = 0;
      this.executioDashboardRes.executionCountByWorkflows.forEach((element: any) => {
        sum = sum + element.count
      });
      this.totalExecutionCount = sum

    });
  }
}
