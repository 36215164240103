import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import vendorData from 'assets/config/vendor.json';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  constructor(private http: HttpClient) {}

  getVendor = (entityId: string): Observable<any> => {
    // return this.http
    // .get(`${environment.server.baseURL}/ar-dashboard/workbench/customer?entityId=${entityId}`)
    // .pipe(catchError(handleError));
    return of(vendorData);
  };

  getVendorDetail = (entityId: string, vendorId: string): Observable<any> => {
    // return this.http
    //   .get(
    //     `${environment.server.baseURL}/vendor?vendor_id=${vendorId}&entity_key=${entityId}`
    //   )
    //   .pipe(catchError(handleError));
    return of(vendorData);
  };
}
