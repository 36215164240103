import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkflowHomeComponent } from './workflow-home/workflow-home.component';
import { CoreModule } from '../core/core.module';
import { WorkflowPageComponent } from './workflow-page/workflow-page.component';
import { WorkflowRoutingModule } from './workflow-routing.module';
import { SharedModule } from 'app/shared/shared.module';
import { WorkflowEditorComponent } from './workflow-editor/workflow-editor.component';
import { ExecutionHistoryComponent } from './execution-history/execution-history.component';



@NgModule({
  declarations: [
    WorkflowHomeComponent,
    WorkflowPageComponent,
    WorkflowEditorComponent,
    ExecutionHistoryComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    WorkflowRoutingModule
  ]
})
export class WorkflowModule { }
