import {NgModule} from '@angular/core';



import { DialogAllModule, DialogModule } from "@syncfusion/ej2-angular-popups";

import { AccumulationChartAllModule, AccumulationChartModule, RangeNavigatorAllModule } from "@syncfusion/ej2-angular-charts";

import {
  ChartAllModule,
} from "@syncfusion/ej2-angular-charts";

import {
  DiagramAllModule,
  SymbolPaletteAllModule,
  OverviewAllModule,
} from "@syncfusion/ej2-angular-diagrams";
import { GridAllModule } from "@syncfusion/ej2-angular-grids";
import { ListViewAllModule } from "@syncfusion/ej2-angular-lists";
import { DatePickerModule, DateRangePickerModule } from "@syncfusion/ej2-angular-calendars";
import { CircularGaugeModule } from "@syncfusion/ej2-angular-circulargauge";
import { DropDownListAllModule, DropDownListModule, MentionModule } from "@syncfusion/ej2-angular-dropdowns";
import { MultiSelectModule } from "@syncfusion/ej2-angular-dropdowns";
import { ToolbarModule } from "@syncfusion/ej2-angular-navigations";
import {
  NumericTextBoxModule,
  ColorPickerModule,
  UploaderModule,
  TextBoxModule,
} from "@syncfusion/ej2-angular-inputs";
import { DropDownButtonModule } from "@syncfusion/ej2-angular-splitbuttons";
import {
  ButtonModule,
  CheckBoxModule,
  RadioButtonModule,
} from "@syncfusion/ej2-angular-buttons";
import { TreeGridAllModule } from '@syncfusion/ej2-angular-treegrid';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';

@NgModule({
  exports: [
    DiagramAllModule,
    ChartAllModule,
    GridAllModule,
    TreeGridAllModule,
    SymbolPaletteAllModule,
    OverviewAllModule,
    ButtonModule,
    ColorPickerModule,
    DateRangePickerModule,
    CheckBoxModule,
    AccumulationChartModule,
    ToolbarModule,
    DropDownButtonModule,
    UploaderModule,
    CircularGaugeModule,
    DropDownListAllModule,
    DropDownListModule,
    ListViewAllModule,
    DialogAllModule,
    TextBoxModule,
    RadioButtonModule,
    MultiSelectModule,
    NumericTextBoxModule,
    AccumulationChartAllModule,
    ChartAllModule,
    RangeNavigatorAllModule,
    DatePickerModule,
    DialogModule,
    RichTextEditorAllModule,
    MentionModule
  ]
})

export class SyncfusionModule { }
