<div  [style.height.px]="chartHeight">
<ejs-chart
  style="display: block;height: inherit !important;width: 100%;"
  [chartArea]="chartArea"
  align="center"
  #chart
  [primaryXAxis]="primaryXAxis"
  [primaryYAxis]="primaryYAxis"
  [palettes]="palette"
  [title]="title"
  [tooltip]="tooltip"
  (load)="load($event)"
  (axisLabelRender)="OnChartAxisLabeRender($event)"
  [legendSettings]="legend"
>

  <e-series-collection>
    <e-series
      [dataSource]="metricData.chart.data"
      [cornerRadius]="cornerRadius"
      type="StackingColumn"
      xName="category"
      yName="value"
      [pointColorMapping]='pointColorMapping'
      [name]="metricData.name"
      [marker]='marker'
      columnWidth="0.5"
      [border]="border"
    >
    </e-series>
  </e-series-collection>
</ejs-chart>
</div>
