import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { from, Observable, of, throwError } from 'rxjs';
import {
  concatMap,
  delay,
  retry,
  retryWhen,
  switchMap,
  tap,
} from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { AuthServices } from '../auth/auth.service';

export const retryCount = 3;
export const retryWaitMilliSeconds = 1000;
@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor {
  constructor(public authServices: AuthServices) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    // this.authServices.isAuthenticated();
    const jwtACC = this.authServices.getJWTFromCookie();
    // var base64Url = jwtACC.split('.')[0];
    // var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    // var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    //     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    // }).join(''));
    // const JWT = JSON.parse(jsonPayload).up.jwt
    if(!req.url.startsWith(environment.n8n.baseURL)){
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ` + jwtACC, //jwt
        },
      });
    }
    return next.handle(req);
  }
}
