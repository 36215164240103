import { AfterViewInit, Component, ViewChild, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JWTToken } from 'app/core/service/auth/auth';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { FlowChartModel } from '../flowchart';
import { FlowchartService } from '../flowchart.service';
import {MatPaginator, MatPaginatorIntl} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { ConnectionDetail } from 'app/service/connectorconfig/connector';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { CommonService } from 'app/service/shared/common.service';
import { CustomPaginator } from 'app/shared/paginator/custom-paginator';

export interface PeriodicElementPF {
  key: string;
  active: boolean;
  name: string;
  category: string;
  tags: string;
  owner: string;
  createdAt: string;
  updatedAt: string;
  updatedBy: string;
  edges: any;
  nodes: any;
  vaersion: number
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-flowchart-list',
  templateUrl: './flowchart-list.component.html',
  styleUrls: ['./flowchart-list.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ]
})
export class FlowchartListComponent  implements OnInit {
  public processFlowList : FlowChartModel[];
  public filteredProcessFlowList : FlowChartModel[];
  public jwtToken: JWTToken = undefined;
  public deleteConfText: string;
  public showAlert: boolean = true;
  public widgetData: any = {};
  public env=  environment;

  @Input() deleteFlow : any = {};

  displayedColumnsPF: string[] = ['name', 'category', 'tags', 'owner', 'createdAt', 'updatedAt', 'actions'];
  public dataSourcePF;
  public flowKey= undefined;

  constructor(
    public modalService: NgbModal,
    public loader: LoaderService,
    public flowChartService: FlowchartService,
    public authServices: AuthServices,
    public router : Router,
    public commonService: CommonService
  ) {}

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  ngOnInit(): void {
    this.widgetData = {
      widgetImage: `https://s3.amazonaws.com/dev-workflow.numero.app/assets/image/bg-image/Processflow-sample.svg`,
      widgetClassname: 'dashboard-widget-add-ds',
      widgetHeading: "",
      widgetDesc: "Let's create your first process flow.",
      widgetBtnText: 'Create New Flow',
      widgetBtnNavigation: '',
      widgetBgImageUrl: `https://s3.amazonaws.com/live.dataflo.io/assets/image/dashboard-bg.png`,
    };
    this.loader.hide();
    this.fetchFlowcharts();
    this.commonService.setPageHeader("Process Flow");
    this.commonService.setHideAiIcon(true);
  }


  childButtonClicked(event) {
    console.log('parebt click', event)
    this.createFlowChart()
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourcePF.filter = filterValue.trim().toLowerCase();

    if (this.dataSourcePF.paginator) {
      this.dataSourcePF.paginator.firstPage();
    }
  }

  launchIframe(flowchartKey) {
    this.router.navigate(['process-model', flowchartKey ])
  }

  async fetchFlowcharts ()  {
    this.loader.show();
    this.jwtToken = await this.authServices.getJWTDetail();
    this.flowChartService.getFlowcharts(this.jwtToken.org.id).subscribe((data : FlowChartModel[]) => {

      if(data.length > 0){
        data.forEach(d=>{
          let tag = (d && d.tags && d.tags.length >= 0) ?   d.tags.split(',') : [];
          d.tags = [...new Set(tag)]
        })
      }
      this.processFlowList = data;
      this.filteredProcessFlowList = data;

      this.dataSourcePF = new MatTableDataSource<FlowChartModel>(this.filteredProcessFlowList);
      // this.dataSourcePF.paginator = this.paginator;
      setTimeout(() => (this.dataSourcePF.paginator = this.paginator));
      this.loader.hide();
    }, err=>{
      this.loader.hide();
    });
  };


  async createFlowChart ()  {
    this.loader.show();
    const obj = {
      name: 'Title',
      nodes: '[{"shape":{"type":"Flow"},"ports":[{"id":"port1","shape":"Circle","offset":{"x":0},"visibility":4,"constraints":28},{"id":"port2","shape":"Circle","offset":{"y":1},"visibility":4,"constraints":28},{"id":"port3","shape":"Circle","offset":{"x":1},"visibility":4,"constraints":28},{"id":"port4","shape":"Circle","offset":{"y":0},"visibility":4,"constraints":28}],"id":"TerminatormvbKM","style":{"fill":"transparent","strokeWidth":2,"strokeColor":"#1C1F2C"},"offsetX":-162,"offsetY":-111.5,"minHeight":40,"minWidth":40,"annotations":[{"id":"hFwmI","content":"Start","style":{"bold":true,"textWrapping":"Wrap"}},{"id":"JsnPh","width":30,"height":30,"offset":{"x":0,"y":0},"template":"<div></div>"}],"width":100,"height":50,"zIndex":728}]',
      edges: JSON.stringify([]),
      active: true,
      login_user: this.jwtToken.userEmail,
      entity_key: this.jwtToken.org.id,
    };
    console.log('obj',obj)
    this.flowChartService.postFlowchart(obj).subscribe((data) => {
    this.flowKey =data;
    this.fetchFlowcharts();
      console.log('data, data', data);
      this.launchIframe(data);
    }, err=>{
      this.loader.hide();
    }
    );
  };


  confirmDeleteFlow(flow) {
    this.deleteFlow = flow;
    this.deleteConfText = '';
    this.showAlert = true
  }

  validateConfirmationMessage = () => {
    if(this.deleteConfText && this.deleteConfText === this.deleteFlow.name) {
      this.showAlert = false;
    } else {
      this.showAlert = true;
    }
  }

  removeFlow() {
    if (this.deleteConfText === this.deleteFlow.name) {

      this.loader.show()
      this.flowChartService.deleteFlowcharts(this.jwtToken.org.id , this.deleteFlow.key).subscribe(data=>{
        console.log('delete',data)
        this.fetchFlowcharts()
      }, err=>{
        this.loader.hide();
      })
    } else {
      console.log("cannot delete")
    }
  }

  searchFlows = async (event) => {
    if (this.jwtToken === undefined) {
      this.jwtToken = await this.authServices.getJWTDetail();
    }
    const val = event.target.value;
    if(event.keyCode === 13){
      if(val && val !== '') {
        this.flowChartService.searchFlowchart(val, this.jwtToken.org.id).subscribe((data) => {
          console.log(data)
          this.filteredProcessFlowList = data;
        });
      } else {
        this.filteredProcessFlowList = this.processFlowList;
      }
    }
  }



}
