import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportingRoutingModule } from './reporting-routing.module';
import { ReportingHomeComponent } from './reporting-home/reporting-home.component';
import { ProfitAndLossComponent } from './profit-and-loss/profit-and-loss.component';
import { CoreModule } from 'app/core/core.module';
import { SharedModule } from 'app/shared/shared.module';
import { ExpenseReportComponent } from './expense-report/expense-report.component';
import { BalanceSheetComponent } from './balance-sheet/balance-sheet.component';
import { CashFlowComponent } from './cash-flow/cash-flow.component';
import { IntercompanyEliminationComponent } from './intercompany-elimination/intercompany-elimination.component';


@NgModule({
  declarations: [
    ReportingHomeComponent,
    ProfitAndLossComponent,
    ExpenseReportComponent,
    BalanceSheetComponent,
    CashFlowComponent,
    IntercompanyEliminationComponent
  ],
  imports: [
    CommonModule,
    ReportingRoutingModule,
    CoreModule,
    SharedModule
  ]
})
export class ReportingModule { }
