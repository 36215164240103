<div class="div-numero-container div-main-content">
  <div class="full-container">
    <div class="row">
      <div class="col-md-9 nc-content">
        <div class="nc-chat-div">
          <router-outlet></router-outlet>
        </div>
  <p class="copyright-text">Numero's Copilot may display inaccurate info,so double-check its responses.</p>

      </div>
      <div class="col-md-3 nc-sidebar">
          <div class="nc-sidebar-div">
            <div class="div-new-btn" routerLink="/ai" *ngIf="_commonService.currentRoute==='/ai/chat'">
              <button class="btn btn-numero-outline">New Chat</button>
            </div>
            <div class="div-copilot-tabs">
              <!-- <button type="button" class="btn btn-cop-tab " >
                  <span>Update</span>
              </button> -->
              <button type="button" class="btn btn-cop-tab active" >
                  <span>Recent</span>
              </button>
              <button type="button" class="btn btn-cop-tab" >
                  <span>Saved</span>
              </button>
          </div>
            <div>
              <div class="container">

                <div class="row history-div">
                    <div class="col-1 p-0 history-div-icon"><i class="fa-light fa-message" style="color: #a5a5a5;"></i></div>
                    <div class="col-9 history-div-text"> <p class="history-div-text-title mb-0 word-wrap">Top Outstanding customers list customers list</p><p class="history-div-text-sub mb-0 word-wrap">List of top 5 outstanding custo..</p></div>
                    <div class="col-2 p-0 history-div-time"></div>

                </div>
              </div>
            </div>
            <!-- <ngb-accordion [closeOthers]="true" class="copilot-accordion nu-accordion " #acc="ngbAccordion">
              <ngb-panel  id="cp-1"  title="Saved Prompts">
                <ng-template ngbPanelTitle>
                </ng-template>
               <!-- <ng-template ngbPanelContent>
                <div class="container">

                  <div class="row history-div">
                      <div class="col-1 p-0 history-div-icon"><i class="fa-light fa-message" style="color: #a5a5a5;"></i></div>
                      <div class="col-9 history-div-text"> <p class="history-div-text-title mb-0 word-wrap">Top Outstanding customers list customers list</p><p class="history-div-text-sub mb-0 word-wrap">List of top 5 outstanding custo..</p></div>
                      <div class="col-2 p-0 history-div-time">15 m</div>

                  </div>
                </div>
                </ng-template> ->
              </ngb-panel>
            </ngb-accordion>
            <ngb-accordion [closeOthers]="true" class="copilot-accordion nu-accordion " #acc="ngbAccordion">
              <ngb-panel  id="cp-2"  title="Recent">
                <ng-template ngbPanelTitle>
                </ng-template>
               <ng-template ngbPanelContent>
                <div class="container">

                  <div class="row history-div">
                      <div class="col-1 p-0 history-div-icon"><i class="fa-light fa-message" style="color: #a5a5a5;"></i></div>
                      <div class="col-9 history-div-text"> <p class="history-div-text-title mb-0 word-wrap">Top Outstanding customers list customers list</p><p class="history-div-text-sub mb-0 word-wrap">List of top 5 outstanding custo..</p></div>
                      <div class="col-2 p-0 history-div-time">15 m</div>

                  </div>
                </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion> -->
          </div>

      </div>

    </div>
  </div>
</div>

