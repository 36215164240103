import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-common-popup-alert',
  templateUrl: './common-popup-alert.component.html',
  styleUrls: ['./common-popup-alert.component.scss']
})
export class CommonPopupAlertComponent {
  
  public messageText ='';
  public btnConfirmText ='Confirm';
  public btnCancelText ='Cancel';
  public btnDeleteText ='DELETE';
  public btnDisconnectText = 'DISCONNECT';
  public btnChangeText = 'CHANGE';

  public showDeleteButton :boolean = false;
  public showConfirmButton :boolean = false;
  public showDeleteConnection :boolean = false;
  public showChangeButton : boolean= false;
  constructor(
    public activeModal: NgbActiveModal
  ) {}
}
