import { Component } from '@angular/core';
import { JWTToken } from 'app/core/service/auth/auth';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { DashboardService } from 'app/service/dashboard/dashboard.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { environment } from 'environments/environment';
@Component({
  selector: 'app-ap-dashboard-demo',
  templateUrl: './ap-action-dashboard.component.html',
  styleUrls: ['./ap-action-dashboard.component.scss']
})
export class ApDashboardDemoComponent {
  arRes: any;
  arResap: any;
  public acPayable;
  public aging;
  public topVendor;
  public environment = environment;
  constructor(
    public _authServices: AuthServices,
    private _dashboardService: DashboardService,
    private _loader: LoaderService) { }
  async ngOnInit() {
    this._loader.show();
    this.jwtToken = await this._authServices.getJWTDetail();
    this.fetchApDashboard();
    this.fetchActionApDashboard();
  }
  jwtToken: JWTToken = undefined;
  async fetchActionApDashboard() {
    this._dashboardService.getActionApdashboard(this.jwtToken.org.id).subscribe(data => {
      this.arRes = data || {};
      setTimeout(() => {
        this._loader.hide();
      }, 2000);
    }, err => {
      this._loader.hide();
    })
  }
  async fetchApDashboard() {
    this._dashboardService.getV2Apdashboard(this.jwtToken.org.id).subscribe(data => {
      this.arResap = data?.data || [];
      this.acPayable = this.arResap.cleared_bills[0];
      this.aging = this.arResap.ap_aging[0];
      this.topVendor = this.arResap.top_outstanding_vendors[0].data;
      setTimeout(() => {
        this._loader.hide();
      }, 2000);
    }, err => {
      this._loader.hide();
    })
  }
}
