import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[inputRegex]'
})
export class InputRegexDirective {

  constructor(private _el: ElementRef) {}
  @Input() inputRegex = 'numberOnly';
  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    if(this.inputRegex === 'numberOnly') {
      this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    } else if (this.inputRegex === 'noSpecialChar') {
      this._el.nativeElement.value = initalValue.replace(/[^0-9a-zA-Z_]*/g, '');
    }
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }

}
