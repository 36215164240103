import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VendorManagementRoutingModule } from './vendor-management-routing.module';
import { VendorComponent } from './vendor/vendor.component';
import { CoreModule } from 'app/core/core.module';
import { SharedModule } from 'app/shared/shared.module';


@NgModule({
  declarations: [
    VendorComponent
  ],
  imports: [
    CommonModule,
    VendorManagementRoutingModule,    
    CoreModule,
    SharedModule
  ]
})
export class VendorManagementModule { }
