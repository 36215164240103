import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { FormService } from 'app/service/shared/form.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  // signupForm: FormGroup;
  // signinForm: FormGroup;
  isConfirmPassword = false
  confirmPasswordForm: FormGroup;
  public environment = environment;
  public show = false;

  constructor(public authServices: AuthServices,
    private _loader: LoaderService,
     private formBuilder: FormBuilder, private router: Router, private toaster: ToastrService) {
  }
  ngOnInit(): void {
    const jwt = this.authServices.getJWTFromCookie();
    if(jwt) {
      this.router.navigate(['/dashboard']);
      return;
    }

    // this.confirmPasswordForm = this.formBuilder.group({
    //   password: ['', Validators.required],
    //   confirmPassword: ['', Validators.required],
    // });

    this.confirmPasswordForm = this.formBuilder.group({

      password: [
        null,
        Validators.compose([
          Validators.required,
          FormService.patternValidator(/\d/, {
            hasNumber: true
          }),
          FormService.patternValidator(/[A-Z]/, {
            hasCapitalCase: true
          }),
          FormService.patternValidator(/[a-z]/, {
            hasSmallCase: true
          }),
          FormService.patternValidator(
            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            {
              hasSpecialCharacters: true
            }
          ),
          Validators.minLength(8)
        ])
      ],
      confirmPassword: [null, Validators.compose([Validators.required])]
    },
    {
      // check whether our password and confirm password match
      validator: FormService.passwordMatchValidator
   });
  }

  confirmPassword(): void {
    this._loader.show()
    let formData = this.confirmPasswordForm.value;
    if (!this.confirmPasswordForm.valid) {
      this.toaster.error(this.validateForm(formData), 'ERROR', {
        timeOut: 3000,
      });
      return;
    }

    this.authServices.newPasswordEntered(formData.password);
  }


  validateForm(formData): string {
    let errorMsg = 'Bad credentials';
      if (!formData.email || formData.email === '') {
        errorMsg = 'Email id cannot be empty';
      } else if (!formData.password || formData.password === '') {
        errorMsg = 'Password cannot be empty';
      }
      return errorMsg;
  }


  showSignIn(): void {
    this.isConfirmPassword = true;
  }

  get confirmPass(): any {
    return this.confirmPasswordForm.controls;
  }
}
