import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JWTToken } from 'app/core/service/auth/auth';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { GrowthbookService } from 'app/core/service/growthBook/growthbook.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { DashboardService } from 'app/service/dashboard/dashboard.service';
import { CommonService } from 'app/service/shared/common.service';
import { environment } from 'environments/environment';
import { WorkbenchService } from 'app/service/workbench/workbench.service';
@Component({
  selector: 'app-ar-dashboard',
  templateUrl: './ar-dashboard.component.html',
  styleUrls: ['./ar-dashboard.component.scss'],
})
export class ARDashboardComponent implements OnInit {
  automation: any;
  constructor(
    public authServices: AuthServices,
    private _loader: LoaderService,
    private _dashboardService: DashboardService,
    public commonService: CommonService,
    public _router: Router,
    public _growthBookServ: GrowthbookService,
    public workbenchService: WorkbenchService
  ) {}

  public isDemo = undefined;
  public currentDate = new Date();
  public selectedAccount = 'asondate';
  public noBills: boolean = false;
  public environment = environment;

  public accounts = [{ name: 'As on Date', value: 'asondate' }];
  ngOnInit(): void {
    this._loader.show();
    this.commonService.setPageHeader('Accounts Receivable ');
    this.getGrowthBook();
    setTimeout(async () => {
      this.jwtToken = await this.authServices.getJWTDetail();
      this.getAutomation();
    }, 1000);
  }

  public arRes: any = undefined;
  jwtToken: JWTToken = undefined;

  async fetchArDashboard() {
    this.jwtToken = await this.authServices.getJWTDetail();
    this._dashboardService.getARdashboard(this.jwtToken.org.id).subscribe(
      (data) => {
        this.arRes = data?.data?.groups || {};
        if (Object.keys(this.arRes).length === 0) {
          this.noBills = true;
          this._loader.hide();
        }
        setTimeout(() => {
          this._loader.hide();
        }, 2000);
      },
      (err) => {
        this._loader.hide();
      }
    );
  }
  async getAutomation(): Promise<void> {
    this.workbenchService
      .getAutomation(this.jwtToken.org.id)
      .subscribe((automation) => {
        this.automation = automation;
        if (
          this.automation.ar_enabled === true &&
          this.automation.workflow === true
        ) {
          this.fetchArDashboardV2();
        } else {
          this.fetchArDashboard();
        }
      });
  }
  async fetchArDashboardV2() {
    this.jwtToken = await this.authServices.getJWTDetail();
    this.workbenchService
      .getWorkbenchArDashboardv2(
        this.jwtToken.org.id,
        this.automation.workflow_sources[0].account_identifier
      )
      .subscribe(
        (data) => {
          this.arRes = data?.data?.groups || {};
          if (Object.keys(this.arRes).length === 0) {
            this.noBills = true;
            this._loader.hide();
          }
          setTimeout(() => {
            this._loader.hide();
          }, 2000);
        },
        (err) => {
          this._loader.hide();
        }
      );
  }

  async getGrowthBook(): Promise<any> {
    this.isDemo = this._growthBookServ.getGBookStatus('ap_dashboard_demo');
  }
}
