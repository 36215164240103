import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { CheckBoxComponent } from '@syncfusion/ej2-angular-buttons';
import {
  RemovingEventArgs,
  UploaderComponent,
} from '@syncfusion/ej2-angular-inputs';
import { EmitType, detach } from '@syncfusion/ej2-base';
import { JWTToken } from 'app/core/service/auth/auth';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { BulkUploadService } from 'app/service/bulk-upload/bulk-upload.service';
import { CommonService } from 'app/service/shared/common.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-multi-file-upload',
  templateUrl: './multi-file-upload.component.html',
  styleUrls: ['./multi-file-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MultiFileUploadComponent implements AfterViewInit, OnInit {
  constructor(
    public authServices: AuthServices,
    private _bulkUploadService: BulkUploadService,
    public _router: Router,
    public commonService: CommonService,
    private _loader: LoaderService
  ) {}
  @ViewChild('defaultupload')
  public uploadObj: UploaderComponent;
  jwtToken: JWTToken = undefined;
  public widgetData: any = {};
  public bulkUploadRes: any = undefined;
  public showHideWidget = undefined;
  public environment = environment;

  public selectedSource = 'select';
  public selectedAccount = 'select';
  public selectedFunction = 'select';
  public selectedTimerange: any;

  public timeRange = [];
  public ddIndex = 0;

  public path: Object = {
    saveUrl: `${environment.server.baseURL}/file-upload`,
    removeUrl: '',
  };
  public allowExtensions: string = '.pdf, .png, .jpeg, .jpg, .bmp, .gif';
  async ngOnInit() {
    this.createTimeRange();
    this.commonService.setHideAiIcon(true);
    this._loader.show();
    this.widgetData = {
      widgetImage: `https://s3.amazonaws.com/dev-workflow.numero.app/assets/image/bg-image/Bulk-upload-dample.svg`,
      widgetClassname: 'dashboard-widget-add-ds',
      widgetHeading: 'Import and manage your files',
      widgetDesc:
        'Upload files to manage bulk operations in your accounting system',
      widgetBtnText: 'Connect',
      widgetBtnNavigation: '',
      widgetBgImageUrl: `https://s3.amazonaws.com/live.dataflo.io/assets/image/dashboard-bg.png`,
    };
    if (this.jwtToken === undefined) {
      this.jwtToken = await this.authServices.getJWTDetail();
      // this.path = {
      //   saveUrl: `${environment.server.baseURL}/?entity_key=${this.jwtToken.org.id}&identifier=${this.selectedAccount}&function=${this.selectedFunction}&source=source${this.selectedSource}`,
      //   removeUrl: '',
      // };
      this.fetchBulkUpload();
    };
    this.commonService.setPageHeader("Bulk Upload");
  }

  ngAfterViewInit() {
    this.uploadObj.autoUpload = false;
    this.uploadObj.sequentialUpload = true;
    this.uploadObj.dropArea = document.getElementById('dropTarget');
    document.getElementById('dropArea').onclick = (e: any) => {
      let target: HTMLElement = <HTMLElement>e.target;
      if (target.classList.contains('e-file-delete-btn')) {
        for (let i: number = 0; i < this.uploadObj.getFilesData().length; i++) {
          if (
            target.parentElement.getAttribute('data-file-name') ===
            this.uploadObj.getFilesData()[i].name
          ) {
            this.uploadObj.remove(this.uploadObj.getFilesData()[i]);
          }
        }
      } else if (target.classList.contains('file-remove-i')) {
        detach(target.parentElement);
        for (let i: number = 0; i < this.uploadObj.getFilesData().length; i++) {
          if (
            target.parentElement.getAttribute('data-file-name') ===
            this.uploadObj.getFilesData()[i].name
          ) {
            this.uploadObj.remove(this.uploadObj.getFilesData()[i]);
          }
        }
        if (this.uploadObj.getFilesData().length === 0) {
          this.uploadObj.clearAll();
        }
      }
    };
  }

  fetchBulkUpload() {
    this._bulkUploadService.getBulkUploadConfig(this.jwtToken.org.id).subscribe(
      (data) => {
        this.bulkUploadRes = data.fileUploadOptionArr;
        if (Object.keys(data).length === 0) {
          this.showHideWidget = true;
        } else {
          this.showHideWidget = false;
        }
        this._loader.hide();
      },
      (err) => {
        this._loader.hide();
      }
    );
  }

  createTimeRange() {
    const cDate = new Date();
    const cMonth = cDate.getMonth();
    const cYear = cDate.getFullYear();
    const cDay = cDate.getDate();

    let t1: any;
    let t2: any;

    if (cMonth < 4) {
      t1 = {
        start_date: this.getDate(cYear - 1, 3, 1),
        end_date: this.getDate(cYear, 2, 31),
        name: 't1a',
      }; //

      t2 = {
        start_date: this.getDate(cYear - 2, 3, 1),
        end_date: this.getDate(cYear - 1, 2, 31),
        name: 't2a',
      };
    } else {
      t1 = {
        start_date: this.getDate(cYear, 3, 1),
        end_date: this.getDate(cYear+1, 2, 31),
        name: 't1b',
      }; //
      t2 = {
        start_date: this.getDate(cYear - 1, 3, 1),
        end_date: this.getDate(cYear, 2, 31),
        name: 't2b',
      };
    }

    let f2 = {
      start_date: this.getDate(cYear, 0, 1),
      end_date: this.getDate(cYear, 11, 31),
      name: 'f2',
    }; //
    let f1 = {
      start_date: this.getDate(cYear - 1, 0, 1),
      end_date: this.getDate(cYear - 1, 11, 31),
      name: 'f1',
    }; //

    this.timeRange.push(f2);
    this.timeRange.push(f1);
    this.timeRange.push(t1);
    this.timeRange.push(t2);

    this.selectedTimerange = this.timeRange[0];
  }
  getDate(year, month, date) {
    return new Date(year, month, date);
  }

  formatDate(date: Date) {
    let day: any = date.getDate();
    day = day < 10 ? '0' + day : day;
    let month: any = date.getMonth() + 1;
    month = month < 10 ? '0' + month : month;

    return `${date.getFullYear() + '-' + month + '-' + day}`;
  }

  updateDropDown() {
    this.bulkUploadRes.forEach((element, i) => {
      if (element.key === this.selectedSource) {
        this.ddIndex = i;
      }
    });
    const start = this.formatDate(this.selectedTimerange.start_date);
    const end = this.formatDate(this.selectedTimerange.end_date);
    this.path = {
      saveUrl: `${environment.server.baseURL}/file-upload?entity_key=${this.jwtToken.org.id}&identifier=${this.selectedAccount}&function=${this.selectedFunction}&source=${this.selectedSource}&start_date=${start}&end_date=${end}`,
      removeUrl: '',
    };
  }

  browseClick() {
    document
      .getElementsByClassName('e-file-select-wrap')[0]
      .querySelector('button')
      .click();
    return false;
  }

  childButtonClicked(event) {
    this._router.navigate(['connectors']);
  }

  public onSelect: EmitType<Object> = (args: any) => {
    let allowedTypes: string[] = ['pdf', 'png', 'txt'];
    let modifiedFiles: object[] = [];
    for (let file of args.filesData) {
      if (allowedTypes.indexOf(file.type.toLowerCase()) > -1) {
        modifiedFiles.push(file);
      }
    }
    if (modifiedFiles.length > 0) {
      args.isModified = true;
      args.modifiedFilesData = modifiedFiles;
    } else {
      args.cancel = true;
    }
  };

  public onUploadSuccess: EmitType<Object> = (args: any) => {
    let li: HTMLElement = this.getLiElement(args);
    li.querySelector('.upload-status').innerHTML = args.file.status;
    li.querySelector('.upload-status').classList.add('upload-success');
  };

  public onUploadFailed: EmitType<Object> = (args: any) => {
    let li: HTMLElement = this.getLiElement(args);
    li.querySelector('.upload-status').innerHTML = args.file.status;
    li.querySelector('.upload-status').classList.add('upload-failed');
  };
  public onUploadInProgress: EmitType<Object> = (args: any) => {
    let progressValue: string =
      Math.round((args.e.loaded / args.e.total) * 100) + '%';
    let li: HTMLElement = this.getLiElement(args);
    li.querySelector('.upload-status').innerHTML =
      args.file.status + '(' + progressValue + ' )';
  };
  public getLiElement(args: any) {
    let liElements: NodeListOf<HTMLElement> = document
      .getElementsByClassName('e-upload')[0]
      .querySelectorAll('.e-upload-files > li');
    let li: HTMLElement;
    for (let i: number = 0; i < liElements.length; i++) {
      if (liElements[i].getAttribute('data-file-name') === args.file.name) {
        li = liElements[i];
      }
    }
    return li;
  }

  public onFileRemove(args: RemovingEventArgs): void {
    args.postRawFile = false;
  }
}
