import { SelectionModel } from '@angular/cdk/collections';
import { Component, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { JWTToken } from 'app/core/service/auth/auth';
import { AuthServices } from 'app/core/service/auth/auth.service';
import { LoaderService } from 'app/core/service/loader/loader.service';
import { CommonService } from 'app/service/shared/common.service';
import { VendorService } from 'app/service/vendor/vendor.service';
import { CustomPaginator } from 'app/shared/paginator/custom-paginator';
import { environment } from 'environments/environment';

export interface vendorint {
  bills: string,
  status: string;
  billAmount: string;
}

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ]
})
export class VendorComponent {

  displayedColumnsExAll: string[];
  displayedColumnsEx: string[];
  public dataSourceEx;
  public selection = new SelectionModel<any>(true, []);
  public vendorData: any;
  public dsHeaderEx;
  noBills:boolean = false;
  isButtonEnable:boolean = true;
  showinvoice:boolean = false;
  public vhead;
  public dhead;
  public environment = environment;

  constructor(
    public _loader : LoaderService,
    public _commonService: CommonService,
    public _authServices: AuthServices,
    public _vendorService: VendorService
  ){
    this.checkboxchange();
  }

  async ngOnInit() {
    this._loader.show();
    this._commonService.setPageHeader("Invoice");
    this._commonService.setsubPageHeader("");
    setTimeout(async () => {
      this.jwtToken = await this._authServices.getJWTDetail();
          this.getVendorData();
    }, 1000);
  }

  jwtToken: JWTToken = undefined;
  
  refreshpage() {
    this.showrefresh();
    this.getVendorData();
  }

  showrefresh() {
    const refreshicon = document.getElementById('refresh-icon');
    refreshicon.classList.add('fa-spin');
    this.selection.clear();
  }

  hiderefresh() {
    const refreshicon = document.getElementById('refresh-icon');
    setTimeout(() => {
      if(refreshicon != null) {
        refreshicon.classList.remove('fa-spin');
      }
    }, 1000);
  }

  async getVendorData(): Promise<void> {
    this._vendorService.getVendor(this.jwtToken.org.id).subscribe((vendor) => {
      this.vendorData = vendor || {};
      console.log("this.vendorData : ", this.vendorData);
      if(Object.keys(this.vendorData).length === 0) {
        this.noBills = true;
        this._loader.hide();
        this.hiderefresh();
      }
      else {
        this.dataSourceEx = new MatTableDataSource<any>(this.vendorData.data);
        this.displayedColumnsExAll = ['select'];
        this.vendorData?.header.forEach(element => {
          this.displayedColumnsExAll.push(element.fieldName)
        });
        const itemTobeRemoved = ['currencyId','entityId','companyId'];
        this.displayedColumnsEx = this.removeItems(this.displayedColumnsExAll, itemTobeRemoved);
        this.dsHeaderEx = this.vendorData.header;
        this.vhead = [...Object.keys(this.vendorData.data[0])];
        this.dhead = this.removeItems(this.vhead, itemTobeRemoved);
        this.dataSourceEx.paginator = this.paginator;
        this._loader.hide();
        this.hiderefresh();
      }
    });
  }

  showDrilldown(element) {
    this._loader.show();
    this._vendorService
      .getVendorDetail(this.jwtToken.org.id, element.vendor_id)
      .subscribe(
        (data) => {
          //this.selectedDrilldown = data;
          this._loader.hide();
          this.showinvoice = true;       
      },
      (err) => {
        this._loader.hide();
      }
    );
  }

  removeItems = (objKeys, itemToRemitemTobeRemovedove) => {
    return objKeys.filter((v) => {
      return !itemToRemitemTobeRemovedove.includes(v);
    });
  };

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceEx.data.length;    
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSourceEx.data);
  }

  checkboxLabel(row?: vendorint): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.bills + 1}`;
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit(): void {
    this.dataSourceEx.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceEx.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceEx.paginator) {
      this.dataSourceEx.paginator.firstPage();
    }
  }

  checkboxchange() {
    this.selection.changed.subscribe(item=>{
      this.isButtonEnable = this.selection.selected.length == 0;
    })
  }

  pdfDownload() {
   
  }

  excelDownload() {
    
  }

  closeDrilldown(){
    this.showinvoice = false;
  }
}