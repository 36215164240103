import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FullDashboardRoutingModule } from './full-dashboard-routing.module';
import { FullDashboardComponent } from './component/full-dashboard/full-dashboard.component';
import { CoreModule } from '../core/core.module';
import { WorkflowModule } from 'app/workflow/workflow.module';
import { ProcessFlowchartModule } from 'app/process-flowchart/process-flowchart.module';
import { DashboardModule } from 'app/dashboard/dashboard.module';
import { ConnectorsModule } from 'app/connectors/connectors.module';
import { ReportingModule } from 'app/reporting/reporting.module';
import { ReconciliationModule } from 'app/reconciliation/reconciliation.module';
import { BulkUploadModule } from 'app/bulk-upload/bulk-upload.module';
import { AiModule } from 'app/ai/ai.module';



@NgModule({
  declarations: [
    FullDashboardComponent
  ],
  imports: [
    CommonModule,
    FullDashboardRoutingModule,
    CoreModule,
    WorkflowModule,
    ProcessFlowchartModule,
    DashboardModule,
    ConnectorsModule,
    ReportingModule,
    ReconciliationModule,
    AiModule,
    BulkUploadModule
  ]
})
export class FullDashboardModule { }
