import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'app/core/core.module';
import { DashboardRoutingModule } from './dashboards-routing.module';
import { SharedModule } from 'app/shared/shared.module';
import { DashboardHomeComponent } from './dashboard-home/dashboard-home.component';
import { ARDashboardComponent } from './ar-dashboard/ar-dashboard.component';
import { ApDashboardComponent } from './ap-dashboard/ap-dashboard.component';
import { ApDashboardDemoComponent } from './action-dashboard/ap-action-dashboard/ap-action-dashboard-component.component';
import { ArDashboardDemoComponent } from './action-dashboard/ar-action-dashboard/ar-action-dashboard.component';



@NgModule({
  declarations: [
    DashboardHomeComponent,
    ARDashboardComponent,
    ApDashboardComponent,
    // ApDashboardDemoComponent,
    // ArDashboardDemoComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    CoreModule,
    SharedModule
  ]
})
export class DashboardModule { }
