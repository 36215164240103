<div class="container div-ai-result">
  <!-- <p class="cursor-pointer" routerLink="/ai">
    <i class="far fa-chevron-left"></i> Back to Home
  </p> -->
  <div class="div-ai-home-content">
    <div class="result">
      <ng-container *ngFor="let chat of chatHistory">


      <div class="row result-sent" *ngIf="chat.type === 'sent'">
        <div class="col-12 result-sent-div">
          <div class="ava-div me-2">
            <ngx-avatars
              [name]="'numero'"
              initialsSize="2"
              size="32"
              [cornerRadius]="75"
            ></ngx-avatars>
          </div>
          <div class="content-div container">
            <div class="head">
              <p class="mb-0">You<span class="time d-none"> . 15m</span></p>
            </div>
            <div class="ques-main">
              {{chat?.question }}
            </div>
          </div>
        </div>
      </div>

      <div class="row result-received d-none">
        <div class="col-12 result-received-div">
          <button class="robot-icon me-2">
            <i class="fa-kit fa-ai-robot"></i>
          </button>
          <div class="content-div">
            <div class="head">
              <p class="mb-0">AI Copilot<span class="time d-none"> . 15m</span></p>
            </div>
            <div class="ques">
              Please select the time frame you are interested in top outstanding
              customer with bill details.
            </div>
            <div class="time-span">
              <button class="btn btn-numero me-2">Last 30 days</button>
              <button class="btn btn-numero me-2">Last quarter</button>
              <button class="btn btn-numero me-2">Last year</button>
              <button class="btn btn-numero me-2">Custom date range</button>
            </div>
          </div>
        </div>
      </div>

      <div class="row result-sent d-none">
        <div class="col-12 result-sent-div">
          <div class="ava-div me-2">
            <ngx-avatars
              [name]="'numero'"
              initialsSize="2"
              size="32"
              [cornerRadius]="75"
            ></ngx-avatars>
          </div>
          <div class="content-div">
            <div class="head">
              <p class="mb-0">You<span class="time d-none"> . 15m</span></p>
            </div>
            <div class="ques-main">Last Quarter</div>
          </div>
        </div>
      </div>

      <div class="row result-received final " *ngIf="chat.type === 'received'">
        <!-- <div class="div-out-btns">
          <div>
            <button type="button"><i class="fak fa-numero-download"></i></button>
            </div>
            <div>
              <button type="button">
                <img src="/assets/image/temp/share.svg" alt="image" />
              </button>
            </div>
            <div>
              <button type="button" class="btn-slack">
                <img src="/assets/image/temp/slack.svg" alt="image" />
              </button>
            </div>
          </div> -->
        <div class="col-12 result-received-div">
          <!-- <button class="robot-icon me-2">
            <i class="fa-kit fa-ai-robot"></i>
          </button> -->

          <img
          class="me-2"
          src="{{ environment.imagePrefixURI }}/ai/copilot/copilot.svg"
        />
          <div class="content-div container">
            <div class="head">
              <p class="mb-0">AI Copilot<span class="time d-none"> . 15m</span></p>
            </div>
            <div class="ques">
              {{chat?.staticResponse}}
            </div>

            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
              <li [ngbNavItem]="1">
                <a ngbNavLink>Table</a>
                <ng-template ngbNavContent>
                  <div class="div-tbl-list" *ngIf="!chart">
                    <table>
                      <thead>
                        <tr>
                          <th
                            class="text-center"
                            *ngFor="let head of chat?.headers"
                          >
                            {{ head.displayName }}
                          </th>
                        </tr>
                      </thead>
                      <tbody *ngIf="chat.data?.length=== 0">
                          <tr>
                            <th class="text-center" [attr.colspan]="chat?.headers?.length">No data Available </th></tr>
                      </tbody>
                      <tbody *ngIf="chat?.data?.length > 0">
                        <tr *ngFor="let row of chat?.data">
                          <td  class="text-center" *ngFor="let col of chat?.headers">{{ row[col.fieldName] }}</td>

                          <!-- <td class="text-end">
                            {{ row.outStanding | currencypipe : row.Currency }}
                          </td> -->
                          <td class="text-end">{{ row.invoice }}</td>
                        </tr>

                        <!-- <tr  *ngFor="let row of tblData">
                            <td class="text-left">{{ row.Inv_No }}</td>
                            <td class="text-start">{{ row.Customer_Name }}</td>
                            <td class="text-left">{{ row.Invoice_Amount | currencypipe: row.Currency}}</td>
                            <td class="text-left">{{ row.Currency }}</td>
                            <td class="text-left">{{ row.Outstanding_Amount  | currencypipe: row.Currency}}</td>
                             <ng-container *ngFor="let cell of tblHeader">
                              <td *ngIf="cell.fieldName === 'Customer_Name'" class="text-left">{{ row[cell.fieldName] }}</td>

                            </ng-container>
                          </tr> -->
                      </tbody>
                    </table>
                  </div>
                </ng-template>
              </li>
              <!-- <li [ngbNavItem]="2">
                <a ngbNavLink>Graph</a>
                <ng-template ngbNavContent>
                  <div [style.height.px]="400">
                    <ejs-accumulationchart
                      [legendSettings]="legendSettings"
                      id="chart-container"
                      (tooltipRender)="ontooltipRender($event)"
                      [tooltip]="pieTooltipSetting"
                    >
                      <e-accumulation-series-collection>
                        <e-accumulation-series
                          [dataSource]="chartData"
                          xName="name"
                          yName="y"
                          legendShape="Circle"
                          [palettes]="palettes"
                        ></e-accumulation-series>
                      </e-accumulation-series-collection>
                    </ejs-accumulationchart>
                  </div>
                </ng-template>
              </li> -->
            </ul>

            <div [ngbNavOutlet]="nav" class="mt-2"></div>
          </div>
        </div>
      </div>

      <div class="row follow-up d-none">
        <p class="title">Follow-up questions</p>
        <div class="follow-up-ques">
          <div class="item" *ngFor="let follow of followUp">{{ follow }}</div>
        </div>
      </div>
    </ng-container>
    </div>

    <!-- <div class="row">
      <div class="col-md-1 text-center">
        <img src="/assets/image/temp/ai-con-new.svg" alt="image" />
      </div>
      <div class="col-md-10 div-ques-overdue">
        <p>
         {{statedata?.name}}
        </p>
        <span
          >{{promptRes?.staticResponse}}</span
        >
        <!-- <img src="/assets/image/temp/flag.svg" alt="image" /> ->
      </div>
    </div> -->
    <!-- <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10 ">
        <div class="div-tbl-list" *ngIf="!chart">
        <table>
          <thead>
            <tr>
              <!-- <th class="text-center" *ngFor="let head of tblHeader">{{head.displayName}}</th> ->
              <th class="text-left">INV NO</th>
              <th class="text-start">CUSTOMER NAME</th>
              <th class="text-left">INVOICE AMOUNT</th>
              <th class="text-left">CURRENCY</th>
              <th class="text-left">OUTSTANDING AMOUNT</th>
            </tr>
          </thead>
          <tbody>
              <tr  *ngFor="let row of tblData">
                <td class="text-left">{{ row.Inv_No }}</td>
                <td class="text-start">{{ row.Customer_Name }}</td>
                <td class="text-left">{{ row.Invoice_Amount | currencypipe: row.Currency}}</td>
                <td class="text-left">{{ row.Currency }}</td>
                <td class="text-left">{{ row.Outstanding_Amount  | currencypipe: row.Currency}}</td>
                <!-- <ng-container *ngFor="let cell of tblHeader">
                  <td *ngIf="cell.fieldName === 'Customer_Name'" class="text-left">{{ row[cell.fieldName] }}</td>

                </ng-container> ->
              </tr>
          </tbody>
        </table>
      </div>
      <div  [style.height.px]="400" *ngIf="chart">
        <ejs-accumulationchart [legendSettings]="legendSettings" id="chart-container" (tooltipRender)="ontooltipRender($event)" [tooltip]="pieTooltipSetting">
            <e-accumulation-series-collection>
              <e-accumulation-series
                [dataSource]="chartData"
                xName="name"
                yName="y"
                legendShape="Circle"
                [palettes]="palettes"
              ></e-accumulation-series>
            </e-accumulation-series-collection>
          </ejs-accumulationchart>
        </div>

      <div class="chat-conv-div " *ngIf="showMsg">
        <div class="sent">
          <p class="text">{{this.conversation.sent}}</p>
        </div>
        <div class="received">
          <p class="text">{{this.conversation.received}}</p>
        </div>
      </div>
      </div>
      <div class="col-md-1 div-out-btns">
        <div>
          <button type="button"><i class="fak fa-numero-download"></i></button>
        </div>
        <div>
          <button type="button">
            <img src="/assets/image/temp/share.svg" alt="image" />
          </button>
        </div>
        <div>
          <button type="button" class="btn-slack">
            <img src="/assets/image/temp/slack.svg" alt="image" />
          </button>
        </div>
      </div>
    </div> -->
    <div class="row">
      <!-- <div class="col-md-1"></div> -->
      <div class="col-md-12  div-ans">
        <div class="div-ans-input">
          <input
            class="form-control input-ai-ans"
            placeholder="Enter your question here"
            type="text"
            [(ngModel)]="inputText"
            (keyup.enter)="getResultForInputText(inputText)"
          />
          <button
            [disabled]="!inputText"
            (click)="getResultForInputText(inputText)"
          >
            <i class="fak fa-numero-tele fa-lg mt-2"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
