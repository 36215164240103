<!-- <div class="control-section">
  <div align='center'>
      <ejs-chart style='display:block' align='center' id='chartcontainer' [title]='title' [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxis'
          [tooltip]='tooltip' (load)='load($event)' [chartArea]='chartArea' [width]='width' [legendSettings]='legend'>
          <e-series-collection>
              <e-series [dataSource]='data' type='Line' xName='Period' yName='Viet_Growth' name='Vietnam' width=2 [marker]='circleMarker' opacity=1> </e-series>
              <e-series [dataSource]='data' type='Line' xName='Period' yName='Can_Growth' name='Canada' width=2 [marker]='triangleMarker' opacity=1> </e-series>
              <e-series [dataSource]='data' type='Line' xName='Period' yName='Mal_Growth' name='Malaysia' width=2 [marker]='diamondMarker' opacity=1> </e-series>
              <e-series [dataSource]='data' type='Line' xName='Period' yName='Egy_Growth' name='Egypt' width=2 [marker]='rectangleMarker' opacity=1> </e-series>
              <e-series [dataSource]='data' type='Line' xName='Period' yName='Ind_Growth' name='Indonesia' width=2 [marker]='pentagonMarker' opacity=1> </e-series>
          </e-series-collection>
      </ejs-chart>
  </div>
</div> -->

<div class="div-ex-dashboard">
  <!-- <div class="row">
    <div class="col-md-12 div-head">
      <p class="primary-text">Account Payable Dashboard</p>
    </div>
  </div> -->
  <!-- <div class="row">
    <div class="col-md-3" *ngFor="let metric of metrics">
      <div class="card metric">
        <div class="card-body">
          <h5 class="card-title metric-header primary-text">
            {{ metric.name }}
          </h5>
          <p class="card-text metric-text primary-text mb-0 mt-3">
            {{ metric.value }}
          </p>
          <p class="card-text metric-sub-text mb-0 mt-2 secondary-text">
            {{ metric.transaction }}
          </p>
        </div>
      </div>
    </div>
  </div> -->
  <div class="row mt-3">
    <div class="col-md-4">
      <div class="card metric-chart number">
        <h4 class="mb-0 ms-3 mt-3">Active workflows </h4>
        <div class="row data">
          <h3 class="text-center ">
            {{executioDashboardRes?.active_workflows}}
          </h3>
        </div>
        </div>
        <div class="card metric-chart number mt-3">
          <h4 class="mb-0 ms-3 mt-3">Total executions </h4>
          <div class="row data">
            <h3 class="text-center">
              {{totalExecutionCount}}
            </h3>
          </div>
      </div>

    </div>
    <div class="col-md-4">
      <div class="card metric-chart">
        <h4 class="mb-4 ms-3 mt-3">Workflow execution by status  </h4>

        <ejs-chart
          style="display: block"
          [chartArea]="chartArea"
          [width]="width"
          height="406px"
          align="center"
          id="chartcontainer"
          [primaryXAxis]="primaryXAxis"
          [primaryYAxis]="primaryYAxis"
          [palettes]='palette'
          [title]="title"
          [tooltip]="tooltip"
          (load)="load($event)"
          (axisLabelRender)="OnChartAxisLabeRender($event)"
          [legendSettings]="legend"
        >
          <e-series-collection>
            <e-series
              [dataSource]="barData"
              [cornerRadius]="cornerRadius"
              type="StackingColumn"
              xName="name"
              yName="success"
              name="Success"

              columnWidth="0.5"
              [border]="border"
            >
            </e-series>
            <e-series
              [dataSource]="barData"
              [cornerRadius]="cornerRadius"
              type="StackingColumn"
              xName="name"
              yName="failed"
              name="Failed"

              columnWidth="0.5"
              [border]="border"
            >
            </e-series>
          </e-series-collection>
        </ejs-chart>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card metric-chart">
        <h4 class="mb-4 ms-3 mt-3">Average run time </h4>
        <div class="control-section">
          <ejs-chart
          style="display: block"
          [chartArea]="BchartArea"
          [palettes]='palette'
          [width]="width"
          height="406px"
          align="center"
          id="chartcontainer1"
          [primaryXAxis]="primaryXAxis"
          [primaryYAxis]="primaryYAxis"
          [title]="title"
          [tooltip]="tooltip"
          (load)="load($event)"
          (axisLabelRender)="OnChartAxisLabeRender($event)"
          [legendSettings]="legend"
        >
          <e-series-collection>
            <e-series
              [dataSource]="barAvgRunData"
              [cornerRadius]="cornerRadius"
              type="StackingColumn"
              xName="workflowName"
              yName="seconds"
              name="Run Time"
              columnWidth="0.5"
              [border]="border"
            >
            </e-series>

          </e-series-collection>
        </ejs-chart>
          <!-- <ejs-accumulationchart
            id="container"
            #pie
            style="display: block; width: 92%"
            (pointRender)="pointRender($event)"
            [legendSettings]="legendSettings"
            (load)="pLoad($event)"
            [enableBorderOnMouseMove]="false"
          >
            <e-accumulation-series-collection>
              <e-accumulation-series
                name="Project"
                [dataSource]="pieData"
                xName="x"
                yName="y"
                [border]="border"
                [startAngle]="startAngle"
                innerRadius="65%"
                [radius]="radius"
                [dataLabel]="dataLabel"
              >
              </e-accumulation-series>
            </e-accumulation-series-collection>
          </ejs-accumulationchart> -->
        </div>
      </div>
    </div>

  </div>
  <div class="row">
    <div class="col-md-12">
      <app-grid *ngIf="tableData.length >0" [gridData]="tableData"></app-grid>
    </div>
  </div>
</div>
