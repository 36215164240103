import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardHomeComponent } from './dashboard-home/dashboard-home.component';
import { ARDashboardComponent } from './ar-dashboard/ar-dashboard.component';
import { ApDashboardComponent } from './ap-dashboard/ap-dashboard.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardHomeComponent,
    children: [
      {
        path: 'ar',
        component : ARDashboardComponent
      },
      {
        path: 'ap',
        component : ApDashboardComponent
      },
      {
        path: '',
        redirectTo: 'ap',
        pathMatch: 'full'
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
